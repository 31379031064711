import { getUserLabel } from "@relatable/helpers/user";
import { Button } from "@relatable/ui/Button";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { type FC, useState } from "react";
import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { useMeQuery } from "hooks/generated";

import { ForwardToInbox, Refresh } from "@mui/icons-material";
import { MessageTags } from "./MessageTags";
import {
  type MessageContentQuery,
  useMandrillPreviewLazyQuery,
  useSendMessagePreviewMutation
} from "./generated";

export const ContentPreview: FC<{
  campaignUsers: MessageContentQuery["campaigns"][number]["campaign_users"];
  templateName: string;
  subject: string;
  body: string;
  footer: string;
}> = ({ campaignUsers, templateName, subject, body, footer }) => {
  const {
    data: { me } = {}
  } = useMeQuery();
  const snackbar = useSnackbar();

  const sortedPreviewUsers = campaignUsers
    ?.map(cu => ({
      label: `@${getUserLabel(cu.user)} (${cu.state ?? "not_set"})`,
      value: cu.id
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const { campaignStub } = useParams<{ campaignStub: string }>();
  const [selectedUser, setSelectedUser] = useState(sortedPreviewUsers[0]?.value || null);

  const [previewHtml, setPreviewHtml] = useState("");

  const [makePreview, { loading: previewLoading }] = useMandrillPreviewLazyQuery({
    fetchPolicy: "no-cache"
  });

  const [sendPreview] = useSendMessagePreviewMutation({
    onCompleted() {
      snackbar.success("The preview was sent to you");
    }
  });

  if (!campaignStub) return <Loader />;

  const previewQuery = {
    campaignStub,

    main: body,
    footer,
    templateName,
    subject
  };

  const handleSendPreview = () => {
    const email = me?.admin?.email;
    if (!email) {
      snackbar.error("Email is missing");
      return;
    }
    if (!selectedUser) {
      snackbar.error("A user has to be selected");
      return;
    }
    sendPreview({
      variables: {
        email: email ?? "",
        query: { ...previewQuery, campaignUserId: selectedUser }
      }
    });
  };

  const handleGeneratePreview = async () => {
    if (!selectedUser) {
      snackbar.error("A user has to be selected");
      return;
    }
    const { data } = await makePreview({
      variables: {
        query: { ...previewQuery, campaignUserId: selectedUser }
      }
    });
    setPreviewHtml(data?.mandrillPreview?.html ?? "");
  };

  return (
    <div>
      <h3>Message preview</h3>
      <br />

      <Select
        label="Preview user data"
        options={sortedPreviewUsers}
        value={selectedUser}
        hideNone
        onChange={cu => setSelectedUser(cu ?? 0)}
        style={{ width: "100%", marginBottom: 20 }}
      />

      <br />

      <MessageTags campaignUserId={selectedUser} />
      <Button icon={<ForwardToInbox />} onClick={handleSendPreview} style={{ marginLeft: 10 }}>
        Send preview to my email
      </Button>

      <br />

      <div style={{ marginTop: 20 }}>Preview: </div>
      <div style={{ position: "relative" }}>
        <Button
          icon={<Refresh />}
          onClick={handleGeneratePreview}
          style={{ position: "absolute", top: 20, right: 20 }}
        >
          Update preview
        </Button>

        <iframe
          title="Preview"
          srcDoc={previewLoading ? "Loading..." : previewHtml}
          style={{ width: "100%", height: 700, border: "1px solid gray", margin: "8px 0" }}
        />
      </div>
    </div>
  );
};
