import { type CSSProperties, type ReactNode, useEffect, useState } from "react";

import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Table as MUITable,
  Paper,
  Switch,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import { useParams } from "react-router-dom";

import { useStoredState } from "@relatable/ui/hooks/useStoredState";

import { Download, ViewColumn } from "@mui/icons-material";
import { LinearLoader } from "./LinearLoader";
import { palette } from "./Palette";
import { downloadCSV } from "./TableCSVExport";

const StyledMUITable = styled(MUITable)`
  > tbody .MuiTableRow-root {
    background-color: ${palette.gray.white};

    &:nth-of-type(odd) {
      background-color: ${palette.gray[10]};
    }

    &:hover {
      background-color: ${palette.gray[20]};
    }
  }

  .MuiTableCell-head.sticky:nth-of-type(1) {
    background-color: ${palette.gray.white};
    z-index: 101;
  }

  .MuiTableCell-root.sticky {
    position: sticky;
    left: 0px;
    background-color: inherit;
    z-index: 100;
  }
`;

export type TableRow = { key: string | number; [key: string]: any };

// This helper is only needed due to TS logic that keyof Record<string, any> is not string but string | number | symbol
type StringKeysOnly<T> = Extract<keyof T, string>;

export type TableColumn<Row extends TableRow[]> = {
  [K in StringKeysOnly<Row[number]>]: {
    field: K;
    headerName?: string;
    hide?: boolean;
    minWidth?: number;
    maxWidth?: number;
    sortable?: boolean;
    useRenderCellForExport?: boolean;
    renderCell?: (params: {
      value: Row[number][K];
      row: Row[number];
    }) => ReactNode | string | number | null;
    sortComparator?: (a: Row[number][K], b: Row[number][K]) => number;
  };
}[StringKeysOnly<Row[number]>];

export type AddFields<Row extends TableRow, T extends string> = Row & Record<T, never>;

const defaultRenderCell = ({ value }: { value: any; row: TableRow }) => {
  return value;
};

function defaultSortComparator<T>(a: T, b: T) {
  if (typeof a === "string" || typeof b === "string") {
    return ((a || "") as string).localeCompare((b || "") as string);
  }
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

type SortDirection = "asc" | "desc";

const getSortedRows = <T extends TableRow>({
  rows,
  field,
  direction,
  customSortComparator
}: {
  rows: T[];
  field: string | null;
  direction: SortDirection;
  customSortComparator?: (a: any, b: any) => number;
}) => {
  if (field === null) return rows;
  const comparator = customSortComparator
    ? (a: any, b: any) => customSortComparator(a[field], b[field])
    : (a: any, b: any) => defaultSortComparator(a[field], b[field]);

  return [...rows].sort(direction === "desc" ? comparator : (a, b) => -comparator(a, b));
};

export const Table = <Row extends TableRow>({
  rows,
  columns,
  onRowClick,
  sortable = false,
  canSelectColumns = false,
  canExportCSV = false,
  noDataText,
  loading = false,
  tableId,
  onSortChange,
  minWidth,
  stickyColumn,
  containerStyles
}: {
  tableId: string;
  rows: Row[];
  columns: TableColumn<Row[]>[];
  sortable?: boolean;
  canSelectColumns?: boolean;
  canExportCSV?: boolean;
  onRowClick?: (row: Row, index: number) => void;
  noDataText?: string;
  loading?: boolean;
  onSortChange?: (field: keyof Row | null, direction?: "asc" | "desc") => void;
  minWidth?: number;
  stickyColumn?: boolean;
  containerStyles?: CSSProperties;
}): ReactNode => {
  const { campaignStub } = useParams<{ campaignStub?: string }>();

  const [sortField, setSortField] = useStoredState<string | null>(`${tableId}-sort-field`, null);

  const [sortDirection, setSortDirection] = useStoredState<SortDirection>(
    `${tableId}-sort-direciton`,
    "asc"
  );

  const [ignoredFields, setIgnoredFields] = useStoredState<string[]>(
    `${tableId}-ignored-fields`,
    []
  );

  const [columnsDialogOpen, setColumnsDialogOpen] = useState(false);

  // remove invalid sort options
  useEffect(() => {
    const col = columns.find(c => c.field === sortField);
    if (!col || col.sortable === false) {
      setSortField(null);
    }
  }, [columns, sortField, setSortField]);

  useEffect(() => {
    onSortChange?.(sortField, sortDirection);
  }, [sortField, sortDirection, onSortChange]);

  const sortColumn = columns.find(c => c.field === sortField);

  if (new Set(rows.map(r => r.key)).size < rows.length) {
    const message = "Error: Cannot render table! Each row must have an unique `key` field!";
    console.error(
      new Error(message),
      rows.map(r => r.key)
    );
    return <span>{message}</span>;
  }

  const sortedRows = sortable
    ? getSortedRows({
        rows,
        field: sortField,
        direction: sortDirection,
        customSortComparator: sortField ? sortColumn?.sortComparator : undefined
      })
    : rows;

  const handleRequestSort = (field: string) => {
    if (sortField !== field) {
      setSortDirection("asc");
      setSortField(field);
    } else if (sortDirection === "asc") {
      setSortDirection("desc");
    } else {
      setSortField(null);
    }
  };

  const onChangeIgnoredFields = (field: string, value: boolean) => {
    if (value) {
      setIgnoredFields(prev => Array.from(new Set([...prev, field])));
    } else {
      setIgnoredFields(prev => [...prev].filter(v => v !== field));
    }
  };

  const renderColumnSelectionDialog = () => {
    return (
      <Dialog onClose={() => setColumnsDialogOpen(false)} open={columnsDialogOpen}>
        <DialogTitle>Select columns</DialogTitle>
        <FormGroup>
          <List sx={{ pt: 0 }}>
            {columns
              .filter(c => !c.hide && c.headerName)
              .map((column, columnIndex) => (
                <ListItem sx={{ px: 2 }} disablePadding key={`${columnIndex}.${column.field}`}>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={!ignoredFields.includes(column.field)}
                        onChange={e => onChangeIgnoredFields(column.field, !e.target.checked)}
                      />
                    }
                    label={column.headerName ?? column.field}
                  />
                </ListItem>
              ))}
          </List>
        </FormGroup>
      </Dialog>
    );
  };

  const renderRow = (row: Row, rowIndex: number) => {
    return (
      <TableRow
        key={row.key}
        onClick={onRowClick ? () => onRowClick(row, rowIndex) : undefined}
        style={onRowClick ? { cursor: "pointer" } : {}}
      >
        {columns
          .filter(c => !c.hide && !ignoredFields.includes(c.field))
          .map((column, columnIndex) => (
            // key prop is critical here for correct behaviour with custom renderCell() fns
            <TableCell
              key={`[${row.key}].${columnIndex}.${column.field}`}
              className={stickyColumn && columnIndex === 0 ? "sticky" : undefined}
              style={
                column.maxWidth ? { maxWidth: column.maxWidth, wordWrap: "break-word" } : undefined
              }
            >
              {(column.renderCell ?? defaultRenderCell)({ value: row[column.field], row })}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper} style={{ ...containerStyles }}>
      {renderColumnSelectionDialog()}
      <LinearLoader visible={loading} />
      <StyledMUITable stickyHeader sx={{ minWidth: minWidth ?? 650 }} size="small">
        <TableHead>
          {(canSelectColumns || canExportCSV) && (
            <TableRow>
              <TableCell
                colSpan={columns.filter(c => !c.hide && !ignoredFields.includes(c.field)).length}
              >
                <div style={{ position: "sticky", left: 16, maxWidth: "calc(100vw - 150px)" }}>
                  {canSelectColumns && (
                    <Button
                      variant="text"
                      onClick={() => setColumnsDialogOpen(true)}
                      style={{ marginRight: 10 }}
                    >
                      <ViewColumn style={{ marginRight: 5 }} />
                      Select columns
                    </Button>
                  )}
                  {canExportCSV && (
                    <Button
                      startIcon={<Download />}
                      variant="text"
                      onClick={() =>
                        downloadCSV({
                          filename: `${campaignStub ?? document.title}-${new Date().toISOString()}`,
                          rows,
                          columns
                        })
                      }
                    >
                      Export CSV
                    </Button>
                  )}
                </div>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            {columns
              .filter(c => !c.hide && !ignoredFields.includes(c.field))
              .map((column, columnIndex) => (
                <TableCell
                  key={`${columnIndex}.${column.field}`}
                  className={stickyColumn && columnIndex === 0 ? "sticky" : undefined}
                  style={{
                    textAlign: "center",
                    ...(column.minWidth ? { minWidth: column.minWidth } : undefined),
                    ...(column.maxWidth ? { maxWidth: column.maxWidth } : undefined)
                  }}
                  sortDirection={sortable && sortField === column.field ? sortDirection : false}
                >
                  {sortable && column.sortable !== false ? (
                    <TableSortLabel
                      active={sortField === column.field}
                      direction={sortField === column.field ? sortDirection : "asc"}
                      onClick={() => handleRequestSort(column.field)}
                      style={{ userSelect: "auto" }}
                    >
                      {column.headerName ?? column.field}
                    </TableSortLabel>
                  ) : (
                    (column.headerName ?? column.field)
                  )}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && Boolean(noDataText) && sortedRows.length === 0 ? (
            <TableRow>
              <TableCell colSpan={columns.length}>{noDataText}</TableCell>
            </TableRow>
          ) : (
            sortedRows.map(renderRow)
          )}
        </TableBody>
      </StyledMUITable>
    </TableContainer>
  );
};
