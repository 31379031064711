import { forwardRef, useImperativeHandle, useState } from "react";

import type { Campaign } from "@relatable/gql/generated-base";
import { Note } from "@relatable/ui/Note";
import { TextInput } from "@relatable/ui/TextInput";

import { Section, SubSection } from "../Section";
import { getChangedFields } from "../helpers";
import { useCampaignDetailsQuery, useUpdateCampaignMutation } from "./generated";
import type { SectionRef } from "./types";

export type PayoutOfferFields = Pick<
  Campaign,
  "default_payout_currency" | "fixed_payout" | "min_payout" | "max_payout"
>;

export const PayoutOffersSection = forwardRef<
  SectionRef<PayoutOfferFields>,
  { campaignStub: string }
>(({ campaignStub }, ref) => {
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);

  const [fields, setFields] = useState<Partial<PayoutOfferFields>>({});

  const { data } = useCampaignDetailsQuery({
    variables: { stub: campaignStub },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ campaigns }) => {
      const [c] = campaigns;
      setFields(c);
    }
  });

  const [updateCampaignByIdMutation, updateCampaignByIdMutationOptions] =
    useUpdateCampaignMutation();

  const campaign = data?.campaigns?.[0];

  const validate = () => {
    const errors: string[] = [];
    if (fields.min_payout && fields.min_payout < 0) {
      errors.push("Min payout should be greater than 0!");
    }
    if (fields.max_payout && fields.max_payout < 0) {
      errors.push("Max payout should be greater than 0!");
    }
    if (fields.fixed_payout && fields.fixed_payout < 0) {
      errors.push("Fixed payout should be greater than 0!");
    }
    setFieldErrors(errors);
    return errors;
  };

  const changedFields = getChangedFields({ data: fields, initialData: campaign });
  const isChanged = Boolean(Object.values(changedFields).length);

  const handleUpdate = async () => {
    if (!campaign) throw new Error("Missing campaign");
    if (validate().length) return;

    if (!isChanged) return;

    await updateCampaignByIdMutation({
      variables: {
        id: campaign.id,
        set: changedFields
      }
    });
  };

  useImperativeHandle(ref, () => ({
    submit: handleUpdate,
    validate,
    getFields: () => fields,
    setFields
  }));

  const handleFieldChange = (partialData: Partial<PayoutOfferFields>) => {
    setFields(p => ({ ...p, ...partialData }));
  };

  return (
    <Section
      title="Payout offers"
      updated_at={undefined}
      updatedAtNotImplemented
      fieldErrors={fieldErrors}
      isChanged={isChanged}
      submitError={updateCampaignByIdMutationOptions.error}
      sidebar={
        campaign?.exchange_rate !== 1 ? (
          <Note variant="info" label="Exchange rate">
            Exchange rate is set when creating a campaign and it cannot be updated. Your payout
            currency is: <b>{campaign?.default_payout_currency}</b> and exchange rate:{" "}
            <b>{campaign?.exchange_rate}</b>
          </Note>
        ) : (
          <div />
        )
      }
    >
      <TextInput
        label="Extra compensation added for each person (SEK)"
        type="number"
        value={String(fields.fixed_payout)}
        onChange={v => handleFieldChange({ fixed_payout: Number(v) })}
      />

      <SubSection>
        <TextInput
          style={{ width: "100%" }}
          label="Min payout"
          type="number"
          value={String(fields.min_payout)}
          onChange={v => handleFieldChange({ min_payout: Number(v) })}
        />
        <TextInput
          style={{ width: "100%" }}
          label="Max payout"
          type="number"
          value={String(fields.max_payout)}
          onChange={v => handleFieldChange({ max_payout: Number(v) })}
        />
      </SubSection>
    </Section>
  );
});
