import { type FC, useState } from "react";

import { ContentEditorFields } from "./ContentEditorFields";
import { ContentPreview } from "./ContentPreview";
import type { MandrillTemplatesQuery, MessageContentQuery } from "./generated";

export interface ContentEditorFieldsType {
  name: string;
  subject: string;
  body: string;
  footer: string;
  mandrillTemplate: string;
}

export const ContentEditor: FC<{
  templates: MandrillTemplatesQuery["mandrillTemplates"];
  campaign: MessageContentQuery["campaigns"][number];
  content: MessageContentQuery["contents"][number];
}> = ({ templates, campaign, content }) => {
  const [fields, setFields] = useState<ContentEditorFieldsType>({
    name: content?.name ?? "",
    subject: content.subject ?? "",
    body: content?.data?.main ?? "",
    footer: content?.data?.footer ?? "",
    mandrillTemplate: content?.template ?? templates?.[0].slug ?? ""
  });

  return (
    <div style={{ display: "flex", gap: 20 }}>
      <div style={{ width: "50%" }}>
        <ContentEditorFields
          fields={fields}
          setFields={setFields}
          templates={templates}
          content={content}
        />
      </div>
      <div style={{ width: "50%" }}>
        <ContentPreview
          templateName={fields.mandrillTemplate}
          campaignUsers={campaign.campaign_users}
          subject={fields.subject}
          body={fields.body}
          footer={fields.footer}
        />
      </div>
    </div>
  );
};
