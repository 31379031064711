import type { FC } from "react";

import { prettifyDate } from "@relatable/helpers";

import { STATE_TYPES } from "lib/constants";
import { ContentFeedbackState } from "modules/campaign/common/ContentFeedbackState";

export const FeedbackState: FC<{
  isApproved: boolean;
  isApprovedLocal: boolean;
  approved_at?: string | null;
  contentName: "reel" | "post";
}> = ({ isApproved, isApprovedLocal, approved_at, contentName }) => {
  if (isApproved && isApprovedLocal) {
    return (
      <ContentFeedbackState type={STATE_TYPES.INFO}>
        <span>
          This <strong>{contentName}</strong> was approved{" "}
          {prettifyDate(approved_at, { full: true })}
        </span>
      </ContentFeedbackState>
    );
  }

  if (!isApproved && !isApprovedLocal) {
    return (
      <ContentFeedbackState type={STATE_TYPES.INFO}>
        <span>You must approve the post to continue</span>
      </ContentFeedbackState>
    );
  }

  if (isApprovedLocal && !isApproved) {
    return null;
  }

  return (
    <ContentFeedbackState type={STATE_TYPES.INVALID}>
      <span>Unexpected error...</span>
    </ContentFeedbackState>
  );
};
