import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCampaignSnapMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  set: Types.Campaign_Snap_Set_Input;
}>;


export type UpdateCampaignSnapMutation = { __typename?: 'Mutation', update_campaign_snap_by_pk?: { __typename?: 'campaign_snap', id: number } | null };

export type CampaignSnapQueryVariables = Types.Exact<{
  snapId: Types.Scalars['Int']['input'];
}>;


export type CampaignSnapQuery = { __typename?: 'Query', campaignSnap: Array<{ __typename?: 'campaign_snap', id: number, approved: boolean, approved_at?: string | null, created_at: string, media_url?: string | null, url?: string | null, track_url?: string | null, shortcode?: string | null, views?: number | null, reach?: number | null, screenshots?: number | null, comments?: number | null, clicks?: number | null, campaign_user: { __typename?: 'campaign_user', id: number, tracking?: any | null, user?: { __typename?: 'users', id: number, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, external_id?: string | null } | null } | null } }> };


export const UpdateCampaignSnapDocument = gql`
    mutation updateCampaignSnap($id: Int!, $set: campaign_snap_set_input!) {
  update_campaign_snap_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
  }
}
    `;
export type UpdateCampaignSnapMutationFn = Apollo.MutationFunction<UpdateCampaignSnapMutation, UpdateCampaignSnapMutationVariables>;

/**
 * __useUpdateCampaignSnapMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignSnapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignSnapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignSnapMutation, { data, loading, error }] = useUpdateCampaignSnapMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCampaignSnapMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignSnapMutation, UpdateCampaignSnapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignSnapMutation, UpdateCampaignSnapMutationVariables>(UpdateCampaignSnapDocument, options);
      }
export type UpdateCampaignSnapMutationHookResult = ReturnType<typeof useUpdateCampaignSnapMutation>;
export type UpdateCampaignSnapMutationResult = Apollo.MutationResult<UpdateCampaignSnapMutation>;
export type UpdateCampaignSnapMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignSnapMutation, UpdateCampaignSnapMutationVariables>;
export const CampaignSnapDocument = gql`
    query campaignSnap($snapId: Int!) {
  campaignSnap: campaign_snap(where: {id: {_eq: $snapId}}, limit: 1) {
    id
    approved
    approved_at
    created_at
    media_url
    url
    track_url
    shortcode
    views
    reach
    screenshots
    comments
    clicks
    campaign_user {
      id
      tracking
      user {
        id
        user_snapchat {
          id
          username
          external_id
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignSnapQuery__
 *
 * To run a query within a React component, call `useCampaignSnapQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignSnapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignSnapQuery({
 *   variables: {
 *      snapId: // value for 'snapId'
 *   },
 * });
 */
export function useCampaignSnapQuery(baseOptions: Apollo.QueryHookOptions<CampaignSnapQuery, CampaignSnapQueryVariables> & ({ variables: CampaignSnapQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignSnapQuery, CampaignSnapQueryVariables>(CampaignSnapDocument, options);
      }
export function useCampaignSnapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignSnapQuery, CampaignSnapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignSnapQuery, CampaignSnapQueryVariables>(CampaignSnapDocument, options);
        }
export function useCampaignSnapSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignSnapQuery, CampaignSnapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignSnapQuery, CampaignSnapQueryVariables>(CampaignSnapDocument, options);
        }
export type CampaignSnapQueryHookResult = ReturnType<typeof useCampaignSnapQuery>;
export type CampaignSnapLazyQueryHookResult = ReturnType<typeof useCampaignSnapLazyQuery>;
export type CampaignSnapSuspenseQueryHookResult = ReturnType<typeof useCampaignSnapSuspenseQuery>;
export type CampaignSnapQueryResult = Apollo.QueryResult<CampaignSnapQuery, CampaignSnapQueryVariables>;