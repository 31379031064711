import styled from "@emotion/styled";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { getCountryFullName } from "@relatable/helpers";
import { Select } from "@relatable/ui/Select";
import { TextInput } from "@relatable/ui/TextInput";
import { useDebounce } from "@relatable/ui/hooks/useDebounce";
import { useSessionState } from "@relatable/ui/hooks/useSessionState";
import { countries } from "country-flag-icons";

import { platformOptions } from "lib/campaigns";
import { COLLABORATION_SCORE_LABELS, CONTENT_QUALITY_SCORE_LABELS } from "lib/constants";
import { useVerticalsQuery } from "modules/generated";

import { MultiSelect } from "../common/MultiSelect";
import { useCreatorSearchOptionsQuery, useSearchCreatorQuery } from "../generated";
import { useQueryParts } from "./useQueryParts";

const Root = styled.div`
  > .row {
    display: grid;
    margin-bottom: 12px;
    grid-template-columns: 49% 49%;
    gap: 2%;
    > .column {
      width: 100%;
    }
  }
`;

export const SearchTab: React.FC<{
  setVisibleUserIds: (v: number[]) => void;
  username: string;
}> = ({ setVisibleUserIds, username }) => {
  const [minFollowers, setMinFollowers] = useSessionState("curation-min-followers", "");
  const [maxFollowers, setMaxFollowers] = useSessionState("curation-max-followers", "");
  const [minEngagement, setMinEngagement] = useSessionState("curation-min-engagement", "");
  const [collaborationScore, setCollaborationScore] = useSessionState<number | null>(
    "curation-collaboration-score",
    null
  );
  const [contentQualityScore, setContentQualityScore] = useSessionState<number | null>(
    "curation-quality-score",
    null
  );
  const [platforms, setPlatforms] = useSessionState<Platform_Enum[]>("curation-platforms", []);
  const [market, setMarket] = useSessionState<string | null>("curation-market", null);
  const [vertical, setVertical] = useSessionState<string | null>("curation-vertical", null);
  const [tagIds, setTagIds] = useSessionState<number[]>("curation-tags", []);
  const debouncedUsername = useDebounce(username, 700);

  const { data: searchOptions, loading: searchOptionsLoading } = useCreatorSearchOptionsQuery();

  const {
    usernameQuery,
    followersQuery,
    engagementQuery,
    collaborationScoreQuery,
    contentQualityScoreQuery,
    platformQuery,
    marketQuery,
    verticalQuery,
    tagsQuery
  } = useQueryParts({
    username: debouncedUsername,
    minFollowers: minFollowers ? Number(minFollowers) : null,
    maxFollowers: maxFollowers ? Number(maxFollowers) : null,
    minEngagement: minEngagement ? Number(minEngagement) / 100 : null,
    collaborationScore,
    contentQualityScore,
    platforms,
    market,
    vertical,
    tagIds
  });

  useSearchCreatorQuery({
    variables: {
      query: {
        _and: [
          usernameQuery,
          followersQuery,
          engagementQuery,
          collaborationScoreQuery,
          contentQualityScoreQuery,
          platformQuery,
          marketQuery,
          verticalQuery,
          tagsQuery
        ]
      }
    },
    onCompleted: data => {
      if (data) {
        setVisibleUserIds(data.users.map(u => u.id));
      }
    }
  });

  const { data } = useVerticalsQuery();

  if (searchOptionsLoading) return null;

  return (
    <Root>
      <div className="row">
        <div className="column">
          <TextInput
            label="Min followers"
            placeholder="1,000"
            size="small"
            type="number"
            value={minFollowers}
            onChange={value => setMinFollowers(value)}
            style={{ width: "49%", marginRight: "2%" }}
          />

          <TextInput
            label="Max followers"
            placeholder="50,000"
            size="small"
            type="number"
            value={maxFollowers}
            onChange={value => setMaxFollowers(value)}
            style={{ width: "49%" }}
          />
        </div>

        <div className="column">
          <TextInput
            label="Minimum engagement rate (%)"
            placeholder="Ex. 10"
            size="small"
            type="number"
            value={minEngagement}
            onChange={value => setMinEngagement(value)}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <Select
            label="Market"
            value={market?.toString()}
            size="small"
            options={
              countries.map(c => ({
                value: c,
                label: getCountryFullName(c) ?? ""
              })) ?? []
            }
            onChange={value => setMarket(value ?? null)}
            style={{ width: "100%" }}
          />
        </div>

        <div className="column">
          <Select
            label="Vertical"
            value={vertical?.toString()}
            size="small"
            options={data?.vertical.map(v => ({ label: v.name, value: String(v.id) })) || []}
            onChange={value => setVertical(value || null)}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <Select
            label="Collaboration score"
            value={collaborationScore?.toString()}
            size="small"
            options={COLLABORATION_SCORE_LABELS.map((label, i) => ({
              value: `${i + 1}`,
              label: `${i + 1} - ${label}`
            }))}
            onChange={value => setCollaborationScore(Number(value))}
            style={{ width: "100%" }}
          />
        </div>

        <div className="column">
          <Select
            label="Content quality score"
            value={contentQualityScore?.toString()}
            size="small"
            options={CONTENT_QUALITY_SCORE_LABELS.map((label, i) => ({
              value: `${i + 1}`,
              label: `${i + 1} - ${label}`
            }))}
            onChange={value => setContentQualityScore(Number(value))}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <MultiSelect
            options={
              searchOptions?.user_tag_types.map(tagItem => ({
                value: tagItem.id.toString(),
                label: tagItem.name ?? ""
              })) ?? []
            }
            value={tagIds.map(String)}
            onChange={values => setTagIds(values.map(v => Number(v)))}
            label="Tags"
          />
        </div>

        <div className="column">
          <MultiSelect
            options={platformOptions}
            value={platforms}
            onChange={values => setPlatforms(values.map(v => v.toString() as Platform_Enum))}
            label="Platform"
          />
        </div>
      </div>
    </Root>
  );
};
