import { prettifyDate, prettifyNumber } from "@relatable/helpers/format";
import { Button } from "@relatable/ui/Button";
import { type AddFields, Table, type TableColumn } from "@relatable/ui/Table";
import { type FC, useState } from "react";
import { Link } from "react-router-dom";

import { Popup } from "components/ui/Popup";
import { CopyIcon } from "modules/user/UserInfo/CopyIcon";

import { Edit, People } from "@mui/icons-material";
import { LinkUsage } from "./LinkUsage";
import type { LinksQuery } from "./generated";
import { prettifyLinkTargetURL } from "./util";

export const LinksTable: FC<{
  loading: boolean;
  links?: LinksQuery["link_clicks"];
}> = ({ loading, links }) => {
  const [popupKey, setPopupKey] = useState<string | null>(null);

  const rows =
    links?.map(e => {
      const r = {
        key: e.id,
        date: e.created_at,
        stub: e.key,
        clicks: e.clicks,
        target: e.target_url
      };
      return r as AddFields<typeof r, "creators" | "actions">;
    }) ?? [];

  const columns: TableColumn<typeof rows>[] = [
    {
      field: "stub",
      headerName: "Relatable link",
      renderCell: ({ value }) => (
        <span>
          rlt.to/{value} <CopyIcon text={`https://rlt.to/${value}`} />
        </span>
      )
    },
    {
      field: "target",
      headerName: "Client URL",
      renderCell: ({ value }) => (
        <Link to={value} target="_blank">
          {prettifyLinkTargetURL(value)}
        </Link>
      )
    },
    {
      field: "clicks",
      headerName: "Clicks",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      field: "creators",
      headerName: "Usage",
      renderCell: ({ row }) => (
        <Button
          variant="text"
          style={{ marginLeft: 4 }}
          onClick={() => setPopupKey(row.stub)}
          icon={<People fontSize="small" style={{ marginRight: 4 }} />}
        >
          View creators
        </Button>
      )
    },
    {
      field: "date",
      headerName: "Created at",
      renderCell: ({ value }) => prettifyDate(value)
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => (
        <span>
          <Link to={`/links/edit/${row.key}`}>
            <Edit />
          </Link>
        </span>
      )
    }
  ];

  return (
    <>
      <Table
        tableId="links-list"
        loading={loading}
        columns={columns}
        rows={rows}
        sortable
        stickyColumn
      />
      {popupKey && (
        <Popup
          title={`Associated creators of rlt.to/${popupKey}`}
          onClose={() => setPopupKey(null)}
          onAction={async () => {
            setPopupKey(null);
          }}
        >
          <LinkUsage linkKey={popupKey} />
        </Popup>
      )}
    </>
  );
};
