import type { Campaign_User_State_Enum, Platform_Enum } from "@relatable/gql/generated-base";

import type { CampaignUserExtendedState } from "src/typings/global";

const appLevelConstants = {
  CONTENT_STATUS_TYPES: {
    APPROVED: "approved",
    DENIED: "denied",
    PENDING: "pending",
    WAITING_FOR_CONTENT: "waiting_for_content"
  },

  COMMENT_TYPES: {
    MEDIA: "media",
    OTHER: "other"
  },

  STATE_TYPES: {
    VALID: "valid",
    INVALID: "invalid",
    INFO: "info"
  },

  LANGUAGES: {
    ENGLISH: "en",
    SWEDISH: "sv"
  },

  CURRENCIES: {
    SEK: "SEK",
    GBP: "GBP",
    EUR: "EUR",
    USD: "USD",
    DKK: "DKK",
    NOK: "NOK",
    AUD: "AUD"
  },

  FEATURE_FLAGS: {
    CONTENT_SETTINGS: "content-settings",
    STORY_DETECTION: "story-detection",
    INSTAGRAM_AGGREMENT: "instagram-agreement",
    INSTAGRAM_BUSINESS_API: "instagram-business-api",
    UPDATED_INSTAGRAM_BRIEF: "updated-instagram-brief"
  },

  CLIENT_RATINGS: {
    2: {
      value: 2,
      label: "3rd priority"
    },
    3: {
      value: 3,
      label: "2nd priority"
    },
    4: {
      value: 4,
      label: "1st priority"
    },
    5: {
      value: 5,
      label: "1🌟 key client"
    }
  },

  PROJECT_TYPES: {
    AWARENESS: { value: "awareness", label: "Awareness" },
    CONSIDERATION: { value: "consideration", label: "Consideration" },
    CONVERSION: { value: "conversion", label: "Conversion" },
    TASTEMAKER: { value: "tastemaker", label: "Tastemaker" },
    OTHER: { value: "other", label: "Other" }
  },

  CONTENT_RIGHT_TYPES: {
    organic_usage_only: {
      value: "organic_usage_only",
      label: "Organic usage only",
      description:
        "It is OK for our client to use your content created for this campaign in their own channels provided that they refer to you as the content creator and publish it without any paid media."
    },
    global_digital_rights: {
      value: "global_digital_rights",
      label: "Global digital rights",
      description:
        "It is OK for our client to use your content created for this campaign for all types of digital marketing."
    },
    branded_content_ads: {
      value: "branded_content_ads",
      label: "Ads",
      description:
        "It is OK for us and our client to use your content created for this campaign for Ads through the Facebook Ads Manager."
    },
    full_commercial_rights: {
      value: "full_commercial_rights",
      label: "Full commercial rights",
      description:
        "It is OK for our client to use the content created for this campaign for all types of marketing."
    }
  }
};

export const { LANGUAGES } = appLevelConstants;
export const { CURRENCIES } = appLevelConstants;
export const { PROJECT_TYPES } = appLevelConstants;
export const { CONTENT_STATUS_TYPES } = appLevelConstants;
export const { STATE_TYPES } = appLevelConstants;
export const { CONTENT_RIGHT_TYPES } = appLevelConstants;

export const CLIENT_RATINGS = [
  {
    value: 2,
    label: "3rd priority"
  },
  {
    value: 3,
    label: "2nd priority"
  },
  {
    value: 4,
    label: "1st priority"
  },
  {
    value: 5,
    label: "1 key client"
  }
];

export const PLATFORMS: Platform_Enum[] = ["instagram", "tiktok", "youtube", "snapchat"];
export const PLATFORM_LABELS: Record<Platform_Enum, string> = {
  instagram: "Instagram",
  tiktok: "TikTok",
  youtube: "YouTube",
  snapchat: "Snapchat"
};

export const CAMPAIGN_USER_STATE_DISPLAY_NAMES = {
  confirmed: "Confirmed",
  approved: "Approved",
  client_approved: "Approved by client",
  client_declined: "Declined by client",
  client_reviewing: "Reviewing by client",
  denied: "Denied",
  lead: "Lead",
  negotiating: "Negotiating",
  not_interested: "Not interested",
  prospect: "Prospect",
  rejected: "Rejected"
} as const;

export const CAMPAIGN_USER_STATE_TYPES = Object.keys(
  CAMPAIGN_USER_STATE_DISPLAY_NAMES
) as Campaign_User_State_Enum[];

// these user states have special meaning
export const CAMPAIGN_USER_SPECIAL_STATE_DISPLAY_NAMES = {
  not_set: "Not set", // db state = null
  not_in_the_campaign: "Not in the campaign" // there is no campaign_user row in the db
} as const;

export const CAMPAIGN_USER_ALL_DISPLAY_NAMES = {
  ...CAMPAIGN_USER_STATE_DISPLAY_NAMES,
  ...CAMPAIGN_USER_SPECIAL_STATE_DISPLAY_NAMES
} as const;

export const CAMPAIGN_USER_EXTENDED_STATE_TYPES = Object.keys(
  CAMPAIGN_USER_ALL_DISPLAY_NAMES
) as CampaignUserExtendedState[];

export const SORTED_CAMPAIGN_USER_STATES = [
  "Approved",
  "Confirmed",
  "Negotiating",
  "Lead",
  "Approved by client",
  "Reviewing by client",
  "Declined by client",
  "Prospect",
  "Denied",
  "Rejected",
  "Not interested",
  "Not set",
  "Not in the campaign"
];

export const COLLABORATION_SCORE_LABELS = [
  "Never work with again",
  "Challenging",
  "Good enough",
  "Smooth collaboration",
  "Great job"
];

export const CONTENT_QUALITY_SCORE_LABELS = [
  "Poor quality",
  "Mediocre",
  "Good enough",
  "Impressive work",
  "Vogue level"
];

export const HYPEAUDITOR_LABELS = {
  // Waiting for our internal worker to process the queue
  pending_rel: "Processing",
  // HypeAuditor needs more time to generate the report (they use scrapers)
  pending_ha: "Waiting for HypeAuditor",
  // An error happened. It might be on Hypeauditor's side.
  // E.g. HypeAuditor cannot generate reports for everybody. They enforce a minimum number of followers for creators.
  error: "Error",
  // All good
  completed: "Completed"
};
