import type { FC, ReactElement } from "react";

import { ToggleButton, ToggleButtonGroup as ToggleButtonGroupMui, Typography } from "@mui/material";

export const ToggleButtonGroup: FC<{
  options: { value: string; content: ReactElement | string }[];
  value: string[];
  onChange(value: string[]): void;
  exclusive?: boolean;
  label: string;
  disabled?: boolean;
}> = ({ options, exclusive, value, onChange, label, disabled }) => (
  <div style={{ position: "relative" }}>
    <span
      style={{
        position: "absolute",
        top: -10,
        left: 10,
        fontSize: 10,
        padding: "0 5px",
        color: "rgba(0, 0, 0, 0.6)",
        background: "white",
        zIndex: 2
      }}
    >
      {label}
    </span>
    <ToggleButtonGroupMui
      disabled={disabled}
      value={value}
      exclusive={exclusive}
      onChange={(_, newValue) => onChange(typeof newValue === "string" ? [newValue] : newValue)}
    >
      {options.map(i => (
        <ToggleButton size="large" key={i.value} color="info" value={i.value}>
          <Typography style={{ textTransform: "none" }}>{i.content}</Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroupMui>
  </div>
);
