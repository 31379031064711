import { type FC, useCallback, useEffect, useMemo } from "react";

import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { getUserLabel } from "@relatable/helpers/user";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInputWithIcon } from "@relatable/ui/TextInput";
import { useMatch } from "react-router-dom";

import { Center } from "lib/styled";

import { People, PriorityHigh, Search } from "@mui/icons-material";
import {
  type CreatorListItemsQuery,
  CreatorsInListDocument,
  useCreatorListItemsQuery,
  useRemoveUserFromListMutation
} from "../generated";
import { CreatorListItem } from "./CreatorListItem";

const Root = styled.div`
  padding-top: 12px;

  > header {
    display: flex;
    justify-content: space-between;
    > h2 {
      font-size: 12px;
    }
  }

  > main {
    > .empty {
      height: 192px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: ${palette.gray[60]};

      > .box {
        max-width: 50%;
      }
    }
  }
`;

const getCampaignUsersRelatedToContext = (
  listId: string | undefined,
  user: CreatorListItemsQuery["users"][number]
) => {
  if (!listId) return user.campaign_users;
  const res = user.campaign_users.find(cu => cu.campaign.list === Number(listId));
  return res ? [res] : [];
};

export const CreatorList: FC<{
  userIds: number[] | null;
  selectedUserId: number | null;
  setSelectedUserId: (userId: number | null) => void;
  usernameFilter: string;
  setUsernameFilter: (query: string) => void;
  showListSelector?: boolean;
  showPlatform?: boolean;
}> = ({
  userIds,
  selectedUserId,
  setSelectedUserId,
  usernameFilter,
  setUsernameFilter,
  showListSelector,
  showPlatform
}) => {
  const routeMatch = useMatch("curation/list-management/:listId");
  const listId = routeMatch?.params.listId;

  const snackbar = useSnackbar();

  const { data, loading, previousData } = useCreatorListItemsQuery({
    variables: { userIds: userIds ?? [0] },
    skip: !userIds?.length
  });

  const [removeUserFromList] = useRemoveUserFromListMutation({
    onCompleted: () => snackbar.success("User was removed from the list"),
    onError: error => snackbar.error(error.message),
    refetchQueries: [CreatorsInListDocument]
  });

  const filteredUsers = useMemo(() => {
    const currentData = data ?? previousData;
    const currentUsers = (currentData?.users ?? []).filter(user =>
      (userIds ?? []).includes(user.id)
    );
    return currentUsers.filter(user => getUserLabel(user).includes(usernameFilter));
  }, [userIds, data, previousData, usernameFilter]);

  useEffect(() => {
    const isFilteredOut =
      filteredUsers && filteredUsers.find(user => user.id === selectedUserId) === undefined;

    if (isFilteredOut) {
      setSelectedUserId(null);
    }
  }, [filteredUsers, selectedUserId, setSelectedUserId]);

  const onRemoveUser = useCallback(
    async (userId: number) => {
      await removeUserFromList({
        variables: { user_id: userId, list_id: Number(listId) }
      });
    },
    [listId, removeUserFromList]
  );

  // handle keyboard navigation
  useEffect(() => {
    if (!selectedUserId || !userIds) return () => undefined;
    const handler = (e: KeyboardEvent) => {
      const selectedUserIndex = userIds.indexOf(selectedUserId);

      if (e.key === "ArrowDown" && selectedUserIndex < userIds.length - 1) {
        setSelectedUserId(userIds[selectedUserIndex + 1]);
      } else if (e.key === "ArrowUp" && selectedUserIndex > 0) {
        setSelectedUserId(userIds[selectedUserIndex - 1]);
      }
    };

    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [selectedUserId, setSelectedUserId, userIds]);

  return (
    <Root>
      <header>
        <h2 style={{ display: "flex" }}>
          <People style={{ marginRight: 5 }} />
          Creators
        </h2>
        <TextInputWithIcon
          iconPosition="start"
          icon={<Search />}
          placeholder="filter by @username"
          value={usernameFilter}
          onChange={value => setUsernameFilter(value)}
          style={{ width: 180 }}
        />
      </header>

      <main>
        {/* The loading spinner would trigger layout shift when the data is only reloading due to deletion */}
        {loading && filteredUsers.length === 0 && (
          <Center>
            <CircularProgress />
          </Center>
        )}

        {filteredUsers?.map(user => (
          <CreatorListItem
            key={user?.id}
            selected={selectedUserId === user?.id}
            onSelect={() => setSelectedUserId(user?.id ?? null)}
            onRemove={listId ? () => onRemoveUser(user?.id) : undefined}
            user={{ ...user, campaign_users: getCampaignUsersRelatedToContext(listId, user) }}
            showListSelector={showListSelector}
            showPlatform={showPlatform}
          />
        ))}

        {!loading && !filteredUsers?.length && (
          <div className="empty">
            <div className="box">
              <PriorityHigh fontSize="large" className="icon" />
              {userIds === null ? (
                <p>
                  Please <strong>select</strong> or <strong>create</strong> a list above to view the
                  creators info
                </p>
              ) : (
                <p>There are no creators</p>
              )}
            </div>
          </div>
        )}
      </main>
    </Root>
  );
};
