import type { QueryHookOptions } from "@apollo/client";

import { type MeQuery, type MeQueryVariables, useMeQuery } from "./generated";

const redirectToLogin = () => {
  const url = new URL(`${process.env.GRAPH_URL}/auth/admin/login`);
  url.searchParams.set("redirectUrl", window.location.href);
  window.location.href = String(url);
};

export const useUser = (props?: QueryHookOptions<MeQuery, MeQueryVariables>) => {
  const { data } = useMeQuery({
    onCompleted: completedData => {
      if (!completedData?.me?.admin) {
        redirectToLogin();
      }
    },
    onError: err => {
      console.error(err);
      redirectToLogin();
    },
    ...props
  });

  return data?.me?.admin ?? null;
};
