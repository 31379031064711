import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HypeAuditorInstagramReportQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
}>;


export type HypeAuditorInstagramReportQuery = { __typename?: 'Query', hypeauditor: Array<{ __typename?: 'hypeauditor', id: number, data?: any | null, state?: Types.Hypeauditor_Report_State_Enum | null, not_available?: boolean | null, user: { __typename?: 'users', id: number, user_instagram?: { __typename?: 'users_instagram', id: number, username: string } | null } }> };


export const HypeAuditorInstagramReportDocument = gql`
    query hypeAuditorInstagramReport($userId: Int!) {
  hypeauditor(where: {users_id: {_eq: $userId}}) {
    id
    data
    state
    not_available
    user {
      id
      user_instagram {
        id
        username
      }
    }
  }
}
    `;

/**
 * __useHypeAuditorInstagramReportQuery__
 *
 * To run a query within a React component, call `useHypeAuditorInstagramReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useHypeAuditorInstagramReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHypeAuditorInstagramReportQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useHypeAuditorInstagramReportQuery(baseOptions: Apollo.QueryHookOptions<HypeAuditorInstagramReportQuery, HypeAuditorInstagramReportQueryVariables> & ({ variables: HypeAuditorInstagramReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HypeAuditorInstagramReportQuery, HypeAuditorInstagramReportQueryVariables>(HypeAuditorInstagramReportDocument, options);
      }
export function useHypeAuditorInstagramReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HypeAuditorInstagramReportQuery, HypeAuditorInstagramReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HypeAuditorInstagramReportQuery, HypeAuditorInstagramReportQueryVariables>(HypeAuditorInstagramReportDocument, options);
        }
export function useHypeAuditorInstagramReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HypeAuditorInstagramReportQuery, HypeAuditorInstagramReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HypeAuditorInstagramReportQuery, HypeAuditorInstagramReportQueryVariables>(HypeAuditorInstagramReportDocument, options);
        }
export type HypeAuditorInstagramReportQueryHookResult = ReturnType<typeof useHypeAuditorInstagramReportQuery>;
export type HypeAuditorInstagramReportLazyQueryHookResult = ReturnType<typeof useHypeAuditorInstagramReportLazyQuery>;
export type HypeAuditorInstagramReportSuspenseQueryHookResult = ReturnType<typeof useHypeAuditorInstagramReportSuspenseQuery>;
export type HypeAuditorInstagramReportQueryResult = Apollo.QueryResult<HypeAuditorInstagramReportQuery, HypeAuditorInstagramReportQueryVariables>;