import type { FC } from "react";

import styled from "@emotion/styled";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { Navigate, Route, Routes, useMatch, useNavigate } from "react-router-dom";

import { useMeQuery } from "hooks/generated";
import { ContentContainer } from "lib/styled";

import { Accounts } from "./accounts";
import { Hours } from "./hours";
import { ProjectReport } from "./reports/ProjectReport";
import { WorkloadReport } from "./reports/WorkloadReport";

const Root = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const TimeReporting: FC = () => {
  useDocumentTitle("Time Reporting");
  const { data: me } = useMeQuery();
  const navigate = useNavigate();
  const match = useMatch("time-reporting/:path/*");
  const path = match?.params?.path ?? "";

  const isAdmin = (me?.me?.admin?.roles ?? []).includes("admin");

  return (
    <ContentContainer>
      <Root>
        <ToggleButtonGroup
          color="primary"
          value={path ?? ""}
          exclusive
          onChange={(event, value) => navigate(`/time-reporting/${value ?? ""}`)}
          style={{ marginBottom: 15 }}
        >
          <ToggleButton value="my-hours">My hours</ToggleButton>
          <ToggleButton value="projects">Project statistics</ToggleButton>
          {isAdmin && <ToggleButton value="workload">Workload statistics</ToggleButton>}
          <ToggleButton value="accounts">Accounts</ToggleButton>
        </ToggleButtonGroup>

        <br />
        <br />

        <Routes>
          <Route path="accounts/*" element={<Accounts />} />
          <Route path="projects" element={<ProjectReport />} />
          <Route path="workload" element={<WorkloadReport />} />
          <Route path="my-hours/:year/:month/:day" element={<Hours />} />
          <Route path="*" element={<Navigate to="/time-reporting/my-hours/0/0/0" />} />
        </Routes>
      </Root>
    </ContentContainer>
  );
};
