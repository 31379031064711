import type { FC } from "react";

import styled from "@emotion/styled";
import { Card, CardContent, CardHeader, Paper } from "@mui/material";
import { isAfter, isBefore, prettifyDate, prettifyNumber } from "@relatable/helpers";
import { useParams } from "react-router-dom";

import { useLinksByKeyQuery } from "modules/links/generated";
import { extractRltToKey } from "modules/links/util";

import { useCampaignVideoStatsQuery } from "./generated";

const sum = (arr: number[]): number => arr.reduce((prev, curr) => prev + curr, 0);

export const StatsBody: FC = () => {
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  const { data } = useCampaignVideoStatsQuery({ variables: { stubs: campaignStubs } });

  const rltToKeys = (
    data?.campaigns?.flatMap(c => c.campaign_users.flatMap(u => u.campaign_videos)) ?? []
  )
    .map(p => extractRltToKey(p.track_url))
    .filter(Boolean) as string[];

  const { data: linkData } = useLinksByKeyQuery({
    variables: { keys: rltToKeys },
    skip: !rltToKeys.length
  });

  const campaigns = data?.campaigns || [];

  const allVideos = campaigns.flatMap(
    campaign => campaign?.campaign_users?.flatMap(cu => cu.campaign_videos) || []
  );

  const allVideoStats = allVideos.map(video => ({
    ...video.campaign_video_stat,
    link_clicks:
      (linkData?.link_clicks.find(lc => lc.key === extractRltToKey(video.track_url))?.clicks ?? 0) +
      (video.campaign_video_stat?.link_clicks ?? 0)
  }));

  const allViews = sum(allVideoStats.map(stats => stats?.view_count ?? 0));
  const allClicks = sum(allVideoStats.map(stats => stats?.link_clicks ?? 0));

  const allInteractions =
    sum(allVideoStats.map(stats => stats?.like_count ?? 0)) +
    sum(allVideoStats.map(stats => stats?.dislike_count ?? 0)) +
    sum(allVideoStats.map(stats => stats?.comment_count ?? 0)) +
    sum(allVideoStats.map(stats => stats?.favorite_count ?? 0));

  const totalCTR = sum(
    allVideoStats.map(stats => (stats?.link_clicks ?? 0) / (stats?.view_count ?? 1))
  );

  const leftCards = [
    {
      title: "Views",
      value: prettifyNumber(allViews)
    },
    {
      title: "Likes",
      value: prettifyNumber(sum(allVideoStats.map(stats => stats?.like_count ?? 0)))
    },
    {
      title: "Comments",
      value: prettifyNumber(sum(allVideoStats.map(stats => stats?.comment_count ?? 0)))
    },
    {
      title: "Dislikes",
      value: prettifyNumber(sum(allVideoStats.map(stats => stats?.dislike_count ?? 0)))
    },
    {
      title: "Engagement",
      value: prettifyNumber(allViews > 0 ? allInteractions / allViews : 0, { percentages: true })
    },
    {
      title: "Clicks",
      value: prettifyNumber(sum(allVideoStats.map(stats => stats?.link_clicks ?? 0)))
    },
    {
      title: "Campaign CTR",
      value: prettifyNumber(allViews > 0 ? allClicks / allViews : 0, { percentages: true })
    },
    {
      title: "Average video CTR",
      value: prettifyNumber(totalCTR / allVideos.length, { percentages: true })
    }
  ];

  const firstDate = campaigns
    .map(c => c.min_max_publish_date.aggregate?.min?.publish_date_min)
    .reduce((prev, curr) => (prev && isBefore(prev, curr || "") ? prev : curr), null);

  const lastDate = campaigns
    .map(c => c.min_max_publish_date.aggregate?.max?.publish_date_max)
    .reduce((prev, curr) => (prev && isAfter(prev, curr || "") ? prev : curr), null);

  const rightCards = [
    { title: "First post date", value: prettifyDate(firstDate) },
    { title: "Last post date", value: prettifyDate(lastDate) }
  ];

  return (
    <StatsContainer>
      <LeftSection elevation={10}>
        {leftCards.map(card => (
          <Card key={card.title}>
            <CardHeader subheader={card.title} />
            <SCardContent>{card.value}</SCardContent>
          </Card>
        ))}
      </LeftSection>

      <RightSection elevation={10}>
        {rightCards.map(card => (
          <Card key={card.title}>
            <CardHeader subheader={card.title} />
            <SCardContent>{card.value}</SCardContent>
          </Card>
        ))}
      </RightSection>
    </StatsContainer>
  );
};

const SCardContent = styled(CardContent)`
  padding-top: 0 !important;
  font-size: 20px;
`;

const LeftSection = styled(Paper)`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  padding: 20px;
`;

const RightSection = styled(Paper)`
  display: flex;
  padding: 20px;
  > div {
    flex-grow: 1;
  }
`;

const StatsContainer = styled.div`
  display: grid;
  gap: 2%;
  grid-template-columns: 49% 49%;
`;
