import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { Check, Close, ModeEdit } from "@mui/icons-material";
import { CircularProgress, TextField } from "@mui/material";
import { palette } from "@relatable/ui/Palette";

const Root = styled.div`
  padding: 20px;
  > header {
    display: flex;
    justify-content: space-between;
    > h4 {
      font-size: 14px;
    }
  }

  > .text {
    text-align: left;
    padding: 12px 0;

    > .field {
      width: 100%;
    }
  }
`;

export const CommentEditor: FC<{
  value: string;
  onChange: (val: string) => Promise<void>;
}> = ({ value, onChange }) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const onEditClick = async () => {
    if (editing) {
      if (localValue !== value) {
        setLoading(true);
        await onChange(localValue);
        setLoading(false);
      }
      setEditing(false);
    } else {
      setEditing(true);
    }
  };

  return (
    <Root>
      <header>
        <h4>Note about profile</h4>
        {loading && <CircularProgress size={16} />}&nbsp;
        {editing && (
          <Close
            style={{ color: palette.primary.gold, cursor: "pointer" }}
            onClick={() => {
              setEditing(false);
              setLocalValue(value);
            }}
          />
        )}
        {editing ? (
          <Check
            style={{ cursor: "pointer", width: 14, color: palette.primary.green }}
            onClick={onEditClick}
          />
        ) : (
          <ModeEdit
            style={{ cursor: "pointer", width: 14, color: palette.gray[50] }}
            onClick={onEditClick}
          />
        )}
      </header>
      <div className="text">
        {editing ? (
          <TextField
            className="field"
            rows={5}
            value={localValue}
            onChange={e => setLocalValue(e.target.value)}
            placeholder="(no comment)"
            multiline
          />
        ) : (
          <span>{value || "(no comment)"}</span>
        )}
      </div>
    </Root>
  );
};
