export const AGE_SECTION_TYPES = { ALL: "all", FEMALE: "female", MALE: "male" };

export const SECTION_KEYS = {
  ACCOUNT_QUALITY: "accountQuality",
  AUDIENCE_DEMOGRAPHICS: "audienceDemographics"
};

export const INSTAGRAM_SECTIONS = {
  [SECTION_KEYS.ACCOUNT_QUALITY]: [
    {
      key: "basics",
      label: "Basics",
      rows: [
        {
          key: "url",
          uiLabel: "Profile URL",
          checked: true
        },
        {
          key: "followers_count",
          uiLabel: "Followers",
          checked: true
        },
        {
          key: "er.value",
          uiLabel: "Organic engagement %",
          checked: true
        },
        {
          key: "one_sentence_summary",
          uiLabel: "One sentence summary",
          checked: false
        },
        {
          key: "photo_url",
          uiLabel: "Profile picture URL",
          checked: false
        }
      ]
    },

    {
      key: "audience_quality",
      label: "Audience quality",
      rows: [
        {
          key: "aqs",
          uiLabel: "AQS score",
          checked: true
        },
        {
          key: "aqs_description",
          uiLabel: "AQS description",
          checked: false
        },
        {
          key: "yearly_growth.value",
          uiLabel: "Yearly growth %",
          checked: false
        }
      ]
    },

    {
      key: "engagement",
      label: "Engagement",
      rows: [
        {
          key: "advertising_data.avg_ad_er",
          uiLabel: "Brand posts engagement %",
          checked: false
        },
        {
          key: "comments_rate.value",
          uiLabel: "Comments rate %",
          checked: false
        },
        {
          key: "comments_authenticity.title",
          uiLabel: "Comment authenticity",
          checked: false
        }
      ]
    },

    {
      key: "audience_type",
      label: "Audience type",
      rows: [
        {
          key: "audience_type.real",
          uiLabel: "Real people %",
          checked: false
        },
        {
          key: "audience_type.susp",
          uiLabel: "Suspicious accounts %",
          checked: false
        },
        {
          key: "audience_type.infs",
          uiLabel: "Influencers %",
          checked: false
        },
        {
          key: "audience_type.mass",
          uiLabel: "Mass followers %",
          checked: false
        },
        {
          key: "audience_type.quality",
          uiLabel: "Quality audience %",
          checked: false
        }
      ]
    },

    {
      key: "advertising_data.brands_categories",
      label: "Brand mention categories",
      rows: [...Array(3).fill({})].map((r, index) => ({
        uiLabel: `#${index + 1}`,
        key: `advertising_data.brands_categories@${index}`,
        checked: index <= -1
      }))
    }
  ],

  [SECTION_KEYS.AUDIENCE_DEMOGRAPHICS]: [
    {
      key: "age_all",
      label: "Age - all",
      rows: [
        {
          key: "demography_by_age@2.by_age_group@0.value",
          uiLabel: "Age 13 to 17",
          checked: false
        },
        {
          key: "demography_by_age@2.by_age_group@1.value",
          uiLabel: "Age 18 to 24",
          checked: false
        },
        {
          key: "demography_by_age@2.by_age_group@2.value",
          uiLabel: "Age 25 to 34",
          checked: false
        },
        {
          key: "demography_by_age@2.by_age_group@3.value",
          uiLabel: "Age 35 to 44",
          checked: false
        },
        {
          key: "demography_by_age@2.by_age_group@4.value",
          uiLabel: "Age 45 to 54",
          checked: false
        },
        {
          key: "demography_by_age@2.by_age_group@5.value",
          uiLabel: "Age 55 to 64",
          checked: false
        },
        {
          key: "demography_by_age@2.by_age_group@6.value",
          uiLabel: "Age 65 and over",
          checked: false
        }
      ]
    },
    {
      key: "age_female",
      label: "Age - women",
      rows: [
        {
          key: "demography_by_age@1.by_age_group@0.value",
          uiLabel: "Age 13 to 17",
          checked: false
        },
        {
          key: "demography_by_age@1.by_age_group@1.value",
          uiLabel: "Age 18 to 24",
          checked: false
        },
        {
          key: "demography_by_age@1.by_age_group@2.value",
          uiLabel: "Age 25 to 34",
          checked: false
        },
        {
          key: "demography_by_age@1.by_age_group@3.value",
          uiLabel: "Age 35 to 44",
          checked: false
        },
        {
          key: "demography_by_age@1.by_age_group@4.value",
          uiLabel: "Age 45 to 54",
          checked: false
        },
        {
          key: "demography_by_age@1.by_age_group@5.value",
          uiLabel: "Age 55 to 64",
          checked: false
        },
        {
          key: "demography_by_age@1.by_age_group@6.value",
          uiLabel: "Age 65 and over",
          checked: false
        }
      ]
    },
    {
      key: "age_male",
      label: "Age - men",
      rows: [
        {
          key: "demography_by_age@0.by_age_group@0.value",
          uiLabel: "Age 13 to 17",
          checked: false
        },
        {
          key: "demography_by_age@0.by_age_group@1.value",
          uiLabel: "Age 18 to 24",
          checked: false
        },
        {
          key: "demography_by_age@0.by_age_group@2.value",
          uiLabel: "Age 25 to 34",
          checked: false
        },
        {
          key: "demography_by_age@0.by_age_group@3.value",
          uiLabel: "Age 35 to 44",
          checked: false
        },
        {
          key: "demography_by_age@0.by_age_group@4.value",
          uiLabel: "Age 45 to 54",
          checked: false
        },
        {
          key: "demography_by_age@0.by_age_group@5.value",
          uiLabel: "Age 55 to 64",
          checked: false
        },
        {
          key: "demography_by_age@0.by_age_group@6.value",
          uiLabel: "Age 65 and over",
          checked: false
        }
      ]
    },
    {
      key: "audience_geography.countries",
      label: "Location: by country",
      rows: [...Array(5).fill({})].map((r, index) => ({
        uiLabel: `#${index + 1}`,
        key: `audience_geography.countries@${index}.name,audience_geography.countries@${index}.value`,
        checked: index === 0
      }))
    },
    {
      key: "audience_geography.cities",
      label: "Location: by city",
      rows: [...Array(5).fill({})].map((r, index) => ({
        uiLabel: `#${index + 1}`,
        key: `audience_geography.cities@${index}.name,audience_geography.cities@${index}.value`,
        checked: index <= -1
      }))
    },
    {
      key: "audience_geography.states",
      label: "Location: by US state",
      rows: [...Array(5).fill({})].map((r, index) => ({
        uiLabel: `#${index + 1}`,
        key: `audience_geography.states@${index}.name,audience_geography.states@${index}.value`,
        checked: index <= -1
      }))
    },
    {
      key: "audience_interests.interests",
      label: "Interests",
      rows: [...Array(10).fill({})].map((r, index) => ({
        uiLabel: `#${index + 1}`,
        key: `audience_interests@${index}.@${0}`,
        checked: index <= -1
      }))
    },
    {
      key: "gender",
      label: "Gender",
      rows: [
        {
          key: "demography_by_age@1.value",
          uiLabel: "Women",
          checked: true
        },
        {
          key: "demography_by_age@0.value",
          uiLabel: "Men",
          checked: true
        }
      ]
    }
  ]
};

export const YOUTUBE_SECTIONS = {
  [SECTION_KEYS.ACCOUNT_QUALITY]: [
    {
      key: "basics",
      label: "Basics",
      rows: [
        {
          key: "url",
          uiLabel: "Profile URL",
          checked: false
        },
        {
          key: "metrics.subscribers_count.value",
          uiLabel: "Subscribers",
          checked: true
        },
        {
          key: "metrics.videos_per_week.performance.90d.value",
          uiLabel: "Videos per week - 90 days",
          checked: true
        },
        {
          key: "metrics.videos_per_week.performance.30d.value",
          uiLabel: "Videos per week - 30 days",
          checked: false
        },
        {
          key: "metrics.views_avg.performance.90d.value",
          uiLabel: "Average views - 90 days",
          checked: true
        },
        {
          key: "metrics.views_avg.performance.30d.value",
          uiLabel: "Average views - 30 days",
          checked: false
        },
        {
          key: "features.cqs.data.value",
          uiLabel: "CQS (Channel Quality Score)",
          checked: false
        }
      ]
    },

    {
      key: "engagement.90d",
      label: "Engagement - 90 days",
      rows: [
        {
          key: "metrics.er.performance.90d.value",
          uiLabel: "Engagement",
          checked: true
        },
        {
          key: "metrics.alikes_avg.performance.90d.value",
          uiLabel: "Average likes",
          checked: true
        },
        {
          key: "metrics.comments_avg.performance.90d.value",
          uiLabel: "Average comments",
          checked: false
        },
        {
          key: "metrics.ltd_rate.performance.90d.value",
          uiLabel: "Like to dislike ratio",
          checked: false
        }
      ]
    },

    {
      key: "engagement.30d",
      label: "Engagement - 30 days",
      rows: [
        {
          key: "metrics.er.performance.30d.value",
          uiLabel: "Engagement",
          checked: false
        },
        {
          key: "metrics.alikes_avg.performance.30d.value",
          uiLabel: "Average likes",
          checked: false
        },
        {
          key: "metrics.comments_avg.performance.30d.value",
          uiLabel: "Average comments",
          checked: false
        },
        {
          key: "metrics.ltd_rate.performance.30d.value",
          uiLabel: "Like to dislike ratio",
          checked: false
        }
      ]
    }
  ],

  [SECTION_KEYS.AUDIENCE_DEMOGRAPHICS]: [
    {
      key: "age_all",
      label: "Age - all",
      rows: [
        {
          key: "features.demography_by_age@2.by_age_group@0.value",
          uiLabel: "Age 13 to 17",
          checked: false
        },
        {
          key: "features.demography_by_age@2.by_age_group@1.value",
          uiLabel: "Age 18 to 24",
          checked: true
        },
        {
          key: "features.demography_by_age@2.by_age_group@2.value",
          uiLabel: "Age 25 to 34",
          checked: true
        },
        {
          key: "features.demography_by_age@2.by_age_group@3.value",
          uiLabel: "Age 35 to 44",
          checked: true
        },
        {
          key: "features.demography_by_age@2.by_age_group@4.value",
          uiLabel: "Age 45 to 54",
          checked: false
        },
        {
          key: "features.demography_by_age@2.by_age_group@5.value",
          uiLabel: "Age 55 to 64",
          checked: false
        },
        {
          key: "features.demography_by_age@2.by_age_group@6.value",
          uiLabel: "Age 65 and over",
          checked: false
        }
      ]
    },
    {
      key: "age_female",
      label: "Age - women",
      rows: [
        {
          key: "features.demography_by_age@1.by_age_group@0.value",
          uiLabel: "Age 13 to 17",
          checked: false
        },
        {
          key: "features.demography_by_age@1.by_age_group@1.value",
          uiLabel: "Age 18 to 24",
          checked: false
        },
        {
          key: "features.demography_by_age@1.by_age_group@2.value",
          uiLabel: "Age 25 to 34",
          checked: false
        },
        {
          key: "features.demography_by_age@1.by_age_group@3.value",
          uiLabel: "Age 35 to 44",
          checked: false
        },
        {
          key: "features.demography_by_age@1.by_age_group@4.value",
          uiLabel: "Age 45 to 54",
          checked: false
        },
        {
          key: "features.demography_by_age@1.by_age_group@5.value",
          uiLabel: "Age 55 to 64",
          checked: false
        },
        {
          key: "features.demography_by_age@1.by_age_group@6.value",
          uiLabel: "Age 65 and over",
          checked: false
        }
      ]
    },
    {
      key: "age_male",
      label: "Age - men",
      rows: [
        {
          key: "features.demography_by_age@0.by_age_group@0.value",
          uiLabel: "Age 13 to 17",
          checked: false
        },
        {
          key: "features.demography_by_age@0.by_age_group@1.value",
          uiLabel: "Age 18 to 24",
          checked: false
        },
        {
          key: "features.demography_by_age@0.by_age_group@2.value",
          uiLabel: "Age 25 to 34",
          checked: false
        },
        {
          key: "features.demography_by_age@0.by_age_group@3.value",
          uiLabel: "Age 35 to 44",
          checked: false
        },
        {
          key: "features.demography_by_age@0.by_age_group@4.value",
          uiLabel: "Age 45 to 54",
          checked: false
        },
        {
          key: "features.demography_by_age@0.by_age_group@5.value",
          uiLabel: "Age 55 to 64",
          checked: false
        },
        {
          key: "features.demography_by_age@0.by_age_group@6.value",
          uiLabel: "Age 65 and over",
          checked: false
        }
      ]
    },
    {
      key: "features.audience_geo",
      label: "Location: by country",
      rows: [...Array(5).fill({})].map((r, index) => ({
        uiLabel: `#${index + 1}`,
        key: `features.audience_geo.data@${index}.name,features.audience_geo.data@${index}.prc`,
        checked: index <= 2
      }))
    },
    {
      key: "gender",
      label: "Gender",
      rows: [
        {
          key: "features.demography_by_age@1.value",
          uiLabel: "Women",
          checked: true
        },
        {
          key: "features.demography_by_age@0.value",
          uiLabel: "Men",
          checked: true
        }
      ]
    }
  ]
};

export const TIKTOK_SECTIONS = {
  [SECTION_KEYS.ACCOUNT_QUALITY]: [
    {
      key: "basics",
      label: "Basics",
      rows: [
        {
          key: "url",
          uiLabel: "Profile URL",
          checked: true
        },
        {
          key: "metrics.subscribers_count.value",
          uiLabel: "Subscribers",
          checked: true
        },
        {
          key: "metrics.views_avg.performance.30d.value",
          uiLabel: "Average views - 30 days",
          checked: true
        }
      ]
    },

    {
      key: "engagement.30d",
      label: "Engagement - 30 days",
      rows: [
        {
          key: "metrics.er.performance.30d.value",
          uiLabel: "Engagement",
          checked: true
        },
        {
          key: "metrics.alikes_avg.performance.30d.value",
          uiLabel: "Average likes",
          checked: false
        },
        {
          key: "metrics.comments_avg.performance.30d.value",
          uiLabel: "Average comments",
          checked: false
        },
        {
          key: "metrics.comments_likes_ratio.performance.30d.value",
          uiLabel: "Comments to likes ratio",
          checked: false
        }
      ]
    }
  ],

  [SECTION_KEYS.AUDIENCE_DEMOGRAPHICS]: [
    {
      key: "age_all",
      label: "Age - all",
      rows: [
        {
          key: "features.demography_by_age@2.by_age_group@0.value",
          uiLabel: "Age 13 to 17",
          checked: false
        },
        {
          key: "features.demography_by_age@2.by_age_group@1.value",
          uiLabel: "Age 18 to 24",
          checked: true
        },
        {
          key: "features.demography_by_age@2.by_age_group@2.value",
          uiLabel: "Age 25 to 34",
          checked: true
        },
        {
          key: "features.demography_by_age@2.by_age_group@3.value",
          uiLabel: "Age 35 to 44",
          checked: true
        },
        {
          key: "features.demography_by_age@2.by_age_group@4.value",
          uiLabel: "Age 45 to 54",
          checked: false
        },
        {
          key: "features.demography_by_age@2.by_age_group@5.value",
          uiLabel: "Age 55 to 64",
          checked: false
        },
        {
          key: "features.demography_by_age@2.by_age_group@6.value",
          uiLabel: "Age 65 and over",
          checked: false
        }
      ]
    },
    {
      key: "age_female",
      label: "Age - women",
      rows: [
        {
          key: "features.demography_by_age@1.by_age_group@0.value",
          uiLabel: "Age 13 to 17",
          checked: false
        },
        {
          key: "features.demography_by_age@1.by_age_group@1.value",
          uiLabel: "Age 18 to 24",
          checked: false
        },
        {
          key: "features.demography_by_age@1.by_age_group@2.value",
          uiLabel: "Age 25 to 34",
          checked: false
        },
        {
          key: "features.demography_by_age@1.by_age_group@3.value",
          uiLabel: "Age 35 to 44",
          checked: false
        },
        {
          key: "features.demography_by_age@1.by_age_group@4.value",
          uiLabel: "Age 45 to 54",
          checked: false
        },
        {
          key: "features.demography_by_age@1.by_age_group@5.value",
          uiLabel: "Age 55 to 64",
          checked: false
        },
        {
          key: "features.demography_by_age@1.by_age_group@6.value",
          uiLabel: "Age 65 and over",
          checked: false
        }
      ]
    },
    {
      key: "age_male",
      label: "Age - men",
      rows: [
        {
          key: "features.demography_by_age@0.by_age_group@0.value",
          uiLabel: "Age 13 to 17",
          checked: false
        },
        {
          key: "features.demography_by_age@0.by_age_group@1.value",
          uiLabel: "Age 18 to 24",
          checked: false
        },
        {
          key: "features.demography_by_age@0.by_age_group@2.value",
          uiLabel: "Age 25 to 34",
          checked: false
        },
        {
          key: "features.demography_by_age@0.by_age_group@3.value",
          uiLabel: "Age 35 to 44",
          checked: false
        },
        {
          key: "features.demography_by_age@0.by_age_group@4.value",
          uiLabel: "Age 45 to 54",
          checked: false
        },
        {
          key: "features.demography_by_age@0.by_age_group@5.value",
          uiLabel: "Age 55 to 64",
          checked: false
        },
        {
          key: "features.demography_by_age@0.by_age_group@6.value",
          uiLabel: "Age 65 and over",
          checked: false
        }
      ]
    },
    {
      key: "features.audience_geo",
      label: "Location: by country",
      rows: [...Array(5).fill({})].map((r, index) => ({
        uiLabel: `#${index + 1}`,
        key: `features.audience_geo.data@${index}.name,features.audience_geo.data@${index}.prc`,
        checked: index <= 2
      }))
    },
    {
      key: "gender",
      label: "Gender",
      rows: [
        {
          key: "features.demography_by_age@1.value",
          uiLabel: "Women",
          checked: true
        },
        {
          key: "features.demography_by_age@0.value",
          uiLabel: "Men",
          checked: true
        }
      ]
    }
  ]
};
