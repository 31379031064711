import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";
import { prettifyDate } from "@relatable/helpers/format";
import { Button } from "@relatable/ui/Button";
import { Table } from "@relatable/ui/Table";

import { useContactFormQuery } from "./generated";

const regionNamesInEnglish = new Intl.DisplayNames(["en"], { type: "region" });

const tryParseRegionName = (code: string) => {
  try {
    return regionNamesInEnglish.of(code);
  } catch {
    return code;
  }
};

const normalizeUsername = (str: string | null | undefined) => {
  if (!str) return null;
  let username = str.trim();
  if (username.startsWith("@")) return username.slice(1);
  username = username.replace(/^(https?:\/\/)/, "");
  username = username.replace(/^(www\.)?(instagram|tiktok|youtube|snapchat)\.com\/@?/, "");
  // trim trailing slash and query parameters
  username = username.replace(/\/?\?(.*)+$/, "");
  return username;
};

export const ContactFormData: React.FC = () => {
  const MAX_PER_PAGE = 20;
  const { data, loading, fetchMore } = useContactFormQuery({
    variables: { limit: MAX_PER_PAGE, offset: 0 }
  });

  const hasMore = Boolean(
    data && data.contact_form.length !== 0 && data.contact_form.length % MAX_PER_PAGE === 0
  );

  return (
    <Root>
      <Table
        tableId="curation-applicants"
        loading={loading}
        rows={
          data?.contact_form?.map(c => {
            const r = {
              key: c.id,
              ...c
            };
            return r;
          }) ?? []
        }
        columns={[
          {
            field: "created_at",
            headerName: "Date",
            renderCell: ({ value }) => prettifyDate(value),
            minWidth: 110
          },
          {
            field: "full_name",
            headerName: "Name"
          },
          {
            field: "email",
            headerName: "Email"
          },
          {
            field: "markets",
            headerName: "Markets",
            renderCell: ({ value }) =>
              (Array.isArray(value) ? value : [value])
                .map(code => tryParseRegionName(code))
                .join(", ")
          },
          {
            field: "verticals",
            headerName: "Verticals",
            renderCell: ({ value }) =>
              (Array.isArray(value) ? value : [value])
                .map(v => data?.verticals.find(item => item.id === v)?.name ?? "?")
                .join(", ")
          },
          {
            field: "ig_username",
            headerName: "Instagram username",
            maxWidth: 150,
            renderCell: ({ value }) => (
              <Tooltip title={value}>
                <span>{normalizeUsername(value)}</span>
              </Tooltip>
            )
          },
          {
            field: "tt_username",
            headerName: "TikTok username",
            maxWidth: 150,
            renderCell: ({ value }) => (
              <Tooltip title={value}>
                <span>{normalizeUsername(value)}</span>
              </Tooltip>
            )
          },
          {
            field: "yt_username",
            headerName: "YouTube username",
            maxWidth: 150,
            renderCell: ({ value }) => (
              <Tooltip title={value}>
                <span>{normalizeUsername(value)}</span>
              </Tooltip>
            )
          },
          {
            field: "sc_username",
            headerName: "Snapchat username",
            maxWidth: 150,
            renderCell: ({ value }) => (
              <Tooltip title={value}>
                <span>{normalizeUsername(value)}</span>
              </Tooltip>
            )
          }
        ]}
      />
      <Button
        disabled={loading || !hasMore}
        onClick={() =>
          fetchMore({
            variables: { offset: data?.contact_form.length },
            updateQuery: (prev, { fetchMoreResult }) => ({
              contact_form: [...prev.contact_form, ...fetchMoreResult.contact_form],
              verticals: prev.verticals
            })
          })
        }
        style={{ marginTop: 10 }}
      >
        Load more
      </Button>
    </Root>
  );
};

const Root = styled.div`
  > .row {
    display: flex;
  }
`;
