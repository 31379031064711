import type { FC } from "react";

import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { NavigationButton } from "components/ui/NavigationButton";

import { LinkEditorFields } from "./LinkEditorFields";
import { useLinkDetailsQuery } from "./generated";

export const LinkEditor: FC = () => {
  const { linkId } = useParams<{ linkId: string }>();
  const { loading, data: accountData } = useLinkDetailsQuery({
    variables: { id: Number(linkId) },
    skip: !linkId
  });

  const details = accountData?.link_clicks_by_pk;

  if (linkId && (loading || !details)) {
    return <Loader />;
  }

  return (
    <>
      <NavigationButton style={{ width: "fit-content" }} pathname="/links" />

      <LinkEditorFields details={details} />
    </>
  );
};
