import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";

import { Title } from "lib/styled";

export const ReportNotFound: React.FC<{
  username?: string | null;
  requestReport: () => void;
  error?: string;
  report?: {
    not_available?: boolean | null;
    state?: string | null;
  } | null;
}> = ({ requestReport, username, error, report }) => {
  if (error) {
    return (
      <Root>
        <Title>{error}</Title>
      </Root>
    );
  }

  if (!username) {
    return (
      <Root>
        <Title>User not found..</Title>
      </Root>
    );
  }

  if (report?.not_available) {
    return (
      <Root>
        <Title>Report not available for {username}</Title>
      </Root>
    );
  }

  if (report?.state && report.state.indexOf("pending") > -1) {
    return (
      <Root>
        <Title>Report for {username} is being generated, please come back in a few minutes!</Title>
      </Root>
    );
  }

  return (
    <Root>
      <Title>Report not found for {username}</Title>
      <Button onClick={requestReport}>Request report</Button>
    </Root>
  );
};

const Root = styled.div``;
