import { type FC, useMemo, useState } from "react";

import styled from "@emotion/styled";

import { debounce } from "@mui/material";
import type { Campaign_Posts_Set_Input } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { NavigationButton } from "components/ui/NavigationButton";
import { Title } from "lib/styled";
import { ContentDisplay } from "modules/campaign/common/ContentDisplay";

import { ThumbDown, ThumbUp } from "@mui/icons-material";
import { FeedbackState } from "./FeedbackState";
import {
  CampaignPostDocument,
  type CampaignPostQuery,
  useCampaignPostQuery,
  useUpdateCampaignPostMutation
} from "./generated";

const getDefaultValues = (
  stat: NonNullable<CampaignPostQuery["campaign_posts_by_pk"]> | undefined | null
) => ({
  impressions: String(stat?.impressions || ""),
  actual_reach: String(stat?.actual_reach || ""),
  saved: String(stat?.saved || ""),
  video_views: String(stat?.video_views || "")
});

export const Post: FC = () => {
  const { postId, campaignStub } = useParams<{ campaignStub: string; postId: string }>();
  const snackbar = useSnackbar();

  const [isApprovedLocal, setIsApprovedLocal] = useState(false);

  const { data, loading } = useCampaignPostQuery({
    onCompleted: ({ campaign_posts_by_pk: post }) => {
      setIsApprovedLocal(Boolean(post?.approved));
      setValues(getDefaultValues(post));
    },
    variables: {
      postId: Number(postId),
      campaignStub: campaignStub ?? ""
    }
  });

  const campaignPost = data?.campaign_posts_by_pk;
  const assets = (() => {
    if (campaignPost?.assets.length) return campaignPost?.assets;
    if (campaignPost?.internal_url) return [campaignPost?.internal_url];
    if (campaignPost?.image_url) return [campaignPost?.image_url];
    return [];
  })();

  const [values, setValues] = useState<{
    impressions: string;
    actual_reach: string;
    saved: string;
    video_views: string;
  }>(() => getDefaultValues(campaignPost));

  const [updatePost, { loading: updatePostLoading }] = useUpdateCampaignPostMutation({
    onError: error => snackbar.error(error.message),
    refetchQueries: [CampaignPostDocument],
    awaitRefetchQueries: true
  });

  const handleApprovePost = async (approved: boolean) => {
    if (!campaignPost?.id) return;
    await updatePost({
      variables: {
        postId: campaignPost.id,
        _set: {
          approved,
          approved_at: approved ? new Date().toISOString() : undefined
        }
      }
    });

    snackbar.success(`Post ${approved ? "" : "un"}approved`);
  };

  const debouncedInputChange = useMemo(
    () =>
      debounce(async (set: Campaign_Posts_Set_Input) => {
        await updatePost({
          variables: {
            postId: Number(postId),
            _set: set
          }
        });
        snackbar.success("Post statistics updated");
      }, 1000),
    [postId, updatePost, snackbar]
  );

  const handleInputChange = (inputValues: Partial<typeof values>) => {
    if (!campaignPost) return;
    const set: Campaign_Posts_Set_Input = {};
    if (inputValues.actual_reach) set.actual_reach = Math.round(Number(inputValues.actual_reach));
    if (inputValues.impressions) set.impressions = Math.round(Number(inputValues.impressions));
    if (inputValues.saved) set.saved = Math.round(Number(inputValues.saved));
    if (inputValues.video_views) set.video_views = Math.round(Number(inputValues.video_views));
    debouncedInputChange(set);
    setValues(prev => ({ ...prev, ...inputValues }));
  };

  const handleTrackingUrlChange = async (url: string | null | undefined) => {
    if (!campaignPost?.id) return;
    await updatePost({
      variables: {
        postId: campaignPost.id,
        _set: {
          track_url: url
        }
      }
    });
    snackbar.success("Post updated");
  };

  if (loading || updatePostLoading) return <Loader />;

  const isApproved = Boolean(campaignPost?.approved);
  return (
    <>
      <NavigationButton
        style={{ width: "fit-content" }}
        pathname={`/campaign/${campaignStub}/posts`}
      />
      <Title>Post - Video or Photo by @{campaignPost?.user?.user_instagram?.username}</Title>
      <MediaContainer>
        {assets[0] ? (
          <ContentDisplay
            url={assets[0]}
            thumbnail={assets[0]}
            status={isApprovedLocal ? "approved" : ""}
          />
        ) : null}

        <PostStateWrapper>
          <Checkbox
            onChange={() => setIsApprovedLocal(prev => !prev)}
            label={
              isApprovedLocal ? (
                <ThumbUp style={{ color: palette.primary.green }} />
              ) : (
                <ThumbDown style={{ color: palette.secondary.gold }} />
              )
            }
            checked={isApprovedLocal}
            disabled={isApproved}
          />
          {isApproved ? (
            <>
              <TextInput
                label="Impressions"
                type="number"
                value={values.impressions}
                onChange={impressions => handleInputChange({ impressions })}
              />
              <TextInput
                label="Actual reach"
                type="number"
                value={values.actual_reach}
                onChange={actualReach => handleInputChange({ actual_reach: actualReach })}
              />
              <TextInput
                label="Saved"
                type="number"
                value={values.saved}
                onChange={saved => handleInputChange({ saved })}
              />
              {campaignPost?.media_type === "VIDEO" ? (
                <TextInput
                  label="Video views"
                  type="number"
                  value={values.video_views}
                  onChange={videoViews => handleInputChange({ video_views: videoViews })}
                />
              ) : null}
              <Select
                label="Tracking url"
                onChange={value => handleTrackingUrlChange(value)}
                value={campaignPost?.track_url}
                options={
                  campaignPost?.user?.campaign_users[0]?.tracking?.shortlinks?.map(url => ({
                    label: url,
                    value: url
                  })) || []
                }
              />
            </>
          ) : null}
        </PostStateWrapper>
      </MediaContainer>

      {/* Show rest of the carousel assets */}
      {assets.slice(1).map(url => (
        <MediaContainer key={url}>
          <ContentDisplay url={url} thumbnail={url} status={isApprovedLocal ? "approved" : ""} />
        </MediaContainer>
      ))}

      <FeedbackState
        contentName="post"
        approved_at={campaignPost?.approved_at}
        isApproved={isApproved}
        isApprovedLocal={isApprovedLocal}
      />
      <Button
        style={{ minWidth: 200, marginTop: 50, marginLeft: "auto" }}
        className="send-button"
        disabled={isApproved || !isApprovedLocal}
        size="full"
        onClick={() => handleApprovePost(isApprovedLocal)}
      >
        Approve
      </Button>
    </>
  );
};

const MediaContainer = styled.div`
  display: flex;
  margin: 25px 0px;
  padding-right: 50px;
`;

const PostStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
