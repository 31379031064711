import type { FC } from "react";

import { CircularProgress } from "@mui/material";
import { prettifyDate, prettifyNumber, prettifyPercentages } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import { Table, type TableColumn } from "@relatable/ui/Table";
import { Link, useParams } from "react-router-dom";

import { Center } from "lib/styled";
import { useLinksByKeyQuery } from "modules/links/generated";
import { extractRltToKey } from "modules/links/util";

import { Check, Close } from "@mui/icons-material";
import { LinksCell } from "./LinksCell";
import { NoteCell } from "./NoteCell";
import { useCampaignSettingTimeFramesQuery } from "./generated";

export const StoriesTable: FC = () => {
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  const { data, loading: dataLoading } = useCampaignSettingTimeFramesQuery({
    variables: {
      stubs: campaignStubs
    }
  });

  const rltToKeys = (
    data?.campaigns?.flatMap(c => c.campaign_stories.flatMap(s => s.campaign_story_clips)) ?? []
  )
    .map(p => extractRltToKey(p.track_url))
    .filter(Boolean) as string[];

  const { data: linkData, loading: linksLoading } = useLinksByKeyQuery({
    variables: { keys: rltToKeys },
    skip: !rltToKeys.length
  });

  if (dataLoading || linksLoading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  const campaignContentSettings =
    data?.campaigns.flatMap(campaign => campaign?.campaign_content_settings) || [];
  const campaignUsers = data?.campaigns.flatMap(campaign => campaign?.campaign_users) || [];
  const campaignStories = data?.campaigns.flatMap(campaign => campaign?.campaign_stories) || [];

  const rows = campaignContentSettings.flatMap(contentSetting => {
    const campaignUserStoryMatches = campaignUsers.map(cu => {
      const story = campaignStories.find(
        cs => cs.content_setting_id === contentSetting.id && cu.user?.id === cs.user_id
      );

      const filteredClips =
        story?.campaign_story_clips?.filter(c => c.content_setting_id === contentSetting.id) ?? [];

      const maxLinkClicks = Math.max(
        ...filteredClips.map(c => {
          const bitlyClicks = c.link_clicks ?? 0;
          const postRltLink = linkData?.link_clicks.find(
            lc => lc.key === extractRltToKey(c.track_url)
          );
          return bitlyClicks + (postRltLink?.clicks ?? 0);
        })
      );

      const clipsAggregate = filteredClips.length
        ? {
            count: filteredClips.length,
            maxActualReach: Math.max(...filteredClips.map(c => c.actual_reach ?? 0)),
            maxCreatedAt: filteredClips[0].created_at, // it's sorted from gql
            maxImpressions: Math.max(...filteredClips.map(c => c.impressions ?? 0)),
            maxLinkClicks,
            minActualReach: Math.min(...filteredClips.map(c => c.actual_reach ?? 0))
          }
        : null;

      return {
        userId: cu.user?.id,
        username: cu.user?.user_instagram?.username || "",
        campaignUserId: cu.id,
        userFollowers: cu.user?.user_instagram?.followed_by || 0,
        userCampaignId: cu.id,
        campaignStub: cu.campaign?.stub,

        campaignStoryId: story?.id ?? null,
        approved: story?.approved ?? false,
        notes: story?.notes ?? "",
        clipsAggregate
      };
    });

    return campaignUserStoryMatches.map(match => {
      const matchedUserProps = {
        key: `${match?.userId}_${contentSetting.id}_${match?.campaignStoryId}`,
        id: `${match?.userId}${contentSetting.id}`,
        numberOfPosts: contentSetting.number_of_posts,
        contentSettingsFrom: contentSetting.publish_date_min,
        contentSettingsTo: contentSetting.publish_date_max,
        totalReach: match?.userFollowers,
        campaignStub: match?.campaignStub || "",
        userId: match?.userId,
        contentSettingId: contentSetting.id,
        username: match?.username || "",
        notes: match.notes,
        links: {
          storyId: Number(match.campaignStoryId),
          campaignStub: match?.campaignStub || ""
        }
      };

      if (!match?.campaignStoryId) {
        return {
          ...matchedUserProps,
          approvedClips: 0,
          latestClipDate: null,
          actualReach: null,
          impressions: null,
          totalFollowedBy: null,
          vtr: null,
          linkClicks: null,
          reachCtr: null,
          actualReachCtr: null
        };
      }

      const aggregate = match?.clipsAggregate;
      const actualReach = aggregate?.maxActualReach;
      const linkClicks = aggregate?.maxLinkClicks;
      return {
        ...matchedUserProps,
        storyId: Number(match.campaignStoryId),
        approved: Boolean(match.approved),
        notes: match.notes,
        approvedClips: aggregate?.count || 0,
        latestClipDate: prettifyDate(aggregate?.maxCreatedAt),
        actualReach,
        impressions: aggregate?.maxImpressions,
        totalFollowedBy: prettifyPercentages({ total: match.userFollowers, of: actualReach }),
        vtr: prettifyPercentages({
          total: aggregate?.maxActualReach,
          of: aggregate?.minActualReach
        }),
        linkClicks,
        reachCtr: prettifyPercentages({ total: match.userFollowers, of: linkClicks }),
        actualReachCtr: prettifyPercentages({ total: actualReach, of: linkClicks })
      };
    });
  });

  const columns: TableColumn<typeof rows>[] = [
    {
      headerName: "User",
      field: "username",
      minWidth: 150,
      renderCell: ({ row, value }) => (
        <Link to={`/user/${row.userId}`}>
          {"approved" in row && row.approved ? (
            <Check style={{ marginRight: 10, color: palette.secondary.green }} />
          ) : (
            <Close style={{ marginRight: 10, color: palette.secondary.red }} />
          )}

          {value}
        </Link>
      )
    },
    {
      headerName: "Prod note",
      field: "notes",
      renderCell: ({ value, row }) =>
        "storyId" in row && row.storyId ? (
          <NoteCell note={value} campaignStoryId={row.storyId} />
        ) : (
          "-"
        )
    },
    {
      headerName: "Content setting",
      field: "contentSettingsFrom",
      minWidth: 170,
      renderCell: ({ value, row }) =>
        value ? (
          <>
            {prettifyDate(value)} - {prettifyDate(row.contentSettingsTo)}
          </>
        ) : (
          "-"
        )
    },
    {
      headerName: "Approved clips",
      field: "approvedClips",
      minWidth: 115,
      renderCell: ({ row, value }) => (
        <Link
          to={`/campaign/${row.campaignStub}/content/story/${row.userId}/${row.contentSettingId}`}
        >
          {value} / {row.numberOfPosts}
        </Link>
      )
    },
    { headerName: "Latest clip", field: "latestClipDate" },
    { headerName: "Reach", field: "totalReach", renderCell: ({ value }) => prettifyNumber(value) },
    {
      headerName: "Actual reach",
      field: "actualReach",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Impressions",
      field: "impressions",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    { headerName: "Audience reach", field: "totalFollowedBy" },
    { headerName: "VTR", field: "vtr" },
    {
      headerName: "Links",
      field: "links",
      renderCell: ({ value }) =>
        value.storyId ? (
          <LinksCell storyId={value.storyId} campaignStub={value.campaignStub} />
        ) : null
    },
    { headerName: "Clicks", field: "linkClicks", renderCell: ({ value }) => prettifyNumber(value) },
    { headerName: "Reach CTR", field: "reachCtr" },
    { headerName: "Actual reach CTR", field: "actualReachCtr" }
  ];

  return (
    <Table
      tableId="campaign-stories"
      sortable
      canSelectColumns
      canExportCSV
      stickyColumn
      rows={rows}
      columns={columns}
    />
  );
};
