import type { FC } from "react";

import styled from "@emotion/styled";
import { Card, CardContent, CardHeader, Divider, Paper, Tooltip } from "@mui/material";
import { prettifyDate, prettifyNumber, prettifyPercentages, sum } from "@relatable/helpers";

import { LinearProgress } from "components/ui/LinearProgress";

import { useCampaignPostStatsQuery } from "./generated";

const mergeHashtags = (a: string | null, b: string | null) => {
  return Array.from(new Set([...(a ?? "").split(/[, ]/), ...(b ?? "").split(/[, ]/)])).join(" ");
};
export const Stats: FC<{ campaignStubs: string[] }> = ({ campaignStubs }) => {
  const { data } = useCampaignPostStatsQuery({
    variables: { stubs: campaignStubs }
  });
  const posts = data?.campaign_posts || [];
  const reels = data?.campaign_reels || [];
  const campaigns = data?.campaigns || [];

  const postsData = posts.reduce(
    (acc, post) => {
      const { comments, likes } = post || {};
      const _followers = post?.user?.user_instagram?.followed_by || 0;

      const totalInteractions = (comments || 0) + (likes || 0) + (post?.saved || 0);

      const actualReach = post?.actual_reach;
      const impressions = post?.impressions;

      acc.actualReachEngagementSum += actualReach ? totalInteractions / actualReach : 0;
      acc.frequency += impressions && actualReach ? impressions / actualReach : 0;
      acc.audienceReachSum += _followers && actualReach ? actualReach / _followers : 0;
      acc.postEngagementSum += totalInteractions / _followers || 0;
      acc.saved += post?.saved || 0;
      acc.assets += post.assets?.length ? post.assets.length : 1;
      acc.postsWithReportedPerformance += impressions && actualReach ? 1 : 0;
      acc.followers += _followers;

      if (actualReach) acc.actualReachedPosts += 1;
      return acc;
    },
    {
      assets: 0,
      followers: 0,
      postsWithReportedPerformance: 0,
      saved: 0,
      postEngagementSum: 0,
      audienceReachSum: 0,
      actualReachedPosts: 0,
      frequency: 0,
      actualReachEngagementSum: 0
    }
  );

  const {
    followers,
    assets,
    postsWithReportedPerformance,
    saved,
    postEngagementSum,
    audienceReachSum,
    actualReachedPosts,
    actualReachEngagementSum,
    frequency
  } = reels.reduce((acc, reel) => {
    const _followers = reel.user?.user_instagram?.followed_by || 0;
    return {
      ...acc,
      followers: acc.followers + _followers,
      postsWithReportedPerformance: acc.postsWithReportedPerformance + (reel.actual_reach ? 1 : 0),
      saved: acc.saved + (reel.saved || 0),
      postEngagementSum: acc.postEngagementSum + (reel.total_interactions || 0) / _followers,
      audienceReachSum: acc.audienceReachSum + (reel.actual_reach || 0) / _followers,
      actualReachedPosts: reel.actual_reach ? acc.actualReachedPosts : acc.actualReachedPosts + 1,
      actualReachEngagementSum:
        acc.actualReachEngagementSum + (reel.total_interactions || 0) / (reel.actual_reach || 0),
      frequency: acc.frequency + (reel.impressions_legacy || 0) / (reel.actual_reach || 0)
    };
  }, postsData);

  const { targetReachPosts, targetPosts, hashtags, avgPostContentCount } = campaigns.reduce(
    (acc, campaign) => {
      const hasContentSettings = campaign.features?.includes("content-settings");
      const postContentCount = hasContentSettings
        ? campaign.campaign_content_settings.length
        : campaign.number_of_posts || 0;

      acc.campaignId = campaign.id;
      acc.targetPosts += postContentCount * (campaign?.target_participants || 0);
      acc.avgPostContentCount += postContentCount;
      acc.hashtags = mergeHashtags(acc.hashtags, campaign?.hashtags || "");
      acc.targetReachPosts +=
        ((campaign?.target_reach || 0) / (campaign?.number_of_posts || 0)) * postContentCount;

      return acc;
    },
    {
      targetReachPosts: 0,
      targetPosts: 0,
      campaignId: 0,
      hashtags: "",
      avgPostContentCount: 0,
      assets: 0
    }
  );

  const postEngagement = postEngagementSum / posts.length;

  const leftCards = [
    {
      title: "Reach (Audience size)",
      value: prettifyNumber(followers)
    },
    {
      title: "Assets",
      value: prettifyNumber(assets)
    },
    {
      title: "Posts with reported performance data",
      value: prettifyNumber(postsWithReportedPerformance)
    },
    {
      title: "Actual reach (int.)",
      value: data
        ? prettifyNumber(
            sum(posts.map(p => p?.actual_reach || 0)) + sum(reels.map(r => r.actual_reach || 0))
          )
        : "…",
      tooltip:
        "The number of people (unique) who saw the post. Note that there may be overlap between posts and accounts"
    },
    {
      title: "Impressions",
      value: data
        ? prettifyNumber(
            sum(posts.map(p => p?.impressions || 0)) +
              sum(reels.map(r => r.impressions_legacy || 0))
          )
        : "…",
      tooltip: "The number of times the posts were shown to someone (non unique)."
    },
    {
      title: "Likes",
      value: data
        ? prettifyNumber(sum(posts.map(p => p?.likes || 0)) + sum(reels.map(r => r.likes || 0)))
        : "…"
    },
    {
      title: "Comments",
      value: data
        ? prettifyNumber(
            sum(posts.map(p => p?.comments || 0)) + sum(reels.map(r => r.comments || 0))
          )
        : "…"
    },
    {
      title: "Saved",
      value: prettifyNumber(saved)
    },
    {
      title: "Reach engagement (int.)",
      value: prettifyNumber(postEngagement, { percentages: true }) || "…"
    }
  ];

  const rightCards = [
    {
      title: "Posts and Reels",
      value: prettifyNumber(posts.length + reels.length)
    },

    {
      title: "Avg. posts/person",
      value: prettifyNumber(avgPostContentCount)
    },

    {
      title: "First publish",
      value: prettifyDate(
        data?.campaign_content_settings_aggregate?.aggregate?.min?.publish_date_min
      )
    },
    {
      title: "Last publish",
      value: prettifyDate(
        data?.campaign_content_settings_aggregate?.aggregate?.max?.publish_date_max
      )
    },
    {
      title: "Audience reach (Average)",
      value: prettifyPercentages({ of: audienceReachSum, total: actualReachedPosts }) || "…",
      tooltip: "The number of people who saw the post divided by the follower count"
    },
    {
      title: "Frequency (Average)",
      value: prettifyPercentages({ of: frequency, total: actualReachedPosts }) || "…",
      tooltip: "The number of impressions divided by the actual reach"
    },
    {
      title: "Actual reach engagement (int.)",
      value: prettifyPercentages({ of: actualReachEngagementSum, total: actualReachedPosts }) || "…"
    },
    {
      title: "#Hashtags",
      value: hashtags || "…"
    }
  ];

  return (
    <Root>
      <HeadStatsContainer>
        <LinearProgress label="Post reach" value={followers} max={targetReachPosts} />
        <LinearProgress
          label="Posts & Reach"
          value={posts.length + reels.length}
          max={targetPosts}
        />
      </HeadStatsContainer>

      <Divider />

      <StatsContainer>
        <LeftSection elevation={10}>
          {leftCards.map(card => (
            <Tooltip key={card.title} title={card.tooltip || ""}>
              <Card>
                <CardHeader subheader={card.title} />
                <SCardContent>{card.value}</SCardContent>
              </Card>
            </Tooltip>
          ))}
        </LeftSection>

        <RightSection elevation={10}>
          {rightCards.map(card => (
            <Card key={card.title}>
              <CardHeader subheader={card.title} />
              <SCardContent>{card.value}</SCardContent>
            </Card>
          ))}
        </RightSection>
      </StatsContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const HeadStatsContainer = styled.div`
  display: grid;
  gap: 2%;
  grid-template-columns: 49% 49%;
`;

const SCardContent = styled(CardContent)`
  padding-top: 0 !important;
  font-size: 20px;
`;

const LeftSection = styled(Paper)`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  padding: 20px;
`;

const RightSection = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  > div {
    flex-grow: 1;
  }
`;

const StatsContainer = styled.div`
  display: grid;
  gap: 2%;
  grid-template-columns: 49% 49%;
`;
