import { type FC, useMemo, useState } from "react";

import { Button } from "@relatable/ui/Button";
import { Select } from "@relatable/ui/Select";
import { useNavigate, useParams } from "react-router-dom";

import { useUpdateCampaignMutation } from "modules/project/CampaignUpdate/generated";

import { ContentListDocument, useContentListQuery } from "./generated";

export const MessageOverrideEditor: FC = () => {
  const navigate = useNavigate();
  const { campaignStub, messageId } = useParams<{ campaignStub: string; messageId: string }>();

  const [value, setValue] = useState(null);

  const { data } = useContentListQuery({
    variables: { campaignStub: campaignStub ?? "", campaignStubLike: `${campaignStub}%` },
    skip: !campaignStub,
    onCompleted({ campaigns }) {
      let selectValue = campaigns[0]?.message_override?.[messageId ?? ""];
      // only string values are supported by the selectbox
      if (selectValue === false) selectValue = "disabled";
      if (!selectValue) selectValue = "default";
      setValue(selectValue);
    }
  });
  const [updateCampaign, { loading }] = useUpdateCampaignMutation();

  const backUrl = `/campaign/${campaignStub}/messages`;

  const campaign = data?.campaigns[0];
  const override = campaign?.message_override?.[messageId ?? ""];

  const handleSave = async () => {
    const newOverrides = { ...campaign?.message_override };
    if (value === "disabled") {
      newOverrides[messageId ?? ""] = false;
    } else if (value === "default") {
      delete newOverrides[messageId ?? ""];
    } else {
      newOverrides[messageId ?? ""] = value;
    }

    await updateCampaign({
      variables: { id: campaign?.id ?? 0, set: { message_override: newOverrides } },
      awaitRefetchQueries: true,
      refetchQueries: [ContentListDocument]
    });

    navigate(backUrl);
  };

  const options = useMemo(() => {
    if (!data?.email_contents) return [];
    return [
      { label: "⛔️ Disabled", value: "disabled" },
      { label: "Default", value: "default" },

      // content made for the campaign
      ...data.email_contents
        .filter(c => !c.is_base_template)
        .map(c => ({
          label: c.name ?? "",
          value: c.name ?? "",
          highlight: true
        })),

      // base templates
      ...data.email_contents
        .filter(c => c.is_base_template)
        .map(c => ({ label: c.name, value: c.name ?? "" })),

      // other content not appearing in the list (for backward compatibility)
      ...(override && !data.email_contents.find(c => c.name === override)
        ? [{ label: override, value: override }]
        : [])
    ];
  }, [data, override]);

  if (!data) return null;

  return (
    <div>
      <h2>Override with message</h2>
      <p>Message: {messageId}</p>
      <br />
      <Select
        label="Override with message"
        options={options}
        value={value}
        hideNone
        onChange={setValue}
        style={{ width: 300 }}
      />
      <br />
      <br />
      <Button disabled={loading} href={backUrl} style={{ marginRight: 10 }}>
        Back
      </Button>
      <Button disabled={loading} onClick={handleSave}>
        Save
      </Button>
    </div>
  );
};
