import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoryLinksQueryVariables = Types.Exact<{
  stub: Types.Scalars['String']['input'];
  storyId: Types.Scalars['Int']['input'];
}>;


export type StoryLinksQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, campaign_stories: Array<{ __typename?: 'campaign_story', id: number, user?: { __typename?: 'users', id: number, user_instagram?: { __typename?: 'users_instagram', id: number, username: string } | null } | null, campaign_story_clips: Array<{ __typename?: 'campaign_story_clip', id: number, track_url?: string | null }> }> }> };

export type CampaignSettingTimeFramesQueryVariables = Types.Exact<{
  stubs: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type CampaignSettingTimeFramesQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, campaign_content_settings: Array<{ __typename?: 'campaign_content_settings', id: number, publish_date_max: string, publish_date_min: string, number_of_posts: number }>, campaign_users: Array<{ __typename?: 'campaign_user', id: number, campaign: { __typename?: 'campaign', id: number, stub: string }, user?: { __typename?: 'users', id: number, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null } | null }>, campaign_stories: Array<{ __typename?: 'campaign_story', id: number, user_id?: number | null, content_setting_id?: number | null, approved?: boolean | null, notes: string, campaign_story_clips: Array<{ __typename?: 'campaign_story_clip', id: number, content_setting_id?: number | null, track_url?: string | null, created_at?: string | null, impressions?: number | null, actual_reach?: number | null, link_clicks?: number | null }> }> }> };

export type UpdateStoryMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  set: Types.Campaign_Story_Set_Input;
}>;


export type UpdateStoryMutation = { __typename?: 'Mutation', update_campaign_story_by_pk?: { __typename?: 'campaign_story', id: number } | null };


export const StoryLinksDocument = gql`
    query storyLinks($stub: String!, $storyId: Int!) {
  campaigns: campaign(where: {stub: {_eq: $stub}}) {
    id
    campaign_stories(where: {id: {_eq: $storyId}}) {
      id
      user {
        id
        user_instagram {
          id
          username
        }
      }
      campaign_story_clips(
        distinct_on: track_url
        where: {track_url: {_is_null: false}}
      ) {
        id
        track_url
      }
    }
  }
}
    `;

/**
 * __useStoryLinksQuery__
 *
 * To run a query within a React component, call `useStoryLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryLinksQuery({
 *   variables: {
 *      stub: // value for 'stub'
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useStoryLinksQuery(baseOptions: Apollo.QueryHookOptions<StoryLinksQuery, StoryLinksQueryVariables> & ({ variables: StoryLinksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryLinksQuery, StoryLinksQueryVariables>(StoryLinksDocument, options);
      }
export function useStoryLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryLinksQuery, StoryLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryLinksQuery, StoryLinksQueryVariables>(StoryLinksDocument, options);
        }
export function useStoryLinksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StoryLinksQuery, StoryLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StoryLinksQuery, StoryLinksQueryVariables>(StoryLinksDocument, options);
        }
export type StoryLinksQueryHookResult = ReturnType<typeof useStoryLinksQuery>;
export type StoryLinksLazyQueryHookResult = ReturnType<typeof useStoryLinksLazyQuery>;
export type StoryLinksSuspenseQueryHookResult = ReturnType<typeof useStoryLinksSuspenseQuery>;
export type StoryLinksQueryResult = Apollo.QueryResult<StoryLinksQuery, StoryLinksQueryVariables>;
export const CampaignSettingTimeFramesDocument = gql`
    query campaignSettingTimeFrames($stubs: [String!]!) {
  campaigns: campaign(where: {stub: {_in: $stubs}}) {
    id
    campaign_content_settings(where: {type: {_eq: "story"}}) {
      id
      publish_date_max
      publish_date_min
      number_of_posts
    }
    campaign_users(where: {state: {_in: ["approved", "confirmed"]}}) {
      id
      campaign {
        id
        stub
      }
      user {
        id
        user_instagram {
          id
          username
          followed_by
        }
      }
    }
    campaign_stories {
      id
      user_id
      content_setting_id
      approved
      notes
      campaign_story_clips(order_by: {created_at: desc_nulls_last}) {
        id
        content_setting_id
        track_url
        created_at
        impressions
        actual_reach
        link_clicks
      }
    }
  }
}
    `;

/**
 * __useCampaignSettingTimeFramesQuery__
 *
 * To run a query within a React component, call `useCampaignSettingTimeFramesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignSettingTimeFramesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignSettingTimeFramesQuery({
 *   variables: {
 *      stubs: // value for 'stubs'
 *   },
 * });
 */
export function useCampaignSettingTimeFramesQuery(baseOptions: Apollo.QueryHookOptions<CampaignSettingTimeFramesQuery, CampaignSettingTimeFramesQueryVariables> & ({ variables: CampaignSettingTimeFramesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignSettingTimeFramesQuery, CampaignSettingTimeFramesQueryVariables>(CampaignSettingTimeFramesDocument, options);
      }
export function useCampaignSettingTimeFramesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignSettingTimeFramesQuery, CampaignSettingTimeFramesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignSettingTimeFramesQuery, CampaignSettingTimeFramesQueryVariables>(CampaignSettingTimeFramesDocument, options);
        }
export function useCampaignSettingTimeFramesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignSettingTimeFramesQuery, CampaignSettingTimeFramesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignSettingTimeFramesQuery, CampaignSettingTimeFramesQueryVariables>(CampaignSettingTimeFramesDocument, options);
        }
export type CampaignSettingTimeFramesQueryHookResult = ReturnType<typeof useCampaignSettingTimeFramesQuery>;
export type CampaignSettingTimeFramesLazyQueryHookResult = ReturnType<typeof useCampaignSettingTimeFramesLazyQuery>;
export type CampaignSettingTimeFramesSuspenseQueryHookResult = ReturnType<typeof useCampaignSettingTimeFramesSuspenseQuery>;
export type CampaignSettingTimeFramesQueryResult = Apollo.QueryResult<CampaignSettingTimeFramesQuery, CampaignSettingTimeFramesQueryVariables>;
export const UpdateStoryDocument = gql`
    mutation updateStory($id: Int!, $set: campaign_story_set_input!) {
  update_campaign_story_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
  }
}
    `;
export type UpdateStoryMutationFn = Apollo.MutationFunction<UpdateStoryMutation, UpdateStoryMutationVariables>;

/**
 * __useUpdateStoryMutation__
 *
 * To run a mutation, you first call `useUpdateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryMutation, { data, loading, error }] = useUpdateStoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateStoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryMutation, UpdateStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoryMutation, UpdateStoryMutationVariables>(UpdateStoryDocument, options);
      }
export type UpdateStoryMutationHookResult = ReturnType<typeof useUpdateStoryMutation>;
export type UpdateStoryMutationResult = Apollo.MutationResult<UpdateStoryMutation>;
export type UpdateStoryMutationOptions = Apollo.BaseMutationOptions<UpdateStoryMutation, UpdateStoryMutationVariables>;