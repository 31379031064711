import { Button } from "@relatable/ui/Button";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import type { Dispatch, FC, SetStateAction } from "react";
import { Link } from "react-router-dom";
import type { ContentEditorFieldsType } from "./ContentEditor";
import { RichTextEditor } from "./RichTextEditor";
import { normalizeContentName } from "./common";
import {
  type MandrillTemplatesQuery,
  MessageContentDocument,
  type MessageContentQuery,
  useUpdateContentMutation
} from "./generated";

export const ContentEditorFields: FC<{
  fields: ContentEditorFieldsType;
  setFields: Dispatch<SetStateAction<ContentEditorFieldsType>>;
  templates: MandrillTemplatesQuery["mandrillTemplates"];
  content: MessageContentQuery["contents"][number];
}> = ({ fields, setFields, templates, content }) => {
  const snackbar = useSnackbar();

  const [updateContent] = useUpdateContentMutation({
    refetchQueries: [MessageContentDocument],
    onError(err) {
      snackbar.error(err.message);
    },
    onCompleted() {
      snackbar.success("Template was updated");
    }
  });

  const getError = (values: Partial<typeof fields>) => {
    if (content.is_base_template) return "This is a base template which cannot be edited";
    if (!values) return "Invalid parameter";

    const mandatoryFields: (keyof typeof fields)[] = ["subject", "body"];

    for (const field of mandatoryFields) {
      if (field in values && !values[field]) return `${field} is mandatory`;
    }

    return "";
  };

  const handleUpsert = async () => {
    const trimmedFields = Object.fromEntries(
      Object.entries(fields).map(([k, v]) => (typeof v === "string" ? [k, v.trim()] : [k, v]))
    );

    const error = getError(trimmedFields);

    if (error) {
      snackbar.error(error);
      return;
    }

    if (!content?.id) {
      throw new Error("Cannot find id");
    }

    await updateContent({
      variables: {
        id: content.id,
        set: {
          name: trimmedFields.name,
          template: trimmedFields.mandrillTemplate,
          subject: trimmedFields.subject,
          data: { main: trimmedFields.body, footer: trimmedFields.footer }
        }
      }
    });
  };

  const handleFieldChange = (values: Partial<typeof fields>) => {
    if (!values) return;

    setFields(prev => ({
      ...prev,
      ...values
    }));
  };

  return (
    <>
      <TextInput
        required
        label="Content name (only lowercase a-z, 0-9 and -, name must be unique)"
        value={fields.name ?? ""}
        onChange={v => handleFieldChange({ name: normalizeContentName(v) })}
        style={{ width: "100%", marginBottom: 10 }}
      />

      <h3>Message info</h3>

      <Select
        label="Mandrill template"
        options={templates.map(template => ({ label: template.name, value: template.slug }))}
        value={fields.mandrillTemplate}
        hideNone
        onChange={mandrillTemplate =>
          handleFieldChange({ mandrillTemplate: mandrillTemplate ?? "" })
        }
        style={{ width: "100%", margin: "20px 0" }}
        disabled // normally this field should not be touched ("looks-like-a-normal-email" is the default template)
      />

      <h3>Message content</h3>

      <p>
        For instructions of how to use merge tags (variables), see{" "}
        <Link
          to="https://mailchimp.com/developer/transactional/docs/templates-dynamic-content/#mailchimp-merge-language"
          target="_blank"
          rel="noreferrer"
        >
          How to Use Merge Tags to Add Dynamic Content
        </Link>{" "}
        in Mandrill&apos;s documentation.
      </p>

      <TextInput
        required
        label="Email subject - tell what's inside the email, don't sell it"
        value={fields.subject ?? ""}
        onChange={v => handleFieldChange({ subject: v })}
        style={{ width: "100%", margin: "20px 0 10px" }}
      />

      <p>Remember about best practices so your email does not fall into spam category.</p>

      <ul>
        <li>No CAPS</li>
        <li>
          No spammy words(&quot;free&quot;, &quot;click&quot;, &quot;help&quot;,
          &quot;reminder&quot;, &quot;!&quot;, &quot;$&quot;)
        </li>
        <li>Maximum one emoji</li>
        <li>Keep it short and sweet</li>
      </ul>

      <RichTextEditor
        initialValue={fields.body ?? ""}
        onChange={html => handleFieldChange({ body: html })}
      />

      <ul>
        <li>No attachments (use links instead)</li>
        <li>Avoid lots of images and colored text</li>
      </ul>

      <TextInput
        label="Footer"
        value={fields.footer ?? ""}
        onChange={v => handleFieldChange({ footer: v })}
        style={{ width: "100%", margin: "20px 0" }}
      />

      <br />

      <Button disabled={content.is_base_template} onClick={handleUpsert}>
        Save changes
      </Button>
    </>
  );
};
