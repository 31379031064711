import {
  type CSSProperties,
  type FC,
  type PropsWithChildren,
  type ReactElement,
  useEffect,
  useRef
} from "react";

import styled from "@emotion/styled";
import { Box, Paper, Typography } from "@mui/material";
import { prettifyDate } from "@relatable/helpers";
import { Note } from "@relatable/ui/Note";

import { Prompt } from "components/Prompt";

export const SubSection: FC<PropsWithChildren<{ style?: CSSProperties }>> = ({
  children,
  style
}) => (
  <Box style={style} display="flex" justifyContent="space-between" alignItems="center" gap={0.5}>
    {children}
  </Box>
);

const Scroll: FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, []);

  return <div ref={ref} />;
};

export const Section: FC<
  PropsWithChildren<{
    fieldErrors: string[];
    submitError?: { message: string };
    sidebar: ReactElement;
    title?: string;
    updated_at: string | undefined | null;
    updatedAtNotImplemented?: boolean;
    isChanged: boolean;
  }>
> = ({
  fieldErrors,
  children,
  sidebar,
  submitError,
  title,
  updated_at,
  updatedAtNotImplemented,
  isChanged
}) => (
  <>
    <Prompt
      shouldPrompt={isChanged}
      promptMessage="You have unsaved changes, do you want to leave?"
    />

    <SectionContainer>
      <SSection variant="outlined">
        {title && <SectionTitle>{title}</SectionTitle>}
        <FieldsContainer>{children}</FieldsContainer>
      </SSection>
      <Sidebar variant="outlined">
        {sidebar}

        {fieldErrors.map(error => (
          <Note key={error} variant="error" label="Missing field!">
            {error}
          </Note>
        ))}
        {submitError && (
          <Note variant="error" label="Error">
            {submitError.message}
          </Note>
        )}
        {(fieldErrors.length || submitError) && <Scroll />}

        {title && !updatedAtNotImplemented && (
          <Typography
            style={{ marginTop: "auto" }}
            display="flex"
            variant="caption"
            color="GrayText"
          >
            Last updated: {prettifyDate(updated_at, { full: true })}
          </Typography>
        )}
        {isChanged && (
          <Note variant="warning" label="Unsaved changes">
            We noticed you changed the data within this section. Remember to click the update button
            before you leave.
          </Note>
        )}
      </Sidebar>
    </SectionContainer>
  </>
);

const Sidebar = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px !important;
  border-radius: 15px !important;
  gap: 5px;
  position: sticky;
  top: 50px;
`;

const SSection = styled(Paper)`
  border-radius: 15px !important;
  padding: 20px !important;
`;

const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: 70% auto;
  gap: 20px;
  padding: 0 50px;
  margin: 20px 0;
`;

export const SectionTitle = styled.h3`
  margin-bottom: 20px;
`;

const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 50% auto;
  gap: 20px;
  align-items: center;
  align-content: center;
`;
