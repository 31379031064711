import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import { UserInfoFragmentDoc, UserProfilePictureFragmentDoc, MediaStateFragmentDoc, CaptionStateFragmentDoc, IntercomFragmentDoc } from '../../../../../../../../packages/gql/generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignUserReviewQueryVariables = Types.Exact<{
  campaignUserId: Types.Scalars['Int']['input'];
}>;


export type CampaignUserReviewQuery = { __typename?: 'Query', campaign_user?: { __typename?: 'campaign_user', id: number, notes?: string | null, collaboration_score?: number | null, content_quality_score?: number | null, user?: { __typename?: 'users', id: number, relatable_comment: string, tag_ids: string | number[], otherCampaignUsers: Array<{ __typename?: 'campaign_user', id: number, collaboration_score?: number | null, content_quality_score?: number | null, notes?: string | null, campaign: { __typename?: 'campaign', id: number, title: string } }>, user_youtube?: { __typename?: 'users_youtube', id: number, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null } | null, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null } | null } | null };


export const CampaignUserReviewDocument = gql`
    query campaignUserReview($campaignUserId: Int!) {
  campaign_user: campaign_user_by_pk(id: $campaignUserId) {
    id
    notes
    collaboration_score
    content_quality_score
    user {
      id
      otherCampaignUsers: campaign_users(
        where: {id: {_neq: $campaignUserId}, content_quality_score: {_eq: 1}}
      ) {
        id
        collaboration_score
        content_quality_score
        notes
        campaign {
          id
          title
        }
      }
      id
      relatable_comment
      tag_ids
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useCampaignUserReviewQuery__
 *
 * To run a query within a React component, call `useCampaignUserReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignUserReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignUserReviewQuery({
 *   variables: {
 *      campaignUserId: // value for 'campaignUserId'
 *   },
 * });
 */
export function useCampaignUserReviewQuery(baseOptions: Apollo.QueryHookOptions<CampaignUserReviewQuery, CampaignUserReviewQueryVariables> & ({ variables: CampaignUserReviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignUserReviewQuery, CampaignUserReviewQueryVariables>(CampaignUserReviewDocument, options);
      }
export function useCampaignUserReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignUserReviewQuery, CampaignUserReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignUserReviewQuery, CampaignUserReviewQueryVariables>(CampaignUserReviewDocument, options);
        }
export function useCampaignUserReviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignUserReviewQuery, CampaignUserReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignUserReviewQuery, CampaignUserReviewQueryVariables>(CampaignUserReviewDocument, options);
        }
export type CampaignUserReviewQueryHookResult = ReturnType<typeof useCampaignUserReviewQuery>;
export type CampaignUserReviewLazyQueryHookResult = ReturnType<typeof useCampaignUserReviewLazyQuery>;
export type CampaignUserReviewSuspenseQueryHookResult = ReturnType<typeof useCampaignUserReviewSuspenseQuery>;
export type CampaignUserReviewQueryResult = Apollo.QueryResult<CampaignUserReviewQuery, CampaignUserReviewQueryVariables>;