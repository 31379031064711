import { useCallback } from "react";

import type { Project_Bool_Exp } from "@relatable/gql/generated-base";

import {
  useSearchBoxCampaignsLazyQuery,
  useSearchBoxListsLazyQuery,
  useSearchBoxProjectsLazyQuery,
  useSearchBoxUserLazyQuery,
  useSearchBoxUsersIgLazyQuery,
  useSearchBoxUsersScLazyQuery,
  useSearchBoxUsersTtLazyQuery,
  useSearchBoxUsersYtLazyQuery
} from "./generated";

export type SearchResult = {
  name: string;
  id: number;
  url: string;
  search?: string;
};

export const useSearch = () => {
  const [getProjects, projectParams] = useSearchBoxProjectsLazyQuery();
  const [getCampaigns, campaignParams] = useSearchBoxCampaignsLazyQuery();
  const [getLists, listParams] = useSearchBoxListsLazyQuery();
  const [getUsers, userParams] = useSearchBoxUserLazyQuery();
  const [getIGUsers, userIGParams] = useSearchBoxUsersIgLazyQuery();
  const [getTTUsers, userTTParams] = useSearchBoxUsersTtLazyQuery();
  const [getSCUsers, userSCParams] = useSearchBoxUsersScLazyQuery();
  const [getYTUsers, userYTParams] = useSearchBoxUsersYtLazyQuery();

  const getter = useCallback(
    (text: string) => {
      if (text.length === 0) return;

      const search = `%${text.replace(/\s+/g, "%")}%`;
      const projectWhere: Project_Bool_Exp = {
        _or: [
          ...(!Number.isNaN(Number(text)) ? [{ hubspot_deal_id: { _eq: text } }] : []),
          { name: { _ilike: search } },
          { stub: { _ilike: search } }
        ]
      };

      getProjects({ variables: { projectWhere } });
      getCampaigns({ variables: { search } });
      getLists({ variables: { search } });
      getUsers({ variables: { search } });
      getIGUsers({ variables: { search } });
      getTTUsers({ variables: { search } });
      getSCUsers({ variables: { search } });
      getYTUsers({ variables: { search } });
    },
    [getProjects, getCampaigns, getLists, getUsers, getIGUsers, getTTUsers, getSCUsers, getYTUsers]
  );

  const loading =
    projectParams.loading ||
    campaignParams.loading ||
    listParams.loading ||
    userParams.loading ||
    userIGParams.loading ||
    userTTParams.loading ||
    userYTParams.loading ||
    userSCParams.loading;

  const projects: SearchResult[] = (projectParams.data?.project || []).map(p => ({
    name: p.name,
    id: p.id,
    url: "/overview",
    search: `project=${encodeURIComponent(p.stub || "")}&archived=true&teamMemberAccountId=all`
  }));

  const campaigns: SearchResult[] = (campaignParams.data?.campaign || []).map(c => ({
    name: c.title,
    id: c.id,
    url: `/campaign/${c.stub}/participants`
  }));

  const lists: SearchResult[] = (listParams.data?.lists || []).map(l => ({
    name: l.name ?? "",
    id: l.id,
    url: `/curation/list-management/${l.id}`
  }));

  const users: SearchResult[] = (userParams.data?.users || []).map(u => {
    const platform = (() => {
      if (u.user_instagram) return "IG";
      if (u.user_tiktok) return "TT";
      if (u.user_snapchat) return "SC";
      if (u.user_youtube) return "YT";
      return "";
    })();

    return {
      name: `${u.email ?? ""} ${platform}`,
      id: u.id,
      url: `/user/${u.id}`
    };
  });

  const instagramUsers: SearchResult[] = (userIGParams.data?.users_instagram || []).map(i => ({
    name: `${i.username ?? ""} (IG)`,
    id: i.id,
    url: `/user/${i.user?.id}`
  }));

  const tiktokUsers: SearchResult[] = (userTTParams.data?.user_tiktok || []).map(i => ({
    name: `${i.username ?? ""} (TT)`,
    id: i.id,
    url: `/user/${i.user?.id}`
  }));

  const snapchatUsers: SearchResult[] = (userSCParams.data?.users_snapchat || []).map(i => ({
    name: `${i.username ?? ""} (SC)`,
    id: i.id,
    url: `/user/${i.user?.id}`
  }));

  const youtubeChannels: SearchResult[] = (userYTParams.data?.users_youtube || []).map(i => ({
    name: `${i.username ?? i.channel_title ?? ""} (YT)`,
    id: i.id,
    url: `/user/${i.user?.id}`
  }));

  const allUsers: SearchResult[] = [
    ...users,
    ...instagramUsers,
    ...tiktokUsers,
    ...snapchatUsers,
    ...youtubeChannels
  ];

  const uniqueUsers = allUsers.filter((x, i) => allUsers.findIndex(y => y.id === x.id) === i);

  return [
    getter,
    {
      loading,
      projects,
      campaigns,
      lists,
      users: uniqueUsers
    }
  ] as const;
};
