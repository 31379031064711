import { type FC, useState } from "react";

import { Input, InputLabel } from "@mui/material";
import { Note } from "@relatable/ui/Note";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { Popup } from "components/ui/Popup";

import {
  CampaignPostsTableDocument,
  useAddCampaignPostMutation,
  useCampaignPostUsersPopupQuery
} from "../generated";
import { FormContainer, InputContainer } from "./common";

export const AddPostPopup: FC<{
  onClose(): void;
  campaignStub: string;
}> = ({ onClose, campaignStub }) => {
  const { data } = useCampaignPostUsersPopupQuery({ variables: { stub: campaignStub } });
  const snackbar = useSnackbar();
  const [addPost] = useAddCampaignPostMutation({
    refetchQueries: [CampaignPostsTableDocument],
    awaitRefetchQueries: true,
    onCompleted: onClose,
    onError: e => snackbar.error(e.message)
  });

  const [selectedUser, setSelectedUser] = useState<number>();
  const [shortcode, setShortcode] = useState("");

  const handleSubmit = async () => {
    const campaign_id = data?.campaign[0].id;
    if (!selectedUser || !campaign_id) {
      snackbar.error("no selected User or campaign is missing an id");
      return;
    }
    await addPost({
      variables: { campaign_id, user_id: selectedUser, shortcode }
    });
  };

  // https://stackoverflow.com/a/39754971/11661273
  const isError = !shortcode || shortcode.includes("/");

  return (
    <Popup
      disabledAction={isError}
      onAction={handleSubmit}
      actionLabel="Add post"
      title="Add post"
      onClose={onClose}
    >
      <InputContainer>
        <Select
          label="Name"
          value={selectedUser}
          onChange={setSelectedUser}
          options={
            data?.campaign_user.map(({ user }) => ({
              value: user?.id,
              label: user?.user_instagram?.username || ""
            })) || []
          }
        />
        <FormContainer>
          <InputLabel htmlFor="shortcode">Shortcode (ex. BA2zPfbKLt4)</InputLabel>
          <Input
            id="shortcode"
            onChange={e => {
              setShortcode(e.target.value);
            }}
          />
          {isError && shortcode && (
            <Note label="Invalid shortcode" variant="error">
              Make sure not to copy paste the entire url but just the shortcode
            </Note>
          )}
        </FormContainer>
      </InputContainer>
    </Popup>
  );
};
