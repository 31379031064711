import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { useUpdateCampaignUserMutation } from "modules/campaign/generated";

import { Check, Close, Comment, ModeEdit } from "@mui/icons-material";
import { UserCampaignsDocument, type UserCampaignsQuery } from "../generated";

const Root = styled.div`
  position: relative;
  > h4 {
    color: ${palette.gray[60]};
    padding-bottom: 14px;
  }

  > .buttons {
    position: absolute;
    top: 0;
    right: 0;
  }

  > .field {
    width: 100%;
  }

  > .text {
    margin: 28px 12px 10px 12px;
  }
`;

export const CampaignComment: FC<{
  campaignUser: NonNullable<UserCampaignsQuery["campaign_user"][number]>;
}> = ({ campaignUser }) => {
  const snackbar = useSnackbar();

  const [notes, setNotes] = useState<string | null>(null);

  const [updateCampaignUser] = useUpdateCampaignUserMutation({
    onCompleted: () => {
      snackbar.success("Comment was saved successfully");
    },
    refetchQueries: [UserCampaignsDocument],
    awaitRefetchQueries: true
  });

  const onSave = async () => {
    await updateCampaignUser({ variables: { _set: { notes }, campaignUserId: campaignUser.id } });
    setNotes(null);
  };

  return (
    <Root>
      <h4>
        <Comment style={{ marginRight: 10 }} />
        Review Comment by CM
      </h4>

      <div className="buttons">
        {notes !== null ? (
          <>
            <Close
              style={{ color: palette.primary.gold, cursor: "pointer", marginRight: 20 }}
              onClick={() => setNotes(null)}
            />
            <Check style={{ color: palette.primary.green, cursor: "pointer" }} onClick={onSave} />
          </>
        ) : (
          <ModeEdit
            style={{ cursor: "pointer" }}
            onClick={() => setNotes(campaignUser.notes ?? "")}
          />
        )}
      </div>

      {notes === null ? (
        <div className="text">{campaignUser.notes || "(no comment)"}</div>
      ) : (
        <TextField
          className="field"
          variant="outlined"
          value={notes}
          multiline
          minRows={5}
          onChange={e => setNotes(e.target.value)}
        />
      )}
    </Root>
  );
};
