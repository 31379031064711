import { useState } from "react";

import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";

import { ReportNotFound } from "../common/ReportNotFound";
import { useRequestHypeAuditorReportMutation } from "../generated";
import { InstagramReport } from "./InstagramReport";
import {
  HypeAuditorInstagramReportDocument,
  useHypeAuditorInstagramReportQuery
} from "./generated";

export const InstagramReportContainer = () => {
  const { userId } = useParams<{ userId: string }>();
  const {
    data: {
      hypeauditor: [report] = []
    } = {},
    loading: getHypeAuditorReportLoading
  } = useHypeAuditorInstagramReportQuery({
    variables: { userId: Number(userId) },
    pollInterval: 30 * 1000
  });

  const [error, setError] = useState("");
  const [requestHypeAuditorReport, { loading: requestHypeAuditorReportLoading }] =
    useRequestHypeAuditorReportMutation({
      onError: err => setError(err.message),
      refetchQueries: [HypeAuditorInstagramReportDocument],
      awaitRefetchQueries: true
    });

  const requestReport = () => {
    setError("");
    if (!report?.user?.id) throw new Error("missing user.id");
    requestHypeAuditorReport({
      variables: {
        objects: [
          {
            users_id: report?.user.id,
            updated_at: new Date().toISOString(),
            state: "pending_rel"
          }
        ]
      }
    });
  };

  if (requestHypeAuditorReportLoading || getHypeAuditorReportLoading) {
    return <Loader />;
  }

  if (
    !report?.data ||
    report?.not_available ||
    error ||
    report?.state === "error" ||
    report?.data?.message
  ) {
    return (
      <ReportNotFound
        requestReport={requestReport}
        username={report?.user?.user_instagram?.username}
        report={report}
        error={error || report?.data?.message}
      />
    );
  }

  return <InstagramReport data={report.data} />;
};
