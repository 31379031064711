import { palette } from "@relatable/ui/Palette";
import type { FC } from "react";

import { CopyAll } from "@mui/icons-material";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";

export const CopyIcon: FC<{ text: string | number | null | undefined }> = ({ text }) => {
  const { copyToClipboard } = useCopyToClipboard();

  return (
    <CopyAll
      onClick={() => {
        if (!text) return;
        copyToClipboard(text);
      }}
      style={{
        color: palette.gray[50],
        cursor: text ? "pointer" : "not-allowed",
        marginLeft: "auto",
        marginRight: 10
      }}
    />
  );
};
