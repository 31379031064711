import type { FC } from "react";

import styled from "@emotion/styled";
import { Paper, Typography } from "@mui/material";
import { prettifyDate } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { Note } from "@relatable/ui/Note";

import { NavigateBefore, Publish } from "@mui/icons-material";
import { animateOpacity } from "lib/styled";

const SPaper = styled(Paper)`
  bottom: 0;
  padding: 10px 20px;
  width: calc(100% - 100px);
  left: 50px;
  position: fixed;
  background: white;
  z-index: 100;
  animation: ${animateOpacity} 1000ms ease;
  display: flex;
`;

export const Footer: FC<{
  handleSubmit(): void;
  isLoading: boolean;
  backHref: string | undefined | null;
  lastUpdatedAt: string | undefined | null;
  isArchived: boolean;
}> = ({ handleSubmit, isLoading, isArchived, backHref, lastUpdatedAt }) => (
  <SPaper variant="outlined">
    <Button icon={<NavigateBefore />} disabled={!backHref} href={backHref || ""}>
      Back
    </Button>

    {isArchived && (
      <div style={{ width: 400, position: "relative", margin: "auto" }}>
        <Note
          style={{
            width: 400,
            position: "absolute",
            bottom: 0,
            left: 200,
            border: "1px solid black"
          }}
          variant="warning"
        >
          Remember! This campaign/project is already archived!
        </Note>
      </div>
    )}
    <div style={{ marginLeft: "auto", display: "flex" }}>
      {lastUpdatedAt && (
        <Typography
          style={{ marginRight: 10, marginTop: "auto", marginBottom: "auto" }}
          display="flex"
          variant="caption"
          color="GrayText"
        >
          Last updated: {prettifyDate(lastUpdatedAt, { full: true })}
        </Typography>
      )}

      <Button
        icon={<Publish />}
        isLoading={isLoading}
        disabled={isLoading}
        size="medium"
        color="primary"
        onClick={() => handleSubmit()}
      >
        Save
      </Button>
    </div>
  </SPaper>
);
