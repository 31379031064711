import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignSnapchatStatsQueryVariables = Types.Exact<{
  stubs: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type CampaignSnapchatStatsQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, stub: string, target_reach?: number | null, min_max_publish_date: { __typename?: 'campaign_content_settings_aggregate', aggregate?: { __typename?: 'campaign_content_settings_aggregate_fields', min?: { __typename?: 'campaign_content_settings_min_fields', publish_date_min?: string | null } | null, max?: { __typename?: 'campaign_content_settings_max_fields', publish_date_max?: string | null } | null } | null }, campaign_content_settings_aggregate: { __typename?: 'campaign_content_settings_aggregate', aggregate?: { __typename?: 'campaign_content_settings_aggregate_fields', sum?: { __typename?: 'campaign_content_settings_sum_fields', snapsCount?: number | null } | null } | null } }>, campaign_snaps: Array<{ __typename?: 'campaign_snap', id: number, shortcode?: string | null, media_url?: string | null, thumbnail_url?: string | null, track_url?: string | null, approved: boolean, approved_at?: string | null, notes: string, created_at: string, views?: number | null, reach?: number | null, screenshots?: number | null, comments?: number | null, clicks?: number | null, campaign_user: { __typename?: 'campaign_user', id: number, user?: { __typename?: 'users', id: number, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, external_id?: string | null } | null } | null, campaign: { __typename?: 'campaign', id: number, stub: string } } }> };

export type AddCampaignSnapMutationVariables = Types.Exact<{
  object: Types.Campaign_Snap_Insert_Input;
}>;


export type AddCampaignSnapMutation = { __typename?: 'Mutation', insert_campaign_snap_one?: { __typename?: 'campaign_snap', id: number } | null };

export type UpdateSnapchatUserMutationVariables = Types.Exact<{
  userSnapchatId: Types.Scalars['Int']['input'];
  set: Types.Users_Snapchat_Set_Input;
}>;


export type UpdateSnapchatUserMutation = { __typename?: 'Mutation', update_users_snapchat_by_pk?: { __typename?: 'users_snapchat', id: number } | null };

export type UpdateSnapMutationVariables = Types.Exact<{
  set: Types.Campaign_Snap_Set_Input;
  id: Types.Scalars['Int']['input'];
}>;


export type UpdateSnapMutation = { __typename?: 'Mutation', update_campaign_snap_by_pk?: { __typename?: 'campaign_snap', id: number } | null };

export type SnapchatUserNamesQueryVariables = Types.Exact<{
  stub: Types.Scalars['String']['input'];
}>;


export type SnapchatUserNamesQuery = { __typename?: 'Query', campaign: Array<{ __typename?: 'campaign', id: number, campaign_users: Array<{ __typename?: 'campaign_user', id: number, user?: { __typename?: 'users', id: number, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, external_id?: string | null } | null } | null }> }> };


export const CampaignSnapchatStatsDocument = gql`
    query campaignSnapchatStats($stubs: [String!]!) {
  campaigns: campaign(where: {stub: {_in: $stubs}}) {
    id
    stub
    target_reach
    min_max_publish_date: campaign_content_settings_aggregate {
      aggregate {
        min {
          publish_date_min
        }
        max {
          publish_date_max
        }
      }
    }
    campaign_content_settings_aggregate(where: {type: {_eq: "snap"}}) {
      aggregate {
        sum {
          snapsCount: number_of_posts
        }
      }
    }
  }
  campaign_snaps: campaign_snap(
    where: {campaign_user: {campaign: {stub: {_in: $stubs}}}}
  ) {
    id
    shortcode
    media_url
    thumbnail_url
    track_url
    approved
    approved_at
    notes
    created_at
    views
    reach
    screenshots
    comments
    clicks
    campaign_user {
      id
      user {
        id
        user_snapchat {
          id
          username
          external_id
        }
      }
      campaign {
        id
        stub
      }
    }
  }
}
    `;

/**
 * __useCampaignSnapchatStatsQuery__
 *
 * To run a query within a React component, call `useCampaignSnapchatStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignSnapchatStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignSnapchatStatsQuery({
 *   variables: {
 *      stubs: // value for 'stubs'
 *   },
 * });
 */
export function useCampaignSnapchatStatsQuery(baseOptions: Apollo.QueryHookOptions<CampaignSnapchatStatsQuery, CampaignSnapchatStatsQueryVariables> & ({ variables: CampaignSnapchatStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignSnapchatStatsQuery, CampaignSnapchatStatsQueryVariables>(CampaignSnapchatStatsDocument, options);
      }
export function useCampaignSnapchatStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignSnapchatStatsQuery, CampaignSnapchatStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignSnapchatStatsQuery, CampaignSnapchatStatsQueryVariables>(CampaignSnapchatStatsDocument, options);
        }
export function useCampaignSnapchatStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignSnapchatStatsQuery, CampaignSnapchatStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignSnapchatStatsQuery, CampaignSnapchatStatsQueryVariables>(CampaignSnapchatStatsDocument, options);
        }
export type CampaignSnapchatStatsQueryHookResult = ReturnType<typeof useCampaignSnapchatStatsQuery>;
export type CampaignSnapchatStatsLazyQueryHookResult = ReturnType<typeof useCampaignSnapchatStatsLazyQuery>;
export type CampaignSnapchatStatsSuspenseQueryHookResult = ReturnType<typeof useCampaignSnapchatStatsSuspenseQuery>;
export type CampaignSnapchatStatsQueryResult = Apollo.QueryResult<CampaignSnapchatStatsQuery, CampaignSnapchatStatsQueryVariables>;
export const AddCampaignSnapDocument = gql`
    mutation addCampaignSnap($object: campaign_snap_insert_input!) {
  insert_campaign_snap_one(object: $object) {
    id
  }
}
    `;
export type AddCampaignSnapMutationFn = Apollo.MutationFunction<AddCampaignSnapMutation, AddCampaignSnapMutationVariables>;

/**
 * __useAddCampaignSnapMutation__
 *
 * To run a mutation, you first call `useAddCampaignSnapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCampaignSnapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCampaignSnapMutation, { data, loading, error }] = useAddCampaignSnapMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddCampaignSnapMutation(baseOptions?: Apollo.MutationHookOptions<AddCampaignSnapMutation, AddCampaignSnapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCampaignSnapMutation, AddCampaignSnapMutationVariables>(AddCampaignSnapDocument, options);
      }
export type AddCampaignSnapMutationHookResult = ReturnType<typeof useAddCampaignSnapMutation>;
export type AddCampaignSnapMutationResult = Apollo.MutationResult<AddCampaignSnapMutation>;
export type AddCampaignSnapMutationOptions = Apollo.BaseMutationOptions<AddCampaignSnapMutation, AddCampaignSnapMutationVariables>;
export const UpdateSnapchatUserDocument = gql`
    mutation updateSnapchatUser($userSnapchatId: Int!, $set: users_snapchat_set_input!) {
  update_users_snapchat_by_pk(pk_columns: {id: $userSnapchatId}, _set: $set) {
    id
  }
}
    `;
export type UpdateSnapchatUserMutationFn = Apollo.MutationFunction<UpdateSnapchatUserMutation, UpdateSnapchatUserMutationVariables>;

/**
 * __useUpdateSnapchatUserMutation__
 *
 * To run a mutation, you first call `useUpdateSnapchatUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSnapchatUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSnapchatUserMutation, { data, loading, error }] = useUpdateSnapchatUserMutation({
 *   variables: {
 *      userSnapchatId: // value for 'userSnapchatId'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateSnapchatUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSnapchatUserMutation, UpdateSnapchatUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSnapchatUserMutation, UpdateSnapchatUserMutationVariables>(UpdateSnapchatUserDocument, options);
      }
export type UpdateSnapchatUserMutationHookResult = ReturnType<typeof useUpdateSnapchatUserMutation>;
export type UpdateSnapchatUserMutationResult = Apollo.MutationResult<UpdateSnapchatUserMutation>;
export type UpdateSnapchatUserMutationOptions = Apollo.BaseMutationOptions<UpdateSnapchatUserMutation, UpdateSnapchatUserMutationVariables>;
export const UpdateSnapDocument = gql`
    mutation updateSnap($set: campaign_snap_set_input!, $id: Int!) {
  update_campaign_snap_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
  }
}
    `;
export type UpdateSnapMutationFn = Apollo.MutationFunction<UpdateSnapMutation, UpdateSnapMutationVariables>;

/**
 * __useUpdateSnapMutation__
 *
 * To run a mutation, you first call `useUpdateSnapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSnapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSnapMutation, { data, loading, error }] = useUpdateSnapMutation({
 *   variables: {
 *      set: // value for 'set'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSnapMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSnapMutation, UpdateSnapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSnapMutation, UpdateSnapMutationVariables>(UpdateSnapDocument, options);
      }
export type UpdateSnapMutationHookResult = ReturnType<typeof useUpdateSnapMutation>;
export type UpdateSnapMutationResult = Apollo.MutationResult<UpdateSnapMutation>;
export type UpdateSnapMutationOptions = Apollo.BaseMutationOptions<UpdateSnapMutation, UpdateSnapMutationVariables>;
export const SnapchatUserNamesDocument = gql`
    query snapchatUserNames($stub: String!) {
  campaign(where: {stub: {_eq: $stub}}) {
    id
    campaign_users(where: {state: {_in: ["approved", "confirmed"]}}) {
      id
      user {
        id
        user_snapchat {
          id
          username
          external_id
        }
      }
    }
  }
}
    `;

/**
 * __useSnapchatUserNamesQuery__
 *
 * To run a query within a React component, call `useSnapchatUserNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnapchatUserNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnapchatUserNamesQuery({
 *   variables: {
 *      stub: // value for 'stub'
 *   },
 * });
 */
export function useSnapchatUserNamesQuery(baseOptions: Apollo.QueryHookOptions<SnapchatUserNamesQuery, SnapchatUserNamesQueryVariables> & ({ variables: SnapchatUserNamesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SnapchatUserNamesQuery, SnapchatUserNamesQueryVariables>(SnapchatUserNamesDocument, options);
      }
export function useSnapchatUserNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SnapchatUserNamesQuery, SnapchatUserNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SnapchatUserNamesQuery, SnapchatUserNamesQueryVariables>(SnapchatUserNamesDocument, options);
        }
export function useSnapchatUserNamesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SnapchatUserNamesQuery, SnapchatUserNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SnapchatUserNamesQuery, SnapchatUserNamesQueryVariables>(SnapchatUserNamesDocument, options);
        }
export type SnapchatUserNamesQueryHookResult = ReturnType<typeof useSnapchatUserNamesQuery>;
export type SnapchatUserNamesLazyQueryHookResult = ReturnType<typeof useSnapchatUserNamesLazyQuery>;
export type SnapchatUserNamesSuspenseQueryHookResult = ReturnType<typeof useSnapchatUserNamesSuspenseQuery>;
export type SnapchatUserNamesQueryResult = Apollo.QueryResult<SnapchatUserNamesQuery, SnapchatUserNamesQueryVariables>;