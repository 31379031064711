import { type FC, useState } from "react";

import { Table, type TableColumn, type TableRow } from "@relatable/ui/Table";
import { Link } from "react-router-dom";

import { PeriodSelector } from "components/PeriodSelector";

import { type AccountHoursLoggedQuery, useAccountHoursLoggedQuery } from "./generated";

const getAccountTopProjects = (
  data: AccountHoursLoggedQuery["accounts"][number]["time_tracking"]
) => {
  const projects = data.reduce<Record<string, number>>((acc, curr) => {
    if (!acc[curr.project_id]) {
      acc[curr.project_id] = 0;
    }

    acc[curr.project_id] += Number(curr.hours);

    return acc;
  }, {});

  return Object.entries(projects)
    .sort((a, b) => b[1] - a[1])
    .map(([projectId, hours]) => ({
      projectId: Number(projectId),
      hours
    }))
    .filter(item => Number(item.hours));
};

export const WorkloadReport: FC = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<null | { start: string; end: string }>(null);

  const { data, loading } = useAccountHoursLoggedQuery({
    variables: {
      dateMin: selectedPeriod?.start ?? "",
      dateMax: selectedPeriod?.end ?? ""
    },
    skip: !selectedPeriod,
    fetchPolicy: "no-cache"
  });

  const headers: TableColumn<typeof rows>[] = [
    {
      field: "name",
      headerName: "Name"
    },
    {
      field: "hours",
      headerName: "Total hours",
      sortComparator: (a, b) => Number(a) - Number(b),
      renderCell: ({ value }) => Number(value).toFixed(2) || "-"
    },
    {
      field: "topProjects",
      headerName: "Top projects",
      renderCell: ({ value }) => (
        <span>
          {value.slice(0, 4).map(v => {
            const project = data?.projects.find(p => p.id === v.projectId);
            return (
              <span key={project?.id}>
                <Link
                  to={`/overview?project=${encodeURIComponent(project?.stub || "")}&archived=true&teamMemberAccountId=all`}
                >
                  {project?.name ?? "?"}
                </Link>
                {" - "}
                {Number(v.hours).toFixed(2)} hours
                <br />
              </span>
            );
          })}
        </span>
      )
    }
  ];

  const rows: TableRow[] =
    data?.accounts
      ?.map(a => ({
        key: a.id,
        name: `${a.first_name} ${a.last_name}`,
        hours: a.time_tracking.reduce((acc, curr) => acc + Number(curr.hours), 0).toFixed(2),
        topProjects: getAccountTopProjects(a.time_tracking)
      }))
      ?.filter(item => Number(item.hours)) ?? [];

  rows.sort((a, b) => Number(b.hours) - Number(a.hours));

  return (
    <>
      <PeriodSelector startDate={2024} onSelect={setSelectedPeriod} showWeeks />
      <br />
      <br />
      <Table
        tableId="timereporting-workload-report"
        loading={loading}
        columns={headers}
        rows={rows}
        sortable
      />
    </>
  );
};
