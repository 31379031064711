import type { FC } from "react";

import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { NavigationButton } from "components/ui/NavigationButton";

import { AccountEditorFields } from "./AccountEditorFields";
import { useAccountDetailsQuery } from "./generated";

export const AccountEditor: FC = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const { loading, data: accountData } = useAccountDetailsQuery({
    variables: { id: Number(accountId) },
    skip: !accountId
  });

  const details = accountData?.accounts_by_pk;

  if (accountId && (loading || !details)) {
    return <Loader />;
  }

  return (
    <>
      <NavigationButton style={{ width: "fit-content" }} pathname="/time-reporting/accounts" />

      <AccountEditorFields details={details} />
    </>
  );
};
