import type { FC } from "react";

import { TagEditor } from "components/ui/TagEditor";

import { UserDocument, useUpdateUserMutation } from "../generated";
import { useOptimisticUserUpdate } from "./useOptimisticUserUpdate";

export const TagsSection: FC<{ tagIds: number[]; userId: number }> = ({ tagIds, userId }) => {
  const optimisticUserUpdate = useOptimisticUserUpdate(userId);

  const [updateUser] = useUpdateUserMutation({
    refetchQueries: [UserDocument]
  });

  const handleOptimisticAddTag = (id: number) => {
    const newTagIds = Array.from(new Set([...tagIds, id]));

    updateUser({
      variables: {
        id: userId,
        _set: {
          tag_ids: `{${newTagIds.join(",")}}`
        }
      }
    });

    optimisticUserUpdate(prev => ({
      ...prev,
      user_tags: newTagIds
    }));
  };

  const handleOptimisticDeleteTag = (id: number) => {
    const newTagIds = tagIds.filter(p => p !== id);

    updateUser({
      variables: {
        id: userId,
        _set: {
          tag_ids: `{${newTagIds.join(",")}}`
        }
      }
    });

    optimisticUserUpdate(prev => ({
      ...prev,
      tag_ids: newTagIds
    }));
  };

  return (
    <TagEditor
      tagIds={tagIds}
      onAdd={handleOptimisticAddTag}
      onDelete={handleOptimisticDeleteTag}
    />
  );
};
