import { Button } from "@relatable/ui/Button";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { type FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Add, ArrowLeft } from "@mui/icons-material";
import { useCampaignsQuery } from "../generated";
import { normalizeContentName } from "./common";
import { MessageListDocument, useInsertCampaignContentMutation } from "./generated";

export const CreateNewMessage: FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const [contentName, setContentName] = useState("");

  const {
    data: {
      campaigns: [campaign] = []
    } = {},
    loading: loadingCampaigns
  } = useCampaignsQuery({
    variables: { where: { stub: { _eq: campaignStub } } }
  });

  const [insertContent, { loading: loadingInsert }] = useInsertCampaignContentMutation({
    onError: error => {
      snackbar.error(`Cannot create message, reason: ${error.message}`);
    }
  });

  const backUrl = `/campaign/${campaignStub}/messages`;

  const handleAdd = async () => {
    if (!contentName) return;

    await insertContent({
      variables: {
        object: {
          campaign_id: campaign.id,
          content: {
            data: {
              name: `${campaign.stub}-${contentName}`,
              template: "looks-like-a-normal-email",
              enabled: true,
              subject: "Subject text",
              data: {
                intro: "Hi @*|USERNAME|*\r\n",
                main: "*|CAMPAIGN_TITLE|*\r\n*|CAMPAIGN_STUB|*\r\n*|POSTCOUNT|*\r\n*|HASHTAGS|*\r\n*|USERNAME|*\r\n*|CURRENCY|*\r\n*|LOCAL_INVOICE_PAY|*\r\n*|PAYMENT_INFO_URL|*",
                footer: "Footer text."
              }
            }
          },
          content_type: "custom"
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [MessageListDocument]
    });

    navigate(backUrl);
  };

  if (loadingCampaigns || !campaign) return null;

  return (
    <div>
      <h2>Create message</h2>
      <p>
        Create a new message for campaign <strong>{campaign.title}</strong>.
      </p>
      <p>The name will be automatically prefixed with the campaign stub.</p>
      <br />
      <div style={{ display: "flex", alignItems: "center" }}>
        {campaign.stub}-
        <TextInput
          required
          label="Content Name (only lowercase a-z, 0-9 and -, name must be unique)"
          value={contentName}
          onChange={name => {
            setContentName(normalizeContentName(name));
          }}
          style={{ width: "60%" }}
        />
      </div>
      <br />
      <br />
      <Button
        icon={<ArrowLeft />}
        disabled={loadingInsert}
        href={backUrl}
        style={{ marginRight: 10 }}
      >
        Cancel
      </Button>

      <Button icon={<Add />} disabled={loadingInsert} onClick={handleAdd}>
        Create
      </Button>
    </div>
  );
};
