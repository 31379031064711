/* eslint-disable no-alert */

import type { Platform_Enum } from "@relatable/gql/generated-base";

const fn = ({
  baseUrl,
  listId,
  platform
}: {
  baseUrl: string;
  listId: number;
  platform: Platform_Enum;
}) => {
  const currentUrl = window.location.href;

  if (currentUrl.includes("relatable.me") || currentUrl.includes("localhost")) {
    window.alert("This link needs to be dragged into the bookmarks bar!");
    return;
  }

  if (!currentUrl.includes(`${platform}.com`)) {
    window.alert(`Only ${platform}.com URL is supported!`);
    return;
  }

  const showTooltip = (text: string) => {
    const tooltip = document.createElement("div");
    tooltip.setAttribute("id", "rel-tooltip");
    const styles = [
      "position:fixed;",
      "top:20px;",
      "left:20px;",
      "z-index:100000;",
      "background:#fc2594;",
      "color:white;",
      "padding:10px 20px;"
    ];
    tooltip.innerHTML = `<div style="${styles.join("")}">${text}</div>`;
    document.body.append(tooltip);
    setTimeout(() => {
      tooltip.remove();
    }, 2000);
  };

  const getUsername = () => {
    if (platform === "instagram") {
      const regex = "^https://www\\.instagram\\.com/([A-Za-z0-9-_.]{3,})/?";
      const match = currentUrl.match(new RegExp(regex, "im"))?.[1];
      if (!match) return null;
      if (["p", "accounts", "explore", "web", "directory", "legal", "blog"].includes(match)) {
        return null;
      }
      return match;
    }

    if (platform === "tiktok") {
      const regex = "^https://www\\.tiktok\\.com/@([A-Za-z0-9-_.]{3,})/?";
      const match = currentUrl.match(new RegExp(regex, "im"));
      return match?.[1];
    }

    if (platform === "snapchat") {
      const regex = "^https://(?:www.)?snapchat.com/add/([A-Za-z0-9-_.]{3,})";
      const match = currentUrl.match(new RegExp(regex, "im"));
      return match?.[1];
    }

    if (platform === "youtube") {
      type ytWindow = {
        ytInitialData?: any;
      };
      let channelId = (window as ytWindow)?.ytInitialData?.metadata?.channelMetadataRenderer
        ?.externalId;
      if (channelId) return channelId;

      channelId = (window as ytWindow)?.ytInitialData?.contents?.twoColumnWatchNextResults?.results
        ?.results?.contents?.[1]?.videoSecondaryInfoRenderer?.subscribeButton
        ?.subscribeButtonRenderer?.channelId;
      if (channelId) return channelId;

      const canonicalUrl =
        (window as ytWindow)?.ytInitialData?.metadata?.channelMetadataRenderer?.externalId ||
        (document.querySelector("link[rel=canonical]") as HTMLLinkElement)?.href;
      if (!canonicalUrl) return null;

      const regex = "^https://www\\.youtube\\.com/channel/(UC[A-Za-z0-9-_.]{3,})/?";
      const match = canonicalUrl.match(new RegExp(regex, "im"));
      return match?.[1];
    }

    throw new Error("Invalid platform");
  };

  const username = getUsername();
  if (!username) {
    window.alert("Cannot detect username! Contact developers.");
    return;
  }

  window.addEventListener("message", event => {
    if (event.origin === document.location.origin) return;
    if (event.data.username !== username) {
      window.alert("Cannot communicate with popup! Contact developers.");
      return;
    }

    const message = event.data.isAlreadyOnList
      ? `"${username}" was already added to the "${event.data.listName}" list`
      : `Successfully added "${username}" to the "${event.data.listName}" list`;

    showTooltip(message);
  });

  // Security headers disallow making cross-origin HTTP requests
  // solution: open a new popup, which auto-closes itself
  // random is required to avoid caching the GET response
  const url = `${[baseUrl, listId, username].join("/")}?${Math.random()}`;

  const popup = window.open(url, "window", "screenX=0,screenY=0,top=0,left=0,width=100,height=100");

  if (popup === null) {
    window.alert("Cannot open popup!");
  }
};

export const getBookmarkletScript = ({
  listId,
  platform
}: {
  listId: number;
  platform: Platform_Enum;
}) => {
  const backendUrl = new URL(process.env.GRAPHQL_API ?? "").origin;

  const variables = [
    `baseUrl:"${backendUrl}/bookmarklet/add/${platform}"`,
    `listId:${listId}`,
    `platform:"${platform}"`
  ].join(",");

  return `(${fn.toString()})({${variables}})`
    .split(/[\n\r]/)
    .map(line => line.trim())
    .filter(line => line && !line.startsWith("//"))
    .join("")
    .trim();
};
