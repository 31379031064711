import type { FC } from "react";

import { useApolloClient } from "@apollo/client";
import styled from "@emotion/styled";

import { Divider } from "@mui/material";
import { getCountryFullName, sum } from "@relatable/helpers";
import {
  getUserFollowedBy,
  getUserFollows,
  getUserKey,
  getUserPlatform
} from "@relatable/helpers/user";
import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

import { useVerticalsQuery } from "modules/generated";
import { useUser } from "src/hooks/useUser";

import { ConnectWithoutContact, Paid, People, Public } from "@mui/icons-material";
import { UserDocument, type UserQuery, useUpdateUserMutation } from "../generated";
import { AttachmentsSection } from "./AttachmentsSection";
import { CommentSection } from "./CommentSection";
import { MiniStatBox } from "./MiniStatBox";
import { ProfileSection } from "./ProfileSection";
import { StatsSection } from "./StatsSection";
import { TagsSection } from "./TagsSection";
import { useOptimisticUserUpdate } from "./useOptimisticUserUpdate";

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 20px;
  gap: 30px;
  width: 100%;
  max-width: 900px;
`;

export const UserInfo: FC<{ user: NonNullable<UserQuery["users_by_pk"]> }> = ({ user }) => {
  const apolloClient = useApolloClient();
  const optimisticUserUpdate = useOptimisticUserUpdate(user.id);

  const admin = useUser();
  const verticals = useVerticalsQuery();
  const [updateUser] = useUpdateUserMutation({
    onError: () => {
      apolloClient.refetchQueries({ include: [UserDocument] });
    }
  });

  const handleRelatableCommentUpdate = (relatable_comment: string) => {
    const set = {
      relatable_comment,
      relatable_comment_updated_at: new Date().toISOString(),
      relatable_comment_account_id: admin?.id || undefined
    };
    updateUser({
      variables: {
        id: user.id,
        _set: set
      }
    });
    optimisticUserUpdate(prev => ({
      ...prev,
      ...set,
      relatable_comment_account: prev.relatable_comment_account || {
        id: 1000000 + Math.round(Math.random() * 1000000),
        full_name: admin?.full_name || "",
        profile_picture: admin?.profileUrl || null
      }
    }));
  };

  const platform = getUserPlatform(user);
  const cpmList = user.campaign_users.map(i => i.cpm || 0).filter(Boolean);

  if (typeof user.tag_ids === "string") {
    throw Error("user.tag_ids is string and should be number[]");
  }

  return (
    <Container>
      <ProfileSection user={user} />
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <StatsSection
          collaborationScore={
            user.campaign_users_aggregate.aggregate?.avg?.collaboration_score || null
          }
          contentQualityScore={
            user.campaign_users_aggregate.aggregate?.avg?.content_quality_score || null
          }
          engagementRate={user.hypeauditor?.avg_engagement || null}
          vertical={
            verticals.data?.vertical.find(
              v =>
                v.id ===
                user.campaign_users.find(i => i.campaign?.vertical_ids?.[0])?.campaign
                  .vertical_ids?.[0]
            )?.name ?? null
          }
        />

        <div style={{ display: "flex", gap: 20 }}>
          <MiniStatBox Icon={People} title="Followers" value={getUserFollowedBy(user)} />

          <MiniStatBox
            Icon={ConnectWithoutContact}
            title={platform === "youtube" ? "Median views" : "Following"}
            value={getUserFollows(user)}
          />
        </div>

        <div style={{ display: "flex", gap: 20 }}>
          <MiniStatBox
            title="Country"
            value={getCountryFullName(user.user_profile?.address_country)}
            Icon={
              user.user_profile?.address_country && hasFlag(user.user_profile?.address_country)
                ? getUnicodeFlagIcon(user.user_profile?.address_country)
                : Public
            }
          />
          <MiniStatBox
            Icon={Paid}
            title="Average CPM"
            value={`${user.campaign_users.find(i => i.currency)?.currency || ""} ${
              cpmList.length ? (sum(cpmList) / cpmList.length).toFixed(2) : "-"
            }`}
          />
        </div>
      </div>
      <CommentSection
        title="Note about profile"
        text={user.relatable_comment}
        updatedAt={user.relatable_comment_updated_at || null}
        authorName={user.relatable_comment_account?.full_name || null}
        avatarUrl={user.relatable_comment_account?.profile_picture || null}
        onSave={handleRelatableCommentUpdate}
      />
      <CommentSection
        title="Creator comment"
        text={user.user_comment}
        updatedAt={user.user_comment_updated_at || null}
      />

      <TagsSection userId={user.id} tagIds={user.tag_ids} />
      <AttachmentsSection
        userId={user.id}
        userName={getUserKey(user) || "no-username"}
        attachments={user.user_attachments.map(a => ({
          id: a.id,
          name: a.original_filename,
          url: a.url
        }))}
      />

      <Divider />
      <Divider />
    </Container>
  );
};
