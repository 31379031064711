import { type FC, useState } from "react";

import styled from "@emotion/styled";
import type { Content_Preapproval_State_Enum } from "@relatable/gql/generated-base";
import { getUserKey, getUserLabel } from "@relatable/helpers/user";
import { Button } from "@relatable/ui/Button";
import { type AddFields, Table, type TableColumn } from "@relatable/ui/Table";
import { TextInput } from "@relatable/ui/TextInput";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { useStoredState } from "@relatable/ui/hooks/useStoredState";
import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { Popup } from "components/ui/Popup";
import { SORTED_CAMPAIGN_USER_STATES } from "lib/constants";
import { CustomDataCell } from "modules/campaign/common/CustomDataCell";
import { StateCell } from "modules/campaign/common/StateCell";
import { TrackingCell } from "modules/campaign/common/TrackingCell";
import { useUpdateCampaignMutation } from "modules/project/CampaignUpdate/generated";
import type { CampaignUserExtendedState } from "src/typings/global";

import { Add } from "@mui/icons-material";
import { FilterStateMenu } from "../common/FilterStateMenu";
import { CommentsCell } from "./CommentsCell";
import { DynamicCell } from "./DynamicCell";
import { CampaignOverviewDocument, useCampaignOverviewQuery } from "./generated";

export const Overview: FC = () => {
  useDocumentTitle("Overview");

  const { campaignStub } = useParams<{ campaignStub: string }>();

  const [isCustomColumnPopup, setIsCustomColumnPopup] = useState(false);
  const [customColumnName, setCustomColumnName] = useState("");

  const [userStateFilterBy, setUserStateFilterBy] = useStoredState<CampaignUserExtendedState[]>(
    `campaign-overview-filter-${campaignStub}`,
    []
  );

  const [updateCampaign] = useUpdateCampaignMutation({
    awaitRefetchQueries: true,
    refetchQueries: [CampaignOverviewDocument]
  });
  const { data, loading: campaignOverviewLoading } = useCampaignOverviewQuery({
    variables: { stub: campaignStub ?? "" }
  });
  const campaign = data?.campaign[0];

  if (campaignOverviewLoading) {
    return <Loader />;
  }

  const rows =
    campaign?.campaign_users.map(cu => {
      const { user } = cu;

      const userLabel = getUserLabel(user);
      const userKey = getUserKey(user);

      const r = {
        userLabel,
        userKey,
        key: cu.id,
        userId: user?.id,
        notes: cu.notes,
        campaignUserId: cu.id,
        customData: cu.signedCustomData,
        tracking: cu.tracking,
        campaignUserState: cu.state,
        state_log: cu.state_log,
        posts: user?.campaign_posts,
        stories: user?.campaign_stories,
        preaprovals: cu.campaign_preapproval_contents.map(pc => ({
          id: pc.id,
          settingId: pc.setting_id,
          state: pc.state
        }))
      };
      return r as AddFields<typeof r, "content">;
    }) ?? [];

  const filteredRows = rows.filter(row => {
    if (userStateFilterBy.length === 0) return true; // inactive filter (all users shown)
    if (!row.campaignUserState) return userStateFilterBy.includes("not_set");
    return userStateFilterBy.includes(row.campaignUserState);
  });

  const columns: TableColumn<typeof filteredRows>[] = [
    { headerName: "Creator", field: "userLabel" },
    {
      headerName: "Comments",
      field: "notes",
      minWidth: 300,
      renderCell: ({ value, row }) => (
        <CommentsCell campaignUserId={row.campaignUserId} value={value || ""} />
      )
    },
    {
      headerName: "Custom data",
      field: "customData",
      renderCell: ({ value }) => <CustomDataCell data={value} />
    },
    {
      field: "tracking",
      headerName: "Tracking",
      renderCell: ({ row }) => (
        <TrackingCell
          username={row.userLabel}
          campaignUserId={row.campaignUserId}
          tracking={row.tracking}
        />
      )
    },
    {
      headerName: "Content approval",
      field: "preaprovals",
      renderCell: ({ value, row }) => {
        return (
          <div style={{ display: "flex" }}>
            {campaign?.campaign_content_settings.map(contentSetting => {
              const v = value.find(val => val.settingId === contentSetting.id);
              if (!v?.state) return null;

              const positive = (
                ["confirmed", "approved", "client_approved"] as Content_Preapproval_State_Enum[]
              ).includes(v.state);

              const negative = (
                [
                  "client_declined",
                  "denied",
                  "not_interested",
                  "rejected"
                ] as Content_Preapproval_State_Enum[]
              ).includes(v.state);

              return (
                <Button
                  key={contentSetting.id}
                  tooltipText={`${contentSetting?.type} ${contentSetting?.preapproval_deadline} ${v.state}`}
                  color={positive ? "success" : negative ? "error" : "warning"}
                  variant="outlined"
                  style={{ borderRadius: 0, boxShadow: "none", minWidth: 0 }}
                  href={`/campaign/${campaign.stub}/approval/${row.userId}/${contentSetting.id}/12358`}
                >
                  {v.state[0].toUpperCase()}
                </Button>
              );
            })}
          </div>
        );
      }
    },
    {
      headerName: "Content",
      field: "content",
      hide: campaign?.platform !== "instagram",
      renderCell: ({ row }) => (
        <div style={{ display: "flex" }}>
          {row.posts?.map(post => (
            <Button
              key={post.id}
              variant="outlined"
              color={post.approved ? "success" : "warning"}
              style={{ borderRadius: 0, boxShadow: "none", minWidth: 0 }}
              href={`/campaign/${campaign?.stub}/content/post/${post.id}`}
            >
              Post
            </Button>
          ))}
          {row.stories?.map(story => (
            <Button
              key={story.id}
              variant="outlined"
              color={story.approved ? "success" : "warning"}
              style={{ borderRadius: 0, boxShadow: "none", minWidth: 0 }}
              href={`/campaign/${campaign?.stub}/participants`}
            >
              Story
            </Button>
          ))}
        </div>
      )
    },
    {
      headerName: "State",
      field: "campaignUserState",
      sortComparator: (v1, v2) => {
        const v1Order = SORTED_CAMPAIGN_USER_STATES.findIndex(v => v === v1);
        const v2Order = SORTED_CAMPAIGN_USER_STATES.findIndex(v => v === v2);
        return v1Order - v2Order;
      },
      renderCell: ({ row }) => (
        <StateCell
          platform={campaign?.platform}
          userLabel={row.userLabel}
          campaignTitle={campaign?.title || ""}
          state={row.campaignUserState}
          campaignUserId={row.campaignUserId}
          campaignId={campaign?.id || 0}
        />
      )
    }
  ];

  const dynamicFieldNames = campaign?.custom_user_fields?.fields?.map(f => f.name) || [];

  const allColumns: TableColumn<typeof filteredRows>[] = [
    ...columns.slice(0, 2),
    ...dynamicFieldNames.map(name => {
      return {
        headerName: name,
        field: `customData[${name}]`, // required to have unique keys
        minWidth: 150,
        renderCell: ({ row }) => (
          <DynamicCell
            key={row.key}
            fieldName={name}
            customData={row.customData || {}}
            campaignUserId={row.campaignUserId}
            userKey={row.userKey ?? "anonymous"}
          />
        )
      };
    }),
    ...columns.slice(2)
  ];

  return (
    <Root>
      <div className="actions">
        <FilterStateMenu
          userStates={rows.map(u => u.campaignUserState)}
          userStateFilterBy={userStateFilterBy}
          toggleUserStateFilterBy={state =>
            setUserStateFilterBy(prev => {
              if (prev.includes(state)) {
                return prev.filter(p => p !== state);
              }
              return [...prev, state];
            })
          }
          resetUserStateFilterBy={() => setUserStateFilterBy([])}
        />
      </div>

      <Table
        tableId="campaign-overview"
        sortable
        stickyColumn
        rows={filteredRows}
        columns={allColumns}
      />

      <Button
        size="medium"
        icon={<Add />}
        style={{ marginTop: 10, marginLeft: "auto" }}
        onClick={() => setIsCustomColumnPopup(true)}
      >
        Add custom column
      </Button>

      {isCustomColumnPopup && (
        <Popup
          onClose={() => setIsCustomColumnPopup(false)}
          actionLabel="Add"
          title="Add custom column"
          actionIcon={<Add />}
          onAction={async () => {
            if (!campaign?.id) return;
            await updateCampaign({
              variables: {
                id: campaign?.id,
                set: {
                  custom_user_fields: {
                    ...(campaign?.custom_user_fields || {}),
                    fields: [
                      ...(campaign?.custom_user_fields?.fields || []),
                      { name: (customColumnName ?? "").trim() }
                    ]
                  }
                }
              }
            });
            setCustomColumnName("");
            setIsCustomColumnPopup(false);
          }}
        >
          <TextInput label="Column name" value={customColumnName} onChange={setCustomColumnName} />
        </Popup>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;

  > .actions {
    display: block;
    margin: 10px 0;
  }
`;
