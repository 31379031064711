import { type FC, useEffect } from "react";

import { unstable_usePrompt as usePrompt } from "react-router-dom";

export const Prompt: FC<{ shouldPrompt: boolean; promptMessage?: string }> = ({
  shouldPrompt,
  promptMessage
}) => {
  usePrompt({
    when: shouldPrompt,
    message: promptMessage ?? "Are you sure you want to leave?"
  });

  useEffect(() => {
    const handlePrompt = event => {
      event.preventDefault();
    };
    if (shouldPrompt) {
      window.addEventListener("beforeunload", handlePrompt);
    }
    return () => {
      window.removeEventListener("beforeunload", handlePrompt);
    };
  }, [shouldPrompt]);

  return <span />;
};
