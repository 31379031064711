import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { FormControl, Input, InputLabel } from "@mui/material";
import { useParams } from "react-router-dom";

import { Popup } from "components/ui/Popup";

import {
  CampaignVideoStatsDocument,
  useAddCampaignVideoMutation,
  useUpdateCampaignVideoStatsMutation
} from "./generated";

export const AddVideoPopup: FC<{ onClose(): void }> = ({ onClose }) => {
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  const [videoId, setVideoId] = useState("");

  const [addCampaignVideo] = useAddCampaignVideoMutation({
    refetchQueries: [CampaignVideoStatsDocument]
  });

  const [updateVideoStats] = useUpdateCampaignVideoStatsMutation({
    refetchQueries: [CampaignVideoStatsDocument],
    awaitRefetchQueries: true
  });

  const handleSubmit = async () => {
    const { data } = await addCampaignVideo({
      variables: {
        input: {
          campaignStub: campaignStubs[0],
          videoId
        }
      }
    });

    if (!data?.addCampaignVideo.id) throw new Error("Cannot insert video!");
    await updateVideoStats({ variables: { id: Number(data.addCampaignVideo.id) } });

    onClose();
  };

  return (
    <Popup
      disabledAction={!videoId}
      onAction={handleSubmit}
      actionLabel="Submit"
      title="Upload video"
      onClose={onClose}
    >
      <FormContainer>
        <InputLabel htmlFor="videoId">Video ID (ex. BA2zPfbKLt4)</InputLabel>
        <Input id="videoId" onChange={e => setVideoId(e.target.value)} />
      </FormContainer>
    </Popup>
  );
};

const FormContainer = styled(FormControl)`
  & label + .MuiInput-formControl {
    height: 30px;
  }
`;
