import { capitalizeFirstLetter, prettifyPercentages } from "@relatable/helpers";

import * as constants from "modules/hypeAuditor/common/constants";

const { AGE_SECTION_TYPES } = constants;

export const getQualityAudienceRows = ({ followers_count, audience_type }) => {
  const { quality } = audience_type;

  const size = Math.round(followers_count * (quality / 100));
  return [
    { name: null, size, percentage: prettifyPercentages({ of: size, total: followers_count }) }
  ];
};

export const getAudienceTypeRows = ({ followers_count, audience_type }) => {
  const { real, susp, infs, mass } = audience_type;
  const realSize = Math.round(followers_count * (real / 100));
  const suspSize = Math.round(followers_count * (susp / 100));
  const infsSize = Math.round(followers_count * (infs / 100));
  const massSize = Math.round(followers_count * (mass / 100));

  const realPeople = { name: "Real people", size: realSize, percentage: real };
  const suspAccounts = { name: "Suspicious accounts", size: suspSize, percentage: susp };
  const influencers = { name: "Influencers", size: infsSize, percentage: infs };
  const massFollowers = { name: "Mass followers", size: massSize, percentage: mass };
  return [realPeople, suspAccounts, influencers, massFollowers];
};

export const getBrandMentionCategoriesRows = ({
  brands_mentions,
  brands_categories = [],
  brands_mentions_count
}) => {
  return brands_categories.map(c => {
    const size = brands_mentions.filter(bm => bm.category === c).length;
    return {
      name: c,
      size,
      percentage: prettifyPercentages({ of: size, total: brands_mentions_count })
    };
  });
};

export const getGrowthRows = ({ yearly_growth }) => {
  return [{ name: "Yearly", percentage: yearly_growth.value }];
};

export const getEngagementRows = ({
  advertising_data,
  er,
  comments_authenticity,
  comments_rate,
  avg_comments
}) => {
  return [
    ...(advertising_data
      ? [
          {
            name: "Brand posts engagement",
            percentage: advertising_data.avg_ad_er
          }
        ]
      : []),
    ...(comments_authenticity
      ? [
          {
            name: "Comment authenticity",
            value: comments_authenticity.title
          }
        ]
      : []),
    { name: "Organic engagement", percentage: er.value },
    { name: "Comments rate", size: avg_comments, percentage: comments_rate.value.toFixed(2) }
  ];
};

export const getGenderRows = ({ demography_by_age, followers_count }) => {
  return demography_by_age
    .filter(({ gender }) => gender !== AGE_SECTION_TYPES.ALL)
    .map(({ gender, size }) => {
      return {
        name: capitalizeFirstLetter(gender),
        size,
        percentage: prettifyPercentages({ of: size, total: followers_count })
      };
    });
};

export const getAgeRows = ({
  demography_by_age,
  followers_count,
  type = AGE_SECTION_TYPES.ALL
}) => {
  return demography_by_age
    .find(({ gender }) => gender === type)
    .by_age_group.map(({ group, size }) => {
      const groupKeyFormatted = group.replace("age", "");
      return {
        name: groupKeyFormatted,
        size,
        percentage: prettifyPercentages({ of: size, total: followers_count })
      };
    });
};

export const getEthnicityRows = ({ followers_count, audience_ethnicity }) => {
  return audience_ethnicity.map(({ name, size }) => ({
    name,
    size,
    percentage: prettifyPercentages({ of: size, total: followers_count })
  }));
};

export const getNativeLanguageRows = ({ followers_count, audience_languages }) => {
  return audience_languages.map(({ size, name }) => ({
    name,
    size,
    percentage: prettifyPercentages({ of: size, total: followers_count })
  }));
};

export const getLocationByCountriesRows = ({ followers_count, countries }) => {
  return countries.map(({ name, size }) => ({
    name,
    size,
    percentage: prettifyPercentages({ of: size, total: followers_count })
  }));
};

export const getLocationByStatesRows = ({ followers_count, states }) => {
  return states.map(({ name, size }) => ({
    name,
    size,
    percentage: prettifyPercentages({ of: size, total: followers_count })
  }));
};

export const getLocationByCitiesRows = ({ followers_count, cities }) => {
  return cities.map(({ name, size }) => ({
    name,
    size,
    percentage: prettifyPercentages({ of: size, total: followers_count })
  }));
};

export const getAudienceInterestsRows = ({ audience_interests, followers_count }) => {
  return audience_interests.map(([name, value]) => {
    const size = Math.round(followers_count * (value / 100));
    return { name, size, percentage: prettifyPercentages({ of: size, total: followers_count }) };
  });
};
