import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTikTokMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  set: Types.Campaign_Tiktok_Set_Input;
}>;


export type UpdateTikTokMutation = { __typename?: 'Mutation', update_campaign_tiktok_by_pk?: { __typename?: 'campaign_tiktok', id: number } | null };

export type CampaignTikTokQueryVariables = Types.Exact<{
  postId: Types.Scalars['Int']['input'];
  campaignStub: Types.Scalars['String']['input'];
}>;


export type CampaignTikTokQuery = { __typename?: 'Query', campaignTikTok: Array<{ __typename?: 'campaign_tiktok', id: number, approved: boolean, approved_at?: string | null, campaign_id?: number | null, created_at: string, filename?: string | null, url?: string | null, views?: number | null, engagement?: number | null, likes?: number | null, comment_count?: number | null, track_url?: string | null, actual_reach?: number | null, average_watch_time?: number | null, completion_rate?: number | null, following_rate?: number | null, for_you_page_rate?: number | null, personal_profile_rate?: number | null, shares?: number | null, saves?: number | null, link_clicks?: number | null, shortcode?: string | null, content_setting?: { __typename?: 'campaign_content_settings', id: number, publish_date_max: string, publish_date_min: string, number_of_posts: number } | null, user_tiktok: { __typename?: 'user_tiktok', id: number, username?: string | null, user?: { __typename?: 'users', id: number, campaignUser: Array<{ __typename?: 'campaign_user', id: number, tracking?: any | null }> } | null } }> };


export const UpdateTikTokDocument = gql`
    mutation updateTikTok($id: Int!, $set: campaign_tiktok_set_input!) {
  update_campaign_tiktok_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
  }
}
    `;
export type UpdateTikTokMutationFn = Apollo.MutationFunction<UpdateTikTokMutation, UpdateTikTokMutationVariables>;

/**
 * __useUpdateTikTokMutation__
 *
 * To run a mutation, you first call `useUpdateTikTokMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTikTokMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTikTokMutation, { data, loading, error }] = useUpdateTikTokMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateTikTokMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTikTokMutation, UpdateTikTokMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTikTokMutation, UpdateTikTokMutationVariables>(UpdateTikTokDocument, options);
      }
export type UpdateTikTokMutationHookResult = ReturnType<typeof useUpdateTikTokMutation>;
export type UpdateTikTokMutationResult = Apollo.MutationResult<UpdateTikTokMutation>;
export type UpdateTikTokMutationOptions = Apollo.BaseMutationOptions<UpdateTikTokMutation, UpdateTikTokMutationVariables>;
export const CampaignTikTokDocument = gql`
    query campaignTikTok($postId: Int!, $campaignStub: String!) {
  campaignTikTok: campaign_tiktok(where: {id: {_eq: $postId}}, limit: 1) {
    id
    approved
    approved_at
    campaign_id
    created_at
    filename
    url
    views
    engagement
    likes
    comment_count
    track_url
    actual_reach
    average_watch_time
    completion_rate
    following_rate
    for_you_page_rate
    personal_profile_rate
    shares
    saves
    link_clicks
    shortcode
    content_setting: campaign_content_setting {
      id
      publish_date_max
      publish_date_min
      number_of_posts
    }
    user_tiktok {
      id
      username
      user {
        id
        campaignUser: campaign_users(where: {campaign: {stub: {_eq: $campaignStub}}}) {
          id
          tracking
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignTikTokQuery__
 *
 * To run a query within a React component, call `useCampaignTikTokQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignTikTokQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignTikTokQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *      campaignStub: // value for 'campaignStub'
 *   },
 * });
 */
export function useCampaignTikTokQuery(baseOptions: Apollo.QueryHookOptions<CampaignTikTokQuery, CampaignTikTokQueryVariables> & ({ variables: CampaignTikTokQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignTikTokQuery, CampaignTikTokQueryVariables>(CampaignTikTokDocument, options);
      }
export function useCampaignTikTokLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignTikTokQuery, CampaignTikTokQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignTikTokQuery, CampaignTikTokQueryVariables>(CampaignTikTokDocument, options);
        }
export function useCampaignTikTokSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignTikTokQuery, CampaignTikTokQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignTikTokQuery, CampaignTikTokQueryVariables>(CampaignTikTokDocument, options);
        }
export type CampaignTikTokQueryHookResult = ReturnType<typeof useCampaignTikTokQuery>;
export type CampaignTikTokLazyQueryHookResult = ReturnType<typeof useCampaignTikTokLazyQuery>;
export type CampaignTikTokSuspenseQueryHookResult = ReturnType<typeof useCampaignTikTokSuspenseQuery>;
export type CampaignTikTokQueryResult = Apollo.QueryResult<CampaignTikTokQuery, CampaignTikTokQueryVariables>;