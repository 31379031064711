import type { FC } from "react";

import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";
import { NavLink } from "react-router-dom";

export const ButtonBar: FC<{
  navigationItems: (
    | {
        name: string;
        url: string;
      }
    | null
    | false
    | undefined
  )[];
  style?: React.CSSProperties;
}> = ({ navigationItems, style }) => {
  return (
    <Root style={style}>
      {navigationItems.map(i => {
        if (!i) return null;
        const { name, url } = i;
        return (
          <NavLink key={name} to={url} className="navigation-link">
            {name}
          </NavLink>
        );
      })}
    </Root>
  );
};

const Root = styled.div`
  height: inherit;
  display: flex;
  user-select: none;
  height: 69px;

  .navigation-link {
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    color: ${palette.gray[40]};
    text-transform: capitalize;
    margin-left: 15px;

    &.active {
      padding-top: 2px;
      color: ${palette.gray[90]};
      border-bottom: 2px solid ${palette.primary.red};
    }
  }
`;
