import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsertCampaignReelMutationVariables = Types.Exact<{
  object: Types.Campaign_Reels_Insert_Input;
}>;


export type InsertCampaignReelMutation = { __typename?: 'Mutation', insert_campaign_reels_one?: { __typename?: 'campaign_reels', id: number } | null };

export type SendCampaignPostZipOnSlackMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['Int']['input'];
}>;


export type SendCampaignPostZipOnSlackMutation = { __typename?: 'Mutation', sendCampaignPostZipOnSlack?: void | null };


export const InsertCampaignReelDocument = gql`
    mutation insertCampaignReel($object: campaign_reels_insert_input!) {
  insert_campaign_reels_one(object: $object) {
    id
  }
}
    `;
export type InsertCampaignReelMutationFn = Apollo.MutationFunction<InsertCampaignReelMutation, InsertCampaignReelMutationVariables>;

/**
 * __useInsertCampaignReelMutation__
 *
 * To run a mutation, you first call `useInsertCampaignReelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCampaignReelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCampaignReelMutation, { data, loading, error }] = useInsertCampaignReelMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCampaignReelMutation(baseOptions?: Apollo.MutationHookOptions<InsertCampaignReelMutation, InsertCampaignReelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCampaignReelMutation, InsertCampaignReelMutationVariables>(InsertCampaignReelDocument, options);
      }
export type InsertCampaignReelMutationHookResult = ReturnType<typeof useInsertCampaignReelMutation>;
export type InsertCampaignReelMutationResult = Apollo.MutationResult<InsertCampaignReelMutation>;
export type InsertCampaignReelMutationOptions = Apollo.BaseMutationOptions<InsertCampaignReelMutation, InsertCampaignReelMutationVariables>;
export const SendCampaignPostZipOnSlackDocument = gql`
    mutation sendCampaignPostZipOnSlack($campaignId: Int!) {
  sendCampaignPostZipOnSlack(campaignId: $campaignId)
}
    `;
export type SendCampaignPostZipOnSlackMutationFn = Apollo.MutationFunction<SendCampaignPostZipOnSlackMutation, SendCampaignPostZipOnSlackMutationVariables>;

/**
 * __useSendCampaignPostZipOnSlackMutation__
 *
 * To run a mutation, you first call `useSendCampaignPostZipOnSlackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCampaignPostZipOnSlackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCampaignPostZipOnSlackMutation, { data, loading, error }] = useSendCampaignPostZipOnSlackMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useSendCampaignPostZipOnSlackMutation(baseOptions?: Apollo.MutationHookOptions<SendCampaignPostZipOnSlackMutation, SendCampaignPostZipOnSlackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCampaignPostZipOnSlackMutation, SendCampaignPostZipOnSlackMutationVariables>(SendCampaignPostZipOnSlackDocument, options);
      }
export type SendCampaignPostZipOnSlackMutationHookResult = ReturnType<typeof useSendCampaignPostZipOnSlackMutation>;
export type SendCampaignPostZipOnSlackMutationResult = Apollo.MutationResult<SendCampaignPostZipOnSlackMutation>;
export type SendCampaignPostZipOnSlackMutationOptions = Apollo.BaseMutationOptions<SendCampaignPostZipOnSlackMutation, SendCampaignPostZipOnSlackMutationVariables>;