import styled from "@emotion/styled";
import { prettifyNumber } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale } from "chart.js";
import { Bar } from "react-chartjs-2";

import { Headline } from "components/ui/Headline";

ChartJS.register(CategoryScale, LinearScale, BarElement);

interface MarginsContainerProps {
  // percentage, between 0 and 1
  estimated: number;
  // percentage, between 0 and 1
  current: number;
}

export const MarginsContainer: React.FC<MarginsContainerProps> = ({ estimated, current }) => {
  const isCurrentMarginAvailable = !Number.isNaN(current);

  return (
    <Root>
      <DescriptionContainer>
        <Headline variant="h6">Margins</Headline>

        <MarginDescription>Current margin</MarginDescription>
        <MarginValue
          fill={current < estimated ? palette.primary.red : palette.secondary.green}
          isAvailable={isCurrentMarginAvailable ? "true" : "false"}
        >
          {isCurrentMarginAvailable ? prettifyNumber(current, { percentages: true }) : "n/a"}
        </MarginValue>
        <MarginDescription>Estimated margin</MarginDescription>
        <MarginValue fill={palette.primary.blue}>
          {prettifyNumber(estimated, { percentages: true })}
        </MarginValue>
      </DescriptionContainer>

      <div style={{ width: 170, height: 250 }}>
        <Bar
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: { legend: { display: false } },
            scales: {
              x: {
                grid: {
                  display: false
                },
                border: {
                  display: false
                }
              },
              y: {
                border: {
                  display: false
                }
              }
            }
          }}
          data={{
            labels: ["Current", "Estimated"],
            datasets: [
              {
                data: [current * 100, estimated * 100],
                backgroundColor: [
                  current < estimated ? palette.primary.red : palette.secondary.green,
                  palette.primary.blue
                ]
              }
            ]
          }}
        />
      </div>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const MarginDescription = styled.span`
  margin-top: 28px;
`;

const MarginValue = styled.span<{ fill: string; isAvailable?: "true" | "false" }>`
  font-size: ${({ isAvailable = "true" }) => (isAvailable === "true" ? "48px" : "26px")};
  line-height: 46px;
  color: ${({ fill }) => fill};
`;
