import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import { UserInfoFragmentDoc, UserProfilePictureFragmentDoc, MediaStateFragmentDoc, CaptionStateFragmentDoc, IntercomFragmentDoc } from '../../../../../../packages/gql/generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignExpensesQueryVariables = Types.Exact<{
  campaignStub: Types.Scalars['String']['input'];
}>;


export type CampaignExpensesQuery = { __typename?: 'Query', campaign_expenses: Array<{ __typename?: 'campaign_expense', id: number, description?: string | null, amount?: number | null, vat?: number | null, currency?: string | null, receipt?: string | null, exchange_rates?: any | null, payment_state: Types.Payment_Status_Enum, campaign_expense_type?: { __typename?: 'campaign_expense_type', id: number, description?: string | null } | null, campaign_user?: { __typename?: 'campaign_user', id: number, state?: Types.Campaign_User_State_Enum | null, currency?: string | null, payment_state: Types.Payment_Status_Enum, campaign: { __typename?: 'campaign', id: number, platform?: Types.Platform_Enum | null, stub: string }, user?: { __typename?: 'users', id: number, user_youtube?: { __typename?: 'users_youtube', id: number, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null } | null, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null } | null } | null }> };

export type CampaignExpenseDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type CampaignExpenseDetailsQuery = { __typename?: 'Query', campaign_expense_by_pk?: { __typename?: 'campaign_expense', id: number, description?: string | null, amount?: number | null, currency?: string | null, receipt?: string | null, comment?: string | null, created_at?: string | null, vat?: number | null, exchange_rates?: any | null, date?: string | null, status?: Types.Campaign_Expense_Status_Enum | null, banking_details: string, campaign_user?: { __typename?: 'campaign_user', id: number, campaign: { __typename?: 'campaign', id: number, platform?: Types.Platform_Enum | null }, user?: { __typename?: 'users', id: number, user_youtube?: { __typename?: 'users_youtube', id: number, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null } | null, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null } | null } | null } | null };

export type CampaignExpenseDependenciesQueryVariables = Types.Exact<{
  campaignStub: Types.Scalars['String']['input'];
}>;


export type CampaignExpenseDependenciesQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number }>, campaign_users: Array<{ __typename?: 'campaign_user', id: number, currency?: string | null, campaign: { __typename?: 'campaign', id: number, platform?: Types.Platform_Enum | null }, user?: { __typename?: 'users', id: number, user_youtube?: { __typename?: 'users_youtube', id: number, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null } | null, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null } | null }> };

export type UpsertExpenseMutationVariables = Types.Exact<{
  set: Types.Campaign_Expense_Insert_Input;
}>;


export type UpsertExpenseMutation = { __typename?: 'Mutation', insert_campaign_expense_one?: { __typename?: 'campaign_expense', id: number } | null };


export const CampaignExpensesDocument = gql`
    query campaignExpenses($campaignStub: String!) {
  campaign_expenses: campaign_expense(
    where: {campaign: {stub: {_eq: $campaignStub}}}
  ) {
    id
    description
    amount
    vat
    currency
    receipt
    exchange_rates
    payment_state
    campaign_expense_type {
      id
      description
    }
    campaign_user {
      id
      state
      currency
      payment_state
      campaign {
        id
        platform
        stub
      }
      user {
        id
        ...UserInfo
      }
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useCampaignExpensesQuery__
 *
 * To run a query within a React component, call `useCampaignExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignExpensesQuery({
 *   variables: {
 *      campaignStub: // value for 'campaignStub'
 *   },
 * });
 */
export function useCampaignExpensesQuery(baseOptions: Apollo.QueryHookOptions<CampaignExpensesQuery, CampaignExpensesQueryVariables> & ({ variables: CampaignExpensesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignExpensesQuery, CampaignExpensesQueryVariables>(CampaignExpensesDocument, options);
      }
export function useCampaignExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignExpensesQuery, CampaignExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignExpensesQuery, CampaignExpensesQueryVariables>(CampaignExpensesDocument, options);
        }
export function useCampaignExpensesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignExpensesQuery, CampaignExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignExpensesQuery, CampaignExpensesQueryVariables>(CampaignExpensesDocument, options);
        }
export type CampaignExpensesQueryHookResult = ReturnType<typeof useCampaignExpensesQuery>;
export type CampaignExpensesLazyQueryHookResult = ReturnType<typeof useCampaignExpensesLazyQuery>;
export type CampaignExpensesSuspenseQueryHookResult = ReturnType<typeof useCampaignExpensesSuspenseQuery>;
export type CampaignExpensesQueryResult = Apollo.QueryResult<CampaignExpensesQuery, CampaignExpensesQueryVariables>;
export const CampaignExpenseDetailsDocument = gql`
    query campaignExpenseDetails($id: Int!) {
  campaign_expense_by_pk(id: $id) {
    id
    description
    amount
    currency
    receipt
    comment
    created_at
    vat
    exchange_rates
    date
    status
    banking_details
    campaign_user {
      id
      campaign {
        id
        platform
      }
      user {
        id
        ...UserInfo
      }
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useCampaignExpenseDetailsQuery__
 *
 * To run a query within a React component, call `useCampaignExpenseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignExpenseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignExpenseDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignExpenseDetailsQuery(baseOptions: Apollo.QueryHookOptions<CampaignExpenseDetailsQuery, CampaignExpenseDetailsQueryVariables> & ({ variables: CampaignExpenseDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignExpenseDetailsQuery, CampaignExpenseDetailsQueryVariables>(CampaignExpenseDetailsDocument, options);
      }
export function useCampaignExpenseDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignExpenseDetailsQuery, CampaignExpenseDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignExpenseDetailsQuery, CampaignExpenseDetailsQueryVariables>(CampaignExpenseDetailsDocument, options);
        }
export function useCampaignExpenseDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignExpenseDetailsQuery, CampaignExpenseDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignExpenseDetailsQuery, CampaignExpenseDetailsQueryVariables>(CampaignExpenseDetailsDocument, options);
        }
export type CampaignExpenseDetailsQueryHookResult = ReturnType<typeof useCampaignExpenseDetailsQuery>;
export type CampaignExpenseDetailsLazyQueryHookResult = ReturnType<typeof useCampaignExpenseDetailsLazyQuery>;
export type CampaignExpenseDetailsSuspenseQueryHookResult = ReturnType<typeof useCampaignExpenseDetailsSuspenseQuery>;
export type CampaignExpenseDetailsQueryResult = Apollo.QueryResult<CampaignExpenseDetailsQuery, CampaignExpenseDetailsQueryVariables>;
export const CampaignExpenseDependenciesDocument = gql`
    query campaignExpenseDependencies($campaignStub: String!) {
  campaigns: campaign(where: {stub: {_eq: $campaignStub}}, limit: 1) {
    id
  }
  campaign_users: campaign_user(
    where: {state: {_in: ["approved", "confirmed"]}, campaign: {stub: {_eq: $campaignStub}}}
  ) {
    id
    currency
    campaign {
      id
      platform
    }
    user {
      id
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useCampaignExpenseDependenciesQuery__
 *
 * To run a query within a React component, call `useCampaignExpenseDependenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignExpenseDependenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignExpenseDependenciesQuery({
 *   variables: {
 *      campaignStub: // value for 'campaignStub'
 *   },
 * });
 */
export function useCampaignExpenseDependenciesQuery(baseOptions: Apollo.QueryHookOptions<CampaignExpenseDependenciesQuery, CampaignExpenseDependenciesQueryVariables> & ({ variables: CampaignExpenseDependenciesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignExpenseDependenciesQuery, CampaignExpenseDependenciesQueryVariables>(CampaignExpenseDependenciesDocument, options);
      }
export function useCampaignExpenseDependenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignExpenseDependenciesQuery, CampaignExpenseDependenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignExpenseDependenciesQuery, CampaignExpenseDependenciesQueryVariables>(CampaignExpenseDependenciesDocument, options);
        }
export function useCampaignExpenseDependenciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignExpenseDependenciesQuery, CampaignExpenseDependenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignExpenseDependenciesQuery, CampaignExpenseDependenciesQueryVariables>(CampaignExpenseDependenciesDocument, options);
        }
export type CampaignExpenseDependenciesQueryHookResult = ReturnType<typeof useCampaignExpenseDependenciesQuery>;
export type CampaignExpenseDependenciesLazyQueryHookResult = ReturnType<typeof useCampaignExpenseDependenciesLazyQuery>;
export type CampaignExpenseDependenciesSuspenseQueryHookResult = ReturnType<typeof useCampaignExpenseDependenciesSuspenseQuery>;
export type CampaignExpenseDependenciesQueryResult = Apollo.QueryResult<CampaignExpenseDependenciesQuery, CampaignExpenseDependenciesQueryVariables>;
export const UpsertExpenseDocument = gql`
    mutation upsertExpense($set: campaign_expense_insert_input!) {
  insert_campaign_expense_one(
    object: $set
    on_conflict: {constraint: campaign_expense_pkey, update_columns: [amount, comment, currency, date, description, exchange_rates, campaign_user_id, payment_state, receipt, vat, banking_details]}
  ) {
    id
  }
}
    `;
export type UpsertExpenseMutationFn = Apollo.MutationFunction<UpsertExpenseMutation, UpsertExpenseMutationVariables>;

/**
 * __useUpsertExpenseMutation__
 *
 * To run a mutation, you first call `useUpsertExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertExpenseMutation, { data, loading, error }] = useUpsertExpenseMutation({
 *   variables: {
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpsertExpenseMutation(baseOptions?: Apollo.MutationHookOptions<UpsertExpenseMutation, UpsertExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertExpenseMutation, UpsertExpenseMutationVariables>(UpsertExpenseDocument, options);
      }
export type UpsertExpenseMutationHookResult = ReturnType<typeof useUpsertExpenseMutation>;
export type UpsertExpenseMutationResult = Apollo.MutationResult<UpsertExpenseMutation>;
export type UpsertExpenseMutationOptions = Apollo.BaseMutationOptions<UpsertExpenseMutation, UpsertExpenseMutationVariables>;