import { useCallback } from "react";

import { SECTION_KEYS } from "modules/hypeAuditor/common/constants";

import { DataPickerSection } from "./DataPickerSection";
import type { DataSections, SectionRowId, ToggleSectionRowArgs } from "./useDataPickerSections";

interface DataPickerProps {
  toggleSectionRow: (args: ToggleSectionRowArgs) => void;
  sections: DataSections;
}

export type HandleCheckboxClick = ({
  sectionKey,
  columnKey,
  rowKey
}: SectionRowId) => (event: React.ChangeEvent<HTMLInputElement>) => void;

export const DataPicker: React.FunctionComponent<DataPickerProps> = ({
  sections,
  toggleSectionRow
}) => {
  const handleCheckboxClick: HandleCheckboxClick = useCallback(
    ({ sectionKey, columnKey, rowKey }) =>
      event => {
        event.persist();
        toggleSectionRow({ sectionKey, columnKey, rowKey, checked: event.target.checked });
      },
    [toggleSectionRow]
  );

  return (
    <>
      <DataPickerSection
        title="Account quality"
        sections={sections[SECTION_KEYS.ACCOUNT_QUALITY]}
        handleCheckboxClick={handleCheckboxClick}
        sectionKey={SECTION_KEYS.ACCOUNT_QUALITY}
      />

      <DataPickerSection
        title="Audience demograpics"
        sections={sections[SECTION_KEYS.AUDIENCE_DEMOGRAPHICS]}
        handleCheckboxClick={handleCheckboxClick}
        sectionKey={SECTION_KEYS.AUDIENCE_DEMOGRAPHICS}
      />
    </>
  );
};
