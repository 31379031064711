import type { CSSProperties } from "react";

import styled from "@emotion/styled";
import { FormControl, InputLabel, MenuItem, Select as MuSelect } from "@mui/material";

export function Select<V>({
  onChange,
  value,
  label,
  options,
  required,
  disabled,
  hideNone,
  customNoneText,
  size = "medium",
  style
}: {
  value: V;
  onChange(v: V | null): void;
  label: string;
  options: { value: V; label: string; highlight?: boolean; variant?: "danger" }[];
  required?: boolean;
  disabled?: boolean;
  hideNone?: boolean;
  customNoneText?: string;
  size?: "small" | "medium";
  style?: CSSProperties;
}) {
  return (
    <FormControl style={style} size={size} required={required} variant="outlined">
      <InputLabel shrink={value !== null && value !== undefined} id={label}>
        {label}
      </InputLabel>
      <MuSelect
        defaultValue={value}
        placeholder="Select…"
        disabled={disabled}
        size={size}
        labelId={label}
        label={label}
        value={value ?? ""}
        onChange={e => {
          const selectedOption = options.find(o => String(o.value) === String(e.target.value));
          if (!selectedOption) {
            onChange(null);
            return;
          }
          onChange(selectedOption?.value);
        }}
      >
        {!hideNone && (
          <StyledMenuItem value="">
            <em>{customNoneText || "None"}</em>
          </StyledMenuItem>
        )}
        {options.map(option => (
          <StyledMenuItem
            key={String(option.value)}
            value={String(option.value)}
            $variant={option.variant}
            classes={option.highlight ? { root: "highlighted" } : {}}
          >
            {option.label}
          </StyledMenuItem>
        ))}
      </MuSelect>
    </FormControl>
  );
}

export function MultipleSelect<V>({
  onChange,
  value,
  label,
  options,
  required,
  disabled,
  style,
  size = "medium"
}: {
  value: V[];
  onChange(v: V[] | null): void;
  label: string;
  options: { value: V; label: string }[];
  required?: boolean;
  disabled?: boolean;
  size?: "small" | "medium";
  style?: CSSProperties;
}) {
  return (
    <FormControl style={style} size={size} required={required} variant="outlined">
      <InputLabel id={label}>{label}</InputLabel>
      <MuSelect
        multiple
        defaultValue={value.map(String)}
        placeholder="Select…"
        disabled={disabled}
        size={size}
        labelId={label}
        label={label}
        value={value.map(String)}
        onChange={e => {
          const getValues = (): unknown => {
            const isNumber = options.every(o => typeof o.value === "number");

            if (!e.target.value || !e.target.value.length) return [];

            if (typeof e.target.value === "string") {
              return isNumber ? [Number(e.target.value)] : [e.target.value];
            }

            if (isNumber) return e.target.value.map(Number);

            return e.target.value;
          };
          const values = getValues();
          onChange(values as V[]);
        }}
      >
        {options.map(option => (
          <StyledMenuItem key={String(option.value)} value={String(option.value)}>
            {option.label}
          </StyledMenuItem>
        ))}
      </MuSelect>
    </FormControl>
  );
}

const StyledMenuItem = styled(MenuItem)<{ $variant?: "danger" }>`
  &.MuiMenuItem-root {
    color: ${p => (p.$variant === "danger" ? "red" : "")};
    &.highlighted {
      font-weight: bold;
    }
  }
`;
