/* eslint-disable newline-per-chained-call */
import { type FC, useCallback } from "react";

import styled from "@emotion/styled";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { BubbleMenu, useCurrentEditor } from "@tiptap/react";

import { Link, LinkOff } from "@mui/icons-material";
import { ToolbarButton } from "./ToolbarButton";

export const LinkToolbar: FC = () => {
  const { editor } = useCurrentEditor();
  const snackbar = useSnackbar();

  const handleOpenLink = useCallback(() => {
    if (!editor) return;
    const link = editor.getAttributes("link").href;
    if (!link || !link.startsWith("http")) {
      snackbar.error(
        `Invalid URL (${link}). Maybe you have a variable inside. Try clicking on the link from the preview.`
      );
      return;
    }
    window.open(link, "_blank");
  }, [snackbar, editor]);

  if (!editor) return null;

  return (
    <BubbleMenu
      className="bubble-menu"
      tippyOptions={{ duration: 100 }}
      editor={editor}
      shouldShow={({ editor: e }) => e.isActive("link")}
    >
      <StyledToolbar>
        <ToolbarButton
          icon={<Link />}
          title={`Open link (${editor.getAttributes("link").href})`}
          onClick={handleOpenLink}
        />

        <ToolbarButton
          icon={<LinkOff />}
          title="Remove link"
          onClick={() => editor.chain().focus().extendMarkRange("link").unsetLink().run()}
        />
      </StyledToolbar>
    </BubbleMenu>
  );
};

const StyledToolbar = styled.div`
  background: white;
  padding: 10px 0 10px 10px;
  border-radius: 6px;
`;
