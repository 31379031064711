import styled from "@emotion/styled";
import { MenuItem, Select } from "@mui/material";
import {
  clientApproved,
  clientReviewed,
  managerApproved,
  managerReviewed
} from "@relatable/helpers/approvalLogs";
import type { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";

import { DeleteMedia } from "./DeleteMedia";
import { ReviewBarIcon } from "./ReviewBarIcon";

interface ReviewBarProps {
  type: "creator" | "client";
  isCaption: boolean;
  state: CONTENT_APPROVAL_STATE | undefined | null;
  onChange?: (newStatus: boolean | null) => void;
  onDelete?: () => void;
}

export const ReviewBar: React.FC<ReviewBarProps> = ({
  type,
  isCaption,
  state,
  onChange,
  onDelete
}) => {
  if (!state) return null;

  // null = not reviewed yet
  const status = (() => {
    if (type === "creator") {
      if (!managerReviewed(state)) return null;
      return managerApproved(state);
    } else {
      if (!clientReviewed(state)) return null;
      return clientApproved(state);
    }
  })();

  const statusText = (() => {
    if (type === "client") {
      if (!managerApproved(state)) {
        return "(not visible to the client)";
      }
      if (!clientReviewed(state)) {
        return "Waiting for feedback";
      }
    }
    return null;
  })();

  const onIconClick = (value: boolean) => {
    if (type !== "client") {
      onChange?.(value);
    }
  };

  const renderStateIcons = () => {
    if (status === null) {
      return (
        <div className="icons">
          {!isCaption && <DeleteMedia onDelete={onDelete} />}
          <Button
            className={type === "client" ? "disabled" : ""}
            onClick={() => onIconClick(true)}
            tooltipText="Approve"
            variant="text"
            style={{ padding: 0, minWidth: "auto" }}
          >
            <ReviewBarIcon value="approved" disabled />
          </Button>
          <Button
            className={type === "client" ? "disabled" : ""}
            onClick={() => onIconClick(false)}
            tooltipText="Decline"
            variant="text"
            style={{ marginLeft: 15, padding: 0, minWidth: "auto" }}
          >
            <ReviewBarIcon value="declined" disabled />
          </Button>
        </div>
      );
    }

    // Material UI's Select component doesn't work with boolean values
    const getStringStatus = s => (s ? "approved" : "declined");

    return (
      <div className="icons">
        {!isCaption && <DeleteMedia onDelete={onDelete} />}

        <StyledSelect
          value={getStringStatus(status)}
          renderValue={() => <ReviewBarIcon value={getStringStatus(status)} />}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            }
          }}
          onChange={x => {
            if (x.target.value === "") {
              onChange?.(null);
            } else {
              onChange?.(x.target.value === "approved");
            }
          }}
        >
          <StyledMenuItem value={getStringStatus(!status)}>
            <ReviewBarIcon value={getStringStatus(!status)} inDropdown />
          </StyledMenuItem>
          <StyledMenuItem value="">
            <ReviewBarIcon value={null} inDropdown />
          </StyledMenuItem>
        </StyledSelect>
      </div>
    );
  };

  const authorTitle = type === "client" ? "Client" : "Relatable";
  const title = `${authorTitle} ${isCaption ? "caption" : "image"} review`;

  return (
    <Root>
      <span className="title">{title}</span>
      <div className="right">
        {statusText ? <span className="status">{statusText}</span> : renderStateIcons()}
      </div>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 65px;
  padding: 12px 0;
  border-bottom: 1px solid ${palette.gray[20]};

  &:last-of-type {
    border-bottom: none;
  }

  > .title {
    font-weight: bold;
  }

  > .right {
    > .icons {
      display: flex;

      > a.disabled {
        pointer-events: none;
      }
    }

    > .status {
      color: ${palette.gray[60]};
    }
  }
`;

const StyledSelect = styled(Select)`
  && {
    > .MuiSelect-select {
      background: inherit;
      padding: 0 !important;

      > .MuiMenuItem-root {
        padding: 0;
      }
    }

    > fieldset {
      border: 0;
    }

    > .MuiSelect-icon {
      display: none;
    }

    &:before,
    &:after {
      content: normal; /* remove default underline */
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &:hover {
      background: inherit;
    }
    &.Mui-disabled {
      && {
        pointer-events: auto;
      }
    }
  }
`;
