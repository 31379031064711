import { useParams } from "react-router-dom";

import { useSignUploadUrlMutation } from "modules/generated";

import {
  CampaignSnapchatStatsDocument,
  useAddCampaignSnapMutation,
  useUpdateSnapchatUserMutation
} from "./generated";

export const parseSnapchatUrl = (url: string) => {
  const pMatch = url.match(/^https:\/\/www\.snapchat\.com\/p\/([0-9a-f-]+)\/([0-9]+)/);
  const addMatch = url.match(/^https:\/\/www\.snapchat\.com\/add\/(\w+)\/(\w+)/);
  const spotlightMatch = url.match(/^https:\/\/www\.snapchat\.com\/spotlight\/(\w+)/);

  return {
    username: addMatch?.[1] ?? null,
    externalId: pMatch?.[1] ?? null,
    shortcode: pMatch?.[2] ?? addMatch?.[2] ?? spotlightMatch?.[1] ?? null
  };
};

export const useUploadSnap = () => {
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const [signUploadUrl] = useSignUploadUrlMutation();
  const [addSnap] = useAddCampaignSnapMutation({
    refetchQueries: [CampaignSnapchatStatsDocument]
  });

  const [updateSnapchatUser] = useUpdateSnapchatUserMutation({
    refetchQueries: [CampaignSnapchatStatsDocument]
  });

  return async ({
    fileType,
    file,
    videoUrl,
    fileName,
    username,
    campaignUserId,
    userSnapchatId
  }: {
    fileType: string;
    file: File;
    videoUrl: string;
    fileName: string;
    username: string;
    campaignUserId: number;
    userSnapchatId: number;
  }) => {
    if (!campaignStub) throw new Error("Missing campaign stub!");

    const parsedUrl = parseSnapchatUrl(videoUrl ?? "");

    if (videoUrl && !parsedUrl.shortcode) {
      throw new Error("Invalid Snapchat URL!");
    }

    const { data } = await signUploadUrl({
      variables: {
        input: {
          fileName: `${username}/${username}-Snap-${new Date().getTime()}-${parsedUrl.shortcode ?? "unknown"}-${fileName}`,
          fileType,
          prefix: "CAMPAIGN_STORIES"
        }
      }
    });

    if (!data) throw new Error("Something went wrong when uploading a file");

    const { signedUploadUrl, url: contentUrl } = data.signUploadUrl;

    await fetch(signedUploadUrl, {
      method: "PUT",
      body: file,
      headers: { "content-type": file.type }
    });

    await Promise.all([
      addSnap({
        variables: {
          object: {
            campaign_user_id: campaignUserId,
            shortcode: parsedUrl.shortcode,
            media_url: contentUrl
          }
        }
      }),
      ...(parsedUrl.externalId
        ? [
            updateSnapchatUser({
              variables: { userSnapchatId, set: { external_id: parsedUrl.externalId } }
            })
          ]
        : [])
    ]);
  };
};
