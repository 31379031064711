import type { FC } from "react";

import { prettifyDate } from "@relatable/helpers";

import { STATE_TYPES } from "lib/constants";
import { ContentFeedbackState } from "modules/campaign/common/ContentFeedbackState";

export const FeedbackState: FC<{
  isStoryApproved: boolean;
  approvedClipsCount: number;
  req_number_of_clips: number;
  approved_at: string;
}> = ({ isStoryApproved, approvedClipsCount, req_number_of_clips, approved_at }) => {
  const getFeedbackType = () => {
    if (isStoryApproved) {
      return STATE_TYPES.INFO;
    } else if (approvedClipsCount < req_number_of_clips) {
      return STATE_TYPES.INFO;
    }
    return STATE_TYPES.VALID;
  };

  const renderTextNode = () => {
    if (isStoryApproved) {
      return (
        <span>
          This <b>story</b> was approved on <i>{prettifyDate(approved_at, { full: true })}</i>
        </span>
      );
    }

    if (approvedClipsCount < req_number_of_clips) {
      return (
        <span>
          {`You must approve ${req_number_of_clips - approvedClipsCount} more clips to continue`}
        </span>
      );
    }
    return null;
  };

  return <ContentFeedbackState type={getFeedbackType()}>{renderTextNode()}</ContentFeedbackState>;
};
