import type { FC } from "react";

import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";

import { ExpenseEditorFields } from "./ExpenseEditorFields";
import { useCampaignExpenseDependenciesQuery, useCampaignExpenseDetailsQuery } from "./generated";

export const ExpenseEditor: FC = () => {
  const { expenseId, campaignStub } = useParams<{ expenseId: string; campaignStub: string }>();

  const {
    loading: loadingDeps,
    data: { campaign_users = [], campaigns } = {}
  } = useCampaignExpenseDependenciesQuery({
    variables: { campaignStub: campaignStub ?? "" },
    skip: !campaignStub
  });

  const { data: accountData } = useCampaignExpenseDetailsQuery({
    variables: { id: Number(expenseId) },
    skip: !expenseId
  });

  const details = accountData?.campaign_expense_by_pk;

  if (loadingDeps || (expenseId && !details)) {
    return <Loader />;
  }

  return (
    <ExpenseEditorFields
      details={details}
      campaignUsers={campaign_users}
      campaignId={campaigns?.[0]?.id}
    />
  );
};
