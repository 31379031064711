import type { FC } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { getOrdinalSuffixOf, prettifyDate } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import { Link, useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { Card } from "components/ui/Card";

import type { CampaignClientSheetQuery } from "./generated";

export const ClientListSubmissions: FC<{
  sheetLogs: CampaignClientSheetQuery["campaign_client_sheet"];
  loading: boolean;
}> = ({ sheetLogs, loading }) => {
  const hasSheetLinked = sheetLogs.length !== 0;
  const submitListLogs = sheetLogs.filter(log => log.action === "submit_list");
  const { campaignStub } = useParams<{ campaignStub: string }>();

  if (loading) {
    return <Loader />;
  }

  const renderLine = (left: JSX.Element | string, right: JSX.Element | string = "") => (
    <Box mx={-7} px={7} py={2} border={1} borderTop={0} borderColor={palette.gray[30]}>
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          {left}
        </Grid>
        <Grid item xs={6}>
          <Typography align="right">{right}</Typography>
        </Grid>
      </Grid>
    </Box>
  );

  const renderLog = (
    sheetLog: CampaignClientSheetQuery["campaign_client_sheet"][number],
    index: number
  ) => {
    const total =
      (sheetLog.preapproved ?? 0) + (sheetLog.declined ?? 0) + (sheetLog.needs_review ?? 0);

    const preapprovedPercentage = Math.round(((sheetLog.preapproved ?? 0) / total) * 100);
    const declinedPercentage = Math.round(((sheetLog.declined ?? 0) / total) * 100);
    const needsReviewPercentage = Math.round(((sheetLog.needs_review ?? 0) / total) * 100);

    return (
      <Box mt={6} key={sheetLog.id}>
        {renderLine(
          <span>
            <strong>{getOrdinalSuffixOf(index)} submission</strong>{" "}
            {prettifyDate(sheetLog.created_at, { full: true })}
          </span>
        )}
        {sheetLog.preapproved
          ? renderLine("Preapproved", `${sheetLog.preapproved} (${preapprovedPercentage}%)`)
          : null}
        {sheetLog.declined
          ? renderLine("Declined", `${sheetLog.declined} (${declinedPercentage}%)`)
          : null}
        {sheetLog.needs_review
          ? renderLine("Needs review", `${sheetLog.needs_review} (${needsReviewPercentage}%)`)
          : null}
        {sheetLog.client_comment ? (
          <Box py={2}>
            <Grid container justifyContent="space-between">
              <Grid item xs={4}>
                Comment
              </Grid>
              <Grid item xs={8}>
                {sheetLog.client_comment}
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Box>
    );
  };

  return (
    <Card>
      <Box mt={1}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <h2 style={{ marginBottom: "16px" }}>Creator List submissions</h2>
          </Grid>
        </Grid>
      </Box>
      {hasSheetLinked ? (
        submitListLogs.length > 0 ? (
          submitListLogs.map((log, index, arr) => renderLog(log, arr.length - index))
        ) : (
          <span>Waiting for client to submit the list.</span>
        )
      ) : (
        <span>
          <Link
            to={`/campaign/${campaignStub}/manage`}
            style={{ color: "inherit", textDecoration: "underline" }}
          >
            <strong>Publish the spreadsheet</strong>
          </Link>{" "}
          first, and then you can view submission info here.
        </span>
      )}
    </Card>
  );
};
