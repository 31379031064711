import { useMemo } from "react";

import { debounce } from "@mui/material";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { Loader } from "@relatable/ui/Loader";

import type { CampaignsQuery } from "../generated";
import { ClientSheet } from "./ClientSheet";
import {
  CampaignClientSheetDocument,
  type CampaignClientSheetQueryResult,
  useDownloadClientSheetMutation,
  useSetClientSheetCreatorsMutation,
  useSetClientSheetDueDateMutation
} from "./generated";

interface ClientSheetContainerProps {
  campaign: CampaignsQuery["campaigns"][0];
  sheetLogsLoading: boolean;
  sheetLogs: NonNullable<CampaignClientSheetQueryResult["data"]>["campaign_client_sheet"];
}

export const ClientSheetContainer: React.FC<ClientSheetContainerProps> = props => {
  const { campaign, sheetLogs, sheetLogsLoading } = props;

  const snackbar = useSnackbar();

  const [downloadSheet, { loading: downloadSheetLoading }] = useDownloadClientSheetMutation({
    onCompleted: () => snackbar.success("Spreadsheet updated"),
    onError: err => snackbar.error(err.message),
    refetchQueries: [CampaignClientSheetDocument]
  });

  const onSave = (sheetUrl: string) => {
    downloadSheet({
      variables: { campaign_id: campaign.id, sheet_url: sheetUrl }
    });
  };

  const onSync = () => {
    downloadSheet({
      variables: { campaign_id: campaign.id }
    });
  };

  const [setDueDate] = useSetClientSheetDueDateMutation();

  const debouncedSetDueDate = useMemo(
    () =>
      debounce(
        (dueDate: string) =>
          setDueDate({ variables: { campaign_id: campaign.id, due_date: dueDate } }),
        1000
      ),
    [campaign, setDueDate]
  );

  const [setMinCreators] = useSetClientSheetCreatorsMutation();

  const debouncedSetMinCreators = useMemo(
    () =>
      debounce(
        (minCreators: number) =>
          setMinCreators({
            variables: { campaign_id: campaign.id, min_creators: minCreators }
          }),
        1000
      ),
    [campaign, setMinCreators]
  );

  if (sheetLogsLoading) {
    return <Loader />;
  }

  return (
    <ClientSheet
      campaign={{ id: campaign.id, listId: campaign.list_id }}
      sheetLogs={sheetLogs}
      loading={downloadSheetLoading}
      onSave={onSave}
      onSync={onSync}
      setDueDate={debouncedSetDueDate}
      setMinCreators={debouncedSetMinCreators}
    />
  );
};
