import { type FC, memo, useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { isVideo as isVideoCheck } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { CONTENT_STATUS_TYPES } from "lib/constants";

// Instagram pushed a new link format from which we cannot determine the format…
// Image sometimes has .jpg 👍
// https://scontent.cdninstagram.com/v/t51.29350-15/400487484_1738143016653561_2457773794254289609_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=c4dd86&_nc_ohc=XhBDqcdCPqAAX9Hqyx4&_nc_ht=scontent.cdninstagram.com&edm=AL-3X8kEAAAA&oh=00_AfCrXx-T_mHVcyQ_wZNOdUNrESFBGLIivuQHKkOXssPlJA&oe=656247DC
// But Video…:
// https:// scontent.cdninstagram.com/o1/v/t16/f1/m69/GOf22xcKzzCtRxMBAIScUHVTGwwFbkYLAAAF?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLmNhcm91c2VsX2l0ZW0udW5rbm93bi1DMy43MjAuZGFzaF9iYXNlbGluZV8xX3YxIn0&_nc_ht=scontent.cdninstagram.com&_nc_cat=107&vs=166459393161575_3522722939&_nc_vs=HBkcFQIYOnBhc3N0aHJvdWdoX2V2ZXJzdG9yZS9HT2YyMnhjS3p6Q3RSeE1CQUlTY1VIVlRHd3dGYmtZTEFBQUYVAALIAQAoABgAGwGIB3VzZV9vaWwBMRUAACaGsuTqqZXwPxUCKAJDMywXQBczMzMzMzMYEmRhc2hfYmFzZWxpbmVfMV92MREAde4HAA%3D%3D&ccb=9-4&oh=00_AfDtv7XoKUh2m7Caj9zW8kEAYV476uuRvRBxelEktGTgsA&oe=655E8B00&_nc_sid=1d576d&_nc_rid=980a910377
const useIsVideo = (url: string, forcedIsVideo?: boolean) => {
  const [isVideo, setIsVideo] = useState(() => {
    if (forcedIsVideo !== undefined) return forcedIsVideo;
    const fileNameWithoutQuery = url.split("?")[0];
    return isVideoCheck(fileNameWithoutQuery);
  });

  useEffect(() => {
    if (forcedIsVideo !== undefined) return;
    const img = new Image();
    img.onload = () => {
      setIsVideo(false);
    };
    img.onerror = () => {
      setIsVideo(true);
    };
    img.src = url;
  }, [url, forcedIsVideo]);

  return isVideo;
};

// memoized to avoid video/img reloading
export const ContentDisplay: FC<{
  status: string;
  thumbnail: string;
  url: string;
  isVideo?: boolean;
}> = memo(({ thumbnail = "", status, url, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isVideoError, setIsVideoError] = useState(false);
  const isVideo = useIsVideo(url, props.isVideo);

  const videoRef = useRef<HTMLVideoElement>(null);

  const handleVideoClick = () => {
    if (!isVideoPlaying) {
      videoRef.current?.play();
      setIsVideoPlaying(true);
    } else {
      videoRef.current?.pause();
      setIsVideoPlaying(false);
    }
  };

  if (url && !thumbnail) {
    return (
      <Root>
        <NoImagePlaceholder>Content not found..</NoImagePlaceholder>
      </Root>
    );
  }

  if (url === undefined) {
    return (
      <Root>
        <NoImagePlaceholder>No content uploaded..</NoImagePlaceholder>
      </Root>
    );
  }

  return (
    <Root>
      <NoImagePlaceholder show={!isLoaded || isVideoError}>
        {!isLoaded ? (
          <Loader message="The content is loading.." />
        ) : (
          <FallbackText>
            Your browser does not support this format. Please <strong>right click</strong>{" "}
            <a href={url} download>
              here
            </a>{" "}
            and select <strong>save link as</strong> to download the content and view it on your
            computer.
          </FallbackText>
        )}
      </NoImagePlaceholder>
      <Frame status={status} show={isLoaded && !isVideoError}>
        {isVideo ? (
          <VideoDisplay
            ref={videoRef}
            src={url}
            onLoadedData={() => setIsLoaded(true)}
            onClick={handleVideoClick}
            onError={() => {
              setIsVideoPlaying(false);
              setIsVideoError(true);
            }}
            controls
          >
            <source src={url} type="video/mp4" />
            <source src={url} type="video/ogg" />
            Your browser does not support the video tag.
          </VideoDisplay>
        ) : (
          <ImageDisplay to={url} target="_blank">
            <PreviewImage
              onLoad={() => setIsLoaded(true)}
              src={thumbnail}
              onError={() => setIsLoaded(true)}
            />
          </ImageDisplay>
        )}
      </Frame>
    </Root>
  );
});

const Root = styled.div`
  position: relative;
  min-width: 240px;
  width: 100%;
  max-width: 320px;
  background-color: inherit;
  color: inherit;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 41px;
`;

const NoImagePlaceholder = styled.div<{ show?: boolean }>`
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 200;
  display: ${({ show = true }) => (show ? "flex" : "none")};
  font-size: 1rem;
`;

const Frame = styled.div<{ show: boolean; status: string }>`
  display: ${props => (props.show ? "flex" : "none")};
  flex-direction: column;
  width: auto;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    border: ${({ status }) => {
      if (status === CONTENT_STATUS_TYPES.APPROVED) {
        return `6px solid ${palette.secondary.green}`;
      }

      if (status === CONTENT_STATUS_TYPES.DENIED) {
        return `6px solid ${palette.primary.red}`;
      }

      return "";
    }};
  }
`;

const VideoDisplay = styled.video`
  max-width: 100%;
  max-height: 450px;
`;

const ImageDisplay = styled(Link)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    opacity: 0.95;
  }
`;

const PreviewImage = styled.img`
  align-self: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FallbackText = styled.span`
  text-align: center;
`;
