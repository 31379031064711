import { type FC, useState } from "react";

import styled from "@emotion/styled";

import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";

import { CAMPAIGN_USER_STATE_DISPLAY_NAMES, SORTED_CAMPAIGN_USER_STATES } from "src/lib/constants";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import type { UserCampaignsQuery, UserQuery } from "../generated";
import { CampaignSection } from "./CampaignSection";

const Container = styled.div`
  display: block;
  margin-top: 20px;
  width: 100%;
  max-width: 900px;

  > .header {
    display: flex;
    justify-content: space-between;

    > h2 {
      font-size: 24px;
      color: ${palette.gray[60]};
      padding: 24px 0;
    }

    > .right {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;

      > .explainer {
        font-size: 12px;
        text-align: right;
      }

      > .sort-button {
        margin-left: 12px;
      }
    }
  }
`;

export const UserCampaigns: FC<{
  user: NonNullable<UserQuery["users_by_pk"]>;
  userCampaigns: NonNullable<UserCampaignsQuery["campaign_user"]>;
}> = ({ user, userCampaigns }) => {
  type SortField = "date" | "globalPay" | "state";
  const [sortBy, setSortBy] = useState<SortField>("date");
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const onChangeOrder = (type: SortField) => {
    if (sortBy === type) {
      setOrder(prev => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(type);
      setOrder("desc");
    }
  };

  const sortedCampaignUsers = [...userCampaigns].sort((a, b) => {
    let res = 0;

    if (sortBy === "date") {
      const aDate = new Date(
        a.campaign?.campaign_content_settings_aggregate.aggregate?.max?.publish_date_max || 0
      ).getTime();
      const bDate = new Date(
        b.campaign?.campaign_content_settings_aggregate.aggregate?.max?.publish_date_max || 0
      ).getTime();
      res = (Number.isSafeInteger(aDate) ? aDate : 0) - (Number.isSafeInteger(bDate) ? bDate : 0);
    } else if (sortBy === "state") {
      if (!a.state || !b.state) return 0;
      const aStateName = CAMPAIGN_USER_STATE_DISPLAY_NAMES[a.state];
      const bStateName = CAMPAIGN_USER_STATE_DISPLAY_NAMES[b.state];
      const aOrder = SORTED_CAMPAIGN_USER_STATES.findIndex(v => v === aStateName);
      const bOrder = SORTED_CAMPAIGN_USER_STATES.findIndex(v => v === bStateName);
      res = aOrder - bOrder;
    }
    return order === "asc" ? res : -res;
  });

  return (
    <Container>
      <div className="header">
        <h2>Campaigns</h2>
        <div className="right">
          <span className="explainer">
            <strong>Sort by</strong>
            <br />
            {order === "asc" ? "asc." : "desc."}
          </span>
          {[
            {
              key: "date",
              value: "Date"
            },
            {
              key: "globalPay",
              value: "Global Pay"
            },
            {
              key: "state",
              value: "State"
            }
          ].map(btn => (
            <Button
              key={btn.key}
              className="sort-button"
              variant={sortBy === btn.key ? "contained" : "outlined"}
              color={sortBy === btn.key ? "primary" : "secondary"}
              icon={
                sortBy === btn.key ? (
                  order === "desc" ? (
                    <ArrowDropDown />
                  ) : (
                    <ArrowDropUp />
                  )
                ) : undefined
              }
              onClick={() => onChangeOrder(btn.key as SortField)}
            >
              {btn.value}
            </Button>
          ))}
        </div>
      </div>
      {sortedCampaignUsers.map(campaignUser => (
        <CampaignSection key={campaignUser.id} user={user} campaignUser={campaignUser} />
      ))}
    </Container>
  );
};
