import type { FC } from "react";

import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { YYYY_MM_DD, isBefore } from "@relatable/helpers/date";
import { prettifyNumber } from "@relatable/helpers/format";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { TextInput } from "@relatable/ui/TextInput";

import { Delete, MoveUp } from "@mui/icons-material";
import type { TimelineItem } from ".";

export const TimelineRow: FC<{
  item: TimelineItem;
  percentage: number;
  canDelete: boolean;
  canMoveUp: boolean;
  onMoveUp: (item: TimelineItem) => void;
  onInputChange: (
    item: TimelineItem,
    field: keyof TimelineItem,
    value: TimelineItem[typeof field]
  ) => void;
  onDelete: (item: TimelineItem) => void;
}> = ({ item, percentage, canDelete, canMoveUp, onMoveUp, onInputChange, onDelete }) => {
  return (
    <Root>
      <TextInput
        label="Name"
        value={item.name}
        size="small"
        style={{ width: 300, marginRight: 10 }}
        onChange={name => onInputChange(item, "name", name)}
      />

      <TextInput
        title="Automatically calculated. Each group has 25%. Inside groups, all timeline items have equal weights."
        label="Weight"
        value={prettifyNumber(percentage, { percentages: true })}
        disabled
        size="small"
        style={{ width: 100, marginRight: 10 }}
      />

      <StyledDateInput
        label="Deadline"
        type="date"
        value={item.end_date ? YYYY_MM_DD(item.end_date) : null}
        size="small"
        InputLabelProps={{ shrink: true }}
        onChange={e => onInputChange(item, "end_date", e.target.value)}
        finished={isBefore(item.end_date, new Date().toISOString()) || undefined}
        missing={!item.end_date}
        style={{ marginRight: 10 }}
      />

      <Button icon={<MoveUp />} disabled={!canMoveUp} variant="text" onClick={() => onMoveUp(item)}>
        Move up
      </Button>

      <Button
        icon={<Delete style={{ color: palette.primary.red }} />}
        disabled={!canDelete}
        variant="text"
        onClick={() => onDelete(item)}
      >
        Delete
      </Button>
    </Root>
  );
};

const Root = styled.div`
  margin: 5px 0;
`;

const StyledDateInput = styled(TextField, {
  shouldForwardProp: prop => prop !== "finished" && prop !== "missing"
})<{
  finished?: boolean;
  missing?: boolean;
}>`
  && {
    > .MuiInputBase-root input {
      height: auto;
    }

    > .MuiOutlinedInput-root {
      fieldset {
        ${({ finished, missing }) =>
          missing
            ? `border-width: 2px; border-color: ${palette.primary.red}`
            : finished
              ? `border-width: 2px; border-color: ${palette.primary.green}`
              : ""};
      }
    }
  }
`;
