import type { FC } from "react";

import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import { LinearProgress } from "components/ui/LinearProgress";

import { useStoryStatsHeadQuery } from "./generated";

export const StatsHead: FC = () => {
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  const { data } = useStoryStatsHeadQuery({ variables: { stubs: campaignStubs } });
  const campaigns = data?.campaigns || [];

  const approvedStoriesCount = campaigns.reduce(
    (prev, curr) => prev + (curr?.campaign_stories.length || 0),
    0
  );
  const totalStoriesCount = campaigns.reduce(
    (prev, curr) =>
      prev + (curr?.campaign_content_settings_aggregate?.aggregate?.sum?.storiesCount || 0) || 0,
    0
  );

  const targetReach = campaigns.reduce((prev, curr) => prev + (curr?.target_reach || 0) || 0, 0);
  const audienceReach = campaigns
    .map(
      campaign =>
        campaign?.campaign_stories.reduce(
          (acc, i) => acc + (i?.user?.user_instagram?.followed_by || 0),
          0
        ) || 0
    )
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <StatsContainer>
      <LinearProgress label="Story reach - Audience size" value={audienceReach} max={targetReach} />
      <LinearProgress
        label="Approved stories"
        value={approvedStoriesCount}
        max={totalStoriesCount}
      />
    </StatsContainer>
  );
};

const StatsContainer = styled.div`
  display: grid;
  gap: 2%;
  grid-template-columns: 49% 49%;
`;
