import styled from "@emotion/styled";
import { debounce } from "@mui/material";
import type React from "react";
import { useCallback, useMemo, useState } from "react";
import { useSnackbar } from "./Snackbar";
import { TextInput } from "./TextInput";

const STimeDurationField = styled.div`
  position: relative;
  
  label {
    background: rgb(247, 247, 247);
  }

  input {
    width: 100px;
  }

  .input-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    display: flex;

    :hover, :focus, :active, :focus-within {
      border: 1px solid rgba(0, 0, 0, 0.87);
    }
  }

  fieldset {
    outline: none !important;
    border-radius: none !important;
    border: none !important;
  }
`;
export const TimeDurationField: React.FC<{
  onChange: (secs: number) => void;
  seconds: number;
  label?: string;
  style?: React.CSSProperties;
  disableSeconds?: boolean;
  disableMinutes?: boolean;
  disableDays?: boolean;
}> = ({ onChange, label, style, disableMinutes, disableSeconds, disableDays, ...rest }) => {
  const snackbar = useSnackbar();

  const [timeData, setTime] = useState(() => {
    if (!rest.seconds) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    const days = rest.seconds ? Math.floor(rest.seconds / 60 / 60 / 24) : 0;
    const daysSeconds = days * 60 * 60 * 24;

    const hours = Math.floor((rest.seconds - daysSeconds) / 60 / 60);
    const hoursSeconds = hours * 60 * 60;

    const minutes = Math.floor((rest.seconds - daysSeconds - hoursSeconds) / 60);
    const minutesSeconds = minutes * 60;

    const seconds = rest.seconds - daysSeconds - hoursSeconds - minutesSeconds;

    return { days, hours, minutes, seconds };
  });
  const { days, hours, minutes, seconds } = timeData;

  const handleUpperStateChange = useCallback(
    (data: Partial<typeof timeData>) => {
      const newSeconds =
        (data.days ?? days) * 24 * 60 * 60 +
        (data.hours ?? hours) * 60 * 60 +
        (data.minutes ?? minutes) * 60 +
        (data.seconds ?? seconds);

      onChange(newSeconds);
    },
    [days, hours, minutes, seconds, onChange]
  );
  const debouncedChange = useMemo(
    () => debounce(handleUpperStateChange, 500),
    [handleUpperStateChange]
  );

  const handleChange = (state: Partial<typeof timeData>) => setTime(p => ({ ...p, ...state }));

  return (
    <STimeDurationField>
      <span style={{ position: "absolute", top: -25, fontSize: 10, opacity: 0.6 }}>{label}</span>
      <div className="input-wrapper">
        {!disableDays && (
          <TextInput
            type="number"
            label="Days"
            value={String(days)}
            onChange={v => {
              handleChange({ days: Number(v) });
              if (Number.isNaN(Number(v))) {
                snackbar.info("Please enter a valid number");
                return;
              }
              debouncedChange({ days: Number(v) });
            }}
          />
        )}
        <TextInput
          type="number"
          label="Hours"
          value={String(hours)}
          onChange={v => {
            const hours = Number(v);
            handleChange({ hours });
            if (Number.isNaN(hours) || hours > 23 || hours < 0) {
              snackbar.info("Please enter a valid number");
              return;
            }
            debouncedChange({ hours });
          }}
        />
        {!disableMinutes && (
          <TextInput
            type="number"
            label="Minutes"
            value={String(minutes)}
            onChange={v => {
              const minutes = Number(v);
              handleChange({ minutes });
              if (Number.isNaN(minutes) || minutes > 59 || minutes < 0) {
                snackbar.info("Please enter a valid number");
                return;
              }
              debouncedChange({ minutes });
            }}
          />
        )}
        {!disableSeconds && (
          <TextInput
            type="number"
            label="Seconds"
            value={String(seconds)}
            onChange={v => {
              const seconds = Number(v);
              handleChange({ seconds });
              if (Number.isNaN(seconds) || seconds > 59 || seconds < 0) {
                snackbar.info("Please enter a valid number");
                return;
              }
              debouncedChange({ seconds });
            }}
          />
        )}
      </div>
    </STimeDurationField>
  );
};
