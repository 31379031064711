import { type FC, useRef } from "react";

import styled from "@emotion/styled";
import { BookmarkOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { palette } from "@relatable/ui/Palette";
import { createPortal } from "react-dom";
import { getBookmarkletScript } from "./script";

const Root = styled.div`
  text-align: right;
  > span {
    > a {
      color: ${palette.gray[60]};
    }
  }
`;

export const Bookmarklet: FC<{ listId: number; listName: string; platform: Platform_Enum }> = ({
  listId,
  listName,
  platform
}) => {
  const URLScript = getBookmarkletScript({ listId, platform });
  const bookmarkletRef = useRef<HTMLSpanElement>(null);

  const id = "bookmarklet-icon";
  const bookmarkletHTML = [
    `<a href="javascript:${window.encodeURIComponent(URLScript)}">`,
    `<span id="${id}" />`,
    `<span style="display: none">Add to "${listName}" list</span>`,
    "</a>"
  ].join("");

  const hook = bookmarkletRef.current?.querySelector(`#${id}`);
  return (
    <Root>
      {hook && createPortal(<BookmarkOutlined />, hook)}
      <Tooltip title="Bookmarklet - Drag this icon to the bookmarks bar">
        <span
          ref={bookmarkletRef}
          // React throws errors for javascript: urls when used without dangerouslySetInnerHTML
          // biome-ignore lint/security/noDangerouslySetInnerHtml:
          dangerouslySetInnerHTML={{
            __html: bookmarkletHTML
          }}
        />
      </Tooltip>
    </Root>
  );
};
