import { type FC, useState } from "react";

import { Table, type TableColumn, type TableRow } from "@relatable/ui/Table";
import { Link } from "react-router-dom";

import { PeriodSelector } from "components/PeriodSelector";
import { useMeQuery } from "hooks/generated";

import { type ProjectTimeRecordsQuery, useProjectTimeRecordsQuery } from "./generated";

const getProjectTopContributors = (
  data: ProjectTimeRecordsQuery["projects"][number]["time_tracking"]
) => {
  const accounts = data.reduce<Record<string, number>>((acc, curr) => {
    if (!acc[curr.account_id]) {
      acc[curr.account_id] = 0;
    }

    acc[curr.account_id] += Number(curr.hours);

    return acc;
  }, {});

  return Object.entries(accounts)
    .sort((a, b) => b[1] - a[1])
    .map(([accountId, hours]) => ({
      accountId: Number(accountId),
      hours
    }))
    .filter(item => Number(item.hours));
};

export const ProjectReport: FC = () => {
  const { data: me } = useMeQuery();
  const isAdmin = (me?.me?.admin?.roles ?? []).includes("admin");

  const [selectedPeriod, setSelectedPeriod] = useState<null | { start: string; end: string }>(null);

  const { data, loading } = useProjectTimeRecordsQuery({
    variables: {
      dateMin: selectedPeriod?.start ?? "",
      dateMax: selectedPeriod?.end ?? ""
    },
    skip: !selectedPeriod,
    fetchPolicy: "no-cache"
  });

  const headers: TableColumn<typeof rows>[] = [
    {
      field: "name",
      headerName: "Name",
      renderCell: ({ value, row }) => (
        <Link
          to={`/overview?project=${encodeURIComponent(row?.stub || "")}&archived=true&teamMemberAccountId=all`}
        >
          {value}
        </Link>
      )
    },
    {
      field: "hours",
      headerName: "Total hours",
      sortComparator: (a, b) => Number(a) - Number(b),
      renderCell: ({ value }) => Number(value).toFixed(2) || "-"
    },
    {
      field: "topContributors",
      headerName: "Top contributors",
      hide: !isAdmin,
      renderCell: ({ value }) => (
        <span>
          {value.slice(0, 4).map(v => {
            const account = data?.accounts.find(p => p.id === v.accountId);
            return (
              <span key={account?.id}>
                {`${account?.first_name}  ${account?.last_name}` ?? "?"}
                {" - "}
                {Number(v.hours).toFixed(2)} hours
                <br />
              </span>
            );
          })}
        </span>
      )
    }
  ];

  const rows: TableRow[] =
    data?.projects
      ?.map(p => ({
        key: p.id,
        stub: p.stub,
        name: p.name,
        hours: p.time_tracking.reduce((acc, curr) => acc + Number(curr.hours), 0).toFixed(2),
        topContributors: getProjectTopContributors(p.time_tracking)
      }))
      ?.filter(item => Number(item.hours)) ?? [];

  rows.sort((a, b) => Number(b.hours) - Number(a.hours));

  return (
    <>
      <PeriodSelector startDate={2024} onSelect={setSelectedPeriod} showWeeks />
      <br />
      <br />
      <Table
        tableId="timereporting-project-report"
        loading={loading}
        columns={headers}
        rows={rows}
        sortable
      />
    </>
  );
};
