import type { FC } from "react";

import { CircularProgress } from "@mui/material";
import { getUserLabel } from "@relatable/helpers/user";
import { Table } from "@relatable/ui/Table";
import { Link } from "react-router-dom";

import { useLinkUsageQuery } from "./generated";

export const LinkUsage: FC<{ linkKey: string }> = ({ linkKey }) => {
  const { data, loading } = useLinkUsageQuery({
    variables: { like: `%https://rlt.to/${linkKey}%` }
  });

  if (loading) return <CircularProgress />;

  if (!data?.campaign_user?.length) return "No creators are associated with this link.";

  return (
    <Table
      tableId="link-usage"
      minWidth={100}
      rows={data?.campaign_user.map(cu => ({
        key: cu.id,
        campaignStub: cu.campaign.stub,
        campaignTitle: cu.campaign.title,
        userLabel: getUserLabel(cu.user),
        userId: cu.user?.id
      }))}
      columns={[
        {
          field: "campaignStub",
          headerName: "Campaign",
          renderCell: ({ row }) => (
            <Link to={`/campaign/${row.campaignStub}`}>{row.campaignTitle}</Link>
          )
        },
        {
          field: "userId",
          headerName: "Creator",
          renderCell: ({ row }) => <Link to={`/user/${row.userId}`}>{row.userLabel}</Link>
        }
      ]}
    />
  );
};
