import type { FC } from "react";

import styled from "@emotion/styled";
import { Card, CardContent, CardHeader, Paper } from "@mui/material";
import { isAfter, isBefore, prettifyDate, prettifyNumber } from "@relatable/helpers";
import { useParams } from "react-router-dom";

import { useCampaignSnapchatStatsQuery } from "./generated";

export const StatsBody: FC = () => {
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  const { data } = useCampaignSnapchatStatsQuery({ variables: { stubs: campaignStubs } });

  const campaigns = data?.campaigns || [];
  const snaps = data?.campaign_snaps || [];

  const leftCards = [
    {
      title: "Number of Snaps",
      value: prettifyNumber(snaps.length)
    }
  ];

  const firstDate = campaigns
    .map(c => c.min_max_publish_date.aggregate?.min?.publish_date_min)
    .reduce((prev, curr) => (prev && isBefore(prev, curr || "") ? prev : curr), null);

  const lastDate = campaigns
    .map(c => c.min_max_publish_date.aggregate?.max?.publish_date_max)
    .reduce((prev, curr) => (prev && isAfter(prev, curr || "") ? prev : curr), null);

  const rightCards = [
    { title: "First post date", value: prettifyDate(firstDate) },
    { title: "Last post date", value: prettifyDate(lastDate) }
  ];

  return (
    <StatsContainer>
      <LeftSection elevation={10}>
        {leftCards.map(card => (
          <Card key={card.title}>
            <CardHeader subheader={card.title} />
            <SCardContent>{card.value}</SCardContent>
          </Card>
        ))}
      </LeftSection>

      <RightSection elevation={10}>
        {rightCards.map(card => (
          <Card key={card.title}>
            <CardHeader subheader={card.title} />
            <SCardContent>{card.value}</SCardContent>
          </Card>
        ))}
      </RightSection>
    </StatsContainer>
  );
};

const SCardContent = styled(CardContent)`
  padding-top: 0 !important;
  font-size: 20px;
`;

const LeftSection = styled(Paper)`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  padding: 20px;
`;

const RightSection = styled(Paper)`
  display: flex;
  padding: 20px;
  > div {
    flex-grow: 1;
  }
`;

const StatsContainer = styled.div`
  display: grid;
  gap: 2%;
  grid-template-columns: 49% 49%;
`;
