import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { palette } from "@relatable/ui/Palette";

// card component container
export const SectionContainer = styled(Grid)`
  && {
    margin: 25px -12px;
    background: ${palette.gray.white};
    border: 1px solid ${palette.gray[10]};
    box-shadow: 0px 20px 40px ${palette.gray[10]};
    border-radius: 8px;
    padding: 40px;
  }
`;

// where the components should go, the body of the page
export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1280px;
  padding-bottom: 40px;
  width: 85%;

  @media screen and (max-width: 1024px) {
    width: 95%;
  }
`;

export const Title = styled.h3`
  font-size: 1.64rem;
  line-height: 110%;
  font-weight: 400;
  margin: 25px 50px;
`;

export const ContentInfo = styled.span`
  display: inline-block;
  color: ${palette.gray[50]};
  margin-bottom: 5px;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: auto;
  min-height: 200px;
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0)
  }

  100% {
    opacity: 1;
    transform: scale(1)
  }
`;

export const animateOpacity = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;
