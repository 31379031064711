import styled from "@emotion/styled";
import { Route, Routes } from "react-router-dom";

import { AggregatedList } from "./AggregatedList";
import { InstagramReportContainer } from "./InstagramReport/InstagramReportContainer";
import { SnapchatReportContainer } from "./SnapchatReport/SnapchatReportContainer";
import { TiktokReportContainer } from "./TiktokReport/TiktokReportContainer";
import { YoutubeReportContainer } from "./YoutubeReport/YoutubeReportContainer";

export const Hypeauditor = () => {
  return (
    <Section>
      <Routes>
        <Route path="instagram/aggregated" element={<AggregatedList />} />
        <Route path="instagram/:userId" element={<InstagramReportContainer />} />
        <Route path="youtube/aggregated" element={<AggregatedList />} />
        <Route path="youtube/:userId" element={<YoutubeReportContainer />} />
        <Route path="tiktok/aggregated" element={<AggregatedList />} />
        <Route path="tiktok/:userId" element={<TiktokReportContainer />} />
        <Route path="snapchat/aggregated" element={<AggregatedList />} />
        <Route path="snapchat/:userId" element={<SnapchatReportContainer />} />
      </Routes>
    </Section>
  );
};

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 50px;
`;
