import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import { UserInfoFragmentDoc, UserProfilePictureFragmentDoc, MediaStateFragmentDoc, CaptionStateFragmentDoc, IntercomFragmentDoc } from '../../../../../../packages/gql/generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HypeAuditorYoutubeReportQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
}>;


export type HypeAuditorYoutubeReportQuery = { __typename?: 'Query', hypeauditor: Array<{ __typename?: 'hypeauditor', id: number, data?: any | null, state?: Types.Hypeauditor_Report_State_Enum | null, not_available?: boolean | null, user: { __typename?: 'users', id: number, user_youtube?: { __typename?: 'users_youtube', id: number, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null } | null, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null } }> };


export const HypeAuditorYoutubeReportDocument = gql`
    query hypeAuditorYoutubeReport($userId: Int!) {
  hypeauditor(where: {users_id: {_eq: $userId}}) {
    id
    data
    state
    not_available
    user {
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useHypeAuditorYoutubeReportQuery__
 *
 * To run a query within a React component, call `useHypeAuditorYoutubeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useHypeAuditorYoutubeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHypeAuditorYoutubeReportQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useHypeAuditorYoutubeReportQuery(baseOptions: Apollo.QueryHookOptions<HypeAuditorYoutubeReportQuery, HypeAuditorYoutubeReportQueryVariables> & ({ variables: HypeAuditorYoutubeReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HypeAuditorYoutubeReportQuery, HypeAuditorYoutubeReportQueryVariables>(HypeAuditorYoutubeReportDocument, options);
      }
export function useHypeAuditorYoutubeReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HypeAuditorYoutubeReportQuery, HypeAuditorYoutubeReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HypeAuditorYoutubeReportQuery, HypeAuditorYoutubeReportQueryVariables>(HypeAuditorYoutubeReportDocument, options);
        }
export function useHypeAuditorYoutubeReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HypeAuditorYoutubeReportQuery, HypeAuditorYoutubeReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HypeAuditorYoutubeReportQuery, HypeAuditorYoutubeReportQueryVariables>(HypeAuditorYoutubeReportDocument, options);
        }
export type HypeAuditorYoutubeReportQueryHookResult = ReturnType<typeof useHypeAuditorYoutubeReportQuery>;
export type HypeAuditorYoutubeReportLazyQueryHookResult = ReturnType<typeof useHypeAuditorYoutubeReportLazyQuery>;
export type HypeAuditorYoutubeReportSuspenseQueryHookResult = ReturnType<typeof useHypeAuditorYoutubeReportSuspenseQuery>;
export type HypeAuditorYoutubeReportQueryResult = Apollo.QueryResult<HypeAuditorYoutubeReportQuery, HypeAuditorYoutubeReportQueryVariables>;