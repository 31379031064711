import { useState } from "react";

import styled from "@emotion/styled";
import { ArrowDropDown } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { getContentAggregateState, normalizeState } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { getUserLabel, getUserLink, getUserProfilePicture } from "@relatable/helpers/user";

import { AttachmentLink } from "./common/AttachmentLink";
import { BottomFeedbackBar } from "./common/BottomFeedbackBar";
import { Caption } from "./common/Caption";
import { Content } from "./common/Content";
import { Header } from "./common/Header";
import type {
  ContentMediaQuery,
  ContentOverviewQuery,
  ContentOverviewSettingsQuery
} from "./generated";

export interface ContentOverviewProps {
  activeContent: ContentOverviewQuery["activeContents"][number] | null;
  medias: ContentMediaQuery["medias"];
  contentOverviewSetting: ContentOverviewSettingsQuery;
  onNotifyCreator: () => void;
  onAcceptClientReview: () => void;
  moveMediaUp: (mediaId: number) => void;
  onGoToPrevious: () => void;
  onGoToNext: () => void;
  onUpdateState: (param: {
    author: "creator" | "client";
    type: "media" | "caption";
    id: number;
    newState: CONTENT_APPROVAL_STATE;
  }) => void;
  onUpdateCaption: (id: number, oldCaption: string, newCaption: string) => void;
  onWriteMessage: (
    id: number,
    contentType: "media" | "caption",
    target: "creator" | "client",
    text: string
  ) => void;
  onSetAttachmentLink: (link: string) => void;
  onDelete: (mediaId: number) => void;
  setLinkLoading: boolean;
  moveMediaLoading: boolean;
  updateCaptionLoading: boolean;
}

export const ContentOverview: React.FC<ContentOverviewProps> = ({
  activeContent,
  medias,
  contentOverviewSetting,
  onNotifyCreator,
  onAcceptClientReview,
  moveMediaUp,
  onGoToPrevious,
  onGoToNext,
  onUpdateState,
  onUpdateCaption,
  onWriteMessage,
  onSetAttachmentLink,
  setLinkLoading,
  moveMediaLoading,
  updateCaptionLoading,
  onDelete
}) => {
  const { setting: contentSetting, campaignUser } = contentOverviewSetting;

  const [currentlyMovedMediaIndices, setCurrentlyMovedMediaIndices] = useState<number[]>([]);

  const activeContentState = (() => {
    const itemStates: (string | null | undefined)[] = [];
    if (activeContent?.new_caption) {
      itemStates.push(activeContent.new_caption.content_preapproval_logs[0]?.state);
    }

    if (medias.length > 0) {
      itemStates.push(
        ...medias.filter(m => !m.deleted).map(m => m.content_preapproval_logs[0]?.state)
      );
    }

    return getContentAggregateState(itemStates);
  })();

  const [filter, setFilter] = useState<"all" | "relatable" | "client">("all");

  const { platform } = contentSetting?.campaign ?? {};

  const deletedMedias = medias?.filter(m => m.deleted);

  const mediasReadyForReview = medias?.filter(
    m =>
      !m.deleted &&
      normalizeState(m.content_preapproval_logs[0]?.state) !==
        CONTENT_APPROVAL_STATE.CREATOR_INITIAL_UPLOAD
  );

  const numMediaComments =
    mediasReadyForReview?.reduce(
      (prev, curr) => prev + curr.logs.filter(log => log.text).length,
      0
    ) ?? 0;

  const numCaptionComments = activeContent?.new_caption?.logs.filter(log => log.text).length ?? 0;

  const numComments = numMediaComments + numCaptionComments;

  const onMoveMedia = (direction: "up" | "down", mediaId: number) => {
    const mediaIndex = mediasReadyForReview.findIndex(m => m.id === mediaId);
    if (mediaIndex === -1) return;

    if (direction === "up") {
      if (mediaIndex === 0) return;
      setCurrentlyMovedMediaIndices([mediaIndex, mediaIndex - 1]);
      moveMediaUp(mediaId);
    } else {
      if (!mediasReadyForReview[mediaIndex + 1]) return;
      setCurrentlyMovedMediaIndices([mediaIndex, mediaIndex + 1]);
      moveMediaUp(mediasReadyForReview[mediaIndex + 1].id);
    }
  };

  if (!contentSetting) return null;

  const renderMedia = (
    media: ContentMediaQuery["medias"][number],
    index: number,
    deleted: boolean
  ) => (
    <Content
      media={media}
      key={media.id}
      moveUp={!deleted && index > 0 ? () => onMoveMedia("up", media.id) : () => null}
      moveDown={
        !deleted && index < mediasReadyForReview.length - 1
          ? () => onMoveMedia("down", media.id)
          : () => null
      }
      index={index}
      numSlides={deleted ? deletedMedias.length : mediasReadyForReview.length}
      numComments={media.logs.filter(log => log.text).length}
      filter={filter}
      loading={moveMediaLoading && currentlyMovedMediaIndices.includes(index)}
      updateCaptionLoading={updateCaptionLoading}
      onUpdateCaption={
        deleted
          ? undefined
          : newCaption => {
              if (!media?.caption?.id) return;
              onUpdateCaption(media.caption.id, media.caption.text || "", newCaption);
            }
      }
      onUpdateState={deleted ? undefined : onUpdateState}
      onWriteMessage={
        deleted ? undefined : (target, text) => onWriteMessage(media.id, "media", target, text)
      }
      onDelete={deleted ? undefined : () => onDelete(media.id)}
    />
  );

  return (
    <Root>
      <Header
        userLabel={getUserLabel(campaignUser?.user)}
        userLink={getUserLink(campaignUser?.user)}
        profilePicture={getUserProfilePicture(campaignUser?.user)}
        numComments={numComments}
        type={contentSetting?.type}
        link={activeContent?.link || ""}
        platform={platform}
        state={activeContentState}
        publishDateMin={contentSetting?.publish_date_min}
        publishDateMax={contentSetting?.publish_date_max}
        filter={filter}
        onChangeFilter={value => setFilter(value)}
        onGoToPrevious={onGoToPrevious}
        onGoToNext={onGoToNext}
      />
      <div className="media-container">
        {mediasReadyForReview
          ?.filter(media => !media.deleted)
          .map((m, i) => renderMedia(m, i, false))}
      </div>

      {activeContent?.legacy_caption ? (
        <Caption legacyCaption={activeContent?.legacy_caption} filter={filter} />
      ) : null}

      {activeContent?.new_caption ? (
        <Caption
          caption={activeContent.new_caption}
          filter={filter}
          updateCaptionLoading={updateCaptionLoading}
          onUpdateCaption={newCaption => {
            if (!activeContent?.new_caption?.id) throw new Error("id is missing");
            onUpdateCaption(
              activeContent.new_caption.id,
              activeContent.new_caption.text || "",
              newCaption
            );
          }}
          onWriteMessage={(target, text) => {
            if (!activeContent?.new_caption?.id) throw new Error("id is missing");
            onWriteMessage(activeContent.new_caption.id, "caption", target, text);
          }}
          onUpdateState={onUpdateState}
        />
      ) : null}

      <AttachmentLink
        link={activeContent?.link || ""}
        onSetLink={onSetAttachmentLink}
        loading={setLinkLoading}
      />

      {deletedMedias?.length > 0 && (
        <Accordion
          className="deleted-media"
          slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
        >
          <AccordionSummary expandIcon={<ArrowDropDown />} className="deleted-media-header">
            View deleted
          </AccordionSummary>
          <AccordionDetails>
            <div className="media-container">
              {deletedMedias?.map((m, i) => renderMedia(m, i, true))}
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      <BottomFeedbackBar
        activeContent={activeContent}
        medias={mediasReadyForReview}
        contentSetting={contentSetting}
        onNotifyCreator={onNotifyCreator}
        onAcceptClientReview={onAcceptClientReview}
      />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  margin: 0 -30px;
  padding: 0px 30px;
  width: calc(100% + 60px);

  .back-button {
    align-self: flex-start;
  }

  > .media-container {
    display: flex;
    flex-direction: column;
  }

  > .deleted-media {
    > .deleted-media-header {
      > .MuiAccordionSummary-content {
        flex-grow: 0;
        font-weight: bold;
        text-transform: uppercase;
        padding-right: 10px;
      }
    }
  }
`;
