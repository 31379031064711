import type { FC } from "react";

import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import { LinearProgress } from "components/ui/LinearProgress";

import { useCampaignSnapchatStatsQuery } from "./generated";

export const StatsHead: FC = () => {
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  const { data } = useCampaignSnapchatStatsQuery({ variables: { stubs: campaignStubs } });
  const snaps = data?.campaign_snaps || [];

  const approvedSnaps = snaps.filter(s => s.approved);

  return (
    <StatsContainer>
      <LinearProgress label="Approved Snaps" value={approvedSnaps.length} max={snaps.length} />
    </StatsContainer>
  );
};

const StatsContainer = styled.div`
  display: grid;
  gap: 2%;
  grid-template-columns: 49% 49%;
`;
