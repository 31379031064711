import { Fragment, memo, useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@mui/material";
import { Loader } from "@relatable/ui/Loader";
import { palette } from "@relatable/ui/Palette";

import { ArrowDropDown, ChatBubble, Check, Close, Photo } from "@mui/icons-material";
import { CaptionEditor } from "./CaptionEditor";

interface ContentDisplayProps {
  url: string;
  thumbnail: string;
  isVideo: boolean;
  index: number;
  numSlides: number;
  numComments: number;
  loading: boolean;
  caption: string;
  updateCaptionLoading: boolean;
  variant: "approved" | "rejected" | null;
  filenameHistory: string[];
  onUpdateCaption?: (caption: string) => void;
}

export const ContentDisplay: React.FC<ContentDisplayProps> = memo(
  ({
    url,
    thumbnail = "",
    isVideo,
    index,
    numSlides,
    numComments,
    loading,
    caption,
    updateCaptionLoading,
    variant,
    filenameHistory,
    onUpdateCaption
  }) => {
    const [contentLoaded, setContentLoaded] = useState(false);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [videoError, setVideoError] = useState(false);
    const video = useRef<HTMLVideoElement>(null);

    useEffect(() => {
      if (loading) {
        setContentLoaded(false);
      }
    }, [loading]);

    const onLoad = () => {
      if (!contentLoaded) {
        setContentLoaded(true);
      }
    };

    const onVideoClick = () => {
      if (!video.current) return;

      if (!videoPlaying) {
        video.current.play();
      } else {
        video.current.pause();
      }

      setVideoPlaying(prev => !prev);
    };

    const onVideoError = e => {
      console.error(e?.target?.error);
      setVideoError(true);
      setContentLoaded(true);
    };

    const onThumbnailError = () => {
      setContentLoaded(true);
    };

    if (url && !thumbnail) {
      return (
        <Root>
          <NoImagePlaceholder>Content not found..</NoImagePlaceholder>
        </Root>
      );
    }

    if (url === undefined) {
      return (
        <Root>
          <NoImagePlaceholder>No content uploaded..</NoImagePlaceholder>
        </Root>
      );
    }

    const renderMediaElement = () => {
      // it's mandatory to unmount the media element while the backend is reloading
      // otherwise the onLoad event won't be triggered and the image loader will be displayed forever
      if (loading) return null;

      return isVideo ? (
        <VideoDisplay
          ref={video}
          src={url}
          onLoadedData={onLoad}
          onClick={onVideoClick}
          onError={onVideoError}
          controls
        >
          <source src={url} type="video/mp4" />
          <source src={url} type="video/ogg" />
          Your browser does not support the video tag.
        </VideoDisplay>
      ) : (
        <ImageLink target="_blank">
          <ImageComponent onLoad={onLoad} src={thumbnail} onError={onThumbnailError} />
        </ImageLink>
      );
    };

    return (
      <Root>
        <NoImagePlaceholder $show={!contentLoaded || videoError}>
          {!contentLoaded ? (
            <Loader message="The content is loading.." />
          ) : (
            <FallbackText>
              Your browser does not support this format. Please <strong>right click</strong>{" "}
              <a href={url} download>
                here
              </a>{" "}
              and select <strong>save link as</strong> to download the content and view it on your
              computer.
            </FallbackText>
          )}
        </NoImagePlaceholder>
        <Frame $show={!loading && contentLoaded && !videoError}>
          <div className={`header ${variant ?? ""}`}>
            <div className="left">
              <Photo style={{ marginRight: 6 }} />
              {index + 1}/{numSlides}
              {numComments > 0 ? (
                <>
                  <ChatBubble style={{ margin: "0 6px 0 12px" }} />
                  {numComments}
                </>
              ) : null}
            </div>
            <div className="right">
              {variant === "approved" && <Check />}
              {variant === "rejected" && <Close />}
            </div>
          </div>
          <div className="content">
            {renderMediaElement()}
            {filenameHistory.length > 0 && (
              <Accordion
                className="previous-media"
                slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
              >
                <AccordionSummary expandIcon={<ArrowDropDown />} className="previous-media-header">
                  Previous uploads
                </AccordionSummary>
                <AccordionDetails>
                  {[...filenameHistory].reverse().map(previousUrl => (
                    <Fragment key={previousUrl}>
                      {isVideo ? (
                        <VideoDisplay src={previousUrl} controls>
                          <source src={previousUrl} type="video/mp4" />
                          <source src={previousUrl} type="video/ogg" />
                          Your browser does not support the video tag.
                        </VideoDisplay>
                      ) : (
                        <ImageLink target="_blank">
                          <ImageComponent src={previousUrl} />
                        </ImageLink>
                      )}
                      <Divider style={{ margin: 10 }} />
                    </Fragment>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
          </div>

          {caption ? (
            <div className="caption">
              <CaptionEditor
                text={caption}
                updateCaptionLoading={updateCaptionLoading}
                onUpdateCaption={onUpdateCaption}
              />
            </div>
          ) : null}
        </Frame>
      </Root>
    );
  }
);

const Root = styled.div`
  position: relative;
  min-width: 240px;
  width: 100%;
  max-width: 460px;
  background-color: inherit;
  color: inherit;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoImagePlaceholder = styled.div<{ $show?: boolean }>`
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 200;
  display: ${({ $show = true }) => ($show ? "flex" : "none")};
  font-size: 1rem;
`;

const Frame = styled.div<{ $show?: boolean }>`
  display: ${({ $show }) => ($show ? "flex" : "none")};
  flex-direction: column;
  width: auto;
  position: relative;
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 40px;
    background: ${palette.gray.white};
    color: ${palette.gray[60]};
    border: 1px solid ${palette.gray[20]};

    &.approved {
      background: ${palette.primary.green};
      color: ${palette.gray.white};
      border: 0;
    }

    &.rejected {
      background: ${palette.primary.gold};
      color: ${palette.gray.white};
      border: 0;
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${palette.gray.white};
  }

  > .caption {
    border-top: 1px solid ${palette.gray[20]};
  }
`;

const VideoDisplay = styled.video`
  max-width: 100%;
  max-height: 450px;

  &::-webkit-media-controls-panel {
    background: none;
  }
`;

const ImageLink = styled.a`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    opacity: 0.95;
  }
`;

const ImageComponent = styled.img`
  align-self: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FallbackText = styled.span`
  text-align: center;
`;
