import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { type FC, useState } from "react";

import { Popup } from "components/ui/Popup";
import { useUpdateCampaignUserMutation } from "modules/campaign/generated";

import { Edit } from "@mui/icons-material";
import { CampaignParticipantsDocument } from "../generated";

export const CampaignNoteCell: FC<{ campaignUserId: number; note: string | null }> = props => {
  const snackbar = useSnackbar();
  const [isPopup, setIsPopup] = useState(false);
  const [note, setNote] = useState(props.note || "");
  const [updateCampaignUser] = useUpdateCampaignUserMutation({
    onCompleted: () => {
      snackbar.success("User has been successfully updated");
      setIsPopup(false);
    },
    onError: () => {
      snackbar.error("Something went wrong");
    },
    refetchQueries: [CampaignParticipantsDocument],
    awaitRefetchQueries: true
  });
  return (
    <>
      <Edit
        onClick={() => setIsPopup(true)}
        style={{ cursor: "pointer", color: props.note ? palette.secondary.blue : palette.gray[50] }}
      />
      {isPopup && (
        <Popup
          onClose={() => setIsPopup(false)}
          actionLabel="Save"
          onAction={() =>
            updateCampaignUser({
              variables: { campaignUserId: props.campaignUserId, _set: { notes: note } }
            })
          }
          title="Campaign user note"
        >
          <TextInput multiline value={note} onChange={setNote} label="Note" />
        </Popup>
      )}
    </>
  );
};
