import type { FC } from "react";

import styled from "@emotion/styled";
import { prettifyDate } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { NavigationButton } from "components/ui/NavigationButton";
import { ContentInfo, Title } from "lib/styled";
import { CampaignTikTokDocument } from "modules/campaign/Content/TikTok/generated";
import { CampaignPostDocument } from "modules/campaign/Content/generated";
import { ContentFeedbackState } from "modules/campaign/common/ContentFeedbackState";

import { FeedbackState } from "./FeedbackState";
import { StoryClip } from "./StoryClip";
import { StoryDocument, useApproveStoryMutation, useStoryQuery } from "./generated";

export const Story: FC = () => {
  const { contentSettingId, userId, campaignStub } = useParams<{
    contentSettingId: string;
    userId: string;
    campaignStub: string;
  }>();
  const { loading, data: story } = useStoryQuery({
    variables: {
      contentSettingId: Number(contentSettingId),
      userId: Number(userId),
      campaignStub: campaignStub ?? ""
    }
  });
  const snackbar = useSnackbar();
  const [approveStory, { loading: approveStoryLoading }] = useApproveStoryMutation({
    onCompleted: () => snackbar.success("Story status updated"),
    refetchQueries: [CampaignTikTokDocument, CampaignPostDocument, StoryDocument],
    awaitRefetchQueries: true
  });

  if (loading || !story) {
    return <Loader />;
  }

  const {
    users: [user] = [],
    campaignStories = [],
    contentSettings: [contentSetting] = [],
    clips = [],
    campaigns = []
  } = story;

  const campaignStory = campaignStories?.[0];

  const clipsToDisplay = campaignStories?.[0]?.approved
    ? clips.filter(c => Boolean(c.campaign_story_id))
    : clips;

  const approvedClipsCount = clips.filter(c => c.content_setting_id).length;
  const username = user?.instagram?.username;
  const isStoryApproved = campaignStory?.approved;
  const campaignId = campaigns?.[0]?.id;

  const handleApproveStory = async (approved: boolean, storyId: number) => {
    await approveStory({
      variables: {
        input: {
          id: storyId,
          user_id: Number(userId),
          content_setting_id: Number(contentSettingId),
          campaign_id: campaignId,
          approved
        }
      }
    });

    snackbar.success(`Post ${approved ? "" : "un"}approved`);
  };

  return (
    <>
      <NavigationButton
        style={{ width: "fit-content" }}
        pathname={`/campaign/${campaignStub}/stories`}
      />

      <Title>Story - Video or Photo by @{username}</Title>
      <ContentInfo>
        Post between {prettifyDate(contentSetting?.publish_date_min)} -{" "}
        {prettifyDate(contentSetting?.publish_date_max)}
      </ContentInfo>
      <ContentInfo>Detected clips: {clips.length}</ContentInfo>
      <ContentInfo>
        Approved clips: {approvedClipsCount} / {contentSetting.number_of_posts}
      </ContentInfo>
      <MediaContainer>
        {clipsToDisplay.map(clip => (
          <StoryClip
            key={clip.id}
            clip={clip}
            contentSetting={contentSetting}
            isStoryApproved={Boolean(isStoryApproved)}
            shortlinks={user.campaignUser[0].tracking?.shortlinks}
          />
        ))}
      </MediaContainer>

      <FeedbackState
        approved_at={campaignStory?.approved_at || ""}
        isStoryApproved={Boolean(isStoryApproved)}
        approvedClipsCount={approvedClipsCount}
        req_number_of_clips={contentSetting.number_of_posts}
      />
      <div style={{ margin: "20px 0" }}>
        <ContentFeedbackState>
          When a story is approved you can see only approved clips
        </ContentFeedbackState>
      </div>

      <div style={{ display: "flex", justifyContent: "right" }}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          isLoading={Boolean(isStoryApproved) && approveStoryLoading}
          disabled={!isStoryApproved}
          onClick={() => handleApproveStory(false, campaignStory?.id)}
          style={{ marginRight: 10 }}
        >
          Unapprove
        </Button>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          isLoading={!isStoryApproved && approveStoryLoading}
          disabled={isStoryApproved || approvedClipsCount < contentSetting.number_of_posts}
          onClick={() => handleApproveStory(true, campaignStory?.id)}
        >
          Approve
        </Button>
      </div>
    </>
  );
};

const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
`;
