import type { FC } from "react";

import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@mui/material";
import { useStoredState } from "@relatable/ui/hooks/useStoredState";

import { ArrowDownward } from "@mui/icons-material";
import { ActionButtons } from "./ActionButtons";
import { StatsBody } from "./StatsBody";
import { StatsHead } from "./StatsHead";
import { StoriesTable } from "./StoriesTable";

export const Stories: FC = () => {
  const [statisticsExpanded, setStatisticsExpanded] = useStoredState("statistics-expanded", false);

  return (
    <>
      <Accordion
        expanded={statisticsExpanded}
        onChange={(_, expanded) => setStatisticsExpanded(expanded)}
        variant="outlined"
      >
        <StyledAccordionSummary expandIcon={<ArrowDownward />}>
          Story statistics
        </StyledAccordionSummary>
        <AccordionDetails>
          <StatsWrapper>
            <StatsHead />
            <Divider />
            <StatsBody />
          </StatsWrapper>
        </AccordionDetails>
      </Accordion>

      <Divider />
      <ActionButtons />
      <StoriesTable />
    </>
  );
};

const StatsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    flex-direction: row-reverse;
    gap: 10px;
  }
`;
