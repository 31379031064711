import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestHypeAuditorReportMutationVariables = Types.Exact<{
  objects: Array<Types.Hypeauditor_Insert_Input> | Types.Hypeauditor_Insert_Input;
}>;


export type RequestHypeAuditorReportMutation = { __typename?: 'Mutation', insert_hypeauditor?: { __typename?: 'hypeauditor_mutation_response', returning: Array<{ __typename?: 'hypeauditor', id: number }> } | null };


export const RequestHypeAuditorReportDocument = gql`
    mutation requestHypeAuditorReport($objects: [hypeauditor_insert_input!]!) {
  insert_hypeauditor(
    objects: $objects
    on_conflict: {constraint: hypeauditor_user_id, update_columns: [state, updated_at]}
  ) {
    returning {
      id
    }
  }
}
    `;
export type RequestHypeAuditorReportMutationFn = Apollo.MutationFunction<RequestHypeAuditorReportMutation, RequestHypeAuditorReportMutationVariables>;

/**
 * __useRequestHypeAuditorReportMutation__
 *
 * To run a mutation, you first call `useRequestHypeAuditorReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestHypeAuditorReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestHypeAuditorReportMutation, { data, loading, error }] = useRequestHypeAuditorReportMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useRequestHypeAuditorReportMutation(baseOptions?: Apollo.MutationHookOptions<RequestHypeAuditorReportMutation, RequestHypeAuditorReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestHypeAuditorReportMutation, RequestHypeAuditorReportMutationVariables>(RequestHypeAuditorReportDocument, options);
      }
export type RequestHypeAuditorReportMutationHookResult = ReturnType<typeof useRequestHypeAuditorReportMutation>;
export type RequestHypeAuditorReportMutationResult = Apollo.MutationResult<RequestHypeAuditorReportMutation>;
export type RequestHypeAuditorReportMutationOptions = Apollo.BaseMutationOptions<RequestHypeAuditorReportMutation, RequestHypeAuditorReportMutationVariables>;