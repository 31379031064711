import { type FC, useRef, useState } from "react";

import styled from "@emotion/styled";
import { CircularProgress, TextField } from "@mui/material";
import { palette } from "@relatable/ui/Palette";

const Root = styled.div`
  display: flex;
  flex-direction: column;

  > .field {
    width: 100%;
    margin-top: 20px;
  }

  > .length {
    color: ${palette.gray[40]};
    text-align: right;
  }
`;

export const CommentStep: FC<{
  value: string;
  onChange(value: string): void;
}> = ({ value, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const timer = useRef(-1);
  const onThrottledUpdate = (newValue: string) => {
    setLocalValue(newValue);
    clearTimeout(timer.current);
    timer.current = setTimeout(async () => {
      if (newValue !== value) {
        setLoading(true);
        await onChange(newValue);
        setLoading(false);
      }
    }, 2000) as unknown as number;
  };

  return (
    <Root>
      <TextField
        className="field"
        rows={5}
        value={localValue}
        onChange={e => onThrottledUpdate(e.target.value)}
        multiline
      />
      <span className="length">
        {loading && <CircularProgress size={16} />}&nbsp;
        {localValue.length}/400
      </span>
    </Root>
  );
};
