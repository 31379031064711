import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";

import type { SvgIconComponent } from "@mui/icons-material";
import { SectionSubtitle, SectionValue } from "../user.styled";

const Root = styled.div<{ $editable: boolean; $editing: boolean }>`
  display: flex;
  cursor: ${({ $editable }) => ($editable ? "pointer" : "auto")};

  > .iconbox {
    background-color: ${({ $editing }) => ($editing ? palette.gray[30] : palette.gray.black)};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  > .values {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  > .edit-field {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 100%;
  }
`;

export const StatsSectionItem: FC<{
  title: string;
  value: string;
  Icon: SvgIconComponent;
  editable?: boolean;
  editLabel?: string;
  editOptions?: { label: string; value: string }[];
  onEdit?: (value: string) => void;
}> = ({ title, value, Icon, editable, editOptions, editLabel, onEdit }) => {
  const [editing, setEditing] = useState(false);

  const onSelectChange = async newValue => {
    await onEdit?.(newValue || "");
    setEditing(false);
  };

  return (
    <Root
      key={title}
      onClick={() => editable && setEditing(prev => !prev)}
      $editable={Boolean(editable)}
      $editing={editing}
    >
      <div className="iconbox">
        <Icon />
      </div>
      {editable && onEdit && editOptions && editing ? (
        <div className="edit-field">
          <Select
            hideNone
            label={editLabel || ""}
            onChange={onSelectChange}
            value={value || ""}
            options={editOptions}
          />
        </div>
      ) : (
        <div className="values">
          <SectionSubtitle>{title}</SectionSubtitle>
          <SectionValue>{value}</SectionValue>
        </div>
      )}
    </Root>
  );
};
