import { type FC, type PropsWithChildren, useCallback, useState } from "react";

import styled from "@emotion/styled";
import { ArrowDropDown } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { palette } from "@relatable/ui/Palette";
import { useOnOutsideClick } from "@relatable/ui/hooks/useOnOutsideClick";

export const Dropdown: FC<PropsWithChildren> = ({ children }) => {
  const [opened, setOpened] = useState(false);

  const handleOutsideClick = useCallback(() => setOpened(false), [setOpened]);
  const ref = useOnOutsideClick(handleOutsideClick);

  return (
    <span ref={ref}>
      <StyledIconButton onClick={() => setOpened(prev => !prev)}>
        <ArrowDropDown fontSize="small" />
      </StyledIconButton>
      {opened && <DropdownContent>{children}</DropdownContent>}
    </span>
  );
};

const DropdownContent = styled.ul`
  display: block;
  position: absolute;
  background-color: ${palette.gray[10]};
  min-width: 160px;
  padding: 0px;
  z-index: 1;
  border: 1px solid ${palette.gray.black};
  box-sizing: border-box;
  box-shadow: 0px 1px 3px ${palette.gray[20]};
  border-radius: 8px;
  top: 52px;
  right: -16px;
  list-style-type: none;

  > li {
    > * {
      padding: 10px 15px;
      display: block;
      color: inherit;
    }

    &:hover {
      background-color: ${palette.tertiary.red};
    }
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;
    z-index: 1;
  }

  &:after {
    top: -15px;
    right: 17px;
    border-width: 0px 15px 15px;
    border-color: ${palette.gray.whiteOff} transparent;
  }

  &:before {
    top: -16px;
    right: 16px;
    border-width: 0px 16px 16px;
    border-color: ${palette.gray.black} transparent;
  }
`;

const StyledIconButton = styled(IconButton)`
  height: 1.5em;
  width: 1.5em;
  && {
    background-color: ${palette.gray[10]};
    margin-left: 6px;

    &:hover {
      color: ${palette.gray.white};
      background-color: ${palette.primary.blue};
    }
  }
`;
