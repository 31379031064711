import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { prettifyNumber } from "@relatable/helpers/format";
import { getCampaignProgress } from "@relatable/helpers/timeline";

import { ArrowDownward } from "@mui/icons-material";
import type { TimelineItem } from ".";
import { TimelineProgressChart } from "./TimelineProgressChart";
import type { TimelineQuery } from "./generated";

export const TimelineProgress: FC<{
  items: TimelineItem[];
  history: TimelineQuery["campaign"][number]["campaign_progress"];
  projectCampaigns: TimelineQuery["campaign"][number]["project"]["campaigns"];
}> = ({ items, history, projectCampaigns }) => {
  const [statisticsExpanded, setStatisticsExpanded] = useState(false);
  const campaignProgress = getCampaignProgress(items);

  const projectProgress =
    projectCampaigns.reduce(
      (acc, campaign) => acc + (getCampaignProgress(campaign.campaign_timeline) ?? 0),
      0
    ) / projectCampaigns.length;

  return (
    <Root>
      <Accordion
        expanded={statisticsExpanded}
        onChange={(_, expanded) => setStatisticsExpanded(expanded)}
        variant="outlined"
      >
        <StyledAccordionSummary expandIcon={<ArrowDownward />}>
          Total Progress:{" "}
          {projectProgress === null ? "-" : prettifyNumber(projectProgress, { percentages: true })}{" "}
          <span style={{ padding: "0 10px" }} />
          Campaign Progress:{" "}
          {campaignProgress === null
            ? "-"
            : prettifyNumber(campaignProgress, { percentages: true })}
        </StyledAccordionSummary>

        <AccordionDetails>
          <TimelineProgressChart history={history} />
        </AccordionDetails>
      </Accordion>
    </Root>
  );
};

const Root = styled.div`
  font-weight: bold;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    flex-direction: row-reverse;
    gap: 10px;
  }
`;
