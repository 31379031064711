import { type FC, useMemo, useState } from "react";

import styled from "@emotion/styled";
import { debounce } from "@mui/material";
import type { Campaign_Tiktok_Set_Input } from "@relatable/gql/generated-base";
import { prettifyDate } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { NavigationButton } from "components/ui/NavigationButton";
import { ContentInfo } from "lib/styled";
import { CampaignTiktokStatsDocument } from "modules/campaign/TikToks/generated";
import { ContentDisplay } from "modules/campaign/common/ContentDisplay";

import {
  CampaignTikTokDocument,
  type CampaignTikTokQuery,
  useCampaignTikTokQuery,
  useUpdateTikTokMutation
} from "./generated";

export const TikTok: FC = () => {
  const { postId, campaignStub } = useParams<{ postId: string; campaignStub: string }>();
  const { loading, data: tikTokData } = useCampaignTikTokQuery({
    variables: { postId: Number(postId), campaignStub: campaignStub ?? "" }
  });

  if (loading || !tikTokData) {
    return <Loader />;
  }

  return <TikTokContent tiktok={tikTokData} />;
};

const TikTokContent: FC<{ tiktok: CampaignTikTokQuery }> = ({ tiktok }) => {
  const snackbar = useSnackbar();
  const [updateTiktok] = useUpdateTikTokMutation({
    onError(err) {
      snackbar.error(err.message);
    },
    refetchQueries: [CampaignTiktokStatsDocument],
    awaitRefetchQueries: true
  });
  const { postId, campaignStub } = useParams<{ postId: string; campaignStub: string }>();

  const [campaignTikTok] = tiktok.campaignTikTok;

  const [tiktokData, setTiktokData] = useState({
    views: campaignTikTok.views,
    likes: campaignTikTok.likes,
    comment_count: campaignTikTok.comment_count,
    average_watch_time: campaignTikTok.average_watch_time,
    completion_rate: campaignTikTok.completion_rate,
    following_rate: campaignTikTok.following_rate,
    for_you_page_rate: campaignTikTok.for_you_page_rate,
    personal_profile_rate: campaignTikTok.personal_profile_rate,
    actual_reach: campaignTikTok.actual_reach,
    track_url: campaignTikTok.track_url,
    shares: campaignTikTok.shares,
    saves: campaignTikTok.saves
  });

  const isStoryApproved = Boolean(campaignTikTok.approved);

  const handleApprove = async () => {
    await updateTiktok({
      variables: {
        id: Number(postId),
        set: {
          approved: true,
          approved_at: new Date().toISOString()
        }
      },
      refetchQueries: [
        {
          query: CampaignTikTokDocument,
          variables: { postId: Number(postId), campaignStub }
        }
      ],
      awaitRefetchQueries: true
    });

    snackbar.success("Tiktok approved");
  };

  const debouncedHandleTiktokChange = useMemo(
    () =>
      debounce(async (set: Campaign_Tiktok_Set_Input) => {
        await updateTiktok({
          variables: {
            id: Number(postId),
            set
          }
        });

        snackbar.success("Tiktok updated");
      }, 500),
    [postId, updateTiktok, snackbar]
  );

  const handleTikTokChange = (set: Campaign_Tiktok_Set_Input) => {
    const user_tiktok_id = campaignTikTok.user_tiktok.id;
    if (!user_tiktok_id) throw new Error("missing user_tiktok_id");

    setTiktokData(p => ({ ...p, ...set }));
    debouncedHandleTiktokChange({ ...tiktokData, ...set });
  };

  const userTikTok = campaignTikTok.user_tiktok;
  const contentSetting = campaignTikTok.content_setting;
  const campaignUser = userTikTok.user?.campaignUser[0];
  const username = userTikTok?.username;

  const shortlinks = Array.isArray(campaignUser?.tracking?.shortlinks)
    ? campaignUser?.tracking.shortlinks
    : [];

  return (
    <>
      <NavigationButton
        style={{ width: "fit-content" }}
        pathname={`/campaign/${campaignStub}/tiktoks`}
      />

      <Title>TikTok video by @{username}</Title>
      {Boolean(contentSetting) && (
        <ContentInfo>
          Post between {prettifyDate(contentSetting?.publish_date_min)} -{" "}
          {prettifyDate(contentSetting?.publish_date_max)}
        </ContentInfo>
      )}
      <MediaContainer>
        <Title>Created at {prettifyDate(campaignTikTok.created_at)}</Title>
        <Container>
          {campaignTikTok.url ? (
            <ContentDisplay
              url={campaignTikTok.url}
              thumbnail={campaignTikTok.url}
              status={isStoryApproved ? "approved" : ""}
              isVideo
            />
          ) : (
            <iframe
              src={`https://www.tiktok.com/embed/v2/${campaignTikTok.shortcode}`}
              style={{ width: 320, height: 800, marginRight: 20 }}
              title="TikTok iframe"
            />
          )}
          <StoryStateContainer>
            {isStoryApproved ? (
              <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", gap: 20 }}>
                <TextInput
                  type="number"
                  label="Views"
                  value={String(tiktokData.views || 0)}
                  onChange={v => handleTikTokChange({ views: Math.round(Number(v)) })}
                />
                <TextInput
                  type="number"
                  label="Likes"
                  value={String(tiktokData.likes || 0)}
                  onChange={v => handleTikTokChange({ likes: Math.round(Number(v)) })}
                />
                <TextInput
                  type="number"
                  label="Comments count"
                  value={String(tiktokData.comment_count || 0)}
                  onChange={v => handleTikTokChange({ comment_count: Math.round(Number(v)) })}
                />
                <TextInput
                  type="number"
                  label="Average watch time"
                  value={String(tiktokData.average_watch_time || 0)}
                  onChange={v => handleTikTokChange({ average_watch_time: Number(v) })}
                />
                <TextInput
                  type="number"
                  label="Shares"
                  value={String(tiktokData.shares || 0)}
                  onChange={v => handleTikTokChange({ shares: Math.round(Number(v)) })}
                />
                <TextInput
                  type="number"
                  label="Saves"
                  value={String(tiktokData.saves || 0)}
                  onChange={v => handleTikTokChange({ saves: Math.round(Number(v)) })}
                />
                <TextInput
                  type="number"
                  label="Completion rate"
                  value={String(tiktokData.completion_rate || 0)}
                  onChange={v => handleTikTokChange({ completion_rate: Number(v) })}
                />
                <TextInput
                  type="number"
                  label="Following rate"
                  value={String(tiktokData.following_rate || 0)}
                  onChange={v => handleTikTokChange({ following_rate: Number(v) })}
                />
                <TextInput
                  type="number"
                  label="For you page Rate"
                  value={String(tiktokData.for_you_page_rate || 0)}
                  onChange={v => handleTikTokChange({ for_you_page_rate: Number(v) })}
                />
                <TextInput
                  type="number"
                  label="Personal profile rate"
                  value={String(tiktokData.personal_profile_rate || 0)}
                  onChange={v => handleTikTokChange({ personal_profile_rate: Number(v) })}
                />
                <TextInput
                  type="number"
                  label="Total viewers"
                  value={String(tiktokData.actual_reach || 0)}
                  onChange={v => handleTikTokChange({ actual_reach: Math.round(Number(v)) })}
                />

                <Select
                  label="Tracking url"
                  onChange={track_url => handleTikTokChange({ track_url })}
                  value={tiktokData.track_url || ""}
                  options={shortlinks.map(url => ({
                    label: url,
                    value: url
                  }))}
                />
              </div>
            ) : null}
          </StoryStateContainer>
        </Container>
      </MediaContainer>

      {isStoryApproved && (
        <span style={{ marginLeft: "auto" }}>
          This <strong>TikTok</strong> was approved on{" "}
          <i>{prettifyDate(campaignTikTok.approved_at)}</i>
        </span>
      )}

      <Button
        style={{ marginTop: 10, minWidth: 200, marginLeft: "auto" }}
        disabled={isStoryApproved}
        onClick={handleApprove}
      >
        {isStoryApproved ? "Approved" : "Approve"}
      </Button>
    </>
  );
};

const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
`;

const Container = styled.div`
  display: flex;
  margin: 20px 0px;
  padding-right: 50px;
`;

const Title = styled.h5`
  font-size: 1.34rem;
  line-height: 110%;
  margin: 0.82rem 0 0.656rem 0;
  font-weight: 400;
  margin-bottom: 10px;
`;

const StoryStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
