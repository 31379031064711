import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HypeAuditorTikTokReportQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
}>;


export type HypeAuditorTikTokReportQuery = { __typename?: 'Query', hypeauditor: Array<{ __typename?: 'hypeauditor', id: number, data?: any | null, state?: Types.Hypeauditor_Report_State_Enum | null, not_available?: boolean | null, user: { __typename?: 'users', id: number, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null } | null } }> };


export const HypeAuditorTikTokReportDocument = gql`
    query hypeAuditorTikTokReport($userId: Int!) {
  hypeauditor(where: {users_id: {_eq: $userId}}) {
    id
    data
    state
    not_available
    user {
      id
      user_tiktok {
        id
        username
      }
    }
  }
}
    `;

/**
 * __useHypeAuditorTikTokReportQuery__
 *
 * To run a query within a React component, call `useHypeAuditorTikTokReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useHypeAuditorTikTokReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHypeAuditorTikTokReportQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useHypeAuditorTikTokReportQuery(baseOptions: Apollo.QueryHookOptions<HypeAuditorTikTokReportQuery, HypeAuditorTikTokReportQueryVariables> & ({ variables: HypeAuditorTikTokReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HypeAuditorTikTokReportQuery, HypeAuditorTikTokReportQueryVariables>(HypeAuditorTikTokReportDocument, options);
      }
export function useHypeAuditorTikTokReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HypeAuditorTikTokReportQuery, HypeAuditorTikTokReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HypeAuditorTikTokReportQuery, HypeAuditorTikTokReportQueryVariables>(HypeAuditorTikTokReportDocument, options);
        }
export function useHypeAuditorTikTokReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HypeAuditorTikTokReportQuery, HypeAuditorTikTokReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HypeAuditorTikTokReportQuery, HypeAuditorTikTokReportQueryVariables>(HypeAuditorTikTokReportDocument, options);
        }
export type HypeAuditorTikTokReportQueryHookResult = ReturnType<typeof useHypeAuditorTikTokReportQuery>;
export type HypeAuditorTikTokReportLazyQueryHookResult = ReturnType<typeof useHypeAuditorTikTokReportLazyQuery>;
export type HypeAuditorTikTokReportSuspenseQueryHookResult = ReturnType<typeof useHypeAuditorTikTokReportSuspenseQuery>;
export type HypeAuditorTikTokReportQueryResult = Apollo.QueryResult<HypeAuditorTikTokReportQuery, HypeAuditorTikTokReportQueryVariables>;