import { type FC, useCallback, useState } from "react";

import { useApolloClient } from "@apollo/client";
import styled from "@emotion/styled";

import { CircularProgress } from "@mui/material";
import { truncateFileName } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import { useOnOutsideClick } from "@relatable/ui/hooks/useOnOutsideClick";
import { useDropzone } from "react-dropzone";

import { useSignUploadUrlMutation } from "modules/generated";

import {
  AddCircle,
  AudioFile,
  Calculate,
  Description,
  FolderZip,
  InsertDriveFile,
  InsertPhoto,
  Link,
  PictureAsPdf,
  Upload,
  VideoFile
} from "@mui/icons-material";
import {
  UserDocument,
  useDeleteUserAttachmentMutation,
  useInsertUserAttachmentMutation
} from "../generated";
import { Section, SectionSubtitle } from "../user.styled";
import { EditSectionIcon } from "./EditSectionIcon";
import { DeleteButton, FloatingContainer, ScrollableSectionContent } from "./styled";
import { useOptimisticUserUpdate } from "./useOptimisticUserUpdate";

const getFileIcon = (name: string) => {
  if (!name) return InsertDriveFile;
  const charList = name.split(".");
  const extension = charList[charList.length - 1];
  switch (extension) {
    case "pdf":
      return PictureAsPdf;
    case "mp3":
      return AudioFile;
    case "mp4":
    case "mov":
      return VideoFile;
    case "png":
    case "jpg":
    case "jpeg":
      return InsertPhoto;
    case "zip":
      return FolderZip;
    case "docx":
    case "txt":
      return Description;
    case "xlsx":
    case "xlsxm":
    case "xlsb":
    case "xltx":
    case "csv":
      return Calculate;

    default:
      return InsertDriveFile;
  }
};

const SAttachment = styled.button`
  background: ${palette.gray[20]};
  padding: 5px 10px;
  color: ${palette.primary.blue};
  border-radius: 10px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  transition: background 250ms ease;
  :hover {
    background: ${palette.gray[30]};
  }
`;

const downloadFile = ({ url, fileName }: { url: string; fileName: string }) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = url;
  a.target = "_blank";
  a.rel = "noreferrer";
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const AttachmentsSection: FC<{
  attachments: { id: number; name: string; url: string }[];
  userName: string;
  userId: number;
}> = ({ attachments, userName, userId }) => {
  const apolloClient = useApolloClient();
  const optimisticUserUpdate = useOptimisticUserUpdate(userId);

  const [isAddPopup, setIsAddPopup] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [signUploadUrlMutation] = useSignUploadUrlMutation();
  const [insertAttachment] = useInsertUserAttachmentMutation();
  const [deleteAttachment] = useDeleteUserAttachmentMutation({
    onError: () => apolloClient.refetchQueries({ include: [UserDocument] })
  });

  const handleDeleteAttachment = (id: number) => {
    deleteAttachment({ variables: { id } });
    optimisticUserUpdate(prev => ({
      user_attachments: prev.user_attachments.filter(attachment => attachment.id !== id)
    }));
  };

  const [statuses, setStatuses] = useState<
    Record<string, { name: string; status: "pending" | "success" | "error" }>
  >({});
  const statusesValues = Object.values(statuses);
  const isUploading = statusesValues.some(i => i.status === "pending");

  const sectionRef = useOnOutsideClick(() => {
    if (!isUploading) {
      setIsAddPopup(false);
      setIsEditing(false);
      setStatuses({});
    }
  });

  const handleDrop = useCallback(
    async (files: File[]) => {
      setStatuses(
        files.reduce(
          (acc, i, index) => ({ ...acc, [index]: { status: "pending", name: i.name } }),
          {}
        )
      );

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          const { data } = await signUploadUrlMutation({
            variables: {
              input: {
                fileName: `${userName}/${file.name}`,
                fileType: file.type,
                prefix: "USER_ATTACHMENT"
              }
            }
          });
          if (!data?.signUploadUrl) {
            throw new Error("Something went wrong when signing a file for the upload");
          }

          const { url, signedUploadUrl } = data.signUploadUrl;
          const response = await fetch(signedUploadUrl, {
            method: "PUT",
            body: file,
            headers: { "content-type": file?.type }
          });

          if (!response.ok) {
            throw new Error("Something went wrong when uploading a file");
          }

          await insertAttachment({
            variables: {
              object: {
                original_filename: file.name,
                stored_filename: url,
                size: String(file.size),
                user_id: userId
              }
            }
          });
          setStatuses(p => ({ ...p, [i]: { ...p[i], status: "success" } }));
        } catch (err) {
          console.error(err);
          setStatuses(p => ({ ...p, [i]: { ...p[i], status: "error" } }));
        }
      }

      apolloClient.refetchQueries({ include: [UserDocument] });
    },
    [apolloClient, signUploadUrlMutation, userName, insertAttachment, userId]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });

  return (
    <Section style={{ background: "transparent", boxShadow: "none" }} ref={sectionRef}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link style={{ color: palette.gray[50] }} />
        <SectionSubtitle style={{ color: "black", marginLeft: 10 }}>Attachments</SectionSubtitle>
        <div style={{ marginLeft: "auto", position: "relative" }}>
          <AddCircle
            style={{ color: palette.gray[50], cursor: "pointer", marginRight: 5 }}
            onClick={() => setIsAddPopup(p => !p)}
          />
          <EditSectionIcon isEditing={isEditing} onToggle={() => setIsEditing(p => !p)} />

          {isAddPopup && (
            <FloatingContainer>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <SectionSubtitle>Add attachments</SectionSubtitle>
                <SectionSubtitle>
                  {statusesValues.filter(i => i.status === "success").length} /{" "}
                  {statusesValues.length}
                </SectionSubtitle>
              </div>

              {statusesValues.length ? (
                <div
                  style={{
                    borderColor: palette.gray[50],
                    color: palette.gray[50],
                    borderRadius: 15,
                    height: 200,
                    width: 300,
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    overflow: "auto"
                  }}
                >
                  {statusesValues.map((value, index) => {
                    const Icon = getFileIcon(value.name);
                    return (
                      <div
                        style={{
                          padding: "10px 20px",
                          borderRadius: 10,
                          background:
                            value.status === "pending"
                              ? palette.tertiary.blue
                              : value.status === "success"
                                ? palette.tertiary.green
                                : palette.tertiary.red,
                          display: "flex",
                          alignItems: "center",
                          color: "black"
                        }}
                        key={index}
                      >
                        <Icon style={{ marginRight: 10 }} />
                        {truncateFileName(value.name, 20)}
                        <span style={{ marginLeft: "auto", color: palette.gray[50] }}>
                          {value.status === "pending" ? (
                            <CircularProgress
                              style={{ marginLeft: 5 }}
                              color="secondary"
                              size={10}
                            />
                          ) : (
                            value.status
                          )}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div
                    style={{
                      border: "1px dashed",
                      borderColor: palette.gray[50],
                      color: palette.gray[50],
                      borderRadius: 15,
                      height: 200,
                      width: 300,
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                  >
                    <Upload style={{ marginRight: 10 }} />
                    Drop here or click to upload
                  </div>
                </div>
              )}
            </FloatingContainer>
          )}
        </div>
      </div>

      <ScrollableSectionContent>
        {!attachments.length && (
          <p style={{ fontSize: 12, color: palette.gray[50] }}>
            No attachments assigned to this user.
          </p>
        )}
        {attachments.map(t => {
          const Icon = getFileIcon(t.name);
          return (
            <div key={t.id} style={{ position: "relative" }}>
              {isEditing && (
                <DeleteButton type="button" onClick={() => handleDeleteAttachment(t.id)}>
                  <div
                    style={{
                      width: 10,
                      height: 2,
                      background: "white",
                      position: "relative",
                      left: -3
                    }}
                  />
                </DeleteButton>
              )}
              <SAttachment
                type="button"
                key={t.id}
                onClick={() => {
                  downloadFile({ fileName: t.name, url: t.url });
                }}
              >
                <Icon style={{ color: palette.primary.blue, marginRight: 10 }} />
                {t.name}
              </SAttachment>
            </div>
          );
        })}
      </ScrollableSectionContent>
    </Section>
  );
};
