import { useState } from "react";

import styled from "@emotion/styled";

import { managerApproved } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";

import { Reply } from "@mui/icons-material";
import type { ContentApprovalLogWithUserFragment } from "../generated";
import { ContentLogs } from "./ContentLogs";
import { ReviewBar } from "./ReviewBar";

interface ContentPieceStateProps {
  isCaption?: boolean;
  captionText: string;
  legacyInfluencerComment?: string | null;
  logs: ContentApprovalLogWithUserFragment[];
  state: CONTENT_APPROVAL_STATE | undefined | null;
  filter: "all" | "relatable" | "client";
  onUpdateState?: (target: "creator" | "client", state: CONTENT_APPROVAL_STATE) => void;
  onWriteMessage?: (target: "creator" | "client", text: string) => void;
  onDelete?: () => void;
}

export const ContentPieceState: React.FC<ContentPieceStateProps> = ({
  isCaption = false,
  captionText,
  legacyInfluencerComment,
  logs,
  state,
  filter,
  onUpdateState,
  onWriteMessage,
  onDelete
}) => {
  const [comment, setComment] = useState("");
  const [loadingReplyTo, setLoadingReplyTo] = useState<"creator" | "client" | false>(false);

  const onSubmit = async (type: "creator" | "client") => {
    setLoadingReplyTo(type);
    await onWriteMessage?.(type, comment);
    setLoadingReplyTo(false);
    setComment("");
  };

  const filteredLogs = (() => {
    if (filter === "relatable") {
      return logs.filter(log => log.target === "creator" || log.campaign_user);
    }
    if (filter === "client") {
      return logs.filter(log => log.target === "client" || log.client_contact);
    }
    return logs;
  })();

  const renderReviewBar = (type: "creator" | "client") => {
    if (!onUpdateState) return null;
    return (
      <ReviewBar
        type={type}
        isCaption={isCaption}
        state={state}
        onChange={
          onUpdateState
            ? newState => {
                if (type === "creator") {
                  if (newState === null) {
                    onUpdateState(type, CONTENT_APPROVAL_STATE.MANAGER_REVIEWING);
                  } else {
                    onUpdateState(
                      type,
                      newState
                        ? CONTENT_APPROVAL_STATE.CLIENT_REVIEWING
                        : CONTENT_APPROVAL_STATE.MANAGER_REJECTED
                    );
                  }
                } else if (newState === null) {
                  onUpdateState(type, CONTENT_APPROVAL_STATE.CLIENT_REVIEWING);
                } else {
                  onUpdateState(
                    type,
                    newState
                      ? CONTENT_APPROVAL_STATE.CLIENT_APPROVED
                      : CONTENT_APPROVAL_STATE.CLIENT_REJECTED
                  );
                }
              }
            : undefined
        }
        onDelete={type === "creator" ? onDelete : undefined}
      />
    );
  };

  return (
    <Root>
      {filter !== "client" ? renderReviewBar("creator") : null}
      {filter !== "relatable" ? renderReviewBar("client") : null}

      {Boolean(onWriteMessage) && (
        <>
          <textarea
            onChange={e => setComment(e.target.value)}
            rows={6}
            placeholder="Add comment"
            value={comment}
          />
          <div className="buttons" style={{ margin: "10px 0" }}>
            {filter !== "client" ? (
              <Button
                icon={<Reply />}
                style={{ marginRight: 10 }}
                isLoading={loadingReplyTo === "creator"}
                onClick={() => onSubmit("creator")}
              >
                Reply to Creator
              </Button>
            ) : null}
            {filter !== "relatable" && managerApproved(state) ? (
              <Button
                icon={<Reply />}
                className="btn-primary"
                isLoading={loadingReplyTo === "client"}
                onClick={() => onSubmit("client")}
              >
                Reply to Client
              </Button>
            ) : null}
          </div>
        </>
      )}

      <ContentLogs
        legacyInfluencerComment={legacyInfluencerComment}
        captionText={captionText}
        logs={filteredLogs}
      />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  > textarea {
    padding: 12px 8px;
    border: 1px solid ${palette.gray[40]};
    resize: none;
    line-height: 1.2;
  }

  > .buttons {
    > .btn-primary {
      background: ${palette.primary.red};
    }
  }
`;
