import type { FC } from "react";

import styled from "@emotion/styled";
import { SmartToy } from "@mui/icons-material";
import { palette } from "@relatable/ui/Palette";

const getRole = (username: string, role: string) => {
  if (role === "assistant" || role === "system") {
    return (
      <>
        <SmartToy fontSize="small" style={{ marginRight: 10 }} />
        Relatable AI
      </>
    );
  }
  return username;
};

export const Message: FC<{
  username: string;
  userRole: string;
  content: string;
  loading?: boolean;
}> = ({ username, userRole, content, loading }) => {
  return (
    <Root>
      <strong style={{ display: "flex", alignItems: "center" }}>
        {getRole(username, userRole)}:
      </strong>
      <pre>
        {content}
        {loading && "..."}
      </pre>
    </Root>
  );
};

const Root = styled.div`
  padding: 10px;

  > strong {
    color: ${palette.primary.red};
  }

  > pre {
    text-wrap: wrap;
    margin: 6px 0;
  }
`;
