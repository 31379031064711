import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { Button } from "@relatable/ui/Button";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { Add, Download, Upload } from "@mui/icons-material";
import { useCampaignPostsTableQuery } from "../generated";
import { AddPostPopup } from "./AddPostPopup";
import { UploadReelPopup } from "./UploadReelPopup";
import { useSendCampaignPostZipOnSlackMutation } from "./generated";

export const ActionButtons: FC<{ campaignStub: string }> = ({ campaignStub }) => {
  const [isUploadReelPopup, setIsUploadReelPopup] = useState(false);
  const [isAddPostPopup, setIsAddPostPopup] = useState(false);

  const { data } = useCampaignPostsTableQuery({ variables: { stubs: [campaignStub] } });
  const [sendCampaignPostZipOnSlack, { loading: downloading }] =
    useSendCampaignPostZipOnSlackMutation();
  const snackbar = useSnackbar();

  const handleDownloadButtonClick = async () => {
    if (!data || data.posts.length < 1) {
      snackbar.error("The campaign has no posts!");
      return;
    }
    try {
      await sendCampaignPostZipOnSlack({ variables: { campaignId: data.campaigns[0]?.id } });
      snackbar.success("Download initiated, it will appear in the #campaigns-posts channel soon");
    } catch {
      snackbar.error("Something went wrong... Please contact the product team");
    }
  };

  return (
    <SActionButtons>
      <Button icon={<Add />} onClick={() => setIsAddPostPopup(true)}>
        Add post
      </Button>
      {isAddPostPopup && (
        <AddPostPopup onClose={() => setIsAddPostPopup(false)} campaignStub={campaignStub} />
      )}

      <Button
        icon={<Download />}
        style={{ minWidth: 160 }}
        disabled={downloading}
        onClick={handleDownloadButtonClick}
        variant="outlined"
      >
        {downloading ? <CircularProgress size={20} /> : "Download posts & reels"}
      </Button>

      <Button icon={<Upload />} onClick={() => setIsUploadReelPopup(true)} variant="outlined">
        Upload reel
      </Button>
      {isUploadReelPopup && (
        <UploadReelPopup onClose={() => setIsUploadReelPopup(false)} campaignStub={campaignStub} />
      )}
    </SActionButtons>
  );
};

const SActionButtons = styled.div`
  display: flex;
  gap: 20px;
`;
