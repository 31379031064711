import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { getUserLink, getUserPlatform } from "@relatable/helpers/user";
import { palette } from "@relatable/ui/Palette";

import { useIframeDetailsQuery } from "../generated";
import { Help } from "./Help";
import { IframeTester } from "./IframeTester";

const Root = styled.div`
  background: ${palette.gray[20]};
  height: calc(100vh - 130px);
  display: flex;
  align-items: center;
  justify-content: center;

  > iframe {
    width: 100%;
    height: 100%;
    border: 1px solid ${palette.gray[20]};
  }
`;

export const Iframe: FC<{ selectedUserId: number | null }> = ({ selectedUserId }) => {
  const [browserWorking, setBrowserWorking] = useState<boolean | null>(null);

  const { data, loading } = useIframeDetailsQuery({
    variables: { userId: selectedUserId ?? 0 },
    skip: !selectedUserId
  });

  if (browserWorking === null) {
    return (
      <Root>
        <IframeTester setResult={isSuccess => setBrowserWorking(isSuccess)} />
      </Root>
    );
  }

  if (browserWorking === false) {
    return (
      <Root>
        <Help />
      </Root>
    );
  }

  const selectedUser = data?.users_by_pk;
  const platform = selectedUser ? getUserPlatform(selectedUser) : null;
  const userLink = selectedUser ? getUserLink(selectedUser ?? {}) : "";

  return (
    <Root>
      {Boolean(selectedUserId) && loading && <CircularProgress />}
      {selectedUser ? <iframe src={userLink} title={platform ?? ""} /> : null}
    </Root>
  );
};
