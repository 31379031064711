import type { FC } from "react";

import { Route, Routes } from "react-router-dom";

import { ContentStatistics } from "./ContentStatistics";
import { ParticipantList } from "./ParticipantList";

export const Participants: FC = () => {
  return (
    <Routes>
      <Route path="statistics" element={<ContentStatistics />} />
      <Route index element={<ParticipantList />} />
    </Routes>
  );
};
