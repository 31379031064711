import { useCallback, useEffect, useRef } from "react";

type AnyEvent = MouseEvent | TouchEvent;

export const useOnOutsideClick = <T extends HTMLElement = HTMLDivElement>(
  callback: (e: AnyEvent) => void
) => {
  const ref = useRef<T>(null);

  const handleClickOutside = useCallback(
    (e: AnyEvent) => {
      if (!ref?.current || !ref.current.contains(e.target as Node)) {
        callback(e);
      }
    },
    [ref, callback]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [handleClickOutside]);

  return ref;
};
