import type { Platform_Enum } from "./generated-base";

export const getPlatformLabel = (platform: Platform_Enum | null | undefined) => {
  switch (platform) {
    case "instagram":
      return "Instagram";
    case "youtube":
      return "YouTube";
    case "tiktok":
      return "TikTok";
    case "snapchat":
      return "Snapchat";
    default:
      throw new Error(`Invalid platform ${platform}`);
  }
};
