import { useCallback, useEffect, useState } from "react";

import type { Platform_Enum } from "@relatable/gql/generated-base";

import {
  INSTAGRAM_SECTIONS,
  SECTION_KEYS,
  TIKTOK_SECTIONS,
  YOUTUBE_SECTIONS
} from "modules/hypeAuditor/common/constants";

type SectionRow = { key: string; checked: boolean; uiLabel: string };
export type SectionRowId = {
  sectionKey: string;
  columnKey: string;
  rowKey: string;
};
export type Section = {
  key: string;
  label: string;
  rows: SectionRow[];
};
export type DataSections = {
  [x: string]: Section[];
};
export type ToggleSectionRowArgs = SectionRowId & { checked: HTMLInputElement["checked"] };

interface UseDataPickerSectionsProps {
  platform: Platform_Enum;
  defaultCheckedRowKeys?: string[];
  hiddenRowsKeys?: string[];
}

const getDefaultSection = (platform: Platform_Enum) => {
  if (platform === "instagram") {
    return INSTAGRAM_SECTIONS;
  }
  if (platform === "youtube") {
    return YOUTUBE_SECTIONS;
  }
  if (platform === "tiktok") {
    return TIKTOK_SECTIONS;
  }
  if (platform === "snapchat") {
    return {};
  }
  throw new Error(`Invalid platform ${platform}`);
};

export const useDataPickerSections = ({
  platform,
  defaultCheckedRowKeys = [],
  hiddenRowsKeys = []
}: UseDataPickerSectionsProps) => {
  const defaultSections = getDefaultSection(platform);

  const getDefaultState = () => {
    const defaultState = {
      [SECTION_KEYS.ACCOUNT_QUALITY]: defaultSections[SECTION_KEYS.ACCOUNT_QUALITY],
      [SECTION_KEYS.AUDIENCE_DEMOGRAPHICS]: defaultSections[SECTION_KEYS.AUDIENCE_DEMOGRAPHICS]
    };

    if (defaultCheckedRowKeys.length || hiddenRowsKeys.length) {
      return Object.entries(defaultState).reduce(
        (acc, [sectionKey, sectionColumns]) => ({
          ...acc,
          [sectionKey]: sectionColumns.map(({ key, label, rows }) => ({
            key,
            label,
            rows: rows
              .map(r => ({
                ...r,
                // we have to use indexOf, as some keys(r.key) can be two in one like 👇
                // audience_geography.countries@${index}.name,audience_geography.countries@${index}.value
                checked: Boolean(
                  defaultCheckedRowKeys.find(
                    defaultCheckedRowKey => r.key.indexOf(defaultCheckedRowKey) > -1
                  )
                )
              }))
              .filter(r => !hiddenRowsKeys.includes(r.key))
          }))
        }),
        {}
      );
    }

    return defaultState;
  };

  const [sections, setSections] = useState<{ [x: string]: Section[] }>(getDefaultState());
  const [checkedRows, setCheckedRows] = useState<SectionRow[]>([]);

  const toggleSectionRow = useCallback(
    ({ sectionKey, columnKey, rowKey, checked }: ToggleSectionRowArgs) => {
      setSections(prevSections => ({
        ...prevSections,
        [sectionKey]: prevSections[sectionKey].map(c =>
          c.key === columnKey
            ? {
                ...c,
                rows: c.rows.map(cr => (cr.key === rowKey ? { ...cr, checked } : cr))
              }
            : c
        )
      }));
    },
    []
  );

  useEffect(() => {
    setCheckedRows(
      Object.values(sections)
        .map(s => s.map(c => c.rows))
        .flat(2)
        .filter(sr => sr.checked)
    );
  }, [sections]);

  return { sections, checkedRows, setSections, toggleSectionRow };
};
