import type { FC } from "react";

import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";

export const Documentation: FC = () => {
  return (
    <Root>
      <h3>Tips & tricks</h3>
      <br />
      <ul>
        <li className="highlighted">
          <strong>Do not share sensitive information</strong> with the AI like credit card details,
          passwords etc.
        </li>
        <li>
          <strong>Always review the AI responses for accuracy.</strong> The AI may hallucinate and
          provide inaccurate information.
        </li>
        <li>
          <strong>Provide feedback to the AI</strong> when you notice it giving incorrect responses.
          This is crucial as we work on training it to deliver improved replies.
        </li>
        <li>
          <strong>Be clear and concise</strong> in your questions to get more accurate responses
          from the AI.
        </li>
        <li>
          Right now, <strong>the AI does not have access to the Internet</strong> or to our internal
          database. So, it cannot check numbers like engagement rates.
        </li>
        <li>
          The AI was trained with data up to <strong>September 2021</strong>, so its knowledge is up
          to that year regarding the latest events and technologies.
        </li>
        <li>
          While the AI can communicate in numerous languages, it&apos;s{" "}
          <strong>optimal performance is in English</strong>.
        </li>
        <li>
          Experiment with different phrasing or approaches if the AI&apos;s initial response
          doesn&apos;t fully address your query. <br />
          <strong>Offering words of encouragement</strong> can be helpful when the AI doesn&apos;t
          immediately provide a response.
        </li>
        <li>
          If you&apos;re seeking creative ideas or solutions, try asking the AI{" "}
          <strong>to brainstorm or provide multiple suggestions.</strong>
        </li>
        <li>
          All context must be provided <strong>within a single conversation</strong> as the AI does
          not retain information between separate conversations.
        </li>
        <li>
          We are planning to create a database of our conversations to{" "}
          <strong>train the AI for our specific use cases</strong>. This is expected to result in
          improved performance compared to ChatGPT.
        </li>
        <li>
          The current limit is approximately <strong>2500 words / conversation.</strong> For
          languages other than English, this limit may be lower.
        </li>
      </ul>
      <br />

      <h3>Some ideas to try</h3>
      <br />
      <ul>
        <li>
          Brainstorm creative concept for promoting blue graphite pencils. No generic answers. Short
          bullet points with some explanation.
        </li>
        <li>
          Write me a 500-word blog post about tips on creating a social media content calendar for
          e-commerce. Make it informal and fun.
        </li>
        <li>Create six rhyming slogans for promoting graphite pencils.</li>
        <li>Write formal email in Swedish to an influencer about these issues: ...</li>
        <li>Summarize following text in two bullet points: &quot;...something long...&quot;</li>
        <li>Translate the following text to English: &quot;...&quot;</li>
        <li>Rephase the following sentence in style of Shakespeare: &quot;...&quot;</li>
        <li>
          Generate 5 social media posts for Instagram that talk about [topic]. Keep them under 75
          words each.
        </li>
        <li>What&apos;s an international number format? Explain it like I&apos;m 5.</li>
      </ul>
    </Root>
  );
};

const Root = styled.div`
  > ul {
    > li {
      margin: 10px 0;
      &.highlighted {
        strong {
          text-decoration: underline;
          color: ${palette.primary.red};
        }
      }
    }
  }
`;
