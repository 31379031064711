import type { FC } from "react";

import { GqlProvider, getGqlClient } from "@relatable/gql";
import { UIProvider } from "@relatable/ui/UIProvider";
import { RouterProvider } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";

import { router } from "./routes";

export const gqlClient = getGqlClient({
  headers: {
    "origin-type": "manager"
  }
});

export const App: FC = () => (
  <GqlProvider client={gqlClient}>
    <UIProvider>
      <RouterProvider router={router} fallbackElement={<Loader />} />
    </UIProvider>
  </GqlProvider>
);
