import type { HypeAuditorSCReport } from "@relatable/helpers/hypeauditor/snapchat";

import type { MetricPeriod } from "src/typings/hypeauditor";

export interface RowReturnValue {
  name?: string | null;
  size?: number | string;
  value?: number | string;
  percentage?: number | string;
}

export const getReachRows = (params: {
  subscribers_count: HypeAuditorSCReport["metrics"]["subscribers_count"];
  views_avg?: HypeAuditorSCReport["metrics"]["views_avg"];
  metricsPeriod: MetricPeriod;
}): RowReturnValue[] => {
  const { subscribers_count, views_avg, metricsPeriod } = params;

  return [
    { name: "Subscribers", size: subscribers_count.value },
    {
      name: "Average video views",
      size:
        metricsPeriod === "all"
          ? views_avg?.value
          : (views_avg?.performance?.[metricsPeriod]?.value ?? "n/a")
    }
  ];
};

export const getEngagementRows = (props: {
  views_count: HypeAuditorSCReport["metrics"]["views_count"];
  subscribers_growth_prc: HypeAuditorSCReport["metrics"]["subscribers_growth_prc"];
  media_per_week: HypeAuditorSCReport["metrics"]["media_per_week"];
  views_avg: HypeAuditorSCReport["metrics"]["views_avg"];
  metricsPeriod: MetricPeriod;
}): RowReturnValue[] => {
  const { views_count, subscribers_growth_prc, media_per_week, views_avg, metricsPeriod } = props;

  return [
    {
      name: "Views",
      size:
        metricsPeriod === "all"
          ? views_count?.value
          : views_count?.performance?.[metricsPeriod]?.value
    },
    {
      name: "Average views",
      size:
        metricsPeriod === "all" ? views_avg?.value : views_avg?.performance?.[metricsPeriod]?.value
    },
    {
      name: "Subscribers growth",
      size:
        metricsPeriod === "all" ? "-" : subscribers_growth_prc?.performance?.[metricsPeriod]?.value
    },
    {
      name: "Medias per week",
      size: metricsPeriod === "all" ? "-" : media_per_week?.performance?.[metricsPeriod]?.value
    }
  ];
};
