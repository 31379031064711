import styled from "@emotion/styled";
import { numberWithCommas, prettifyNumber } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import { ArcElement, Chart as ChartJS } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement);

interface PieChartMetricProps {
  current: number;
  total: number;
  title: string;
}

export const PieChartMetric: React.FC<PieChartMetricProps> = ({ current, total, title }) => {
  const percentage = total !== 0 ? current / total : 0;
  const totalValue =
    current < total || (current === total && total === 0) ? 100 - percentage * 100 : 0;
  const actualValue =
    current < total || (current === total && total === 0) ? percentage * 100 : 100;

  return (
    <Root>
      <div style={{ width: 100, height: 100 }}>
        <Doughnut
          data={{
            labels: ["Actual", "Total"],

            datasets: [
              {
                label: "# of Votes",
                data: [actualValue, totalValue],
                backgroundColor: [palette.primary.blue, palette.gray.whiteOff]
              }
            ]
          }}
          options={{ cutout: "75%" }}
        />
      </div>
      <DescriptionContainer>
        <h6>{title}</h6>
        <span>
          {numberWithCommas(current)} of {numberWithCommas(total)}
        </span>
        {total - current > 0 ? <span>{numberWithCommas(total - current)} left</span> : null}
      </DescriptionContainer>
      <ChartLabel>
        {percentage < 9 ? prettifyNumber(percentage, { percentages: true }) : ""}
      </ChartLabel>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const ChartLabel = styled.span`
  position: absolute;
  font-weight: 700;
  width: 76px;
  text-align: center;
  top: 36px;
  left: 12px;
  font-size: 20px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  h6 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  span {
    line-height: 18px;
  }
`;
