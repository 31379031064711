import type { FC, ReactElement } from "react";

import { ArrowDropDown } from "@mui/icons-material";
import { AccordionDetails, Accordion as AccordionMui, AccordionSummary } from "@mui/material";

export const Accordion: FC<{
  options: {
    title: string | ReactElement;
    content: ReactElement;
    disabled?: boolean;
    defaultExpanded?: boolean;
  }[];
  style?: React.CSSProperties;
  contentContainerStyles?: React.CSSProperties;
}> = ({ options, style, contentContainerStyles }) => (
  <div style={style}>
    {options.map((o, index) => (
      <AccordionMui defaultExpanded={o.defaultExpanded} disabled={o.disabled} key={index}>
        <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls="panel1a-content">
          {o.title}
        </AccordionSummary>
        <AccordionDetails style={contentContainerStyles}>{o.content}</AccordionDetails>
      </AccordionMui>
    ))}
  </div>
);
