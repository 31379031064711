import type { FC, ReactNode } from "react";

import styled from "@emotion/styled";
import { CardContent, Card as MaterialCard } from "@mui/material";
import { palette } from "@relatable/ui/Palette";

export const Card: FC<{
  children: ReactNode;
  footer?: ReactNode;
}> = ({ footer, children }) => (
  <StyledCard variant="outlined">
    <StyledCardContent>{children}</StyledCardContent>
    {footer ? <Actions>{footer}</Actions> : null}
  </StyledCard>
);

const StyledCard = styled(MaterialCard)`
  border-color: ${palette.gray[50]};
`;

const StyledCardContent = styled(CardContent)`
  && {
    padding: 20px 50px 10px;
  }
`;

const Actions = styled.div`
  && {
    background-color: ${palette.gray.whiteOff};
    font-size: 10px;
    color: ${palette.gray[50]};
    padding: 10px 50px;
    display: block;
  }
`;
