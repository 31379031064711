import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetClientSheetCreatorsMutationVariables = Types.Exact<{
  campaign_id: Types.Scalars['Int']['input'];
  min_creators: Types.Scalars['Int']['input'];
}>;


export type SetClientSheetCreatorsMutation = { __typename?: 'Mutation', insert_campaign_client_sheet_one?: { __typename?: 'campaign_client_sheet', campaign_id: number } | null };

export type SetClientSheetDueDateMutationVariables = Types.Exact<{
  campaign_id: Types.Scalars['Int']['input'];
  due_date: Types.Scalars['timestamp']['input'];
}>;


export type SetClientSheetDueDateMutation = { __typename?: 'Mutation', insert_campaign_client_sheet_one?: { __typename?: 'campaign_client_sheet', campaign_id: number } | null };

export type CampaignClientSheetQueryVariables = Types.Exact<{
  campaignStub: Types.Scalars['String']['input'];
}>;


export type CampaignClientSheetQuery = { __typename?: 'Query', campaign_client_sheet: Array<{ __typename?: 'campaign_client_sheet', id: number, campaign_id: number, action: Types.Campaign_Client_Sheet_Action_Enum, client_comment?: string | null, preapproved?: number | null, declined?: number | null, needs_review?: number | null, url?: string | null, due_date?: string | null, min_creators?: number | null, created_at: string }> };

export type DownloadClientSheetMutationVariables = Types.Exact<{
  campaign_id: Types.Scalars['Int']['input'];
  sheet_url?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DownloadClientSheetMutation = { __typename?: 'Mutation', downloadClientSheet?: { __typename?: 'DownloadClientSheetReturn', error?: string | null } | null };


export const SetClientSheetCreatorsDocument = gql`
    mutation setClientSheetCreators($campaign_id: Int!, $min_creators: Int!) {
  insert_campaign_client_sheet_one(
    object: {campaign_id: $campaign_id, min_creators: $min_creators, action: "set_min_creators"}
  ) {
    campaign_id
  }
}
    `;
export type SetClientSheetCreatorsMutationFn = Apollo.MutationFunction<SetClientSheetCreatorsMutation, SetClientSheetCreatorsMutationVariables>;

/**
 * __useSetClientSheetCreatorsMutation__
 *
 * To run a mutation, you first call `useSetClientSheetCreatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClientSheetCreatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClientSheetCreatorsMutation, { data, loading, error }] = useSetClientSheetCreatorsMutation({
 *   variables: {
 *      campaign_id: // value for 'campaign_id'
 *      min_creators: // value for 'min_creators'
 *   },
 * });
 */
export function useSetClientSheetCreatorsMutation(baseOptions?: Apollo.MutationHookOptions<SetClientSheetCreatorsMutation, SetClientSheetCreatorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetClientSheetCreatorsMutation, SetClientSheetCreatorsMutationVariables>(SetClientSheetCreatorsDocument, options);
      }
export type SetClientSheetCreatorsMutationHookResult = ReturnType<typeof useSetClientSheetCreatorsMutation>;
export type SetClientSheetCreatorsMutationResult = Apollo.MutationResult<SetClientSheetCreatorsMutation>;
export type SetClientSheetCreatorsMutationOptions = Apollo.BaseMutationOptions<SetClientSheetCreatorsMutation, SetClientSheetCreatorsMutationVariables>;
export const SetClientSheetDueDateDocument = gql`
    mutation setClientSheetDueDate($campaign_id: Int!, $due_date: timestamp!) {
  insert_campaign_client_sheet_one(
    object: {campaign_id: $campaign_id, due_date: $due_date, action: "set_due_date"}
  ) {
    campaign_id
  }
}
    `;
export type SetClientSheetDueDateMutationFn = Apollo.MutationFunction<SetClientSheetDueDateMutation, SetClientSheetDueDateMutationVariables>;

/**
 * __useSetClientSheetDueDateMutation__
 *
 * To run a mutation, you first call `useSetClientSheetDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClientSheetDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClientSheetDueDateMutation, { data, loading, error }] = useSetClientSheetDueDateMutation({
 *   variables: {
 *      campaign_id: // value for 'campaign_id'
 *      due_date: // value for 'due_date'
 *   },
 * });
 */
export function useSetClientSheetDueDateMutation(baseOptions?: Apollo.MutationHookOptions<SetClientSheetDueDateMutation, SetClientSheetDueDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetClientSheetDueDateMutation, SetClientSheetDueDateMutationVariables>(SetClientSheetDueDateDocument, options);
      }
export type SetClientSheetDueDateMutationHookResult = ReturnType<typeof useSetClientSheetDueDateMutation>;
export type SetClientSheetDueDateMutationResult = Apollo.MutationResult<SetClientSheetDueDateMutation>;
export type SetClientSheetDueDateMutationOptions = Apollo.BaseMutationOptions<SetClientSheetDueDateMutation, SetClientSheetDueDateMutationVariables>;
export const CampaignClientSheetDocument = gql`
    query campaignClientSheet($campaignStub: String!) {
  campaign_client_sheet(
    where: {campaign: {stub: {_eq: $campaignStub}}}
    order_by: {created_at: desc}
  ) {
    id
    campaign_id
    action
    client_comment
    preapproved
    declined
    needs_review
    url
    due_date
    min_creators
    created_at
  }
}
    `;

/**
 * __useCampaignClientSheetQuery__
 *
 * To run a query within a React component, call `useCampaignClientSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignClientSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignClientSheetQuery({
 *   variables: {
 *      campaignStub: // value for 'campaignStub'
 *   },
 * });
 */
export function useCampaignClientSheetQuery(baseOptions: Apollo.QueryHookOptions<CampaignClientSheetQuery, CampaignClientSheetQueryVariables> & ({ variables: CampaignClientSheetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignClientSheetQuery, CampaignClientSheetQueryVariables>(CampaignClientSheetDocument, options);
      }
export function useCampaignClientSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignClientSheetQuery, CampaignClientSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignClientSheetQuery, CampaignClientSheetQueryVariables>(CampaignClientSheetDocument, options);
        }
export function useCampaignClientSheetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignClientSheetQuery, CampaignClientSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignClientSheetQuery, CampaignClientSheetQueryVariables>(CampaignClientSheetDocument, options);
        }
export type CampaignClientSheetQueryHookResult = ReturnType<typeof useCampaignClientSheetQuery>;
export type CampaignClientSheetLazyQueryHookResult = ReturnType<typeof useCampaignClientSheetLazyQuery>;
export type CampaignClientSheetSuspenseQueryHookResult = ReturnType<typeof useCampaignClientSheetSuspenseQuery>;
export type CampaignClientSheetQueryResult = Apollo.QueryResult<CampaignClientSheetQuery, CampaignClientSheetQueryVariables>;
export const DownloadClientSheetDocument = gql`
    mutation downloadClientSheet($campaign_id: Int!, $sheet_url: String) {
  downloadClientSheet(campaign_id: $campaign_id, sheet_url: $sheet_url) {
    error
  }
}
    `;
export type DownloadClientSheetMutationFn = Apollo.MutationFunction<DownloadClientSheetMutation, DownloadClientSheetMutationVariables>;

/**
 * __useDownloadClientSheetMutation__
 *
 * To run a mutation, you first call `useDownloadClientSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadClientSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadClientSheetMutation, { data, loading, error }] = useDownloadClientSheetMutation({
 *   variables: {
 *      campaign_id: // value for 'campaign_id'
 *      sheet_url: // value for 'sheet_url'
 *   },
 * });
 */
export function useDownloadClientSheetMutation(baseOptions?: Apollo.MutationHookOptions<DownloadClientSheetMutation, DownloadClientSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadClientSheetMutation, DownloadClientSheetMutationVariables>(DownloadClientSheetDocument, options);
      }
export type DownloadClientSheetMutationHookResult = ReturnType<typeof useDownloadClientSheetMutation>;
export type DownloadClientSheetMutationResult = Apollo.MutationResult<DownloadClientSheetMutation>;
export type DownloadClientSheetMutationOptions = Apollo.BaseMutationOptions<DownloadClientSheetMutation, DownloadClientSheetMutationVariables>;