import { type ReactNode, isValidElement } from "react";

import type { TableColumn, TableRow } from "./Table";

const retrieveTextFromRenderedComponent = ({ component }: { component: ReactNode }) => {
  if (!component) return [];
  if (typeof component === "string") return component;

  if (!isValidElement(component)) {
    throw Error("component is not valid");
  }
  if (typeof component.props.children === "string") return component.props.children;
  return component.props.children.filter(Boolean).flatMap(ch => {
    return retrieveTextFromRenderedComponent({ component: ch });
  });
};

export const downloadCSV = ({
  filename,
  rows,
  columns
}: {
  filename: string;
  rows: TableRow[];
  columns: TableColumn<any[]>[];
}) => {
  const serialiseValue = (value: string) => {
    if (typeof value === "string") {
      // eslint-disable-next-line quotes
      const formattedValue = value.replace(/"/g, '""');

      // Make sure value containing delimiter or line break won't be split into multiple rows
      if ([",", "\n", "\r"].some(delimiter => formattedValue.includes(delimiter))) {
        return `"${formattedValue}"`;
      }

      return formattedValue;
    }

    return value;
  };

  const headers = columns.map(c => serialiseValue(c.headerName ?? c.field)).join(",");

  const csvRows = rows.map(row =>
    columns
      .map(column => {
        const value = row[column.field];
        if (column.useRenderCellForExport && column.renderCell) {
          const rendered = column.renderCell({ value, row });
          if (typeof rendered === "string") return rendered;
          if (isValidElement(rendered)) {
            return retrieveTextFromRenderedComponent({ component: rendered }).join(" ");
          }
        }
        if (value === null || value === undefined) return "";
        if (["number", "string", "boolean"].includes(typeof value)) {
          return value;
        }
        return JSON.stringify(value);
      })
      .map(value => serialiseValue(value))
      .join(",")
  );

  const csv = [headers, ...csvRows].join("\r\n");

  const blob = new Blob(["", csv], {
    type: "text/csv"
  });

  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.csv`;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });

  return csv;
};
