import { type FC, useState } from "react";

import { Input, InputLabel } from "@mui/material";
import { DateInput } from "@relatable/ui/DateInput";
import { Note } from "@relatable/ui/Note";
import { Select } from "@relatable/ui/Select";

import { Popup } from "components/ui/Popup";
import { UploadInput } from "components/ui/UploadInput";
import {
  CampaignPostsTableDocument,
  useCampaignPostUsersPopupQuery
} from "modules/campaign/Posts/generated";
import { useSignUploadUrlMutation } from "modules/generated";

import { FormContainer, InputContainer } from "./common";
import { useInsertCampaignReelMutation } from "./generated";

export const UploadReelPopup: FC<{
  campaignStub: string;
  onClose(): void;
}> = ({ campaignStub, onClose }) => {
  const { data } = useCampaignPostUsersPopupQuery({ variables: { stub: campaignStub } });
  const [insertReel] = useInsertCampaignReelMutation({
    refetchQueries: [CampaignPostsTableDocument],
    awaitRefetchQueries: true
  });

  const [creationDate, setCreationDate] = useState(new Date().toISOString());
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [shortcode, setShortcode] = useState("");

  const [signUrl] = useSignUploadUrlMutation();

  // https://stackoverflow.com/a/39754971/11661273
  const isError = !shortcode || shortcode.includes("/");

  const uploadFile = async () => {
    const userName = data?.campaign_user?.find(({ user }) => user?.id === selectedUserId)?.user
      ?.user_instagram?.username;
    if (!uploadedFile) throw new Error("missing uploadedFile");
    if (!userName) throw new Error("missing userName");

    const signUrlResponse = await signUrl({
      variables: {
        input: {
          prefix: "APPROVAL_UPLOADS",
          fileName: `${userName}/${new Date().getTime()}-${uploadedFile.name}`,
          fileType: uploadedFile.type
        }
      }
    });

    if (!signUrlResponse.data) {
      throw new Error("Something went wrong when uploading a file");
    }

    const { signedUploadUrl, url } = signUrlResponse.data.signUploadUrl;

    await insertReel({
      variables: {
        object: {
          user_id: selectedUserId,
          campaign_id: data?.campaign[0].id,
          internal_url: url,
          created_at: new Date(creationDate).toISOString(),
          shortcode
        }
      }
    });

    // upload to s3 trigger converting to mp4 lambda's function which updates the campaignPost's internal_url
    // make sure to await insertCampaign before
    await fetch(signedUploadUrl, {
      method: "PUT",
      body: uploadedFile,
      headers: { "content-type": uploadedFile?.type }
    });
    onClose();
  };

  return (
    <Popup
      actionLabel="Upload reel"
      title="Upload reel"
      disabledAction={!uploadedFile || isError || !selectedUserId}
      onAction={uploadFile}
      onClose={onClose}
    >
      <InputContainer>
        <Select
          label="Creator"
          value={selectedUserId}
          onChange={setSelectedUserId}
          options={
            data?.campaign_user.map(({ user }) => ({
              value: user?.id,
              label: user?.user_instagram?.username || ""
            })) || []
          }
        />
        <FormContainer>
          <InputLabel htmlFor="reel-shortcode">Shortcode (ex. BA2zPfbKLt4)</InputLabel>
          <Input id="reel-shortcode" onChange={e => setShortcode(e.target.value)} />
          {isError && shortcode && (
            <Note label="Invalid shortcode" variant="error">
              Make sure not to copy paste the entire url but just the shortcode
            </Note>
          )}
        </FormContainer>
        <DateInput label="Reel's created at" value={creationDate} onChange={setCreationDate} />
        <FormContainer style={{ marginBottom: 0 }}>
          {uploadedFile?.name}
          <br />
          <br />
          <UploadInput
            multiple
            accept={["videos", "images"]}
            onChange={f => setUploadedFile(f[0])}
          />
        </FormContainer>
      </InputContainer>
    </Popup>
  );
};
