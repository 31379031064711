import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'AccountType', admin?: { __typename?: 'AdminAccount', id: number, email?: string | null, full_name?: string | null, profileUrl?: string | null, roles?: Array<string | null> | null } | null } | null };

export type SendSlackNotificationMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['Int']['input'];
  channel: Types.Scalars['String']['input'];
  text: Types.Scalars['String']['input'];
}>;


export type SendSlackNotificationMutation = { __typename?: 'Mutation', sendSlackNotification?: { __typename?: 'SendSlackNotificationReturn', error?: string | null } | null };


export const MeDocument = gql`
    query me {
  me {
    admin {
      id
      email
      full_name
      profileUrl
      roles
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SendSlackNotificationDocument = gql`
    mutation sendSlackNotification($campaignId: Int!, $channel: String!, $text: String!) {
  sendSlackNotification(campaign_id: $campaignId, channel: $channel, text: $text) {
    error
  }
}
    `;
export type SendSlackNotificationMutationFn = Apollo.MutationFunction<SendSlackNotificationMutation, SendSlackNotificationMutationVariables>;

/**
 * __useSendSlackNotificationMutation__
 *
 * To run a mutation, you first call `useSendSlackNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSlackNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSlackNotificationMutation, { data, loading, error }] = useSendSlackNotificationMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      channel: // value for 'channel'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSendSlackNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendSlackNotificationMutation, SendSlackNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSlackNotificationMutation, SendSlackNotificationMutationVariables>(SendSlackNotificationDocument, options);
      }
export type SendSlackNotificationMutationHookResult = ReturnType<typeof useSendSlackNotificationMutation>;
export type SendSlackNotificationMutationResult = Apollo.MutationResult<SendSlackNotificationMutation>;
export type SendSlackNotificationMutationOptions = Apollo.BaseMutationOptions<SendSlackNotificationMutation, SendSlackNotificationMutationVariables>;