import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HypeAuditorUserListQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Users_In_Lists_Bool_Exp>;
  order_by?: Types.InputMaybe<Array<Types.Users_In_Lists_Order_By> | Types.Users_In_Lists_Order_By>;
}>;


export type HypeAuditorUserListQuery = { __typename?: 'Query', listUsers: Array<{ __typename?: 'users_in_lists', user_id?: number | null, users_instagram_username?: string | null, users_youtube_channel_title?: string | null, users_youtube_channel_id?: string | null, user_tiktok_username?: string | null, user_tiktok_id?: number | null, users_snapchat_username?: string | null, users_snapchat_id?: number | null, lists_id?: number | null, lists_name?: string | null, campaign_title?: string | null, campaign_stub?: string | null, campaign_id?: number | null, campaign_user?: { __typename?: 'campaign_user', id: number, state?: Types.Campaign_User_State_Enum | null } | null, hypeauditor?: { __typename?: 'hypeauditor', id: number, data?: any | null, updated_at?: string | null, state?: Types.Hypeauditor_Report_State_Enum | null } | null }> };


export const HypeAuditorUserListDocument = gql`
    query hypeAuditorUserList($where: users_in_lists_bool_exp, $order_by: [users_in_lists_order_by!]) {
  listUsers: users_in_lists(where: $where, order_by: $order_by) {
    user_id
    users_instagram_username
    users_youtube_channel_title
    users_youtube_channel_id
    user_tiktok_username
    user_tiktok_id
    users_snapchat_username
    users_snapchat_id
    lists_id
    lists_name
    campaign_title
    campaign_stub
    campaign_id
    campaign_user {
      id
      state
    }
    hypeauditor {
      id
      data
      updated_at
      state
    }
  }
}
    `;

/**
 * __useHypeAuditorUserListQuery__
 *
 * To run a query within a React component, call `useHypeAuditorUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHypeAuditorUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHypeAuditorUserListQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useHypeAuditorUserListQuery(baseOptions?: Apollo.QueryHookOptions<HypeAuditorUserListQuery, HypeAuditorUserListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HypeAuditorUserListQuery, HypeAuditorUserListQueryVariables>(HypeAuditorUserListDocument, options);
      }
export function useHypeAuditorUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HypeAuditorUserListQuery, HypeAuditorUserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HypeAuditorUserListQuery, HypeAuditorUserListQueryVariables>(HypeAuditorUserListDocument, options);
        }
export function useHypeAuditorUserListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HypeAuditorUserListQuery, HypeAuditorUserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HypeAuditorUserListQuery, HypeAuditorUserListQueryVariables>(HypeAuditorUserListDocument, options);
        }
export type HypeAuditorUserListQueryHookResult = ReturnType<typeof useHypeAuditorUserListQuery>;
export type HypeAuditorUserListLazyQueryHookResult = ReturnType<typeof useHypeAuditorUserListLazyQuery>;
export type HypeAuditorUserListSuspenseQueryHookResult = ReturnType<typeof useHypeAuditorUserListSuspenseQuery>;
export type HypeAuditorUserListQueryResult = Apollo.QueryResult<HypeAuditorUserListQuery, HypeAuditorUserListQueryVariables>;