import { prettifyDate, prettifyNumber, prettifyPercentages } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { Table, type TableColumn } from "@relatable/ui/Table";
import { type FC, useState } from "react";
import { Link } from "react-router-dom";

import { UpdateStats } from "components/UpdateStats";
import { Popup } from "components/ui/Popup";
import { useUpdateCampaignPostMutation } from "modules/campaign/Content/generated";
import { useLinksByKeyQuery } from "modules/links/generated";
import { extractRltToKey } from "modules/links/util";

import { Check, Close, Delete } from "@mui/icons-material";
import { PlatformIcon } from "@relatable/ui/icons/PlatformIcon";
import { NoteCell } from "./NoteCell";
import {
  CampaignPostsTableDocument,
  useCampaignPostsTableQuery,
  useDeleteCampaignPostMutation,
  useUpdateCampaignPostStatsMutation
} from "./generated";

const DeletePostButton: FC<{ id: number; title: string }> = ({ id, title }) => {
  const [isConfirmPopup, setIsConfirmPopup] = useState(false);
  const [deletePost] = useDeleteCampaignPostMutation({
    variables: { id },
    onCompleted: () => setIsConfirmPopup(false),
    refetchQueries: [CampaignPostsTableDocument],
    awaitRefetchQueries: true
  });
  return (
    <>
      <Button
        variant="text"
        onClick={() => setIsConfirmPopup(true)}
        icon={<Delete style={{ color: palette.primary.red }} />}
      >
        Delete
      </Button>
      {isConfirmPopup && (
        <Popup
          actionLabel="Delete"
          onAction={() => deletePost()}
          title={`Delete ${title} post`}
          onClose={() => setIsConfirmPopup(false)}
        />
      )}
    </>
  );
};

export const PostsTable: FC<{ campaignStubs: string[] }> = ({ campaignStubs }) => {
  const snackbar = useSnackbar();
  const { data, loading: postsLoading } = useCampaignPostsTableQuery({
    variables: { stubs: campaignStubs }
  });

  const rltToKeys = (data?.posts ?? [])
    .map(p => extractRltToKey(p.track_url))
    .filter(Boolean) as string[];

  const { data: linkData, loading: linksLoading } = useLinksByKeyQuery({
    variables: { keys: rltToKeys },
    skip: !rltToKeys.length
  });

  const loading = postsLoading || linksLoading;

  const [updatePost] = useUpdateCampaignPostMutation({
    awaitRefetchQueries: true,
    refetchQueries: [CampaignPostsTableDocument]
  });
  const [updatePostStats] = useUpdateCampaignPostStatsMutation({
    awaitRefetchQueries: true,
    refetchQueries: [CampaignPostsTableDocument],
    onError(error) {
      snackbar.error("Sth went wrong when updating a post");
      snackbar.error(error.message);
      console.error(error);
    },
    onCompleted() {
      snackbar.success("Post successfully updated");
    }
  });

  const { posts = [], campaigns = [] } = data || {};

  const hasLinkClicks = posts.some(post => post.track_url);
  const columns: TableColumn<typeof rows>[] = [
    {
      field: "username",
      headerName: "User",
      renderCell: ({ value, row }) => (
        <Link
          target="_blank"
          rel="noreferrer"
          to={`https://www.instagram.com/${value}`}
          style={{ whiteSpace: "nowrap" }}
        >
          {row.approved ? (
            <Check style={{ marginRight: 10, color: palette.secondary.green }} />
          ) : (
            <Close style={{ marginRight: 10, color: palette.secondary.red }} />
          )}
          {campaigns.length > 1 ? `${row.campaign_title} - ` : ""}
          {value}
        </Link>
      )
    },
    {
      field: "instagramLink",
      headerName: "Post",
      minWidth: 150,
      renderCell: ({ value, row }) => (
        <>
          <Link
            style={{ display: "inline-block", verticalAlign: "middle", marginRight: 5 }}
            target="_blank"
            rel="noreferrer"
            to={value}
          >
            <PlatformIcon platform={"instagram"} color={palette.social.instagram} />
          </Link>
          <Link to={`/campaign/${row.campaignStub}/content/post/${row.id}`}>
            {value.split("https://www.instagram.com/p/")[1]}
          </Link>
        </>
      )
    },
    {
      headerName: "Notes",
      field: "notes",
      renderCell: ({ value, row }) => <NoteCell note={value} postId={row.key} />
    },
    {
      field: "assets",
      headerName: "Assets",
      minWidth: 80,
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      field: "lock_statistics_at",
      headerName: "Statistics",
      minWidth: 75,
      renderCell: ({ value, row }) => (
        <UpdateStats
          onUpdate={() => updatePostStats({ variables: { postId: row.id } })}
          lockStats={{
            lockedAt: value,
            isTokenValid: row.isBusinessTokenValid,
            onToggle: lock_statistics =>
              updatePost({
                variables: {
                  postId: row.id,
                  _set: { lock_statistics_at: lock_statistics ? new Date().toISOString() : null }
                }
              })
          }}
        />
      )
    },
    {
      field: "lockedStatsAt",
      headerName: "Locked",
      minWidth: 130
    },
    {
      field: "reach",
      headerName: "Reach",
      renderCell: ({ value }) => prettifyNumber(value)
    },

    {
      field: "actualReach",
      headerName: "Actual reach",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      field: "audienceReach",
      headerName: "Audience reach"
    },
    {
      field: "impressions",
      headerName: "Impressions",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      field: "frequency",
      headerName: "Frequency",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      field: "videoViews",
      headerName: "Video views",
      hide: !campaigns.some(c =>
        c.campaign_content_settings?.some(s => ["photo", "carousel", "video"].includes(s.type))
      ),
      renderCell: ({ value }) => prettifyNumber(value)
    },
    { field: "likes", headerName: "Likes", renderCell: ({ value }) => prettifyNumber(value) },
    { field: "comments", headerName: "Comments", renderCell: ({ value }) => prettifyNumber(value) },
    { field: "saved", headerName: "Saved", renderCell: ({ value }) => prettifyNumber(value) },

    { field: "reachEngagement", headerName: "Reach eng. (int.)" },
    { field: "actualReachEngagement", headerName: "Actual reach eng. (int.)" },
    { field: "avgEngagement", headerName: "Organic eng. (int.)" },
    {
      field: "clicks",
      headerName: "Clicks",
      hide: !hasLinkClicks,
      renderCell: ({ value }) => prettifyNumber(value)
    },
    { field: "reachCtr", headerName: "Reach CTR", hide: !hasLinkClicks },
    {
      field: "id",
      headerName: "Delete",
      renderCell: ({ value, row }) => <DeletePostButton title={row.shortcode} id={value} />
    }
  ];

  const rows = posts.map(post => {
    const campaign = campaigns.find(c => c.id === post.campaign_id);

    const { comments, likes, link_clicks: bitlyClicks } = post || {};
    const postRltLink = linkData?.link_clicks.find(
      lc => lc.key === extractRltToKey(post.track_url)
    );
    const clicks = (bitlyClicks ?? 0) + (postRltLink?.clicks ?? 0);

    const saved = post?.saved || 0;
    const actual_reach = post?.actual_reach || 0;
    const impressions = post?.impressions || 0;

    const totalInteractions = (comments || 0) + (likes || 0) + (saved || 0);
    const followers = post?.user?.user_instagram?.followed_by;

    return {
      ...post,
      key: post.id,
      campaign_title: campaign?.title || "",
      campaignStub: campaign?.stub || "",
      username: post?.user?.user_instagram?.username,
      instagramLink: `https://www.instagram.com/p/${post.shortcode}`,
      lockedStatsAt: prettifyDate(post.lock_statistics_at),
      audienceReach: prettifyPercentages({
        of: actual_reach,
        total: followers
      }),
      isBusinessTokenValid: Boolean(
        post.user?.user_instagram?.access_token_facebook?.token_is_valid
      ),
      assets: post.assets?.length || 1,
      reach: followers,
      actualReach: actual_reach,
      impressions,
      frequency: impressions / actual_reach,
      videoViews: post?.video_views,
      likes,
      comments,
      saved,
      clicks,
      reachEngagement: prettifyPercentages({ total: followers, of: totalInteractions }),
      actualReachEngagement: prettifyPercentages({
        total: actual_reach,
        of: totalInteractions
      }),
      avgEngagement: prettifyPercentages({ of: post.user?.hypeauditor?.avg_engagement, total: 1 }),
      reachCtr: prettifyPercentages({
        total: followers,
        of: clicks
      })
    };
  });

  return (
    <Table
      tableId="campaign-posts"
      columns={columns}
      rows={rows}
      sortable
      canSelectColumns
      canExportCSV
      stickyColumn
      loading={loading}
    />
  );
};
