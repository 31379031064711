import { calculatePercentages, capitalizeFirstLetter } from "@relatable/helpers";

import { Gender, type MetricPeriod } from "src/typings/hypeauditor";

import type { HypeAuditorTikTokReportQuery } from "../generated";

export interface RowReturnValue {
  name?: string | null;
  size?: number | string;
  value?: number | string;
  percentage?: number | string;
}

type TiktokReportData = HypeAuditorTikTokReportQuery["hypeauditor"][number]["data"];

export const getReachRows = (props: {
  subscribers_count: TiktokReportData["metrics"]["subscribers_count"];
  views_avg?: TiktokReportData["metrics"]["views_avg"];
  metricsPeriod: MetricPeriod;
  subscribers_quality?: TiktokReportData["metrics"]["subscribers_quality"];
}): RowReturnValue[] => {
  const { subscribers_count, views_avg, metricsPeriod, subscribers_quality } = props;
  return [
    { name: "Subscribers", size: subscribers_count.value },
    {
      name: "Average video views",
      size:
        metricsPeriod === "all"
          ? views_avg?.value
          : (views_avg?.performance?.[metricsPeriod]?.value ?? "n/a")
    },
    {
      name: "Subscribers quality",
      size: subscribers_quality?.value ?? "n/a",
      value: subscribers_quality?.mark
    }
  ];
};

export const getEngagementRows = (props: {
  er: TiktokReportData["metrics"]["er"];
  comments_likes_ratio: TiktokReportData["metrics"]["comments_likes_ratio"];
  comments_avg: TiktokReportData["metrics"]["comments_avg"];
  alikes_avg: TiktokReportData["metrics"]["alikes_avg"];
  views_avg: TiktokReportData["metrics"]["views_avg"];
  reactions_avg: TiktokReportData["metrics"]["reactions_avg"];
  metricsPeriod: MetricPeriod;
}): RowReturnValue[] => {
  const {
    er,
    comments_likes_ratio,
    comments_avg,
    alikes_avg,
    views_avg,
    reactions_avg,
    metricsPeriod
  } = props;
  return [
    {
      name: "Engagement (HA)",
      size: metricsPeriod === "all" ? er?.value : er?.performance?.[metricsPeriod]?.value,
      value:
        metricsPeriod === "all"
          ? comments_avg?.mark
          : comments_avg?.performance?.[metricsPeriod]?.mark
    },
    {
      name: "Average views",
      size:
        metricsPeriod === "all" ? views_avg?.value : views_avg?.performance?.[metricsPeriod]?.value
    },
    {
      name: "Average reactions",
      size:
        metricsPeriod === "all"
          ? reactions_avg?.value
          : reactions_avg?.performance?.[metricsPeriod]?.value
    },
    {
      name: "Average likes on videos",
      size:
        metricsPeriod === "all"
          ? alikes_avg?.value
          : (alikes_avg?.performance?.[metricsPeriod]?.value ?? "n/a"),
      value:
        metricsPeriod === "all"
          ? alikes_avg?.mark
          : (alikes_avg?.performance?.[metricsPeriod]?.mark ?? "n/a")
    },
    {
      name: "Average comments on videos",
      size:
        metricsPeriod === "all"
          ? comments_avg?.value
          : comments_avg?.performance?.[metricsPeriod]?.value,
      value:
        metricsPeriod === "all"
          ? comments_avg?.mark
          : comments_avg?.performance?.[metricsPeriod]?.mark
    },
    {
      name: "Likes to comments ratio",
      size:
        metricsPeriod === "all"
          ? comments_likes_ratio?.value
          : comments_likes_ratio?.performance?.[metricsPeriod]?.value,
      value:
        metricsPeriod === "all"
          ? comments_likes_ratio?.mark
          : comments_likes_ratio?.performance?.[metricsPeriod]?.mark
    }
  ];
};

export const getGenderRows = (props: {
  demography_by_age: TiktokReportData["features"]["demography_by_age"];
  subscribers_count: TiktokReportData["metrics"]["subscribers_count"];
}): RowReturnValue[] => {
  const { demography_by_age, subscribers_count } = props;
  return demography_by_age
    .filter(({ gender }) => gender !== Gender.all)
    .map(({ gender, size }) => {
      return {
        name: capitalizeFirstLetter(gender),
        size,
        percentage: calculatePercentages({ of: size, total: subscribers_count.value })
      };
    });
};

export const getAgeRows = (props: {
  demography_by_age: TiktokReportData["features"]["demography_by_age"];
  subscribers_count: TiktokReportData["metrics"]["subscribers_count"];
  type: Gender;
}): RowReturnValue[] => {
  const { demography_by_age = [], subscribers_count, type = "all" } = props;
  return demography_by_age
    .find(({ gender }) => gender === type)
    .by_age_group.map(({ group, size }) => {
      const groupKeyFormatted = group.replace("age", "");
      return {
        name: groupKeyFormatted,
        size,
        percentage: calculatePercentages({ of: size, total: subscribers_count.value })
      };
    });
};

export const getLocationByCountriesRows = (props: {
  subscribers_count: TiktokReportData["metrics"]["subscribers_count"];
  audience_geo: TiktokReportData["features"]["audience_geo"]["data"];
}): RowReturnValue[] => {
  const { subscribers_count, audience_geo } = props;
  return audience_geo.map(({ name, size }) => ({
    name,
    size,
    percentage: calculatePercentages({ of: size, total: subscribers_count.value })
  }));
};
