import { type FC, useEffect, useState } from "react";

import { useSnackbar } from "@relatable/ui/Snackbar";
import { useNavigate, useParams } from "react-router-dom";

import { useMeQuery } from "hooks/generated";

import { ChatView } from "./ChatView";
import {
  AiConversationWithMessagesDocument,
  type AiConversationWithMessagesQuery,
  useAbortAiMutation,
  useAiConversationWithMessagesQuery,
  useCreateNewAiConversationMutation,
  useInsertAiMessageMutation,
  useStartAiMutation
} from "./generated";

export const ChatLogic: FC = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { conversationId } = useParams<{ conversationId: string }>();
  const {
    data: { me } = {}
  } = useMeQuery();

  // we need to provide optimistic response
  const [generating, setGenerating] = useState(false);
  const [messages, setMessages] = useState<
    NonNullable<AiConversationWithMessagesQuery["ai_conversations_by_pk"]>["ai_messages"]
  >([]);

  const {
    data: conversationData,
    loading: loadingData,
    startPolling,
    stopPolling,
    refetch
  } = useAiConversationWithMessagesQuery({
    variables: { conversationId: conversationId ? Number(conversationId) : 0 },
    skip: !conversationId
  });

  useEffect(() => {
    const inProgress = conversationData?.ai_conversations_by_pk?.generating ?? false;
    setGenerating(inProgress);
    setMessages(conversationData?.ai_conversations_by_pk?.ai_messages ?? []);
    if (inProgress) {
      startPolling(900);
    } else {
      stopPolling();
    }
  }, [conversationData, startPolling, stopPolling]);

  const [createConversation, { loading: loadingCreateConversation }] =
    useCreateNewAiConversationMutation({});

  const [insertMessage, { loading: loadingInsertMessage }] = useInsertAiMessageMutation({});

  const [startAi, { loading: loadingStartAI }] = useStartAiMutation({
    refetchQueries: [AiConversationWithMessagesDocument],
    awaitRefetchQueries: true
  });

  const [abortAi, { loading: loadingAbortAI }] = useAbortAiMutation({
    refetchQueries: [AiConversationWithMessagesDocument],
    awaitRefetchQueries: true
  });

  const handleAbort = async () => {
    if (!conversationId) return;
    await abortAi({ variables: { conversationId: Number(conversationId) } });
    setTimeout(() => {
      refetch();
    }, 1500);
  };

  const handleSubmit = async ({ text, temperature }: { text: string; temperature?: number }) => {
    const trimmedText = text.trim();
    if (!trimmedText) {
      snackbar.error("Please enter a question!");
      return;
    }

    if (!me?.admin?.id) return;

    setGenerating(true);
    setMessages(prev => [...prev, { id: -1, role: "user", content: text }]);

    let convId = conversationId ? Number(conversationId) : undefined;
    if (!convId) {
      const { data: res } = await createConversation({
        variables: { accountId: me.admin.id, userQuestion: trimmedText }
      });

      convId = res?.insert_ai_conversations_one?.id ?? undefined;
    } else {
      await insertMessage({ variables: { conversationId: convId, content: trimmedText } });
    }

    if (!convId) {
      setGenerating(false);
      throw new Error("Cannot create conversation!");
    }

    if (!conversationId) {
      navigate(`/chat/${convId}`, { replace: true });
    }

    await startAi({ variables: { conversationId: convId, temperature } });
  };

  return (
    <ChatView
      messages={messages}
      loading={loadingData}
      generating={
        generating ||
        loadingCreateConversation ||
        loadingInsertMessage ||
        loadingStartAI ||
        loadingAbortAI ||
        false
      }
      onAbort={handleAbort}
      onSubmit={handleSubmit}
    />
  );
};
