import type { FC } from "react";

import styled from "@emotion/styled";

import { NavigationButton } from "components/ui/NavigationButton";

export const ErrorPage: FC<{ message?: string; description?: string }> = ({
  message = "Something went wrong.. Please retry",
  description
}) => (
  <Root>
    <h1>😕</h1>
    <p>{message}</p>
    {description && <p>{description}</p>}
    <NavigationButton direction="back" />
  </Root>
);

const Root = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
