import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateProjectInvoiceMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  _set?: Types.InputMaybe<Types.Project_Invoice_Set_Input>;
}>;


export type UpdateProjectInvoiceMutation = { __typename?: 'Mutation', update_project_invoice_by_pk?: { __typename?: 'project_invoice', id: number, name?: string | null, amount?: number | null, invoiced?: boolean | null, invoice_by_date?: string | null, invoice_by_condition?: string | null, invoice_upon: string, accounted_at?: string | null } | null };


export const UpdateProjectInvoiceDocument = gql`
    mutation updateProjectInvoice($id: Int!, $_set: project_invoice_set_input) {
  update_project_invoice_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    name
    amount
    invoiced
    invoice_by_date
    invoice_by_condition
    invoice_upon
    accounted_at
  }
}
    `;
export type UpdateProjectInvoiceMutationFn = Apollo.MutationFunction<UpdateProjectInvoiceMutation, UpdateProjectInvoiceMutationVariables>;

/**
 * __useUpdateProjectInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateProjectInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectInvoiceMutation, { data, loading, error }] = useUpdateProjectInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateProjectInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectInvoiceMutation, UpdateProjectInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectInvoiceMutation, UpdateProjectInvoiceMutationVariables>(UpdateProjectInvoiceDocument, options);
      }
export type UpdateProjectInvoiceMutationHookResult = ReturnType<typeof useUpdateProjectInvoiceMutation>;
export type UpdateProjectInvoiceMutationResult = Apollo.MutationResult<UpdateProjectInvoiceMutation>;
export type UpdateProjectInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateProjectInvoiceMutation, UpdateProjectInvoiceMutationVariables>;