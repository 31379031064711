import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
  useSyncExternalStore
} from "react";

type SubscribeFn = () => void;

const valueCache = new Map<string, any>();
const subscribers = new Map<string, SubscribeFn[]>();

const extStore = {
  get<T>(key: string) {
    // it must return same object when called twice -> it needs caching
    if (valueCache.has(key)) {
      return valueCache.get(key) as T;
    }

    const valueInWindowStorage = window.localStorage.getItem(key);
    if (valueInWindowStorage) {
      try {
        const res = JSON.parse(valueInWindowStorage);
        valueCache.set(key, res);
        return res as T;
      } catch {
        this.remove(key);
      }
    }
    return null;
  },

  subscribe(key: string, callback: SubscribeFn) {
    const arr = subscribers.get(key) || [];
    arr.push(callback);
    subscribers.set(key, arr);
    return () => {
      const callbacks = subscribers.get(key);
      if (!callbacks) return;
      subscribers.set(
        key,
        callbacks.filter(cb => cb !== callback)
      );
    };
  },

  set<T>(key: string, defaultValue: T, newValue: SetStateAction<T>) {
    const oldValue: T = this.get(key) ?? defaultValue;
    const value =
      typeof newValue === "function" ? (newValue as (prev: T) => T)(oldValue) : newValue;

    valueCache.set(key, value);
    const strValue = JSON.stringify(value);
    window.localStorage.setItem(key, strValue);
    this.notify(key);
  },

  notify(key: string) {
    const callbacks = subscribers.get(key);
    if (!callbacks) return;
    callbacks.forEach(cb => cb());
  },

  remove(key: string) {
    window.localStorage.removeItem(key);
    valueCache.delete(key);
    this.notify(key);
  }
};

export const useStoredState = <T>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const prefixedKey = `relatable-${key}`;

  // default value needs to be cached to prevent re-rendering
  const [storedDefaultValue] = useState(defaultValue);

  const storeSubscriber = useCallback(
    (callback: SubscribeFn) => extStore.subscribe(prefixedKey, callback),
    [prefixedKey]
  );

  const storeGetter = useCallback(() => extStore.get(prefixedKey), [prefixedKey]);

  const state = (useSyncExternalStore(storeSubscriber, storeGetter) ?? storedDefaultValue) as T;

  const handleSetState = useCallback(
    (newState: SetStateAction<T>) => {
      extStore.set(prefixedKey, storedDefaultValue, newState);
    },
    [prefixedKey, storedDefaultValue]
  );

  return [state, handleSetState];
};
