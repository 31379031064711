import type { CSSProperties, ReactElement } from "react";

import { Autocomplete as AutocompleteMui } from "@mui/material";
import { TextInput } from "@relatable/ui/TextInput";

export const Autocomplete = <T extends string | number>({
  options,
  value,
  label,
  style,
  onChange,
  isDisabled
}: {
  options: { label: string; id: T }[];
  value: T | undefined | null;
  label: string;
  style?: CSSProperties;
  isDisabled?: boolean;
  onChange(o: T | null): void;
}): ReactElement => (
  <AutocompleteMui
    disabled={isDisabled}
    style={style}
    openOnFocus
    options={options}
    // fallback to empty string to not avoid changing uncontrolled input to controlled
    value={(options.find(o => String(o.id) === String(value)) || null) as any}
    onChange={(_event, newValue) => {
      const id: T = typeof value === "number" ? Number(newValue?.id) : newValue?.id;
      onChange(id || null);
    }}
    renderInput={params => <TextInput onChange={() => null} {...params} label={label} />}
  />
);
