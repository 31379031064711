import { CircularProgress, Tooltip } from "@mui/material";
import { prettifyDate, prettifyNumber } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { Table, type TableColumn } from "@relatable/ui/Table";
import type { FC } from "react";
import { Link, useParams } from "react-router-dom";

import { UpdateStats } from "components/UpdateStats";
import { Center } from "lib/styled";
import { useLinksByKeyQuery } from "modules/links/generated";
import { extractRltToKey } from "modules/links/util";

import { Check, Close, Info } from "@mui/icons-material";
import { PlatformIcon } from "@relatable/ui/icons/PlatformIcon";
import { useUpdateTikTokMutation } from "../Content/TikTok/generated";
import { TikTokNoteCell } from "./TikTokNoteCell";
import {
  CampaignTiktokStatsDocument,
  useCampaignTiktokStatsQuery,
  useUpdateTikTokStatisticsMutation
} from "./generated";

export const TiktoksTable: FC = () => {
  const snackbar = useSnackbar();
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  const { data, loading: tiktoksLoading } = useCampaignTiktokStatsQuery({
    variables: { stubs: campaignStubs }
  });
  const [updateStats] = useUpdateTikTokStatisticsMutation({
    awaitRefetchQueries: true,
    refetchQueries: [CampaignTiktokStatsDocument],
    onError(error) {
      snackbar.error("Sth went wrong when updating a tiktok");
      snackbar.error(error.message);
      console.error(error);
    },
    onCompleted() {
      snackbar.success("Tiktok successfully updated");
    }
  });

  const rltToKeys = (data?.campaigns?.flatMap(c => c.campaign_tiktoks) ?? [])
    .map(p => extractRltToKey(p.track_url))
    .filter(Boolean) as string[];

  const { data: linkData, loading: linksLoading } = useLinksByKeyQuery({
    variables: { keys: rltToKeys },
    skip: !rltToKeys.length
  });

  const [updateTiktok] = useUpdateTikTokMutation({
    awaitRefetchQueries: true,
    refetchQueries: [CampaignTiktokStatsDocument]
  });

  if (!data) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  const rows = data.campaigns.flatMap(campaign =>
    campaign.campaign_tiktoks.map(tiktok => {
      const bitlyClicks = tiktok.link_clicks ?? 0;
      const postRltLink = linkData?.link_clicks.find(
        lc => lc.key === extractRltToKey(tiktok.track_url)
      );
      const clicks = bitlyClicks + (postRltLink?.clicks ?? 0);

      return {
        ...tiktok,
        lockedStatsAt: prettifyDate(tiktok.lock_statistics_at) || "",
        key: tiktok.id,
        username: tiktok.user_tiktok.username,
        id: tiktok.id,
        isBusinessTokenValid: Boolean(tiktok.user_tiktok.access_tokens_tiktok?.is_valid),
        userId: tiktok.user_tiktok?.user?.id,
        campaignStub: campaign.stub,
        totalReach: tiktok.user_tiktok.followed_by,
        comments: tiktok.comment_count,
        notFound: tiktok.not_found,
        clicks
      };
    })
  );

  const columns: TableColumn<typeof rows>[] = [
    {
      headerName: "User",
      field: "username",
      minWidth: 200,
      renderCell: ({ row, value }) => (
        <Link to={`/user/${row.userId}`}>
          {row.approved ? (
            <Check style={{ marginRight: 10, color: palette.secondary.green }} />
          ) : (
            <Close style={{ marginRight: 10, color: palette.secondary.red }} />
          )}
          {value}
        </Link>
      )
    },
    {
      headerName: "TikTok",
      field: "user_tiktok",
      minWidth: 150,
      renderCell: ({ row }) => {
        const tiktokIcon = row.shortcode ? (
          <Link
            style={{ display: "inline-block", verticalAlign: "middle", marginRight: 5 }}
            target="_blank"
            rel="noreferrer"
            to={`https://www.tiktok.com/@${row.username}/video/${row.shortcode}`}
          >
            <PlatformIcon platform={"tiktok"} color={palette.social.tiktok} />
          </Link>
        ) : null;

        if (row.notFound) {
          return (
            <Tooltip
              arrow
              title="This tiktok is not found, meaning, the user most probably removed the tiktok from their feed."
            >
              <>
                {tiktokIcon}
                <Link
                  style={{ color: row.notFound ? palette.primary.gold : undefined }}
                  to={`/campaign/${row.campaignStub}/content/tiktok/${row.id}`}
                >
                  View TikTok ⛔️
                </Link>
              </>
            </Tooltip>
          );
        }

        return (
          <>
            {tiktokIcon}
            <Link to={`/campaign/${row.campaignStub}/content/tiktok/${row.id}`}>View TikTok</Link>
          </>
        );
      }
    },
    {
      headerName: "Prod note",
      field: "notes",
      renderCell: ({ value, row }) => <TikTokNoteCell note={value} campaignTikTokId={row.key} />
    },
    {
      field: "lock_statistics_at",
      headerName: "Statistics",
      minWidth: 75,
      renderCell: ({ value, row }) => (
        <UpdateStats
          onUpdate={() => updateStats({ variables: { campaignTikTokId: row.id } })}
          lockStats={{
            isTokenValid: row.isBusinessTokenValid,
            lockedAt: value,
            onToggle: lock_statistics =>
              updateTiktok({
                variables: {
                  id: row.id,
                  set: { lock_statistics_at: lock_statistics ? new Date().toISOString() : null }
                }
              })
          }}
        />
      )
    },
    {
      headerName: "Locked",
      field: "lockedStatsAt",
      minWidth: 170
    },

    {
      headerName: "Followers",
      field: "totalReach",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Total viewers",
      field: "actual_reach",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Views",
      field: "views",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    { headerName: "Likes", field: "likes", renderCell: ({ value }) => prettifyNumber(value) },
    { headerName: "Comments", field: "comments", renderCell: ({ value }) => prettifyNumber(value) },
    { headerName: "Saves", field: "saves", renderCell: ({ value }) => prettifyNumber(value) },
    { headerName: "Shares", field: "shares", renderCell: ({ value }) => prettifyNumber(value) },
    {
      headerName: "Link clicks",
      field: "clicks",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Personal profile rate",
      field: "personal_profile_rate",
      minWidth: 170,
      renderCell: ({ value }) => prettifyNumber(value ? value / 100 : 0, { percentages: true })
    },
    {
      headerName: "For You page rate",
      field: "for_you_page_rate",
      minWidth: 170,
      renderCell: ({ value }) => prettifyNumber(value ? value / 100 : 0, { percentages: true })
    },
    {
      headerName: "Following rate",
      field: "following_rate",
      renderCell: ({ value }) => prettifyNumber(value ? value / 100 : 0, { percentages: true })
    },
    {
      headerName: "Completion rate",
      field: "completion_rate",
      renderCell: ({ value }) => prettifyNumber(value ? value / 100 : 0, { percentages: true })
    },
    {
      headerName: "Average watch time",
      field: "average_watch_time",
      minWidth: 150,
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Total time watched",
      field: "total_time_watched",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Video duration",
      field: "duration",
      renderCell: ({ value }) => `${prettifyNumber(value)}s`
    },
    {
      headerName: "Impression sources",
      field: "impression_sources",
      renderCell: ({ value: val }) => {
        if (!val) return "-";
        const value = Array.isArray(val) ? val : [val];
        const title = value
          .map(v => {
            const [key, percentage] = v.split("|#|");
            return `${key} ${prettifyNumber(Number(percentage), {
              percentages: true
            })}`;
          })
          .join("\n");

        return <Info style={{ color: palette.secondary.blue }} />;
      }
    },
    {
      headerName: "Audience countries",
      field: "audience_countries",
      renderCell: ({ value: val }) => {
        if (!val) return "-";
        const value = Array.isArray(val) ? val : [val];
        const title = value
          .map(v => {
            const [key, percentage] = v.split("|#|");
            return `${key} ${prettifyNumber(Number(percentage), {
              percentages: true
            })}`;
          })
          .join("\n");

        return <Info style={{ color: palette.secondary.blue }} />;
      }
    },
    {
      headerName: "Engagement Total Viewers",
      field: "actual_reach",
      minWidth: 170,
      renderCell: ({ row }) =>
        prettifyNumber(
          ((row.likes || 0) + (row.comments || 0) + (row.shares || 0) + (row.saves || 0)) /
            (row.actual_reach || 0),
          { percentages: true }
        )
    },
    {
      headerName: "Engagement Followers",
      field: "totalReach",
      minWidth: 170,
      renderCell: ({ row }) =>
        prettifyNumber(
          ((row.likes || 0) + (row.comments || 0) + (row.shares || 0) + (row.saves || 0)) /
            (row.totalReach || 0),
          { percentages: true }
        )
    }
  ];

  return (
    <Table
      tableId="campaign-tiktoks"
      rows={rows}
      columns={columns}
      loading={tiktoksLoading || linksLoading}
      sortable
      canSelectColumns
      canExportCSV
      stickyColumn
    />
  );
};
