import styled from "@emotion/styled";

import { AGE_SECTION_TYPES } from "modules/hypeAuditor/common/constants";

export const AccountSummary = ({
  username,
  audience_interests,
  demography_by_age,
  audience_geography,
  audience_unavailability_reason
}) => {
  const audienceInterests = audience_interests.map(([name, value]) => ({
    name: name.replace("&amp;", "&"),
    value
  }));
  const topGender = demography_by_age
    .filter(da => da.gender !== AGE_SECTION_TYPES.ALL)
    .sort((a, b) => b.value - a.value)[0];
  const topGenderAgeGroup = [...topGender.by_age_group].sort((a, b) => b.value - a.value)[0];
  const topCountry = audience_geography?.countries ? audience_geography?.countries[0] : null;

  return (
    <Root>
      {audience_unavailability_reason || (
        <>
          <strong>@{username}</strong> is followed by a majority of{" "}
          <strong>{topGender.gender}s</strong> aged{" "}
          <strong>{topGenderAgeGroup?.group.replace("age", "")}</strong>{" "}
          {topCountry ? (
            <>
              living in <strong>{topCountry.name}</strong>{" "}
            </>
          ) : null}
          {audienceInterests.length > 0 ? (
            <>
              who are interested in <strong>{audienceInterests[0].name}</strong>,{" "}
              <strong>{audienceInterests[1].name}</strong> and{" "}
              <strong>{audienceInterests[2].name}</strong>
            </>
          ) : null}
        </>
      )}
    </Root>
  );
};

const Root = styled.p`
  margin: 30px 0;
  font-size: 17px;
`;
