import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";

import { NavigationButton } from "components/ui/NavigationButton";
import { Title } from "lib/styled";

import { SectionTable } from "../common/SectionTable";
import * as constants from "../common/constants";
import { AQS } from "./common/AQS";
import { AccountSummary } from "./common/AccountSummary";
import {
  getAgeRows,
  getAudienceInterestsRows,
  getAudienceTypeRows,
  getBrandMentionCategoriesRows,
  getEngagementRows,
  getEthnicityRows,
  getGenderRows,
  getGrowthRows,
  getLocationByCitiesRows,
  getLocationByCountriesRows,
  getLocationByStatesRows,
  getNativeLanguageRows,
  getQualityAudienceRows
} from "./utils/rows";

const { AGE_SECTION_TYPES } = constants;

export const InstagramReport = ({ data }) => {
  const {
    username,
    aqs,
    aqs_name,
    aqs_description,
    followers_count,
    audience_type,
    demography_by_age,
    advertising_data,
    yearly_growth,
    comments_authenticity,
    comments_rate,
    avg_comments,
    er,
    audience_ethnicity,
    audience_languages,
    audience_geography,
    audience_interests,
    audience_unavailability_reason
  } = data;

  const { state: locationState } = useLocation() as { state: { from: string } };

  return (
    <Root>
      {["/hypeauditor/instagram/aggregated"].includes(locationState?.from) && (
        <NavigationButton direction="back" className="nav-button" />
      )}

      <Title>
        Account analysis for{" "}
        <Link
          to={`https://app.hypeauditor.com/instagram/${username}/`}
          rel="noreferrer"
          target="_blank"
        >
          @{username}
        </Link>
      </Title>
      <AccountSummary
        username={username}
        audience_interests={audience_interests}
        demography_by_age={demography_by_age}
        audience_geography={audience_geography}
        audience_unavailability_reason={audience_unavailability_reason}
      />

      <AQS score={aqs} name={aqs_name} description={aqs_description} />

      <SectionTable
        sectionName="Quality audience"
        rows={getQualityAudienceRows({ followers_count, audience_type })}
      />
      <SectionTable
        sectionName="Audience type"
        rows={getAudienceTypeRows({ followers_count, audience_type })}
      />
      <SectionTable
        sectionName="Engagement"
        rows={getEngagementRows({
          er,
          advertising_data,
          comments_authenticity,
          comments_rate,
          avg_comments
        })}
      />
      {advertising_data && (
        <SectionTable
          sectionName="Brand mention categories"
          rows={getBrandMentionCategoriesRows(advertising_data)}
        />
      )}
      <SectionTable sectionName="Growth" rows={getGrowthRows({ yearly_growth })} />

      <Title>Audience demographics</Title>
      <SectionTable
        sectionName="Gender"
        rows={getGenderRows({ demography_by_age, followers_count })}
      />
      <SectionTable
        sectionName="Age - all"
        rows={getAgeRows({ demography_by_age, followers_count, type: AGE_SECTION_TYPES.ALL })}
      />
      <SectionTable
        sectionName="Age - male"
        rows={getAgeRows({ demography_by_age, followers_count, type: AGE_SECTION_TYPES.MALE })}
        defaultOpen={false}
      />
      <SectionTable
        sectionName="Age - female"
        rows={getAgeRows({ demography_by_age, followers_count, type: AGE_SECTION_TYPES.FEMALE })}
        defaultOpen={false}
      />
      <SectionTable
        sectionName="Ethnicity"
        rows={getEthnicityRows({ audience_ethnicity, followers_count })}
        defaultOpen={false}
      />
      <SectionTable
        sectionName="Native language"
        rows={getNativeLanguageRows({ audience_languages, followers_count })}
        defaultOpen={false}
      />
      {audience_geography?.countries && (
        <SectionTable
          sectionName="Location: by country"
          rows={getLocationByCountriesRows({
            countries: audience_geography.countries,
            followers_count
          })}
        />
      )}
      {audience_geography?.states && (
        <SectionTable
          sectionName="Location: by US state"
          rows={getLocationByStatesRows({
            states: audience_geography.states,
            followers_count
          })}
          defaultOpen={false}
        />
      )}
      {audience_geography?.cities && (
        <SectionTable
          sectionName="Location: by city"
          rows={getLocationByCitiesRows({
            cities: audience_geography.cities,
            followers_count
          })}
        />
      )}

      <SectionTable
        sectionName="Interests"
        rows={getAudienceInterestsRows({ audience_interests, followers_count })}
      />
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
  margin: auto;

  .nav-button {
    position: absolute;
    left: 0px;
    top: 23px;
  }
`;
