import { type FC, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { Button, ButtonGroup, Chip, CircularProgress, Grid, Typography } from "@mui/material";
import { prettifyDate, truncateFileName } from "@relatable/helpers";
import { Checkbox } from "@relatable/ui/Checkbox";
import { DateInput } from "@relatable/ui/DateInput";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useParams } from "react-router-dom";

import { Popup } from "components/ui/Popup";
import { UploadInput } from "components/ui/UploadInput";
import { useSignUploadUrlMutation } from "modules/generated";

import { ArrowDownward, ArrowUpward, Check, Delete, InsertDriveFile } from "@mui/icons-material";
import { CampaignSettingTimeFramesDocument } from "./StoriesTable/generated";
import {
  useInsertStoryClipMutation,
  useInsertStoryMutation,
  useStoriesUserInstagramNamesQuery
} from "./generated";

const useUploadStoryClip = () => {
  const [insertStoryClip] = useInsertStoryClipMutation();
  const [signUploadUrl] = useSignUploadUrlMutation();
  return async ({
    fileName,
    user,
    fileType,
    file,
    creationDate,
    contentSettingId,
    storyId
  }: {
    user: { name: string; id: number };
    fileName: string;
    fileType: string;
    file: File;
    creationDate: Date;
    contentSettingId: null | number;
    storyId: number;
  }) => {
    const { data } = await signUploadUrl({
      variables: {
        input: {
          fileName: `${user.name}/${user.name}-Story-${new Date().getTime()}-${fileName}`,
          fileType,
          prefix: "CAMPAIGN_STORIES"
        }
      }
    });

    if (!data) {
      throw new Error("Something went wrong when signing upload Url");
    }

    const { signedUploadUrl, url } = data.signUploadUrl;

    await fetch(signedUploadUrl, {
      method: "PUT",
      body: file,
      headers: { "content-type": file.type }
    });

    await insertStoryClip({
      variables: {
        object: {
          user_id: user.id,
          created_at: creationDate.toISOString(),
          filename: url,
          thumbnail: url,
          content_setting_id: contentSettingId,
          campaign_story_id: storyId
        }
      }
    });
  };
};

type FileStatus = "uploading" | "awaiting upload" | "uploaded";

const StatusIcon: FC<{ status: FileStatus }> = ({ status }) => {
  if (status === "uploaded") {
    return <Check style={{ marginRight: 5, color: palette.secondary.green }} />;
  }
  if (status === "uploading") return <CircularProgress size={10} style={{ marginRight: 5 }} />;
  return null;
};

export const UploadClipsPopup: FC<{ onClose(): void; onUpload(): void }> = ({
  onClose,
  onUpload
}) => {
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const snackbar = useSnackbar();
  const apolloClient = useApolloClient();

  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [creationDate, setCreationDate] = useState(new Date().toISOString());
  const [approveClips, setApproveClips] = useState(true);
  const [files, setFiles] = useState<{ content: File; status: FileStatus }[]>([]);
  const [contentSettingId, setContentSettingId] = useState<number | null>(null);

  const { data } = useStoriesUserInstagramNamesQuery({ variables: { stub: campaignStub ?? "" } });
  const campaign = data?.campaign[0];
  const campaignUsers = campaign?.campaign_users || [];
  const users = campaignUsers?.map(({ user }) => ({
    id: user?.id || "",
    name: user?.user_instagram?.username || ""
  }));

  const uploadStoryClip = useUploadStoryClip();
  const [createStory] = useInsertStoryMutation();

  const handleSubmit = async () => {
    setFiles(files.map(file => ({ ...file, status: "uploading" })));
    const user = users.find(u => u.id === selectedUserId);

    if (!user || !selectedUserId) throw new Error("selected user is invalid");
    if (!campaign) throw new Error("no campaign");

    let storyId = campaign?.campaign_stories.find(
      i => i.user_id === user.id && i.content_setting_id === contentSettingId
    )?.id;

    if (!storyId) {
      try {
        const createdStory = await createStory({
          variables: {
            object: {
              campaign_id: campaign.id,
              content_setting_id: contentSettingId,
              user_id: selectedUserId
            }
          }
        });
        storyId = createdStory.data?.insert_campaign_story_one?.id;
      } catch (e) {
        console.error(e);
        snackbar.error(e.message);
      }
    }
    if (!storyId) throw new Error("no storyId");

    // to keep the order of uploading files we cannot use Promise.all
    for (const index in files) {
      const file = files[index];
      if (!user.id) return;
      await uploadStoryClip({
        user: { id: Number(user.id), name: user.name },
        fileName: file.content.name,
        fileType: file.content.type,
        creationDate: new Date(creationDate),
        file: file.content,
        contentSettingId,
        storyId
      });

      setFiles(prev =>
        prev.map(prevFile =>
          prevFile.content.name + prevFile.content.lastModified ===
          file.content.name + file.content.lastModified
            ? { ...file, status: "uploaded" }
            : prevFile
        )
      );
    }

    await apolloClient.refetchQueries({ include: [CampaignSettingTimeFramesDocument] });

    onUpload();
    onClose();
  };

  return (
    <Popup
      disabledAction={!files.length || !selectedUserId}
      onAction={handleSubmit}
      actionLabel="Submit"
      title="Upload story clips"
      onClose={onClose}
    >
      <Select
        required
        label="User"
        value={selectedUserId || ""}
        onChange={v => setSelectedUserId(Number(v))}
        options={users.map(user => ({
          value: user.id,
          label: user.name || ""
        }))}
      />

      <DateInput value={creationDate} onChange={setCreationDate} />

      <Checkbox
        style={{ marginLeft: 0 }}
        label="Mark clips as approved"
        checked={approveClips}
        onChange={setApproveClips}
      />

      {approveClips && (
        <Select
          required
          label="Content setting [story]"
          value={contentSettingId}
          onChange={v => setContentSettingId(v)}
          options={
            campaign?.campaign_content_settings.map(setting => ({
              value: setting.id,
              label: `${prettifyDate(setting.publish_date_min)} - ${prettifyDate(
                setting.publish_date_max
              )}`
            })) || []
          }
        />
      )}

      <UploadInput
        multiple
        accept={["videos", "images"]}
        onChange={f =>
          setFiles(f.map(file => ({ content: file, status: "awaiting upload" as const })))
        }
      />

      <Typography>
        It is important to upload the files in chronological order. You can use the buttons bellow
        to reorder the files if the order is wrong.
      </Typography>

      {files.map((file, index) => (
        <Grid
          justifyContent="space-between"
          container
          spacing={4}
          key={file.content.name + file.content.lastModified}
        >
          <Grid item>
            <Chip
              onDelete={() => null}
              deleteIcon={<StatusIcon status={file.status} />}
              label={truncateFileName(file.content.name)}
              icon={<InsertDriveFile style={{ color: palette.primary.gold }} />}
            />
          </Grid>

          <Grid item>
            <ButtonGroup>
              <Button
                variant="text"
                disabled={index === 0}
                onClick={() => {
                  const newList = [...files];
                  const upperEl = files[index - 1];
                  newList[index] = upperEl;
                  newList[index - 1] = file;
                  setFiles(newList);
                }}
                endIcon={
                  <ArrowUpward
                    style={{ color: index === 0 ? palette.gray[50] : palette.secondary.blue }}
                  />
                }
              >
                Up
              </Button>
              <Button
                variant="text"
                disabled={index === files.length - 1 || (approveClips && !contentSettingId)}
                onClick={() => {
                  const newList = [...files];
                  const lowerEl = files[index + 1];
                  newList[index] = lowerEl;
                  newList[index + 1] = file;
                  setFiles(newList);
                }}
                endIcon={
                  <ArrowDownward
                    style={{
                      color: index === files.length - 1 ? palette.gray[50] : palette.secondary.blue
                    }}
                  />
                }
              >
                Down
              </Button>
              <Button
                onClick={() =>
                  setFiles(prev =>
                    prev.filter(
                      f =>
                        f.content.name !== file.content.name &&
                        f.content.lastModified !== file.content.lastModified
                    )
                  )
                }
                endIcon={<Delete style={{ color: palette.primary.red }} />}
                variant="text"
              >
                Delete
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      ))}
    </Popup>
  );
};
