import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { prettifyNumber } from "@relatable/helpers/format";
import { rndLongString } from "@relatable/helpers/index";
import { Button } from "@relatable/ui/Button";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { useNavigate } from "react-router-dom";

import { type LinkDetailsQuery, LinksDocument, useUpsertLinkMutation } from "./generated";
import { validateRltKey, validateURL, withHttps } from "./util";

export const LinkEditorFields: FC<{
  details: LinkDetailsQuery["link_clicks_by_pk"];
}> = ({ details }) => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [fields, setFields] = useState({
    key: details?.key ?? rndLongString(4),
    target_url: details?.target_url ?? ""
  });

  const getError = (values: Partial<LinkDetailsQuery["link_clicks_by_pk"]>) => {
    if (!values) return "Invalid parameter";

    const mandatoryFields: (keyof typeof fields)[] = ["key", "target_url"];

    for (const field of mandatoryFields) {
      if (field in values && !values[field]) return `${field} is mandatory`;
    }

    if (!validateRltKey(values.key ?? "")) {
      return "Invalid URL key format. Only lowercase letters, numbers and `_` `-` characters are allowed";
    }

    if (!validateURL(withHttps(values.target_url ?? ""))) {
      return "Invalid client URL";
    }

    return "";
  };

  const handleFieldChange = (values: Partial<typeof fields>) => {
    if (!values) return;

    setFields(prev => ({
      ...prev,
      ...values
    }));
  };

  const [upsertLink, { loading }] = useUpsertLinkMutation({
    onError(err) {
      snackbar.error(err.message);
    }
  });

  const handleUpsert = async () => {
    const trimmedFields = Object.fromEntries(
      Object.entries(fields).map(([k, v]) => (typeof v === "string" ? [k, v.trim()] : [k, v]))
    );

    const error = getError(trimmedFields);
    if (error) {
      snackbar.error(error);
      return;
    }

    await upsertLink({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: LinksDocument }],
      variables: {
        set: {
          id: details?.id ?? undefined,
          ...trimmedFields,
          target_url: withHttps(trimmedFields.target_url)
        }
      },
      onCompleted: () => {
        snackbar.success("Link was updated");
      },
      onError: err => {
        if (err.graphQLErrors?.[0]?.extensions?.code === "constraint-violation") {
          console.error(err);
          snackbar.error("This shortlink is already taken");
        } else {
          throw err;
        }
      }
    }).then(() => {
      navigate("/links");
    });
  };

  return (
    <>
      <h2 style={{ marginBottom: 20 }}>
        {details ? <span>Editing rlt.to/{details.key}</span> : <span>Create new link</span>}
      </h2>

      <Container>
        <TextInput
          required
          disabled={Boolean(details?.id)}
          label={details ? "Relatable shortlink" : "Relatable shortlink (editable)"}
          value={fields.key ?? ""}
          onChange={v => handleFieldChange({ key: v })}
        />

        <TextInput
          label="Client URL"
          value={fields.target_url ?? ""}
          onChange={v => handleFieldChange({ target_url: v })}
        />

        <TextInput disabled label="Clicks" value={prettifyNumber(details?.clicks ?? 0)} />
      </Container>

      <Button
        size="medium"
        style={{ marginLeft: "auto", marginTop: 10 }}
        isLoading={loading}
        onClick={handleUpsert}
      >
        Save
      </Button>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
