import type { FC } from "react";

import {
  Box,
  type CircularProgressProps,
  CircularProgress as MuiCircularProgress,
  Typography
} from "@mui/material";

export const CircularProgress: FC<CircularProgressProps & { value: number }> = props => (
  <Box sx={{ position: "relative", display: "inline-flex" }}>
    <MuiCircularProgress variant="determinate" {...props} />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Typography variant="caption" component="div" color="text.secondary">
        {`${Math.round(props.value)}%`}
      </Typography>
    </Box>
  </Box>
);
