import type { CSSProperties, FC } from "react";

import { type InputProps, TextField, type TextFieldProps } from "@mui/material";

export const DateInput: FC<{
  value: string | null;
  onChange(v: string): void;
  label?: string;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  size?: InputProps["size"];
  color?: TextFieldProps["color"];
}> = ({ value, onChange, label, style, inputStyle, size, color }) => (
  <TextField
    style={style}
    color={color}
    size={size}
    type="date"
    inputProps={{
      style: { height: "auto", ...inputStyle }
    }}
    InputLabelProps={{ shrink: true }}
    aria-label={label}
    label={label}
    value={value}
    onChange={e => onChange(e.target.value)}
  />
);
