import styled from "@emotion/styled";

import { PriorityHigh, ThumbDown, ThumbUp } from "@mui/icons-material";
import { palette } from "@relatable/ui/Palette";

import { STATE_TYPES } from "lib/constants";

const StatusMessageBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  width: 100%;
  height: auto;
  min-height: 85px;
  border: solid 1px ${palette.gray[50]};
  align-items: center;
  padding-left: 90px;

  > .status-icon {
    position: absolute;
    height: 100%;
    padding: 10px 6px;
    left: 0;
    width: 65px;
  }
`;

const getIcon = (type: string) => {
  switch (type) {
    case STATE_TYPES.INVALID:
      return <ThumbDown className="status-icon" style={{ color: palette.primary.gold }} />;

    case STATE_TYPES.VALID:
      return <ThumbUp className="status-icon" style={{ color: palette.primary.green }} />;

    default:
      return <PriorityHigh className="status-icon" style={{ color: palette.gray[50] }} />;
  }
};

export const ContentFeedbackState = ({ type = "info", children }) => (
  <StatusMessageBox>
    {getIcon(type)}
    <div>{children}</div>
  </StatusMessageBox>
);
