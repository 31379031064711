import { useEffect, useMemo, useState } from "react";

import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useDebounce } from "@relatable/ui/hooks/useDebounce";

import { useSearchCampaignsLazyQuery } from "../Timeline/generated";

export const CampaignDropdown: React.FC<{
  campaignId: number | null;
  onSelect: (campaignId: number | null) => void;
  label?: string;
}> = ({ campaignId, onSelect, label }) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string | null>(null);
  const debouncedQuery = useDebounce(query, 700);

  const [searchCampaigns, { data: searchCampaignsData, loading }] = useSearchCampaignsLazyQuery();
  useEffect(() => {
    const titleLike = `%${(debouncedQuery ?? "").replace(/\s+/g, "%")}%`;
    searchCampaigns({
      variables: {
        where: { title: { _ilike: titleLike } }
      }
    });
  }, [searchCampaigns, debouncedQuery]);

  // memo is required to prevent reset of internal state in <Autocomplete />
  const listOptions = useMemo(() => {
    const campaigns = searchCampaignsData?.campaigns ?? [];

    return campaigns.map(p => ({
      id: p.id,
      label: `${p.title} (${p.project?.name ?? "?"})`
    }));
  }, [searchCampaignsData?.campaigns]);

  const value = campaignId ? (listOptions.find(l => l.id === campaignId) ?? null) : null;

  return (
    <Autocomplete
      disablePortal
      options={listOptions}
      fullWidth
      onChange={(e, v) => (v?.id ? onSelect(v.id) : null)}
      value={value}
      inputValue={(open ? query : value?.label) ?? ""}
      onInputChange={(e, v, reason) => {
        if (reason === "input") setQuery(v);
        if (reason === "clear") onSelect(null);
      }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading}
      loadingText="Loading..."
      isOptionEqualToValue={(option, v) => option.id === v.id}
      autoSelect
      /* Built-in filtering needs to be disabled at lazy loaded requests */
      filterOptions={x => x}
      renderInput={params => (
        <TextField
          {...params}
          label={label ?? "Select campaign"}
          InputLabelProps={{ style: { marginTop: -6 } }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};
