import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendEmailToCampaignUserMutationVariables = Types.Exact<{
  campaignUserId: Types.Scalars['Int']['input'];
  template: Types.Scalars['String']['input'];
}>;


export type SendEmailToCampaignUserMutation = { __typename?: 'Mutation', sendCreatorEmail?: { __typename?: 'SendCreatorEmailReturn', error?: string | null } | null };

export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  set: Types.Users_Set_Input;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', update_users_by_pk?: { __typename?: 'users', id: number } | null };

export type SignUploadUrlMutationVariables = Types.Exact<{
  input: Types.SignUploadUrlInput;
}>;


export type SignUploadUrlMutation = { __typename?: 'Mutation', signUploadUrl: { __typename?: 'SingedUpload', url: string, signedUploadUrl: string, signedDownloadUrl: string } };

export type VerticalsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type VerticalsQuery = { __typename?: 'Query', vertical: Array<{ __typename?: 'vertical', id: number, name: string }> };

export type TagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TagsQuery = { __typename?: 'Query', user_tag_type: Array<{ __typename?: 'user_tag_type', id: number, name: string }> };


export const SendEmailToCampaignUserDocument = gql`
    mutation sendEmailToCampaignUser($campaignUserId: Int!, $template: String!) {
  sendCreatorEmail(campaign_user_id: $campaignUserId, template: $template) {
    error
  }
}
    `;
export type SendEmailToCampaignUserMutationFn = Apollo.MutationFunction<SendEmailToCampaignUserMutation, SendEmailToCampaignUserMutationVariables>;

/**
 * __useSendEmailToCampaignUserMutation__
 *
 * To run a mutation, you first call `useSendEmailToCampaignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailToCampaignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailToCampaignUserMutation, { data, loading, error }] = useSendEmailToCampaignUserMutation({
 *   variables: {
 *      campaignUserId: // value for 'campaignUserId'
 *      template: // value for 'template'
 *   },
 * });
 */
export function useSendEmailToCampaignUserMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailToCampaignUserMutation, SendEmailToCampaignUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailToCampaignUserMutation, SendEmailToCampaignUserMutationVariables>(SendEmailToCampaignUserDocument, options);
      }
export type SendEmailToCampaignUserMutationHookResult = ReturnType<typeof useSendEmailToCampaignUserMutation>;
export type SendEmailToCampaignUserMutationResult = Apollo.MutationResult<SendEmailToCampaignUserMutation>;
export type SendEmailToCampaignUserMutationOptions = Apollo.BaseMutationOptions<SendEmailToCampaignUserMutation, SendEmailToCampaignUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: Int!, $set: users_set_input!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const SignUploadUrlDocument = gql`
    mutation signUploadUrl($input: SignUploadUrlInput!) {
  signUploadUrl(input: $input) {
    url
    signedUploadUrl
    signedDownloadUrl
  }
}
    `;
export type SignUploadUrlMutationFn = Apollo.MutationFunction<SignUploadUrlMutation, SignUploadUrlMutationVariables>;

/**
 * __useSignUploadUrlMutation__
 *
 * To run a mutation, you first call `useSignUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUploadUrlMutation, { data, loading, error }] = useSignUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<SignUploadUrlMutation, SignUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUploadUrlMutation, SignUploadUrlMutationVariables>(SignUploadUrlDocument, options);
      }
export type SignUploadUrlMutationHookResult = ReturnType<typeof useSignUploadUrlMutation>;
export type SignUploadUrlMutationResult = Apollo.MutationResult<SignUploadUrlMutation>;
export type SignUploadUrlMutationOptions = Apollo.BaseMutationOptions<SignUploadUrlMutation, SignUploadUrlMutationVariables>;
export const VerticalsDocument = gql`
    query verticals {
  vertical {
    id
    name
  }
}
    `;

/**
 * __useVerticalsQuery__
 *
 * To run a query within a React component, call `useVerticalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerticalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerticalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVerticalsQuery(baseOptions?: Apollo.QueryHookOptions<VerticalsQuery, VerticalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerticalsQuery, VerticalsQueryVariables>(VerticalsDocument, options);
      }
export function useVerticalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerticalsQuery, VerticalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerticalsQuery, VerticalsQueryVariables>(VerticalsDocument, options);
        }
export function useVerticalsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VerticalsQuery, VerticalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerticalsQuery, VerticalsQueryVariables>(VerticalsDocument, options);
        }
export type VerticalsQueryHookResult = ReturnType<typeof useVerticalsQuery>;
export type VerticalsLazyQueryHookResult = ReturnType<typeof useVerticalsLazyQuery>;
export type VerticalsSuspenseQueryHookResult = ReturnType<typeof useVerticalsSuspenseQuery>;
export type VerticalsQueryResult = Apollo.QueryResult<VerticalsQuery, VerticalsQueryVariables>;
export const TagsDocument = gql`
    query tags {
  user_tag_type {
    id
    name
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export function useTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsSuspenseQueryHookResult = ReturnType<typeof useTagsSuspenseQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;