type Entries<T> = [keyof T, ValueOf<T>][];
type Keys<T> = Array<keyof T>;
type ValueOf<T> = T[keyof T];

// Same as `Object.entries()` but with type inference
export function objectEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>;
}

// Same as `Object.keys()` but with type inference
export function objectKeys<T extends object>(obj: T): Keys<T> {
  return Object.keys(obj) as Keys<T>;
}

// Same as `Object.values()` but with type inference
export function objectValues<T extends object>(obj: T): ValueOf<T>[] {
  return Object.values(obj) as ValueOf<T>[];
}
