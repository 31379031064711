import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import { UserInfoFragmentDoc, UserProfilePictureFragmentDoc, MediaStateFragmentDoc, CaptionStateFragmentDoc, IntercomFragmentDoc } from '../../../../../packages/gql/generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LinksQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Link_Clicks_Bool_Exp>;
}>;


export type LinksQuery = { __typename?: 'Query', link_clicks: Array<{ __typename?: 'link_clicks', id: number, key: string, clicks: number, created_at: string, target_url: string }> };

export type LinkDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type LinkDetailsQuery = { __typename?: 'Query', link_clicks_by_pk?: { __typename?: 'link_clicks', id: number, key: string, clicks: number, created_at: string, target_url: string } | null };

export type LinksByKeyQueryVariables = Types.Exact<{
  keys: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type LinksByKeyQuery = { __typename?: 'Query', link_clicks: Array<{ __typename?: 'link_clicks', id: number, key: string, clicks: number }> };

export type LinksByCampaignQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['Int']['input'];
}>;


export type LinksByCampaignQuery = { __typename?: 'Query', campaign_user: Array<{ __typename?: 'campaign_user', id: number, tracking?: any | null }> };

export type LinkUsageQueryVariables = Types.Exact<{
  like: Types.Scalars['String']['input'];
}>;


export type LinkUsageQuery = { __typename?: 'Query', campaign_user: Array<{ __typename?: 'campaign_user', id: number, tracking?: any | null, campaign: { __typename?: 'campaign', id: number, stub: string, title: string }, user?: { __typename?: 'users', id: number, user_youtube?: { __typename?: 'users_youtube', id: number, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null } | null, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null } | null }> };

export type UpsertLinkMutationVariables = Types.Exact<{
  set: Types.Link_Clicks_Insert_Input;
}>;


export type UpsertLinkMutation = { __typename?: 'Mutation', insert_link_clicks_one?: { __typename?: 'link_clicks', id: number } | null };


export const LinksDocument = gql`
    query links($where: link_clicks_bool_exp) {
  link_clicks(where: $where, order_by: {created_at: desc}, limit: 30) {
    id
    key
    clicks
    created_at
    target_url
  }
}
    `;

/**
 * __useLinksQuery__
 *
 * To run a query within a React component, call `useLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLinksQuery(baseOptions?: Apollo.QueryHookOptions<LinksQuery, LinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinksQuery, LinksQueryVariables>(LinksDocument, options);
      }
export function useLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinksQuery, LinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinksQuery, LinksQueryVariables>(LinksDocument, options);
        }
export function useLinksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LinksQuery, LinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LinksQuery, LinksQueryVariables>(LinksDocument, options);
        }
export type LinksQueryHookResult = ReturnType<typeof useLinksQuery>;
export type LinksLazyQueryHookResult = ReturnType<typeof useLinksLazyQuery>;
export type LinksSuspenseQueryHookResult = ReturnType<typeof useLinksSuspenseQuery>;
export type LinksQueryResult = Apollo.QueryResult<LinksQuery, LinksQueryVariables>;
export const LinkDetailsDocument = gql`
    query linkDetails($id: Int!) {
  link_clicks_by_pk(id: $id) {
    id
    key
    clicks
    created_at
    target_url
  }
}
    `;

/**
 * __useLinkDetailsQuery__
 *
 * To run a query within a React component, call `useLinkDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLinkDetailsQuery(baseOptions: Apollo.QueryHookOptions<LinkDetailsQuery, LinkDetailsQueryVariables> & ({ variables: LinkDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinkDetailsQuery, LinkDetailsQueryVariables>(LinkDetailsDocument, options);
      }
export function useLinkDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkDetailsQuery, LinkDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinkDetailsQuery, LinkDetailsQueryVariables>(LinkDetailsDocument, options);
        }
export function useLinkDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LinkDetailsQuery, LinkDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LinkDetailsQuery, LinkDetailsQueryVariables>(LinkDetailsDocument, options);
        }
export type LinkDetailsQueryHookResult = ReturnType<typeof useLinkDetailsQuery>;
export type LinkDetailsLazyQueryHookResult = ReturnType<typeof useLinkDetailsLazyQuery>;
export type LinkDetailsSuspenseQueryHookResult = ReturnType<typeof useLinkDetailsSuspenseQuery>;
export type LinkDetailsQueryResult = Apollo.QueryResult<LinkDetailsQuery, LinkDetailsQueryVariables>;
export const LinksByKeyDocument = gql`
    query linksByKey($keys: [String!]!) {
  link_clicks(where: {key: {_in: $keys}}) {
    id
    key
    clicks
  }
}
    `;

/**
 * __useLinksByKeyQuery__
 *
 * To run a query within a React component, call `useLinksByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksByKeyQuery({
 *   variables: {
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useLinksByKeyQuery(baseOptions: Apollo.QueryHookOptions<LinksByKeyQuery, LinksByKeyQueryVariables> & ({ variables: LinksByKeyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinksByKeyQuery, LinksByKeyQueryVariables>(LinksByKeyDocument, options);
      }
export function useLinksByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinksByKeyQuery, LinksByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinksByKeyQuery, LinksByKeyQueryVariables>(LinksByKeyDocument, options);
        }
export function useLinksByKeySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LinksByKeyQuery, LinksByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LinksByKeyQuery, LinksByKeyQueryVariables>(LinksByKeyDocument, options);
        }
export type LinksByKeyQueryHookResult = ReturnType<typeof useLinksByKeyQuery>;
export type LinksByKeyLazyQueryHookResult = ReturnType<typeof useLinksByKeyLazyQuery>;
export type LinksByKeySuspenseQueryHookResult = ReturnType<typeof useLinksByKeySuspenseQuery>;
export type LinksByKeyQueryResult = Apollo.QueryResult<LinksByKeyQuery, LinksByKeyQueryVariables>;
export const LinksByCampaignDocument = gql`
    query linksByCampaign($campaignId: Int!) {
  campaign_user(
    where: {campaign_id: {_eq: $campaignId}, tracking: {_has_key: "shortlinks", _cast: {String: {_ilike: "%rlt.to%"}}}}
  ) {
    id
    tracking
  }
}
    `;

/**
 * __useLinksByCampaignQuery__
 *
 * To run a query within a React component, call `useLinksByCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksByCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksByCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useLinksByCampaignQuery(baseOptions: Apollo.QueryHookOptions<LinksByCampaignQuery, LinksByCampaignQueryVariables> & ({ variables: LinksByCampaignQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinksByCampaignQuery, LinksByCampaignQueryVariables>(LinksByCampaignDocument, options);
      }
export function useLinksByCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinksByCampaignQuery, LinksByCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinksByCampaignQuery, LinksByCampaignQueryVariables>(LinksByCampaignDocument, options);
        }
export function useLinksByCampaignSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LinksByCampaignQuery, LinksByCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LinksByCampaignQuery, LinksByCampaignQueryVariables>(LinksByCampaignDocument, options);
        }
export type LinksByCampaignQueryHookResult = ReturnType<typeof useLinksByCampaignQuery>;
export type LinksByCampaignLazyQueryHookResult = ReturnType<typeof useLinksByCampaignLazyQuery>;
export type LinksByCampaignSuspenseQueryHookResult = ReturnType<typeof useLinksByCampaignSuspenseQuery>;
export type LinksByCampaignQueryResult = Apollo.QueryResult<LinksByCampaignQuery, LinksByCampaignQueryVariables>;
export const LinkUsageDocument = gql`
    query linkUsage($like: String!) {
  campaign_user(where: {tracking: {_cast: {String: {_like: $like}}}}) {
    id
    tracking
    campaign {
      id
      stub
      title
    }
    user {
      id
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useLinkUsageQuery__
 *
 * To run a query within a React component, call `useLinkUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkUsageQuery({
 *   variables: {
 *      like: // value for 'like'
 *   },
 * });
 */
export function useLinkUsageQuery(baseOptions: Apollo.QueryHookOptions<LinkUsageQuery, LinkUsageQueryVariables> & ({ variables: LinkUsageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinkUsageQuery, LinkUsageQueryVariables>(LinkUsageDocument, options);
      }
export function useLinkUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkUsageQuery, LinkUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinkUsageQuery, LinkUsageQueryVariables>(LinkUsageDocument, options);
        }
export function useLinkUsageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LinkUsageQuery, LinkUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LinkUsageQuery, LinkUsageQueryVariables>(LinkUsageDocument, options);
        }
export type LinkUsageQueryHookResult = ReturnType<typeof useLinkUsageQuery>;
export type LinkUsageLazyQueryHookResult = ReturnType<typeof useLinkUsageLazyQuery>;
export type LinkUsageSuspenseQueryHookResult = ReturnType<typeof useLinkUsageSuspenseQuery>;
export type LinkUsageQueryResult = Apollo.QueryResult<LinkUsageQuery, LinkUsageQueryVariables>;
export const UpsertLinkDocument = gql`
    mutation upsertLink($set: link_clicks_insert_input!) {
  insert_link_clicks_one(
    object: $set
    on_conflict: {constraint: link_clicks_pk, update_columns: [target_url]}
  ) {
    id
  }
}
    `;
export type UpsertLinkMutationFn = Apollo.MutationFunction<UpsertLinkMutation, UpsertLinkMutationVariables>;

/**
 * __useUpsertLinkMutation__
 *
 * To run a mutation, you first call `useUpsertLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLinkMutation, { data, loading, error }] = useUpsertLinkMutation({
 *   variables: {
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpsertLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLinkMutation, UpsertLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLinkMutation, UpsertLinkMutationVariables>(UpsertLinkDocument, options);
      }
export type UpsertLinkMutationHookResult = ReturnType<typeof useUpsertLinkMutation>;
export type UpsertLinkMutationResult = Apollo.MutationResult<UpsertLinkMutation>;
export type UpsertLinkMutationOptions = Apollo.BaseMutationOptions<UpsertLinkMutation, UpsertLinkMutationVariables>;