export const getChangedFields = <T>({
  data,
  initialData
}: {
  data: Partial<T>;
  initialData: T | null | undefined;
}): Partial<T> => {
  const set = {};
  if (!initialData) return set as T;
  const BLACK_LIST_KEYS = ["__typename", "id"];
  Object.keys(initialData).forEach(key => {
    if (BLACK_LIST_KEYS.includes(key)) return;
    const value = initialData[key];
    if (value === data[key]) return;
    if (!value && !data[key]) return;
    if (data[key] === undefined) return;
    set[key] = data[key];
  });
  return set as T;
};
