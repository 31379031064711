import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateScriveAgreementMutationVariables = Types.Exact<{
  campaignUserId: Types.Scalars['Int']['input'];
  scriveAgreementId: Types.Scalars['String']['input'];
}>;


export type UpdateScriveAgreementMutation = { __typename?: 'Mutation', updateScriveAgreement?: void | null };

export type RefreshScriveAgreementMutationVariables = Types.Exact<{
  campaignUserId: Types.Scalars['Int']['input'];
}>;


export type RefreshScriveAgreementMutation = { __typename?: 'Mutation', refreshScriveAgreement?: void | null };


export const UpdateScriveAgreementDocument = gql`
    mutation updateScriveAgreement($campaignUserId: Int!, $scriveAgreementId: String!) {
  updateScriveAgreement(
    campaignUserId: $campaignUserId
    scriveAgreementId: $scriveAgreementId
  )
}
    `;
export type UpdateScriveAgreementMutationFn = Apollo.MutationFunction<UpdateScriveAgreementMutation, UpdateScriveAgreementMutationVariables>;

/**
 * __useUpdateScriveAgreementMutation__
 *
 * To run a mutation, you first call `useUpdateScriveAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScriveAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScriveAgreementMutation, { data, loading, error }] = useUpdateScriveAgreementMutation({
 *   variables: {
 *      campaignUserId: // value for 'campaignUserId'
 *      scriveAgreementId: // value for 'scriveAgreementId'
 *   },
 * });
 */
export function useUpdateScriveAgreementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScriveAgreementMutation, UpdateScriveAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScriveAgreementMutation, UpdateScriveAgreementMutationVariables>(UpdateScriveAgreementDocument, options);
      }
export type UpdateScriveAgreementMutationHookResult = ReturnType<typeof useUpdateScriveAgreementMutation>;
export type UpdateScriveAgreementMutationResult = Apollo.MutationResult<UpdateScriveAgreementMutation>;
export type UpdateScriveAgreementMutationOptions = Apollo.BaseMutationOptions<UpdateScriveAgreementMutation, UpdateScriveAgreementMutationVariables>;
export const RefreshScriveAgreementDocument = gql`
    mutation refreshScriveAgreement($campaignUserId: Int!) {
  refreshScriveAgreement(campaignUserId: $campaignUserId)
}
    `;
export type RefreshScriveAgreementMutationFn = Apollo.MutationFunction<RefreshScriveAgreementMutation, RefreshScriveAgreementMutationVariables>;

/**
 * __useRefreshScriveAgreementMutation__
 *
 * To run a mutation, you first call `useRefreshScriveAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshScriveAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshScriveAgreementMutation, { data, loading, error }] = useRefreshScriveAgreementMutation({
 *   variables: {
 *      campaignUserId: // value for 'campaignUserId'
 *   },
 * });
 */
export function useRefreshScriveAgreementMutation(baseOptions?: Apollo.MutationHookOptions<RefreshScriveAgreementMutation, RefreshScriveAgreementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshScriveAgreementMutation, RefreshScriveAgreementMutationVariables>(RefreshScriveAgreementDocument, options);
      }
export type RefreshScriveAgreementMutationHookResult = ReturnType<typeof useRefreshScriveAgreementMutation>;
export type RefreshScriveAgreementMutationResult = Apollo.MutationResult<RefreshScriveAgreementMutation>;
export type RefreshScriveAgreementMutationOptions = Apollo.BaseMutationOptions<RefreshScriveAgreementMutation, RefreshScriveAgreementMutationVariables>;