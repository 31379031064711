import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchCampaignsQueryVariables = Types.Exact<{
  where: Types.Campaign_Bool_Exp;
}>;


export type SearchCampaignsQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, title: string, stub: string, project: { __typename?: 'project', id: number, name: string } }> };

export type CampaignIdByStubQueryVariables = Types.Exact<{
  stub: Types.Scalars['String']['input'];
}>;


export type CampaignIdByStubQuery = { __typename?: 'Query', campaign: Array<{ __typename?: 'campaign', id: number, stub: string }> };

export type TimelineByCampaignIdQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['Int']['input'];
}>;


export type TimelineByCampaignIdQuery = { __typename?: 'Query', campaign: Array<{ __typename?: 'campaign', id: number, stub: string, campaign_timeline: Array<{ __typename?: 'campaign_timeline', id: number, name: string, end_date?: string | null, group: number, order: number }> }> };

export type TimelineQueryVariables = Types.Exact<{
  stub: Types.Scalars['String']['input'];
}>;


export type TimelineQuery = { __typename?: 'Query', campaign: Array<{ __typename?: 'campaign', id: number, campaign_timeline: Array<{ __typename?: 'campaign_timeline', id: number, name: string, end_date?: string | null, group: number, order: number }>, campaign_progress: Array<{ __typename?: 'campaign_progress', id: number, date: string, percentage: string }>, project: { __typename?: 'project', id: number, campaigns: Array<{ __typename?: 'campaign', id: number, campaign_timeline: Array<{ __typename?: 'campaign_timeline', id: number, end_date?: string | null, group: number }> }> } }> };

export type InsertTimelineRowsMutationVariables = Types.Exact<{
  objects: Array<Types.Campaign_Timeline_Insert_Input> | Types.Campaign_Timeline_Insert_Input;
}>;


export type InsertTimelineRowsMutation = { __typename?: 'Mutation', insert_campaign_timeline?: { __typename?: 'campaign_timeline_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'campaign_timeline', id: number, name: string, group: number, order: number, campaign_id: number, end_date?: string | null }> } | null };

export type UpdateTimelineRowsMutationVariables = Types.Exact<{
  updates: Array<Types.Campaign_Timeline_Updates> | Types.Campaign_Timeline_Updates;
}>;


export type UpdateTimelineRowsMutation = { __typename?: 'Mutation', update_campaign_timeline_many?: Array<{ __typename?: 'campaign_timeline_mutation_response', affected_rows: number } | null> | null };

export type DeleteTimelineRowsMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type DeleteTimelineRowsMutation = { __typename?: 'Mutation', delete_campaign_timeline?: { __typename?: 'campaign_timeline_mutation_response', affected_rows: number } | null };

export type InsertProgressRowsMutationVariables = Types.Exact<{
  objects: Array<Types.Campaign_Progress_Insert_Input> | Types.Campaign_Progress_Insert_Input;
}>;


export type InsertProgressRowsMutation = { __typename?: 'Mutation', insert_campaign_progress?: { __typename?: 'campaign_progress_mutation_response', affected_rows: number } | null };

export type DeleteAllTimelineItemsMutationVariables = Types.Exact<{
  campaignId: Types.Scalars['Int']['input'];
}>;


export type DeleteAllTimelineItemsMutation = { __typename?: 'Mutation', delete_campaign_timeline?: { __typename?: 'campaign_timeline_mutation_response', affected_rows: number } | null };


export const SearchCampaignsDocument = gql`
    query searchCampaigns($where: campaign_bool_exp!) {
  campaigns: campaign(
    where: $where
    limit: 30
    order_by: {updated_at: desc_nulls_last}
  ) {
    id
    title
    stub
    project {
      id
      name
    }
  }
}
    `;

/**
 * __useSearchCampaignsQuery__
 *
 * To run a query within a React component, call `useSearchCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCampaignsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSearchCampaignsQuery(baseOptions: Apollo.QueryHookOptions<SearchCampaignsQuery, SearchCampaignsQueryVariables> & ({ variables: SearchCampaignsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCampaignsQuery, SearchCampaignsQueryVariables>(SearchCampaignsDocument, options);
      }
export function useSearchCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCampaignsQuery, SearchCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCampaignsQuery, SearchCampaignsQueryVariables>(SearchCampaignsDocument, options);
        }
export function useSearchCampaignsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchCampaignsQuery, SearchCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCampaignsQuery, SearchCampaignsQueryVariables>(SearchCampaignsDocument, options);
        }
export type SearchCampaignsQueryHookResult = ReturnType<typeof useSearchCampaignsQuery>;
export type SearchCampaignsLazyQueryHookResult = ReturnType<typeof useSearchCampaignsLazyQuery>;
export type SearchCampaignsSuspenseQueryHookResult = ReturnType<typeof useSearchCampaignsSuspenseQuery>;
export type SearchCampaignsQueryResult = Apollo.QueryResult<SearchCampaignsQuery, SearchCampaignsQueryVariables>;
export const CampaignIdByStubDocument = gql`
    query campaignIdByStub($stub: String!) {
  campaign(where: {stub: {_eq: $stub}}, limit: 1) {
    id
    stub
  }
}
    `;

/**
 * __useCampaignIdByStubQuery__
 *
 * To run a query within a React component, call `useCampaignIdByStubQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignIdByStubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignIdByStubQuery({
 *   variables: {
 *      stub: // value for 'stub'
 *   },
 * });
 */
export function useCampaignIdByStubQuery(baseOptions: Apollo.QueryHookOptions<CampaignIdByStubQuery, CampaignIdByStubQueryVariables> & ({ variables: CampaignIdByStubQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignIdByStubQuery, CampaignIdByStubQueryVariables>(CampaignIdByStubDocument, options);
      }
export function useCampaignIdByStubLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignIdByStubQuery, CampaignIdByStubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignIdByStubQuery, CampaignIdByStubQueryVariables>(CampaignIdByStubDocument, options);
        }
export function useCampaignIdByStubSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignIdByStubQuery, CampaignIdByStubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignIdByStubQuery, CampaignIdByStubQueryVariables>(CampaignIdByStubDocument, options);
        }
export type CampaignIdByStubQueryHookResult = ReturnType<typeof useCampaignIdByStubQuery>;
export type CampaignIdByStubLazyQueryHookResult = ReturnType<typeof useCampaignIdByStubLazyQuery>;
export type CampaignIdByStubSuspenseQueryHookResult = ReturnType<typeof useCampaignIdByStubSuspenseQuery>;
export type CampaignIdByStubQueryResult = Apollo.QueryResult<CampaignIdByStubQuery, CampaignIdByStubQueryVariables>;
export const TimelineByCampaignIdDocument = gql`
    query timelineByCampaignId($campaignId: Int!) {
  campaign(where: {id: {_eq: $campaignId}}, limit: 1) {
    id
    stub
    campaign_timeline(order_by: [{group: asc}, {order: asc}]) {
      id
      name
      end_date
      group
      order
    }
  }
}
    `;

/**
 * __useTimelineByCampaignIdQuery__
 *
 * To run a query within a React component, call `useTimelineByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineByCampaignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useTimelineByCampaignIdQuery(baseOptions: Apollo.QueryHookOptions<TimelineByCampaignIdQuery, TimelineByCampaignIdQueryVariables> & ({ variables: TimelineByCampaignIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimelineByCampaignIdQuery, TimelineByCampaignIdQueryVariables>(TimelineByCampaignIdDocument, options);
      }
export function useTimelineByCampaignIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelineByCampaignIdQuery, TimelineByCampaignIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimelineByCampaignIdQuery, TimelineByCampaignIdQueryVariables>(TimelineByCampaignIdDocument, options);
        }
export function useTimelineByCampaignIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TimelineByCampaignIdQuery, TimelineByCampaignIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TimelineByCampaignIdQuery, TimelineByCampaignIdQueryVariables>(TimelineByCampaignIdDocument, options);
        }
export type TimelineByCampaignIdQueryHookResult = ReturnType<typeof useTimelineByCampaignIdQuery>;
export type TimelineByCampaignIdLazyQueryHookResult = ReturnType<typeof useTimelineByCampaignIdLazyQuery>;
export type TimelineByCampaignIdSuspenseQueryHookResult = ReturnType<typeof useTimelineByCampaignIdSuspenseQuery>;
export type TimelineByCampaignIdQueryResult = Apollo.QueryResult<TimelineByCampaignIdQuery, TimelineByCampaignIdQueryVariables>;
export const TimelineDocument = gql`
    query timeline($stub: String!) {
  campaign(where: {stub: {_eq: $stub}}, limit: 1) {
    id
    campaign_timeline(order_by: [{group: asc}, {order: asc}]) {
      id
      name
      end_date
      group
      order
    }
    campaign_progress(order_by: {date: asc}) {
      id
      date
      percentage
    }
    project {
      id
      campaigns {
        id
        campaign_timeline {
          id
          end_date
          group
        }
      }
    }
  }
}
    `;

/**
 * __useTimelineQuery__
 *
 * To run a query within a React component, call `useTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineQuery({
 *   variables: {
 *      stub: // value for 'stub'
 *   },
 * });
 */
export function useTimelineQuery(baseOptions: Apollo.QueryHookOptions<TimelineQuery, TimelineQueryVariables> & ({ variables: TimelineQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimelineQuery, TimelineQueryVariables>(TimelineDocument, options);
      }
export function useTimelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelineQuery, TimelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimelineQuery, TimelineQueryVariables>(TimelineDocument, options);
        }
export function useTimelineSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TimelineQuery, TimelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TimelineQuery, TimelineQueryVariables>(TimelineDocument, options);
        }
export type TimelineQueryHookResult = ReturnType<typeof useTimelineQuery>;
export type TimelineLazyQueryHookResult = ReturnType<typeof useTimelineLazyQuery>;
export type TimelineSuspenseQueryHookResult = ReturnType<typeof useTimelineSuspenseQuery>;
export type TimelineQueryResult = Apollo.QueryResult<TimelineQuery, TimelineQueryVariables>;
export const InsertTimelineRowsDocument = gql`
    mutation insertTimelineRows($objects: [campaign_timeline_insert_input!]!) {
  insert_campaign_timeline(objects: $objects) {
    affected_rows
    returning {
      id
      name
      group
      order
      campaign_id
      end_date
    }
  }
}
    `;
export type InsertTimelineRowsMutationFn = Apollo.MutationFunction<InsertTimelineRowsMutation, InsertTimelineRowsMutationVariables>;

/**
 * __useInsertTimelineRowsMutation__
 *
 * To run a mutation, you first call `useInsertTimelineRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTimelineRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTimelineRowsMutation, { data, loading, error }] = useInsertTimelineRowsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertTimelineRowsMutation(baseOptions?: Apollo.MutationHookOptions<InsertTimelineRowsMutation, InsertTimelineRowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertTimelineRowsMutation, InsertTimelineRowsMutationVariables>(InsertTimelineRowsDocument, options);
      }
export type InsertTimelineRowsMutationHookResult = ReturnType<typeof useInsertTimelineRowsMutation>;
export type InsertTimelineRowsMutationResult = Apollo.MutationResult<InsertTimelineRowsMutation>;
export type InsertTimelineRowsMutationOptions = Apollo.BaseMutationOptions<InsertTimelineRowsMutation, InsertTimelineRowsMutationVariables>;
export const UpdateTimelineRowsDocument = gql`
    mutation updateTimelineRows($updates: [campaign_timeline_updates!]!) {
  update_campaign_timeline_many(updates: $updates) {
    affected_rows
  }
}
    `;
export type UpdateTimelineRowsMutationFn = Apollo.MutationFunction<UpdateTimelineRowsMutation, UpdateTimelineRowsMutationVariables>;

/**
 * __useUpdateTimelineRowsMutation__
 *
 * To run a mutation, you first call `useUpdateTimelineRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimelineRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimelineRowsMutation, { data, loading, error }] = useUpdateTimelineRowsMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateTimelineRowsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimelineRowsMutation, UpdateTimelineRowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimelineRowsMutation, UpdateTimelineRowsMutationVariables>(UpdateTimelineRowsDocument, options);
      }
export type UpdateTimelineRowsMutationHookResult = ReturnType<typeof useUpdateTimelineRowsMutation>;
export type UpdateTimelineRowsMutationResult = Apollo.MutationResult<UpdateTimelineRowsMutation>;
export type UpdateTimelineRowsMutationOptions = Apollo.BaseMutationOptions<UpdateTimelineRowsMutation, UpdateTimelineRowsMutationVariables>;
export const DeleteTimelineRowsDocument = gql`
    mutation deleteTimelineRows($ids: [Int!]!) {
  delete_campaign_timeline(where: {id: {_in: $ids}}) {
    affected_rows
  }
}
    `;
export type DeleteTimelineRowsMutationFn = Apollo.MutationFunction<DeleteTimelineRowsMutation, DeleteTimelineRowsMutationVariables>;

/**
 * __useDeleteTimelineRowsMutation__
 *
 * To run a mutation, you first call `useDeleteTimelineRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimelineRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimelineRowsMutation, { data, loading, error }] = useDeleteTimelineRowsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteTimelineRowsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimelineRowsMutation, DeleteTimelineRowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimelineRowsMutation, DeleteTimelineRowsMutationVariables>(DeleteTimelineRowsDocument, options);
      }
export type DeleteTimelineRowsMutationHookResult = ReturnType<typeof useDeleteTimelineRowsMutation>;
export type DeleteTimelineRowsMutationResult = Apollo.MutationResult<DeleteTimelineRowsMutation>;
export type DeleteTimelineRowsMutationOptions = Apollo.BaseMutationOptions<DeleteTimelineRowsMutation, DeleteTimelineRowsMutationVariables>;
export const InsertProgressRowsDocument = gql`
    mutation insertProgressRows($objects: [campaign_progress_insert_input!]!) {
  insert_campaign_progress(objects: $objects) {
    affected_rows
  }
}
    `;
export type InsertProgressRowsMutationFn = Apollo.MutationFunction<InsertProgressRowsMutation, InsertProgressRowsMutationVariables>;

/**
 * __useInsertProgressRowsMutation__
 *
 * To run a mutation, you first call `useInsertProgressRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProgressRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProgressRowsMutation, { data, loading, error }] = useInsertProgressRowsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertProgressRowsMutation(baseOptions?: Apollo.MutationHookOptions<InsertProgressRowsMutation, InsertProgressRowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertProgressRowsMutation, InsertProgressRowsMutationVariables>(InsertProgressRowsDocument, options);
      }
export type InsertProgressRowsMutationHookResult = ReturnType<typeof useInsertProgressRowsMutation>;
export type InsertProgressRowsMutationResult = Apollo.MutationResult<InsertProgressRowsMutation>;
export type InsertProgressRowsMutationOptions = Apollo.BaseMutationOptions<InsertProgressRowsMutation, InsertProgressRowsMutationVariables>;
export const DeleteAllTimelineItemsDocument = gql`
    mutation deleteAllTimelineItems($campaignId: Int!) {
  delete_campaign_timeline(where: {campaign_id: {_eq: $campaignId}}) {
    affected_rows
  }
}
    `;
export type DeleteAllTimelineItemsMutationFn = Apollo.MutationFunction<DeleteAllTimelineItemsMutation, DeleteAllTimelineItemsMutationVariables>;

/**
 * __useDeleteAllTimelineItemsMutation__
 *
 * To run a mutation, you first call `useDeleteAllTimelineItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllTimelineItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllTimelineItemsMutation, { data, loading, error }] = useDeleteAllTimelineItemsMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDeleteAllTimelineItemsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllTimelineItemsMutation, DeleteAllTimelineItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAllTimelineItemsMutation, DeleteAllTimelineItemsMutationVariables>(DeleteAllTimelineItemsDocument, options);
      }
export type DeleteAllTimelineItemsMutationHookResult = ReturnType<typeof useDeleteAllTimelineItemsMutation>;
export type DeleteAllTimelineItemsMutationResult = Apollo.MutationResult<DeleteAllTimelineItemsMutation>;
export type DeleteAllTimelineItemsMutationOptions = Apollo.BaseMutationOptions<DeleteAllTimelineItemsMutation, DeleteAllTimelineItemsMutationVariables>;