import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { Card, Chip } from "@mui/material";
import { unique } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  > .list {
    padding: 12px;
    padding-top: 2px;
    box-shadow: none;
    border: 1px solid ${palette.gray[40]};
    border-top: 0;

    > .chip {
      background: ${palette.gray[10]};
      margin-top: 10px;
      margin-right: 10px;
      height: 23px;
    }
  }
`;

export const MultiSelect: React.FC<{
  options: { value: string; label: string }[];
  value: string[];
  onChange: (tags: string[]) => void;
  label: string;
}> = ({ options, value, onChange, label }) => {
  if (!options) return null;

  return (
    <Root>
      <Select
        label={label}
        value={null}
        hideNone
        size="small"
        options={options?.filter(o => !value.includes(o.value)) ?? []}
        onChange={newValue => {
          if (newValue) {
            onChange(unique([...value, newValue]));
          }
        }}
        style={{ width: "100%" }}
      />

      {value.length > 0 && (
        <Card className="list">
          {value.map(item => (
            <Chip
              key={item}
              label={options.find(t => t.value === item)?.label}
              className="chip"
              deleteIcon={
                <Close style={{ fontSize: 12, color: palette.gray[40], paddingRight: 4 }} />
              }
              onDelete={() => onChange(value.filter(t => t !== item))}
            />
          ))}
        </Card>
      )}
    </Root>
  );
};
