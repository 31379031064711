import { useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { maybePluralize } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";

import { Headline } from "components/ui/Headline";

import type { reshapeClients } from "../reshapeClients";
import { ProjectRow } from "./ProjectRow";

type ClientCardProps = {
  name: string;
  projects: ReturnType<typeof reshapeClients>["clients"][number]["projects"];
};

export const ClientCard: React.FC<ClientCardProps> = ({ name, projects = [] }) => {
  const campaignsCount = projects.reduce((acc, p) => acc + (p.campaigns?.length ?? 0), 0);

  const rowRef = useRef<HTMLTableElement>(null);

  const [tableWidth, setTableWidth] = useState(
    rowRef.current?.clientWidth || Math.min(0.85 * window.innerWidth, 1280)
  );

  useEffect(() => {
    const handleResize = () => rowRef.current && setTableWidth(rowRef.current.clientWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <Root>
      <TitleContainer>
        <Headline variant="h4">{name}</Headline>
        <span>
          {maybePluralize(projects.length, { noun: "project" })} •{" "}
          {maybePluralize(campaignsCount, { noun: "campaign" })}{" "}
        </span>
      </TitleContainer>

      <TableContainer component={Paper}>
        <Table ref={rowRef} component="table">
          <StyledTableHead>
            <TableRow>
              <TableCell />
              <TableCell>Project</TableCell>
              <TableCell align="right">Invoice amount</TableCell>
              <TableCell align="right">Invoiced</TableCell>
              <TableCell>Sales rep.</TableCell>
              <TableCell align="right">Margin</TableCell>
              <TableCell align="right">Amount invoiced</TableCell>
              <TableCell>Due</TableCell>
              <TableCell>Accounted</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {projects.map(p => (
              <ProjectRow
                account={p.account}
                currency={p.project_invoicing_detail?.currency || "n/a"}
                additionalInvoiceInfo={p.project_invoicing_detail?.additional_info || ""}
                key={p.id}
                name={p.name}
                project_invoices={p.project_invoices}
                stub={p.stub || ""}
                tableWidth={tableWidth}
                projectMargin={p.projectMargin ?? 0}
                estimatedProjectMargin={p.estimatedProjectMargin ?? 0}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  padding: 2em 0;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 1.5em;
  }
`;

const StyledTableHead = styled(TableHead)`
  background-color: ${palette.gray[10]};
  font-weight: 500;
  border-top: 1px solid ${palette.gray[20]};
`;
