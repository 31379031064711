import { type CSSProperties, type FC, type PropsWithChildren, useCallback, useState } from "react";

import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";

import { ArrowDropDown } from "@mui/icons-material";
import { useOnOutsideClick } from "@relatable/ui/hooks/useOnOutsideClick";

const Dropdown: FC<PropsWithChildren> = ({ children }) => {
  const [opened, setOpened] = useState(false);

  const handleOutsideClick = useCallback(() => {
    setOpened(false);
  }, []);

  const ref = useOnOutsideClick(handleOutsideClick);

  return (
    <span ref={ref} style={{ display: "inline-flex", alignItems: "center" }}>
      <ArrowDropDown
        onClick={() => setOpened(prev => !prev)}
        style={{ cursor: "pointer" }}
        fontSize="medium"
      />

      {opened && <DropdownContent>{children}</DropdownContent>}
    </span>
  );
};

const DropdownContent = styled.ul`
  display: block;
  position: absolute;
  background-color: ${palette.gray.white};
  min-width: 225px;
  padding: 0px;
  z-index: 100;
  padding: 10px 15px;

  box-shadow: 0px 0px 1px 1px ${palette.gray[40]};
  border-radius: 8px;
  top: 30px;
  left: 0px;
  list-style-type: none;

  > li {
    line-height: initial;
    padding: 5px 0px;
    > * {
      display: block;
      color: inherit;
    }
  }
`;

export type BreadcrumbItem = {
  path?: string;
  title: string;
  isReactLink?: boolean;
  isLink?: boolean;
  dropdownItems?: Omit<BreadcrumbItem, "dropdownItems">[];
  linkState?: { [key: string]: any };
  search?: string;
};

export const Breadcrumbs: FC<{
  items: BreadcrumbItem[];
  defaultExpanded?: boolean;
  style?: CSSProperties;
}> = ({ items = [], defaultExpanded = false, style }) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const renderLink = (item: BreadcrumbItem) => {
    const { isReactLink = true, isLink = true, path, title, linkState, search } = item;

    if (!isLink) {
      return <span className="nav-link">{title}</span>;
    }

    if (isReactLink) {
      return (
        <Link className="nav-link" to={{ pathname: path, search }} title={title} state={linkState}>
          {title}
        </Link>
      );
    }

    return (
      <Link className="nav-link" to={path ?? ""}>
        {title}
      </Link>
    );
  };

  return (
    <Root style={style}>
      {!expanded ? (
        // biome-ignore lint/a11y/useSemanticElements: <explanation>
        <ExpandButton role="button" onClick={() => setExpanded(true)}>
          ...
        </ExpandButton>
      ) : null}
      {items.map(({ dropdownItems = [], ...item }, index) => {
        if (!expanded && index !== items.length - 1) return null;

        return (
          <div className="breadcrumb-item" key={item.title}>
            {index !== 0 ? <Divider>/</Divider> : null}
            {renderLink(item)}
            {dropdownItems.length > 1 ? (
              <Dropdown>
                {dropdownItems.map(di => (
                  <li key={`dropdown-${di.path}`}>{renderLink(di)}</li>
                ))}
              </Dropdown>
            ) : null}
          </div>
        );
      })}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding-top: 9px;
  padding-left: 50px;

  > .breadcrumb-item {
    position: relative;
    display: flex;

    > .nav-link {
      color: ${palette.gray[60]};
      font-size: 15px;
      display: inline-block;

      &:hover {
        color: ${palette.gray[80]};
      }
    }

    &:last-of-type {
      > .nav-link {
        color: ${palette.gray.black};
        font-weight: 700;
      }
    }
  }
`;

const ExpandButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const Divider = styled.span`
  margin: 0px 8px;
  color: ${palette.gray[60]};
`;
