import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Accounts_Bool_Exp>;
}>;


export type AccountsQuery = { __typename?: 'Query', accounts: Array<{ __typename?: 'accounts', id: number, email?: string | null, intercom_email?: string | null, first_name?: string | null, last_name?: string | null, is_active?: boolean | null }> };

export type AccountDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type AccountDetailsQuery = { __typename?: 'Query', accounts_by_pk?: { __typename?: 'accounts', id: number, email?: string | null, intercom_email?: string | null, first_name?: string | null, last_name?: string | null, is_active?: boolean | null, instagram?: string | null, is_campaign_owner?: boolean | null, linkedin?: string | null, office?: string | null, phone?: string | null, profile_picture?: string | null, slack_id?: string | null, slack_username?: string | null, title?: string | null } | null };

export type UpsertAccountMutationVariables = Types.Exact<{
  set: Types.Accounts_Insert_Input;
}>;


export type UpsertAccountMutation = { __typename?: 'Mutation', insert_accounts_one?: { __typename?: 'accounts', id: number } | null };


export const AccountsDocument = gql`
    query accounts($where: accounts_bool_exp) {
  accounts(where: $where) {
    id
    email
    intercom_email
    first_name
    last_name
    is_active
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export function useAccountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsSuspenseQueryHookResult = ReturnType<typeof useAccountsSuspenseQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const AccountDetailsDocument = gql`
    query accountDetails($id: Int!) {
  accounts_by_pk(id: $id) {
    id
    email
    intercom_email
    first_name
    last_name
    is_active
    instagram
    is_campaign_owner
    linkedin
    office
    phone
    profile_picture
    slack_id
    slack_username
    title
  }
}
    `;

/**
 * __useAccountDetailsQuery__
 *
 * To run a query within a React component, call `useAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountDetailsQuery(baseOptions: Apollo.QueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables> & ({ variables: AccountDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(AccountDetailsDocument, options);
      }
export function useAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(AccountDetailsDocument, options);
        }
export function useAccountDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(AccountDetailsDocument, options);
        }
export type AccountDetailsQueryHookResult = ReturnType<typeof useAccountDetailsQuery>;
export type AccountDetailsLazyQueryHookResult = ReturnType<typeof useAccountDetailsLazyQuery>;
export type AccountDetailsSuspenseQueryHookResult = ReturnType<typeof useAccountDetailsSuspenseQuery>;
export type AccountDetailsQueryResult = Apollo.QueryResult<AccountDetailsQuery, AccountDetailsQueryVariables>;
export const UpsertAccountDocument = gql`
    mutation upsertAccount($set: accounts_insert_input!) {
  insert_accounts_one(
    object: $set
    on_conflict: {constraint: accounts_pkey, update_columns: [email, first_name, last_name, instagram, intercom_email, is_active, is_campaign_owner, linkedin, office, phone, profile_picture, roles, slack_id, slack_username, title]}
  ) {
    id
  }
}
    `;
export type UpsertAccountMutationFn = Apollo.MutationFunction<UpsertAccountMutation, UpsertAccountMutationVariables>;

/**
 * __useUpsertAccountMutation__
 *
 * To run a mutation, you first call `useUpsertAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAccountMutation, { data, loading, error }] = useUpsertAccountMutation({
 *   variables: {
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpsertAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpsertAccountMutation, UpsertAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertAccountMutation, UpsertAccountMutationVariables>(UpsertAccountDocument, options);
      }
export type UpsertAccountMutationHookResult = ReturnType<typeof useUpsertAccountMutation>;
export type UpsertAccountMutationResult = Apollo.MutationResult<UpsertAccountMutation>;
export type UpsertAccountMutationOptions = Apollo.BaseMutationOptions<UpsertAccountMutation, UpsertAccountMutationVariables>;