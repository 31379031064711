import type { CampaignParticipantsQuery } from "./generated";

export const filterCampaignParticipantsData = (
  data?: CampaignParticipantsQuery,
  filterFn?: (user: CampaignParticipantsQuery["users_in_lists"][number]) => boolean
) => {
  if (!data) return data;

  let includedUsers = data.users_in_lists.filter(({ campaign_user }) => Boolean(campaign_user));

  if (filterFn) {
    includedUsers = includedUsers.filter(user => filterFn(user));
  }

  return { ...data, users_in_lists: includedUsers };
};
