import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { type FC, useState } from "react";

import { Popup } from "components/ui/Popup";

import { Delete } from "@mui/icons-material";
import { CampaignVideoStatsDocument, useDeleteCampaignVideoMutation } from "./generated";

export const DeleteVideoButton: FC<{ id: number; title: string }> = ({ id, title }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [deleteVideo] = useDeleteCampaignVideoMutation({
    variables: { id },
    onCompleted: () => setShowPopup(false),
    refetchQueries: [CampaignVideoStatsDocument],
    awaitRefetchQueries: true
  });

  return (
    <>
      <Button
        variant="text"
        onClick={() => setShowPopup(true)}
        icon={<Delete style={{ color: palette.primary.red }} />}
      >
        Delete
      </Button>
      {showPopup && (
        <Popup
          actionLabel="Delete"
          onAction={() => deleteVideo()}
          title={`Delete the "${title}" video`}
          onClose={() => setShowPopup(false)}
        />
      )}
    </>
  );
};
