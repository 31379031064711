import type { FC } from "react";

import { Note } from "@relatable/ui/Note";
import { JsonViewer } from "@textea/json-viewer";

import { Section } from "../Section";
import { useCampaignDetailsQuery } from "./generated";

export const TrackingSection: FC<{ campaignStub: string }> = ({ campaignStub }) => {
  const { data } = useCampaignDetailsQuery({ variables: { stub: campaignStub } });
  const tracking = data?.campaigns[0].tracking;
  return (
    <Section
      title="Tracking clicks & links"
      fieldErrors={[]}
      isChanged={false}
      sidebar={
        <>
          <Note label="Note" variant="info">
            This section is not editable
          </Note>
          {tracking && (
            <Note variant="info" label="Tracking">
              Current tracking configuration(this cannot be edited - ask the Product Team instead).
            </Note>
          )}
        </>
      }
      updated_at={undefined}
      updatedAtNotImplemented
    >
      {tracking ? (
        <JsonViewer value={tracking} displayDataTypes={false} displaySize={false} />
      ) : (
        <p>
          Campaign has no tracking configuration. This is done manually for now - ask the product
          team in the #development channel on Slack.
        </p>
      )}
    </Section>
  );
};
