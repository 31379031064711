import { Check, ModeEdit } from "@mui/icons-material";
import { palette } from "@relatable/ui/Palette";
import type { FC } from "react";

export const EditSectionIcon: FC<{ isEditing: boolean; onToggle(): void }> = ({
  isEditing,
  onToggle
}) =>
  isEditing ? (
    <Check
      style={{ color: palette.primary.red, cursor: "pointer", width: 14 }}
      onClick={onToggle}
    />
  ) : (
    <ModeEdit
      style={{ color: palette.gray[50], cursor: "pointer", width: 14 }}
      onClick={onToggle}
    />
  );
