import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { Checkbox } from "@relatable/ui/Checkbox";
import { palette } from "@relatable/ui/Palette";
import { TextInput } from "@relatable/ui/TextInput";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";

import { ContentContainer } from "lib/styled";

import { PaymentsTable } from "./PaymentsTable";
import { usePayments } from "./usePayments";

const HeaderContainer = styled(ContentContainer)`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 20px;
  padding: 0;
  justify-content: space-between;
`;

const HeaderWrapper = styled.div`
  background-color: ${palette.gray.white};
  border-bottom: 1px solid ${palette.gray.whiteOff};
  display: flex;
  width: 100%;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

export const Payments: FC = () => {
  useDocumentTitle("Payments");

  const [search, setSearch] = useState("");

  const [includePaid, setIncludePaid] = useState(false);
  const [show2years, setShow2years] = useState(false);

  const { users, loading } = usePayments({
    search,
    includePaid,
    show2years
  });

  return (
    <>
      <HeaderWrapper>
        <HeaderContainer>
          <Checkbox label="Include paid" checked={includePaid} onChange={setIncludePaid} />
          <Checkbox
            style={{ marginRight: "auto" }}
            label="Extend to 2 years"
            checked={show2years}
            onChange={setShow2years}
          />

          <TextInput
            label="Search"
            value={search}
            onChange={v => setSearch(v)}
            style={{ width: 300 }}
          />
          <div style={{ width: 200 }} />
        </HeaderContainer>
      </HeaderWrapper>

      <ContentContainer>
        <PaymentsTable loading={loading} users={users} />
      </ContentContainer>
    </>
  );
};
