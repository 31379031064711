import { type FC, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";

export const Redirect: FC<{ to: (pathname: string) => string }> = ({ to }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = to(location.pathname);
  useEffect(() => {
    navigate(path, { replace: true });
  }, [navigate, path]);

  return <Loader />;
};
