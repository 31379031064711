import type { FC } from "react";

import styled from "@emotion/styled";
import {
  LinearProgress as LinearProgressMui,
  Typography,
  linearProgressClasses
} from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { calculatePercentages } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";

const BorderLinearProgress = muiStyled(LinearProgressMui)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  backgroundColor: theme.palette.mode === "light" ? palette.gray[20] : palette.gray[80],

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: palette.primary.red
  }
}));

export const LinearProgress: FC<{
  value: number | undefined | null;
  max: number | undefined | null;
  label?: string;
  showLeft?: boolean;
  variant?: "determinate" | "indeterminate" | "buffer" | "query";
}> = ({ label, variant = "determinate", showLeft, ...props }) => {
  const max = props.max || 0;
  const value = props.value || 0;
  const progress = calculatePercentages({ total: max, of: value });

  const left = max - value;
  return (
    <div>
      <Flex>
        <Typography>
          {label} ({value.toLocaleString()}/{max.toLocaleString()})
        </Typography>
        <Typography>{progress}%</Typography>
      </Flex>
      <BorderLinearProgress value={progress > 100 ? 100 : progress} variant={variant} />
      {left > 0 && showLeft && (
        <Flex>
          <Typography style={{ marginLeft: "auto" }}>Left: {left.toLocaleString()}</Typography>
        </Flex>
      )}
    </div>
  );
};

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
