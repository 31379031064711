import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StoryStatsHeadQueryVariables = Types.Exact<{
  stubs: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type StoryStatsHeadQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, target_reach?: number | null, campaign_content_settings_aggregate: { __typename?: 'campaign_content_settings_aggregate', aggregate?: { __typename?: 'campaign_content_settings_aggregate_fields', sum?: { __typename?: 'campaign_content_settings_sum_fields', storiesCount?: number | null } | null } | null }, campaign_stories: Array<{ __typename?: 'campaign_story', id: number, approved?: boolean | null, user?: { __typename?: 'users', id: number, user_instagram?: { __typename?: 'users_instagram', id: number, followed_by?: number | null } | null } | null }> }> };

export type StoryStatsQueryVariables = Types.Exact<{
  stubs: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type StoryStatsQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, campaign_content_settings_aggregate: { __typename?: 'campaign_content_settings_aggregate', aggregate?: { __typename?: 'campaign_content_settings_aggregate_fields', min?: { __typename?: 'campaign_content_settings_min_fields', publish_date_min?: string | null } | null, max?: { __typename?: 'campaign_content_settings_max_fields', publish_date_max?: string | null } | null } | null }, stories: Array<{ __typename?: 'campaign_story', id: number, user?: { __typename?: 'users', id: number, user_instagram?: { __typename?: 'users_instagram', id: number, followed_by?: number | null } | null } | null, clip_urls: Array<{ __typename?: 'campaign_story_clip', id: number, track_url?: string | null }>, clips: { __typename?: 'campaign_story_clip_aggregate', aggregate?: { __typename?: 'campaign_story_clip_aggregate_fields', max?: { __typename?: 'campaign_story_clip_max_fields', actual_reach?: number | null, impressions?: number | null, link_clicks?: number | null } | null, min?: { __typename?: 'campaign_story_clip_min_fields', actual_reach?: number | null } | null } | null } }> }> };

export type StoriesUserInstagramNamesQueryVariables = Types.Exact<{
  stub: Types.Scalars['String']['input'];
}>;


export type StoriesUserInstagramNamesQuery = { __typename?: 'Query', campaign: Array<{ __typename?: 'campaign', id: number, campaign_content_settings: Array<{ __typename?: 'campaign_content_settings', id: number, publish_date_min: string, publish_date_max: string }>, campaign_stories: Array<{ __typename?: 'campaign_story', id: number, content_setting_id?: number | null, user_id?: number | null }>, campaign_users: Array<{ __typename?: 'campaign_user', id: number, user?: { __typename?: 'users', id: number, user_instagram?: { __typename?: 'users_instagram', id: number, username: string } | null } | null }> }> };

export type InsertStoryClipMutationVariables = Types.Exact<{
  object: Types.Campaign_Story_Clip_Insert_Input;
}>;


export type InsertStoryClipMutation = { __typename?: 'Mutation', insert_campaign_story_clip_one?: { __typename?: 'campaign_story_clip', id: number } | null };

export type InsertStoryMutationVariables = Types.Exact<{
  object: Types.Campaign_Story_Insert_Input;
}>;


export type InsertStoryMutation = { __typename?: 'Mutation', insert_campaign_story_one?: { __typename?: 'campaign_story', id: number } | null };

export type SendCampaignStoryZipOnSlackMutationVariables = Types.Exact<{
  stub: Types.Scalars['String']['input'];
}>;


export type SendCampaignStoryZipOnSlackMutation = { __typename?: 'Mutation', sendCampaignStoryZipOnSlack: string };


export const StoryStatsHeadDocument = gql`
    query storyStatsHead($stubs: [String!]!) {
  campaigns: campaign(where: {stub: {_in: $stubs}}) {
    id
    target_reach
    campaign_content_settings_aggregate(where: {type: {_eq: "story"}}) {
      aggregate {
        sum {
          storiesCount: number_of_posts
        }
      }
    }
    campaign_stories {
      id
      approved
      user {
        id
        user_instagram {
          id
          followed_by
        }
      }
    }
  }
}
    `;

/**
 * __useStoryStatsHeadQuery__
 *
 * To run a query within a React component, call `useStoryStatsHeadQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryStatsHeadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryStatsHeadQuery({
 *   variables: {
 *      stubs: // value for 'stubs'
 *   },
 * });
 */
export function useStoryStatsHeadQuery(baseOptions: Apollo.QueryHookOptions<StoryStatsHeadQuery, StoryStatsHeadQueryVariables> & ({ variables: StoryStatsHeadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryStatsHeadQuery, StoryStatsHeadQueryVariables>(StoryStatsHeadDocument, options);
      }
export function useStoryStatsHeadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryStatsHeadQuery, StoryStatsHeadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryStatsHeadQuery, StoryStatsHeadQueryVariables>(StoryStatsHeadDocument, options);
        }
export function useStoryStatsHeadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StoryStatsHeadQuery, StoryStatsHeadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StoryStatsHeadQuery, StoryStatsHeadQueryVariables>(StoryStatsHeadDocument, options);
        }
export type StoryStatsHeadQueryHookResult = ReturnType<typeof useStoryStatsHeadQuery>;
export type StoryStatsHeadLazyQueryHookResult = ReturnType<typeof useStoryStatsHeadLazyQuery>;
export type StoryStatsHeadSuspenseQueryHookResult = ReturnType<typeof useStoryStatsHeadSuspenseQuery>;
export type StoryStatsHeadQueryResult = Apollo.QueryResult<StoryStatsHeadQuery, StoryStatsHeadQueryVariables>;
export const StoryStatsDocument = gql`
    query storyStats($stubs: [String!]!) {
  campaigns: campaign(where: {stub: {_in: $stubs}}) {
    id
    campaign_content_settings_aggregate {
      aggregate {
        min {
          publish_date_min
        }
        max {
          publish_date_max
        }
      }
    }
    stories: campaign_stories(
      where: {campaign_story_clips: {actual_reach: {_gt: 0}}}
    ) {
      id
      user {
        id
        user_instagram {
          id
          followed_by
        }
      }
      clip_urls: campaign_story_clips {
        id
        track_url
      }
      clips: campaign_story_clips_aggregate {
        aggregate {
          max {
            actual_reach
            impressions
            link_clicks
          }
          min {
            actual_reach
          }
        }
      }
    }
  }
}
    `;

/**
 * __useStoryStatsQuery__
 *
 * To run a query within a React component, call `useStoryStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryStatsQuery({
 *   variables: {
 *      stubs: // value for 'stubs'
 *   },
 * });
 */
export function useStoryStatsQuery(baseOptions: Apollo.QueryHookOptions<StoryStatsQuery, StoryStatsQueryVariables> & ({ variables: StoryStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryStatsQuery, StoryStatsQueryVariables>(StoryStatsDocument, options);
      }
export function useStoryStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryStatsQuery, StoryStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryStatsQuery, StoryStatsQueryVariables>(StoryStatsDocument, options);
        }
export function useStoryStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StoryStatsQuery, StoryStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StoryStatsQuery, StoryStatsQueryVariables>(StoryStatsDocument, options);
        }
export type StoryStatsQueryHookResult = ReturnType<typeof useStoryStatsQuery>;
export type StoryStatsLazyQueryHookResult = ReturnType<typeof useStoryStatsLazyQuery>;
export type StoryStatsSuspenseQueryHookResult = ReturnType<typeof useStoryStatsSuspenseQuery>;
export type StoryStatsQueryResult = Apollo.QueryResult<StoryStatsQuery, StoryStatsQueryVariables>;
export const StoriesUserInstagramNamesDocument = gql`
    query storiesUserInstagramNames($stub: String!) {
  campaign(where: {stub: {_eq: $stub}}) {
    id
    campaign_content_settings(where: {type: {_eq: "story"}}) {
      id
      publish_date_min
      publish_date_max
    }
    campaign_stories {
      id
      content_setting_id
      user_id
    }
    campaign_users(where: {state: {_in: ["approved", "confirmed"]}}) {
      id
      user {
        id
        user_instagram {
          id
          username
        }
      }
    }
  }
}
    `;

/**
 * __useStoriesUserInstagramNamesQuery__
 *
 * To run a query within a React component, call `useStoriesUserInstagramNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesUserInstagramNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesUserInstagramNamesQuery({
 *   variables: {
 *      stub: // value for 'stub'
 *   },
 * });
 */
export function useStoriesUserInstagramNamesQuery(baseOptions: Apollo.QueryHookOptions<StoriesUserInstagramNamesQuery, StoriesUserInstagramNamesQueryVariables> & ({ variables: StoriesUserInstagramNamesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoriesUserInstagramNamesQuery, StoriesUserInstagramNamesQueryVariables>(StoriesUserInstagramNamesDocument, options);
      }
export function useStoriesUserInstagramNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoriesUserInstagramNamesQuery, StoriesUserInstagramNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoriesUserInstagramNamesQuery, StoriesUserInstagramNamesQueryVariables>(StoriesUserInstagramNamesDocument, options);
        }
export function useStoriesUserInstagramNamesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StoriesUserInstagramNamesQuery, StoriesUserInstagramNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StoriesUserInstagramNamesQuery, StoriesUserInstagramNamesQueryVariables>(StoriesUserInstagramNamesDocument, options);
        }
export type StoriesUserInstagramNamesQueryHookResult = ReturnType<typeof useStoriesUserInstagramNamesQuery>;
export type StoriesUserInstagramNamesLazyQueryHookResult = ReturnType<typeof useStoriesUserInstagramNamesLazyQuery>;
export type StoriesUserInstagramNamesSuspenseQueryHookResult = ReturnType<typeof useStoriesUserInstagramNamesSuspenseQuery>;
export type StoriesUserInstagramNamesQueryResult = Apollo.QueryResult<StoriesUserInstagramNamesQuery, StoriesUserInstagramNamesQueryVariables>;
export const InsertStoryClipDocument = gql`
    mutation insertStoryClip($object: campaign_story_clip_insert_input!) {
  insert_campaign_story_clip_one(object: $object) {
    id
  }
}
    `;
export type InsertStoryClipMutationFn = Apollo.MutationFunction<InsertStoryClipMutation, InsertStoryClipMutationVariables>;

/**
 * __useInsertStoryClipMutation__
 *
 * To run a mutation, you first call `useInsertStoryClipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertStoryClipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStoryClipMutation, { data, loading, error }] = useInsertStoryClipMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertStoryClipMutation(baseOptions?: Apollo.MutationHookOptions<InsertStoryClipMutation, InsertStoryClipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertStoryClipMutation, InsertStoryClipMutationVariables>(InsertStoryClipDocument, options);
      }
export type InsertStoryClipMutationHookResult = ReturnType<typeof useInsertStoryClipMutation>;
export type InsertStoryClipMutationResult = Apollo.MutationResult<InsertStoryClipMutation>;
export type InsertStoryClipMutationOptions = Apollo.BaseMutationOptions<InsertStoryClipMutation, InsertStoryClipMutationVariables>;
export const InsertStoryDocument = gql`
    mutation insertStory($object: campaign_story_insert_input!) {
  insert_campaign_story_one(object: $object) {
    id
  }
}
    `;
export type InsertStoryMutationFn = Apollo.MutationFunction<InsertStoryMutation, InsertStoryMutationVariables>;

/**
 * __useInsertStoryMutation__
 *
 * To run a mutation, you first call `useInsertStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStoryMutation, { data, loading, error }] = useInsertStoryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertStoryMutation(baseOptions?: Apollo.MutationHookOptions<InsertStoryMutation, InsertStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertStoryMutation, InsertStoryMutationVariables>(InsertStoryDocument, options);
      }
export type InsertStoryMutationHookResult = ReturnType<typeof useInsertStoryMutation>;
export type InsertStoryMutationResult = Apollo.MutationResult<InsertStoryMutation>;
export type InsertStoryMutationOptions = Apollo.BaseMutationOptions<InsertStoryMutation, InsertStoryMutationVariables>;
export const SendCampaignStoryZipOnSlackDocument = gql`
    mutation sendCampaignStoryZipOnSlack($stub: String!) {
  sendCampaignStoryZipOnSlack(stub: $stub)
}
    `;
export type SendCampaignStoryZipOnSlackMutationFn = Apollo.MutationFunction<SendCampaignStoryZipOnSlackMutation, SendCampaignStoryZipOnSlackMutationVariables>;

/**
 * __useSendCampaignStoryZipOnSlackMutation__
 *
 * To run a mutation, you first call `useSendCampaignStoryZipOnSlackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCampaignStoryZipOnSlackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCampaignStoryZipOnSlackMutation, { data, loading, error }] = useSendCampaignStoryZipOnSlackMutation({
 *   variables: {
 *      stub: // value for 'stub'
 *   },
 * });
 */
export function useSendCampaignStoryZipOnSlackMutation(baseOptions?: Apollo.MutationHookOptions<SendCampaignStoryZipOnSlackMutation, SendCampaignStoryZipOnSlackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCampaignStoryZipOnSlackMutation, SendCampaignStoryZipOnSlackMutationVariables>(SendCampaignStoryZipOnSlackDocument, options);
      }
export type SendCampaignStoryZipOnSlackMutationHookResult = ReturnType<typeof useSendCampaignStoryZipOnSlackMutation>;
export type SendCampaignStoryZipOnSlackMutationResult = Apollo.MutationResult<SendCampaignStoryZipOnSlackMutation>;
export type SendCampaignStoryZipOnSlackMutationOptions = Apollo.BaseMutationOptions<SendCampaignStoryZipOnSlackMutation, SendCampaignStoryZipOnSlackMutationVariables>;