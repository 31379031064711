import type { FC } from "react";

import styled from "@emotion/styled";
import { MMM_DD } from "@relatable/helpers/date";
import { prettifyNumber } from "@relatable/helpers/format";
import { palette } from "@relatable/ui/Palette";
import { Chart as ChartJS, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import { Line } from "react-chartjs-2";

import type { TimelineQuery } from "./generated";

ChartJS.register(LinearScale, PointElement, LineElement, Title, Tooltip);

export const TimelineProgressChart: FC<{
  history: TimelineQuery["campaign"][number]["campaign_progress"];
}> = ({ history }) => {
  return (
    <Root>
      <Line
        options={{
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          plugins: {
            tooltip: {
              callbacks: {
                label: context =>
                  `Progress: ${prettifyNumber(context.parsed.y, { percentages: true })}`
              }
            }
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Date"
              }
            },
            y: {
              min: 0,
              max: 1,
              ticks: {
                format: {
                  style: "percent"
                }
              },
              title: {
                display: true,
                text: "Campaign progress (%)"
              }
            }
          }
        }}
        data={{
          labels: history.map(({ date }) => MMM_DD(date)),
          datasets: [
            {
              label: "Campaign progress",
              data: history.map(({ percentage }) => percentage),
              borderColor: palette.primary.red
            }
          ]
        }}
      />
    </Root>
  );
};

const Root = styled.div`
  height: 400px;
`;
