import { Button } from "@relatable/ui/Button";
import { TextInput } from "@relatable/ui/TextInput";
import { useDebounce } from "@relatable/ui/hooks/useDebounce";
import { type FC, useMemo, useState } from "react";

import { CampaignDropdown } from "modules/campaign/common/CampaignDropdown";

import { AddCircle } from "@mui/icons-material";
import { LinksTable } from "./LinksTable";
import { useLinksByCampaignQuery, useLinksQuery } from "./generated";
import { extractRltToKey } from "./util";

const normalizeQuery = (q: string) => {
  return q.replace(/^https?:\/\//, "").replace(/^rlt\.to\//, "");
};

export const LinkList: FC = () => {
  const [query, setQuery] = useState("");
  const [campaignId, setCampaignId] = useState<number | null>(null);

  const { data: campaignLinks, loading: campaignLinksLoading } = useLinksByCampaignQuery({
    variables: {
      campaignId: campaignId ?? 0
    },
    skip: !campaignId
  });

  const campaignLinkKeys = useMemo(
    () =>
      campaignLinks?.campaign_user
        ?.flatMap(cu => (cu.tracking.shortlinks ?? []).map(l => extractRltToKey(l)))
        .filter(x => Boolean(x)),
    [campaignLinks]
  );

  const debouncedQuery = useDebounce(query, 700);

  const queryLike = `%${normalizeQuery(debouncedQuery).replace(/\s+/g, "%")}%`;
  const { data: linksData, loading: linksLoading } = useLinksQuery({
    variables: {
      where: {
        ...(campaignId !== null ? { key: { _in: campaignLinkKeys } } : {}),
        ...(query
          ? {
              _or: [{ key: { _ilike: queryLike } }, { target_url: { _ilike: queryLike } }]
            }
          : {})
      }
    }
  });

  const loading = campaignLinksLoading || linksLoading;

  return (
    <>
      <h2>rlt.to tracking links</h2>

      <div style={{ marginTop: 10, marginBottom: 15, display: "flex", gap: 15, padding: "15px 0" }}>
        <div style={{ display: "inline-block", width: 250 }}>
          <CampaignDropdown
            campaignId={campaignId}
            onSelect={setCampaignId}
            label="Filter by campaign"
          />
        </div>
        <TextInput label="Search" size="small" value={query} onChange={value => setQuery(value)} />
        <Button href="/links/new">
          <AddCircle style={{ marginRight: 10 }} />
          Add new
        </Button>
      </div>

      <LinksTable loading={loading} links={linksData?.link_clicks} />
    </>
  );
};
