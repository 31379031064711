import type { FC } from "react";

import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";

import { SnapContent } from "./SnapContent";
import { useCampaignSnapQuery } from "./generated";

export const Snap: FC = () => {
  const { snapId } = useParams<{ snapId: string }>();
  const { data: snapData } = useCampaignSnapQuery({
    variables: { snapId: Number(snapId) }
  });

  if (!snapData) {
    return <Loader />;
  }

  return <SnapContent snap={snapData} />;
};
