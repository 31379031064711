export const extractRltToKey = (url: string | undefined | null) => {
  if (!url) return null;

  try {
    const urlObj = new URL(url);
    if (!urlObj.hostname.endsWith("rlt.to") || !urlObj.pathname.startsWith("/")) return null;
    const parts = urlObj.pathname.split("/");
    return parts[1] || null;
  } catch {
    return null;
  }
};

export const withHttps = (url: string) => {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  }
  return `https://${url}`;
};

export const validateURL = (url: string) => {
  try {
    const urlObj = new URL(url);
    return ["http:", "https:"].includes(urlObj.protocol) && urlObj.hostname.includes(".");
  } catch {
    return false;
  }
};

export const validateRltKey = (key: string) => {
  return Boolean(key?.match(/^[0-9a-z_-]+$/));
};

export const prettifyLinkTargetURL = (url: string) => {
  let res = url.replace(/^(https?:\/\/)?(www\.)?/, "");
  if (res.length > 40) {
    res = `${res.slice(0, 40)}...`;
  }
  return res;
};
