import type { FC } from "react";

import styled from "@emotion/styled";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { Route, Routes } from "react-router-dom";

import { ContentContainer } from "lib/styled";

import { LinkEditor } from "./LinkEditor";
import { LinkList } from "./LinkList";

const Root = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const Links: FC = () => {
  useDocumentTitle("Links");

  return (
    <ContentContainer>
      <Root>
        <Routes>
          <Route path="/" element={<LinkList />} />
          <Route path="/edit/:linkId" element={<LinkEditor />} />
          <Route path="/new" element={<LinkEditor />} />
        </Routes>
      </Root>
    </ContentContainer>
  );
};
