import type { FC } from "react";

import styled from "@emotion/styled";
import { prettifyDate, prettifyNumber, prettifyPercentages } from "@relatable/helpers";
import { getUserFollowedBy } from "@relatable/helpers/user";
import { palette } from "@relatable/ui/Palette";

import { Insights, QueryStats } from "@mui/icons-material";
import type { CreatorListItemsQuery } from "../generated";

const Root = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: 20% 18% 24% 19% 19%;
  > .block {
    padding: 0 15px;
    font-weight: bold;
    border-right: 1px solid ${palette.gray[20]};

    &:last-child {
      border-right: 0;
    }

    > .title {
      font-weight: normal;
      font-size: 12px;
      color: ${palette.gray[60]};
      display: block;
      white-space: nowrap;
    }

    > .icon {
      color: ${palette.gray.white};
      background: ${palette.primary.red};
      border-radius: 9px;
      width: 18px;
      height: 18px;
      padding: 4px;
      box-sizing: border-box;
    }
  }
`;

export const CreatorListItemStats: FC<{
  user: CreatorListItemsQuery["users"][number];
}> = ({ user }) => {
  if (!user) return null;

  const followedBy = getUserFollowedBy(user);
  const avgEngagement = user.hypeauditor?.avg_engagement;
  return (
    <Root>
      <div className="block">
        <span className="title">Imported at</span>
        {prettifyDate(user.created_at)}
      </div>

      <div className="block">
        <span className="title">Followers</span>
        {followedBy !== null ? prettifyNumber(followedBy) : "-"}
      </div>

      <div className="block">
        <span className="title">Engagement</span>
        {avgEngagement ? prettifyPercentages({ of: avgEngagement, total: 1 }) : "-"}
      </div>

      <div className="block">
        <span className="title">Colab. Score</span>
        <QueryStats className="icon" />
        &nbsp;
        {prettifyNumber(user.campaign_users_aggregate.aggregate?.avg?.collaboration_score) || "-"}
      </div>

      <div className="block">
        <span className="title">Quality Score</span>
        <Insights className="icon" />
        &nbsp;
        {prettifyNumber(user.campaign_users_aggregate.aggregate?.avg?.content_quality_score) || "-"}
      </div>
    </Root>
  );
};
