import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import { UserInfoFragmentDoc, UserProfilePictureFragmentDoc, MediaStateFragmentDoc, CaptionStateFragmentDoc, IntercomFragmentDoc } from '../../../../../packages/gql/generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchListsQueryVariables = Types.Exact<{
  where: Types.Lists_Bool_Exp;
}>;


export type SearchListsQuery = { __typename?: 'Query', lists: Array<{ __typename?: 'lists', id: number, name?: string | null, users_in_lists_aggregate: { __typename?: 'users_in_lists_aggregate', aggregate?: { __typename?: 'users_in_lists_aggregate_fields', count: number } | null } }> };

export type HypeauditorListsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HypeauditorListsQuery = { __typename?: 'Query', hypeauditorLists: Array<{ __typename?: 'HypeauditorListResponse', id: number, name: string, count: number } | null> };

export type IframeDetailsQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
}>;


export type IframeDetailsQuery = { __typename?: 'Query', users_by_pk?: { __typename?: 'users', id: number, user_youtube?: { __typename?: 'users_youtube', id: number, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null } | null, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null } | null };

export type SearchCreatorQueryVariables = Types.Exact<{
  query: Types.Users_Bool_Exp;
}>;


export type SearchCreatorQuery = { __typename?: 'Query', users: Array<{ __typename?: 'users', id: number }> };

export type CreatorsInListQueryVariables = Types.Exact<{
  listId: Types.Scalars['Int']['input'];
}>;


export type CreatorsInListQuery = { __typename?: 'Query', users_in_lists: Array<{ __typename?: 'users_in_lists', user?: { __typename?: 'users', id: number } | null, campaign_user?: { __typename?: 'campaign_user', id: number, created_at?: string | null } | null }>, list?: { __typename?: 'lists', id: number, name?: string | null, platform?: Types.Platform_Enum | null, created_at?: string | null, campaigns: Array<{ __typename?: 'campaign', id: number, title: string, stub: string }> } | null };

export type CreatorSearchOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CreatorSearchOptionsQuery = { __typename?: 'Query', user_tag_types: Array<{ __typename?: 'user_tag_type', id: number, name: string }> };

export type ListsOfCreatorQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
}>;


export type ListsOfCreatorQuery = { __typename?: 'Query', users_in_lists: Array<{ __typename?: 'users_in_lists', user_id?: number | null, list?: { __typename?: 'lists', id: number, name?: string | null, users_in_lists_aggregate: { __typename?: 'users_in_lists_aggregate', aggregate?: { __typename?: 'users_in_lists_aggregate_fields', count: number } | null } } | null }> };

export type CreatorListItemsQueryVariables = Types.Exact<{
  userIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type CreatorListItemsQuery = { __typename?: 'Query', users: Array<{ __typename?: 'users', id: number, first_name?: string | null, created_at?: string | null, relatable_comment: string, country?: string | null, campaign_users_aggregate: { __typename?: 'campaign_user_aggregate', aggregate?: { __typename?: 'campaign_user_aggregate_fields', avg?: { __typename?: 'campaign_user_avg_fields', collaboration_score?: number | null, content_quality_score?: number | null } | null } | null }, campaign_users: Array<{ __typename?: 'campaign_user', id: number, state?: Types.Campaign_User_State_Enum | null, collaboration_score?: number | null, content_quality_score?: number | null, notes?: string | null, campaign: { __typename?: 'campaign', id: number, platform?: Types.Platform_Enum | null, list?: number | null } }>, user_instagram?: { __typename?: 'users_instagram', id: number, full_name?: string | null, profile_picture?: string | null, followed_by?: number | null, username: string } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, full_name?: string | null, profile_picture?: string | null, followed_by?: number | null, username?: string | null } | null, user_youtube?: { __typename?: 'users_youtube', id: number, thumbnail?: string | null, subscribers?: string | null, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, title?: string | null, profile_picture?: string | null, subscribers?: number | null, username: string } | null, hypeauditor?: { __typename?: 'hypeauditor', id: number, avg_engagement?: number | null } | null }> };

export type CreateListMutationVariables = Types.Exact<{
  object: Types.Lists_Insert_Input;
}>;


export type CreateListMutation = { __typename?: 'Mutation', insert_lists_one?: { __typename?: 'lists', id: number } | null };

export type AddUserToListMutationVariables = Types.Exact<{
  list_id: Types.Scalars['Int']['input'];
  user_id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  ig_username?: Types.InputMaybe<Types.Scalars['String']['input']>;
  tt_username?: Types.InputMaybe<Types.Scalars['String']['input']>;
  yt_channel_id?: Types.InputMaybe<Types.Scalars['String']['input']>;
  yt_username?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sc_username?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type AddUserToListMutation = { __typename?: 'Mutation', addUserToList: string };

export type RemoveUserFromListMutationVariables = Types.Exact<{
  list_id: Types.Scalars['Int']['input'];
  user_id: Types.Scalars['Int']['input'];
}>;


export type RemoveUserFromListMutation = { __typename?: 'Mutation', removeUserFromList: string };

export type ImportHypeauditorListMutationVariables = Types.Exact<{
  hypeauditor_list_id: Types.Scalars['Int']['input'];
  internal_list_id: Types.Scalars['Int']['input'];
}>;


export type ImportHypeauditorListMutation = { __typename?: 'Mutation', importHypeauditorList?: void | null };

export type ImportInternalListMutationVariables = Types.Exact<{
  src_list_id: Types.Scalars['Int']['input'];
  dest_list_id: Types.Scalars['Int']['input'];
}>;


export type ImportInternalListMutation = { __typename?: 'Mutation', copyListUsers: { __typename?: 'CopyListUsersResposne', affected_users: number, total_users: number } };


export const SearchListsDocument = gql`
    query searchLists($where: lists_bool_exp!) {
  lists(where: $where, limit: 30, order_by: {created_at: desc_nulls_last}) {
    id
    name
    users_in_lists_aggregate(distinct_on: user_id) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useSearchListsQuery__
 *
 * To run a query within a React component, call `useSearchListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchListsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSearchListsQuery(baseOptions: Apollo.QueryHookOptions<SearchListsQuery, SearchListsQueryVariables> & ({ variables: SearchListsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchListsQuery, SearchListsQueryVariables>(SearchListsDocument, options);
      }
export function useSearchListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchListsQuery, SearchListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchListsQuery, SearchListsQueryVariables>(SearchListsDocument, options);
        }
export function useSearchListsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchListsQuery, SearchListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchListsQuery, SearchListsQueryVariables>(SearchListsDocument, options);
        }
export type SearchListsQueryHookResult = ReturnType<typeof useSearchListsQuery>;
export type SearchListsLazyQueryHookResult = ReturnType<typeof useSearchListsLazyQuery>;
export type SearchListsSuspenseQueryHookResult = ReturnType<typeof useSearchListsSuspenseQuery>;
export type SearchListsQueryResult = Apollo.QueryResult<SearchListsQuery, SearchListsQueryVariables>;
export const HypeauditorListsDocument = gql`
    query hypeauditorLists {
  hypeauditorLists {
    id
    name
    count
  }
}
    `;

/**
 * __useHypeauditorListsQuery__
 *
 * To run a query within a React component, call `useHypeauditorListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHypeauditorListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHypeauditorListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHypeauditorListsQuery(baseOptions?: Apollo.QueryHookOptions<HypeauditorListsQuery, HypeauditorListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HypeauditorListsQuery, HypeauditorListsQueryVariables>(HypeauditorListsDocument, options);
      }
export function useHypeauditorListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HypeauditorListsQuery, HypeauditorListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HypeauditorListsQuery, HypeauditorListsQueryVariables>(HypeauditorListsDocument, options);
        }
export function useHypeauditorListsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HypeauditorListsQuery, HypeauditorListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HypeauditorListsQuery, HypeauditorListsQueryVariables>(HypeauditorListsDocument, options);
        }
export type HypeauditorListsQueryHookResult = ReturnType<typeof useHypeauditorListsQuery>;
export type HypeauditorListsLazyQueryHookResult = ReturnType<typeof useHypeauditorListsLazyQuery>;
export type HypeauditorListsSuspenseQueryHookResult = ReturnType<typeof useHypeauditorListsSuspenseQuery>;
export type HypeauditorListsQueryResult = Apollo.QueryResult<HypeauditorListsQuery, HypeauditorListsQueryVariables>;
export const IframeDetailsDocument = gql`
    query iframeDetails($userId: Int!) {
  users_by_pk(id: $userId) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useIframeDetailsQuery__
 *
 * To run a query within a React component, call `useIframeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIframeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIframeDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useIframeDetailsQuery(baseOptions: Apollo.QueryHookOptions<IframeDetailsQuery, IframeDetailsQueryVariables> & ({ variables: IframeDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IframeDetailsQuery, IframeDetailsQueryVariables>(IframeDetailsDocument, options);
      }
export function useIframeDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IframeDetailsQuery, IframeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IframeDetailsQuery, IframeDetailsQueryVariables>(IframeDetailsDocument, options);
        }
export function useIframeDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IframeDetailsQuery, IframeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IframeDetailsQuery, IframeDetailsQueryVariables>(IframeDetailsDocument, options);
        }
export type IframeDetailsQueryHookResult = ReturnType<typeof useIframeDetailsQuery>;
export type IframeDetailsLazyQueryHookResult = ReturnType<typeof useIframeDetailsLazyQuery>;
export type IframeDetailsSuspenseQueryHookResult = ReturnType<typeof useIframeDetailsSuspenseQuery>;
export type IframeDetailsQueryResult = Apollo.QueryResult<IframeDetailsQuery, IframeDetailsQueryVariables>;
export const SearchCreatorDocument = gql`
    query searchCreator($query: users_bool_exp!) {
  users(where: $query, limit: 20) {
    id
  }
}
    `;

/**
 * __useSearchCreatorQuery__
 *
 * To run a query within a React component, call `useSearchCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCreatorQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchCreatorQuery(baseOptions: Apollo.QueryHookOptions<SearchCreatorQuery, SearchCreatorQueryVariables> & ({ variables: SearchCreatorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCreatorQuery, SearchCreatorQueryVariables>(SearchCreatorDocument, options);
      }
export function useSearchCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCreatorQuery, SearchCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCreatorQuery, SearchCreatorQueryVariables>(SearchCreatorDocument, options);
        }
export function useSearchCreatorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchCreatorQuery, SearchCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCreatorQuery, SearchCreatorQueryVariables>(SearchCreatorDocument, options);
        }
export type SearchCreatorQueryHookResult = ReturnType<typeof useSearchCreatorQuery>;
export type SearchCreatorLazyQueryHookResult = ReturnType<typeof useSearchCreatorLazyQuery>;
export type SearchCreatorSuspenseQueryHookResult = ReturnType<typeof useSearchCreatorSuspenseQuery>;
export type SearchCreatorQueryResult = Apollo.QueryResult<SearchCreatorQuery, SearchCreatorQueryVariables>;
export const CreatorsInListDocument = gql`
    query creatorsInList($listId: Int!) {
  users_in_lists(where: {lists_id: {_eq: $listId}}, distinct_on: [user_id]) {
    user {
      id
    }
    campaign_user {
      id
      created_at
    }
  }
  list: lists_by_pk(id: $listId) {
    id
    name
    platform
    created_at
    campaigns {
      id
      title
      stub
    }
  }
}
    `;

/**
 * __useCreatorsInListQuery__
 *
 * To run a query within a React component, call `useCreatorsInListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorsInListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorsInListQuery({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useCreatorsInListQuery(baseOptions: Apollo.QueryHookOptions<CreatorsInListQuery, CreatorsInListQueryVariables> & ({ variables: CreatorsInListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreatorsInListQuery, CreatorsInListQueryVariables>(CreatorsInListDocument, options);
      }
export function useCreatorsInListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorsInListQuery, CreatorsInListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreatorsInListQuery, CreatorsInListQueryVariables>(CreatorsInListDocument, options);
        }
export function useCreatorsInListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreatorsInListQuery, CreatorsInListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreatorsInListQuery, CreatorsInListQueryVariables>(CreatorsInListDocument, options);
        }
export type CreatorsInListQueryHookResult = ReturnType<typeof useCreatorsInListQuery>;
export type CreatorsInListLazyQueryHookResult = ReturnType<typeof useCreatorsInListLazyQuery>;
export type CreatorsInListSuspenseQueryHookResult = ReturnType<typeof useCreatorsInListSuspenseQuery>;
export type CreatorsInListQueryResult = Apollo.QueryResult<CreatorsInListQuery, CreatorsInListQueryVariables>;
export const CreatorSearchOptionsDocument = gql`
    query creatorSearchOptions {
  user_tag_types: user_tag_type {
    id
    name
  }
}
    `;

/**
 * __useCreatorSearchOptionsQuery__
 *
 * To run a query within a React component, call `useCreatorSearchOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorSearchOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorSearchOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreatorSearchOptionsQuery(baseOptions?: Apollo.QueryHookOptions<CreatorSearchOptionsQuery, CreatorSearchOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreatorSearchOptionsQuery, CreatorSearchOptionsQueryVariables>(CreatorSearchOptionsDocument, options);
      }
export function useCreatorSearchOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorSearchOptionsQuery, CreatorSearchOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreatorSearchOptionsQuery, CreatorSearchOptionsQueryVariables>(CreatorSearchOptionsDocument, options);
        }
export function useCreatorSearchOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreatorSearchOptionsQuery, CreatorSearchOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreatorSearchOptionsQuery, CreatorSearchOptionsQueryVariables>(CreatorSearchOptionsDocument, options);
        }
export type CreatorSearchOptionsQueryHookResult = ReturnType<typeof useCreatorSearchOptionsQuery>;
export type CreatorSearchOptionsLazyQueryHookResult = ReturnType<typeof useCreatorSearchOptionsLazyQuery>;
export type CreatorSearchOptionsSuspenseQueryHookResult = ReturnType<typeof useCreatorSearchOptionsSuspenseQuery>;
export type CreatorSearchOptionsQueryResult = Apollo.QueryResult<CreatorSearchOptionsQuery, CreatorSearchOptionsQueryVariables>;
export const ListsOfCreatorDocument = gql`
    query listsOfCreator($userId: Int!) {
  users_in_lists(where: {user_id: {_eq: $userId}}) {
    user_id
    list {
      id
      name
      users_in_lists_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useListsOfCreatorQuery__
 *
 * To run a query within a React component, call `useListsOfCreatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useListsOfCreatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListsOfCreatorQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListsOfCreatorQuery(baseOptions: Apollo.QueryHookOptions<ListsOfCreatorQuery, ListsOfCreatorQueryVariables> & ({ variables: ListsOfCreatorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListsOfCreatorQuery, ListsOfCreatorQueryVariables>(ListsOfCreatorDocument, options);
      }
export function useListsOfCreatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListsOfCreatorQuery, ListsOfCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListsOfCreatorQuery, ListsOfCreatorQueryVariables>(ListsOfCreatorDocument, options);
        }
export function useListsOfCreatorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListsOfCreatorQuery, ListsOfCreatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListsOfCreatorQuery, ListsOfCreatorQueryVariables>(ListsOfCreatorDocument, options);
        }
export type ListsOfCreatorQueryHookResult = ReturnType<typeof useListsOfCreatorQuery>;
export type ListsOfCreatorLazyQueryHookResult = ReturnType<typeof useListsOfCreatorLazyQuery>;
export type ListsOfCreatorSuspenseQueryHookResult = ReturnType<typeof useListsOfCreatorSuspenseQuery>;
export type ListsOfCreatorQueryResult = Apollo.QueryResult<ListsOfCreatorQuery, ListsOfCreatorQueryVariables>;
export const CreatorListItemsDocument = gql`
    query creatorListItems($userIds: [Int!]!) {
  users(where: {id: {_in: $userIds}}) {
    id
    first_name
    created_at
    relatable_comment
    country
    campaign_users_aggregate {
      aggregate {
        avg {
          collaboration_score
          content_quality_score
        }
      }
    }
    campaign_users(
      order_by: {campaign: {campaign_content_settings_aggregate: {min: {publish_date_min: desc}}}}
    ) {
      id
      state
      collaboration_score
      content_quality_score
      notes
      campaign {
        id
        platform
        list
      }
    }
    ...UserInfo
    user_instagram {
      id
      full_name
      profile_picture
      followed_by
    }
    user_tiktok {
      id
      full_name
      profile_picture
      followed_by
    }
    user_youtube {
      id
      thumbnail
      subscribers
    }
    user_snapchat {
      id
      title
      profile_picture
      subscribers
    }
    hypeauditor {
      id
      avg_engagement
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useCreatorListItemsQuery__
 *
 * To run a query within a React component, call `useCreatorListItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatorListItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatorListItemsQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useCreatorListItemsQuery(baseOptions: Apollo.QueryHookOptions<CreatorListItemsQuery, CreatorListItemsQueryVariables> & ({ variables: CreatorListItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreatorListItemsQuery, CreatorListItemsQueryVariables>(CreatorListItemsDocument, options);
      }
export function useCreatorListItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatorListItemsQuery, CreatorListItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreatorListItemsQuery, CreatorListItemsQueryVariables>(CreatorListItemsDocument, options);
        }
export function useCreatorListItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreatorListItemsQuery, CreatorListItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreatorListItemsQuery, CreatorListItemsQueryVariables>(CreatorListItemsDocument, options);
        }
export type CreatorListItemsQueryHookResult = ReturnType<typeof useCreatorListItemsQuery>;
export type CreatorListItemsLazyQueryHookResult = ReturnType<typeof useCreatorListItemsLazyQuery>;
export type CreatorListItemsSuspenseQueryHookResult = ReturnType<typeof useCreatorListItemsSuspenseQuery>;
export type CreatorListItemsQueryResult = Apollo.QueryResult<CreatorListItemsQuery, CreatorListItemsQueryVariables>;
export const CreateListDocument = gql`
    mutation createList($object: lists_insert_input!) {
  insert_lists_one(object: $object) {
    id
  }
}
    `;
export type CreateListMutationFn = Apollo.MutationFunction<CreateListMutation, CreateListMutationVariables>;

/**
 * __useCreateListMutation__
 *
 * To run a mutation, you first call `useCreateListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListMutation, { data, loading, error }] = useCreateListMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateListMutation(baseOptions?: Apollo.MutationHookOptions<CreateListMutation, CreateListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateListMutation, CreateListMutationVariables>(CreateListDocument, options);
      }
export type CreateListMutationHookResult = ReturnType<typeof useCreateListMutation>;
export type CreateListMutationResult = Apollo.MutationResult<CreateListMutation>;
export type CreateListMutationOptions = Apollo.BaseMutationOptions<CreateListMutation, CreateListMutationVariables>;
export const AddUserToListDocument = gql`
    mutation addUserToList($list_id: Int!, $user_id: Int, $ig_username: String, $tt_username: String, $yt_channel_id: String, $yt_username: String, $sc_username: String) {
  addUserToList(
    objects: {list_id: $list_id, user_id: $user_id, ig_username: $ig_username, tt_username: $tt_username, yt_channel_id: $yt_channel_id, yt_username: $yt_username, sc_username: $sc_username}
  )
}
    `;
export type AddUserToListMutationFn = Apollo.MutationFunction<AddUserToListMutation, AddUserToListMutationVariables>;

/**
 * __useAddUserToListMutation__
 *
 * To run a mutation, you first call `useAddUserToListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToListMutation, { data, loading, error }] = useAddUserToListMutation({
 *   variables: {
 *      list_id: // value for 'list_id'
 *      user_id: // value for 'user_id'
 *      ig_username: // value for 'ig_username'
 *      tt_username: // value for 'tt_username'
 *      yt_channel_id: // value for 'yt_channel_id'
 *      yt_username: // value for 'yt_username'
 *      sc_username: // value for 'sc_username'
 *   },
 * });
 */
export function useAddUserToListMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToListMutation, AddUserToListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToListMutation, AddUserToListMutationVariables>(AddUserToListDocument, options);
      }
export type AddUserToListMutationHookResult = ReturnType<typeof useAddUserToListMutation>;
export type AddUserToListMutationResult = Apollo.MutationResult<AddUserToListMutation>;
export type AddUserToListMutationOptions = Apollo.BaseMutationOptions<AddUserToListMutation, AddUserToListMutationVariables>;
export const RemoveUserFromListDocument = gql`
    mutation removeUserFromList($list_id: Int!, $user_id: Int!) {
  removeUserFromList(objects: {list_id: $list_id, user_id: $user_id})
}
    `;
export type RemoveUserFromListMutationFn = Apollo.MutationFunction<RemoveUserFromListMutation, RemoveUserFromListMutationVariables>;

/**
 * __useRemoveUserFromListMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromListMutation, { data, loading, error }] = useRemoveUserFromListMutation({
 *   variables: {
 *      list_id: // value for 'list_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useRemoveUserFromListMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromListMutation, RemoveUserFromListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromListMutation, RemoveUserFromListMutationVariables>(RemoveUserFromListDocument, options);
      }
export type RemoveUserFromListMutationHookResult = ReturnType<typeof useRemoveUserFromListMutation>;
export type RemoveUserFromListMutationResult = Apollo.MutationResult<RemoveUserFromListMutation>;
export type RemoveUserFromListMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromListMutation, RemoveUserFromListMutationVariables>;
export const ImportHypeauditorListDocument = gql`
    mutation importHypeauditorList($hypeauditor_list_id: Int!, $internal_list_id: Int!) {
  importHypeauditorList(
    objects: {hypeauditor_list_id: $hypeauditor_list_id, internal_list_id: $internal_list_id}
  )
}
    `;
export type ImportHypeauditorListMutationFn = Apollo.MutationFunction<ImportHypeauditorListMutation, ImportHypeauditorListMutationVariables>;

/**
 * __useImportHypeauditorListMutation__
 *
 * To run a mutation, you first call `useImportHypeauditorListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportHypeauditorListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importHypeauditorListMutation, { data, loading, error }] = useImportHypeauditorListMutation({
 *   variables: {
 *      hypeauditor_list_id: // value for 'hypeauditor_list_id'
 *      internal_list_id: // value for 'internal_list_id'
 *   },
 * });
 */
export function useImportHypeauditorListMutation(baseOptions?: Apollo.MutationHookOptions<ImportHypeauditorListMutation, ImportHypeauditorListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportHypeauditorListMutation, ImportHypeauditorListMutationVariables>(ImportHypeauditorListDocument, options);
      }
export type ImportHypeauditorListMutationHookResult = ReturnType<typeof useImportHypeauditorListMutation>;
export type ImportHypeauditorListMutationResult = Apollo.MutationResult<ImportHypeauditorListMutation>;
export type ImportHypeauditorListMutationOptions = Apollo.BaseMutationOptions<ImportHypeauditorListMutation, ImportHypeauditorListMutationVariables>;
export const ImportInternalListDocument = gql`
    mutation importInternalList($src_list_id: Int!, $dest_list_id: Int!) {
  copyListUsers(objects: {src_list_id: $src_list_id, dest_list_id: $dest_list_id}) {
    affected_users
    total_users
  }
}
    `;
export type ImportInternalListMutationFn = Apollo.MutationFunction<ImportInternalListMutation, ImportInternalListMutationVariables>;

/**
 * __useImportInternalListMutation__
 *
 * To run a mutation, you first call `useImportInternalListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportInternalListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importInternalListMutation, { data, loading, error }] = useImportInternalListMutation({
 *   variables: {
 *      src_list_id: // value for 'src_list_id'
 *      dest_list_id: // value for 'dest_list_id'
 *   },
 * });
 */
export function useImportInternalListMutation(baseOptions?: Apollo.MutationHookOptions<ImportInternalListMutation, ImportInternalListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportInternalListMutation, ImportInternalListMutationVariables>(ImportInternalListDocument, options);
      }
export type ImportInternalListMutationHookResult = ReturnType<typeof useImportInternalListMutation>;
export type ImportInternalListMutationResult = Apollo.MutationResult<ImportInternalListMutation>;
export type ImportInternalListMutationOptions = Apollo.BaseMutationOptions<ImportInternalListMutation, ImportInternalListMutationVariables>;