import { useCallback, useEffect, useState } from "react";

import { useSnackbar } from "@relatable/ui/Snackbar";
import copy from "copy-to-clipboard";

export const useCopyToClipboard = (
  resetInterval?: number
): { copyToClipboard: (text: string | number) => void; isCopied: boolean } => {
  const [isCopied, setCopied] = useState(false);
  const snackbar = useSnackbar();

  const handleCopy = useCallback(
    text => {
      if (typeof text === "string" || typeof text === "number") {
        copy(text.toString());
        setCopied(true);
        snackbar.success("Copied to clipboard");
      } else {
        setCopied(false);
        snackbar.error(
          `Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`
        );
      }
    },
    [snackbar]
  );

  useEffect(() => {
    let timeout;
    if (isCopied && resetInterval) {
      timeout = setTimeout(() => setCopied(false), resetInterval);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied, resetInterval]);

  return { copyToClipboard: handleCopy, isCopied };
};
