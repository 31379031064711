import type { FC } from "react";

import styled from "@emotion/styled";
import { Card, CardContent, CardHeader, Paper } from "@mui/material";
import { isAfter, isBefore, prettifyDate, prettifyNumber } from "@relatable/helpers";
import { useParams } from "react-router-dom";

import { useLinksByKeyQuery } from "modules/links/generated";
import { extractRltToKey } from "modules/links/util";

import { useStoryStatsQuery } from "./generated";

export const StatsBody: FC = () => {
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  const { data } = useStoryStatsQuery({ variables: { stubs: campaignStubs } });

  const rltToKeys = (data?.campaigns?.flatMap(c => c.stories.flatMap(s => s.clip_urls)) ?? [])
    .map(p => extractRltToKey(p.track_url))
    .filter(Boolean) as string[];

  const { data: linkData } = useLinksByKeyQuery({
    variables: { keys: rltToKeys },
    skip: !rltToKeys.length
  });

  const campaigns = data?.campaigns || [];
  const stories = campaigns.flatMap(campaign => campaign?.stories || []);

  const leftCards = [
    {
      title: "Reached stories",
      value: prettifyNumber(stories.length)
    },
    {
      title: "Actual reach",
      value: prettifyNumber(
        stories.reduce((acc, story) => acc + (story.clips?.aggregate?.max?.actual_reach || 0), 0)
      )
    },
    {
      title: "Impressions",
      value: prettifyNumber(
        stories.reduce((acc, story) => acc + (story.clips?.aggregate?.max?.impressions || 0), 0)
      )
    },
    {
      title: "Audience reach (avg)",
      value: prettifyNumber(
        stories.reduce(
          (acc, story) =>
            acc +
            (story.clips?.aggregate?.max?.actual_reach || 0) /
              (story.user?.user_instagram?.followed_by || 0),
          0
        ) / stories.length,
        { percentages: true }
      )
    },
    {
      title: "View Through Rate (avg)",
      value: prettifyNumber(
        stories.reduce(
          (acc, story) =>
            acc +
            (story.clips?.aggregate?.min?.actual_reach || 0) /
              (story.clips?.aggregate?.max?.actual_reach || 0),
          0
        ) / stories.length,
        { percentages: true }
      )
    }
  ];

  const firstDate = campaigns
    .map(c => c.campaign_content_settings_aggregate.aggregate?.min?.publish_date_min)
    .reduce((prev, curr) => (prev && isBefore(prev, curr || "") ? prev : curr), null);

  const lastDate = campaigns
    .map(c => c.campaign_content_settings_aggregate.aggregate?.max?.publish_date_max)
    .reduce((prev, curr) => (prev && isAfter(prev, curr || "") ? prev : curr), null);

  const maxClicks = stories.reduce((acc, story) => {
    const maxBitlyClicks = story.clips?.aggregate?.max?.link_clicks || 0;

    const allRltKeys = story.clip_urls
      .map(u => extractRltToKey(u.track_url))
      .filter(Boolean) as string[];

    const maxRltClicks = Math.max(
      ...allRltKeys.map(key => linkData?.link_clicks.find(lc => lc.key === key)?.clicks || 0),
      0
    );

    return acc + maxBitlyClicks + maxRltClicks;
  }, 0);

  const rightCards = [
    { title: "First post date", value: prettifyDate(firstDate) },
    { title: "Last post date", value: prettifyDate(lastDate) },
    {
      title: "Tracked clicks",
      value: prettifyNumber(maxClicks)
    },
    {
      title: "Reach CTR (avg)",
      value: prettifyNumber(
        stories.reduce(
          (acc, story) => acc + maxClicks / (story.user?.user_instagram?.followed_by || 0),
          0
        ) / stories.length,
        { percentages: true }
      )
    },
    {
      title: "Actual reach CTR (avg)",
      value: prettifyNumber(
        stories.reduce(
          (acc, story) => acc + maxClicks / (story.clips?.aggregate?.max?.actual_reach || 0),
          0
        ) / stories.length,
        { percentages: true }
      )
    }
  ];

  return (
    <StatsContainer>
      <Section elevation={10}>
        {leftCards.map(card => (
          <Card key={card.title}>
            <CardHeader subheader={card.title} />
            <SCardContent>{card.value}</SCardContent>
          </Card>
        ))}
      </Section>

      <Section elevation={10}>
        {rightCards.map(card => (
          <Card key={card.title}>
            <CardHeader subheader={card.title} />
            <SCardContent>{card.value}</SCardContent>
          </Card>
        ))}
      </Section>
    </StatsContainer>
  );
};

const SCardContent = styled(CardContent)`
  padding-top: 0 !important;
  font-size: 20px;
`;

const Section = styled(Paper)`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  padding: 20px;
`;

const StatsContainer = styled.div`
  display: grid;
  gap: 2%;
  grid-template-columns: 49% 49%;
`;
