import type { FC, PropsWithChildren } from "react";

import { Typography } from "@mui/material";

type HeadlineProps = {
  className?: string;
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

export const Headline: FC<PropsWithChildren<HeadlineProps>> = ({
  className = "",
  children,
  variant
}) => {
  return (
    <Typography className={className} variant={variant}>
      {children}
    </Typography>
  );
};
