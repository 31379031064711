import type { FC } from "react";

import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";
import { type AddFields, Table } from "@relatable/ui/Table";
import { Link, useParams } from "react-router-dom";

import { LinearProgress } from "components/ui/LinearProgress";

import { Add, ContentCopy, JoinLeft, QueryStats } from "@mui/icons-material";
import { useMessageListQuery } from "./generated";

export const MessageList: FC = () => {
  const { campaignStub } = useParams<{ campaignStub: string }>();

  const { data, loading } = useMessageListQuery({
    variables: { campaignStub: campaignStub ?? "" },
    skip: !campaignStub
  });

  return (
    <Root>
      <div>
        <Button icon={<Add />} href="new" style={{ marginRight: 10 }}>
          Add new message
        </Button>
        <Button icon={<ContentCopy />} href="link" style={{ marginRight: 10 }}>
          Add existing message
        </Button>
        <Button icon={<JoinLeft />} href="overrides">
          Message overrides
        </Button>
      </div>
      <br />
      <Table
        tableId="campaign-messages"
        loading={loading}
        columns={[
          {
            field: "stats",
            headerName: "Stats",
            renderCell: ({ row }) => (
              <Link to={`stats/${row.name}`}>
                <QueryStats />
              </Link>
            )
          },
          {
            field: "name",
            headerName: "Message",
            renderCell: ({ value }) => <Link to={`edit/${value}`}>{value}</Link>
          },
          {
            field: "opened",
            headerName: "Opened",
            renderCell: ({ row, value }) => (
              <LinearProgress label="Opened" value={value} max={row.sent} />
            )
          },
          {
            field: "clicked",
            headerName: "Clicked",
            renderCell: ({ row, value }) => (
              <LinearProgress label="Clicked" value={value} max={row.sent} />
            )
          },
          {
            field: "clicked",
            headerName: "Click/Open",
            renderCell: ({ row }) => (
              <LinearProgress
                label="Click/Open"
                value={row.opened > 0 ? row.clicked : 0}
                max={row.opened}
              />
            )
          }
        ]}
        rows={
          data?.campaign_contents.map(content => {
            const r = {
              key: content.id,
              name: content.content?.name,
              sent: content.mandrills.length,
              opened: content.mandrills.reduce((acc, curr) => acc + (curr?.opens ? 1 : 0), 0),
              clicked: content.mandrills.reduce((acc, curr) => acc + (curr?.clicks ? 1 : 0), 0)
            };
            return r as AddFields<typeof r, "stats">;
          }) ?? []
        }
      />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
