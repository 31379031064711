import type { FC } from "react";

import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const ToolbarButton: FC<{
  icon: React.JSX.Element;
  active?: boolean;
  disabled?: boolean;
  title?: string;
  onClick: () => void;
}> = ({ icon, active, disabled, title, onClick }) => {
  return (
    <StyledButton
      color={active ? "primary" : "secondary"}
      disabled={disabled}
      title={title}
      onClick={event => {
        event.preventDefault();
        onClick();
      }}
    >
      {icon}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  width: 35px;
  min-width: 35px;
  height: 35px;
  margin-right: 10px;
`;
