import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignDetailsQueryVariables = Types.Exact<{
  stub: Types.Scalars['String']['input'];
}>;


export type CampaignDetailsQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, archived?: boolean | null, title: string, updated_at?: string | null, currency?: string | null, tracking?: any | null, open_to_join?: boolean | null, language?: string | null, platform?: Types.Platform_Enum | null, max_budget?: number | null, cpm_payout?: number | null, target_reach?: number | null, max_participants?: number | null, target_participants?: number | null, logistics_enabled: boolean, campaign_owner?: number | null, campaign_client?: string | null, hashtags?: string | null, case_sensitive_tags?: boolean | null, creative_brief_url?: string | null, list?: number | null, fixed_payout?: number | null, min_payout?: number | null, max_payout?: number | null, features?: string | string[] | null, default_payout_currency?: string | null, exchange_rate?: number | null, agreement_penalty?: string | null, product_range?: string | null, agreement_attachment_url?: string | null, market_ids: string | number[], vertical_ids: string | number[], project: { __typename?: 'project', id: number, name: string, stub: string, hubspot_deal_id: string, client: { __typename?: 'clients', name?: string | null, hubspot_company_id: string }, campaigns: Array<{ __typename?: 'campaign', id: number, title: string, stub: string }>, im_project_budgets: Array<{ __typename?: 'project_budget', id: number, target_margin: number, revenue_allocation: number, type: string }>, project_team_members: Array<{ __typename?: 'project_team_member', id: number, account: { __typename?: 'accounts', id: number, full_name?: string | null } }> } }> };

export type UpdateCampaignMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  set: Types.Campaign_Set_Input;
}>;


export type UpdateCampaignMutation = { __typename?: 'Mutation', update_campaign_by_pk?: { __typename?: 'campaign', id: number } | null };

export type DownloadBriefAttachmentMutationVariables = Types.Exact<{
  url: Types.Scalars['String']['input'];
}>;


export type DownloadBriefAttachmentMutation = { __typename?: 'Mutation', downloadBriefAttachment?: { __typename?: 'DownloadBriefAttachmentReturn', url?: string | null, error?: string | null } | null };


export const CampaignDetailsDocument = gql`
    query campaignDetails($stub: String!) {
  campaigns: campaign(where: {stub: {_eq: $stub}}, limit: 1) {
    id
    archived
    title
    updated_at
    currency
    tracking
    open_to_join
    language
    platform
    max_budget
    cpm_payout
    target_reach
    max_participants
    target_participants
    logistics_enabled
    campaign_owner
    campaign_client
    hashtags
    case_sensitive_tags
    creative_brief_url
    list
    fixed_payout
    min_payout
    max_payout
    features
    default_payout_currency
    exchange_rate
    agreement_penalty
    product_range
    agreement_attachment_url
    market_ids
    vertical_ids
    project {
      id
      name
      stub
      hubspot_deal_id
      client {
        name
        hubspot_company_id
      }
      campaigns {
        id
        title
        stub
      }
      im_project_budgets: project_budgets(
        where: {type: {_ilike: "influencer_marketing%"}}
      ) {
        id
        target_margin
        revenue_allocation
        type
      }
      project_team_members {
        id
        account {
          id
          full_name
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignDetailsQuery__
 *
 * To run a query within a React component, call `useCampaignDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignDetailsQuery({
 *   variables: {
 *      stub: // value for 'stub'
 *   },
 * });
 */
export function useCampaignDetailsQuery(baseOptions: Apollo.QueryHookOptions<CampaignDetailsQuery, CampaignDetailsQueryVariables> & ({ variables: CampaignDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignDetailsQuery, CampaignDetailsQueryVariables>(CampaignDetailsDocument, options);
      }
export function useCampaignDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignDetailsQuery, CampaignDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignDetailsQuery, CampaignDetailsQueryVariables>(CampaignDetailsDocument, options);
        }
export function useCampaignDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignDetailsQuery, CampaignDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignDetailsQuery, CampaignDetailsQueryVariables>(CampaignDetailsDocument, options);
        }
export type CampaignDetailsQueryHookResult = ReturnType<typeof useCampaignDetailsQuery>;
export type CampaignDetailsLazyQueryHookResult = ReturnType<typeof useCampaignDetailsLazyQuery>;
export type CampaignDetailsSuspenseQueryHookResult = ReturnType<typeof useCampaignDetailsSuspenseQuery>;
export type CampaignDetailsQueryResult = Apollo.QueryResult<CampaignDetailsQuery, CampaignDetailsQueryVariables>;
export const UpdateCampaignDocument = gql`
    mutation updateCampaign($id: Int!, $set: campaign_set_input!) {
  update_campaign_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;
export type UpdateCampaignMutationFn = Apollo.MutationFunction<UpdateCampaignMutation, UpdateCampaignMutationVariables>;

/**
 * __useUpdateCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignMutation, { data, loading, error }] = useUpdateCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignMutation, UpdateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignMutation, UpdateCampaignMutationVariables>(UpdateCampaignDocument, options);
      }
export type UpdateCampaignMutationHookResult = ReturnType<typeof useUpdateCampaignMutation>;
export type UpdateCampaignMutationResult = Apollo.MutationResult<UpdateCampaignMutation>;
export type UpdateCampaignMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignMutation, UpdateCampaignMutationVariables>;
export const DownloadBriefAttachmentDocument = gql`
    mutation downloadBriefAttachment($url: String!) {
  downloadBriefAttachment(url: $url) {
    url
    error
  }
}
    `;
export type DownloadBriefAttachmentMutationFn = Apollo.MutationFunction<DownloadBriefAttachmentMutation, DownloadBriefAttachmentMutationVariables>;

/**
 * __useDownloadBriefAttachmentMutation__
 *
 * To run a mutation, you first call `useDownloadBriefAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadBriefAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadBriefAttachmentMutation, { data, loading, error }] = useDownloadBriefAttachmentMutation({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useDownloadBriefAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DownloadBriefAttachmentMutation, DownloadBriefAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadBriefAttachmentMutation, DownloadBriefAttachmentMutationVariables>(DownloadBriefAttachmentDocument, options);
      }
export type DownloadBriefAttachmentMutationHookResult = ReturnType<typeof useDownloadBriefAttachmentMutation>;
export type DownloadBriefAttachmentMutationResult = Apollo.MutationResult<DownloadBriefAttachmentMutation>;
export type DownloadBriefAttachmentMutationOptions = Apollo.BaseMutationOptions<DownloadBriefAttachmentMutation, DownloadBriefAttachmentMutationVariables>;