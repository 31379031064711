import { type FC, useEffect, useMemo, useState } from "react";

import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useDebounce } from "@relatable/ui/hooks/useDebounce";

import { useSearchContentLazyQuery } from "./generated";

export const ContentSearch: FC<{
  contentId: number | null;
  onChange: (contentId: number | null) => void;
}> = ({ contentId, onChange }) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string | null>(null);
  const debouncedQuery = useDebounce(query, 700);

  const [
    searchContent,
    {
      data: { contents = [] } = {},
      loading
    }
  ] = useSearchContentLazyQuery();

  useEffect(() => {
    searchContent({
      variables: {
        where: { name: { _ilike: `%${debouncedQuery ?? ""}%` } }
      }
    });
  }, [searchContent, debouncedQuery]);

  // memo is required to prevent reset of internal state in <Autocomplete />
  const contentOptions = useMemo(
    () =>
      contents.map(content => ({
        id: content.id,
        label: content.name
      })),
    [contents]
  );

  const value = contentId ? (contentOptions.find(l => l.id === contentId) ?? null) : null;

  return (
    <Autocomplete
      disablePortal
      options={contentOptions}
      onChange={(e, v) => {
        onChange(v?.id ?? null);
      }}
      value={value}
      inputValue={(open ? query : value?.label) ?? ""}
      onInputChange={(e, v, reason) => {
        if (reason === "input") setQuery(v);
      }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading}
      loadingText="Loading..."
      isOptionEqualToValue={(option, v) => option.id === v.id}
      autoSelect
      /* Built-in filtering needs to be disabled at lazy loaded requests */
      filterOptions={x => x}
      renderInput={params => (
        <TextField
          {...params}
          label="Message"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};
