import {
  getEstimatedProjectBudgetInSEK,
  getProjectBudgetUsedInSEK,
  getProjectMargin,
  getProjectPayoutBudgetUsedInSEK
} from "lib/calculations";

import type { FinanceQuery } from "./generated";

export function reshapeClients({
  clients,
  filterValues,
  showArchived
}: {
  clients: FinanceQuery["clients"];
  filterValues: {
    status: "all" | "not_invoiced" | "late" | "sent";
  };
  showArchived: boolean;
}) {
  const filteredData = clients
    .map(c => ({
      ...c,
      projects: c.projects.filter(
        p =>
          shouldIncludeArchived(Boolean(p.archived), showArchived, {
            project_invoices: p.project_invoices
          }) && shouldIncludeInvoiceStatus(p.project_invoices || [], filterValues.status)
      )
    }))
    .filter(c => c.projects.length > 0);

  const clientsWithProjectMargins = filteredData.map(c => ({
    ...c,
    projects: c.projects.map(project => {
      const invoiceAmountSEK = project.project_invoicing_detail
        ? (project.project_invoicing_detail.total_amount || 0) /
          project.project_invoicing_detail.exchange_rate
        : 0;
      const payoutBudgetUsedInSEK = getProjectPayoutBudgetUsedInSEK(
        project?.campaigns.map(
          ca =>
            (ca.campaign_participants_aggregate.aggregate?.sum?.local_invoice_pay || 0) /
            (ca.exchange_rate || 1)
        )
      );
      const budgetUsed = getProjectBudgetUsedInSEK(project.project_budgets, {
        payoutBudgetUsedInSEK
      });
      const estimatedBudgtUsed = getEstimatedProjectBudgetInSEK(project.project_budgets);
      const projectMargin = getProjectMargin(budgetUsed, invoiceAmountSEK);
      const estimatedProjectMargin = getProjectMargin(estimatedBudgtUsed, invoiceAmountSEK);

      return {
        ...project,
        projectMargin,
        estimatedProjectMargin
      };
    })
  }));

  const metadata = clientsWithProjectMargins.reduce(
    (metadataAcc, c, index) => {
      const isLastClient = index === clientsWithProjectMargins.length - 1;

      const projectsCount = metadataAcc.projectsCount + c.projects.length;
      const invoicesToSendCount =
        metadataAcc.invoicesToSendCount +
        c.projects.reduce(
          (acc2, { project_invoices = [] }) =>
            acc2 + project_invoices.filter(pi => !pi.invoiced).length,
          0
        );

      const { avgMarginSum } = c.projects.reduce(
        (projectAcc, { projectMargin = 0 }) => ({
          avgMarginSum: projectAcc.avgMarginSum + projectMargin
        }),
        {
          avgMarginSum: metadataAcc.avgMarginSum
        }
      );

      return {
        projectsCount,
        invoicesToSendCount,
        avgMarginSum,
        avgMargin: isLastClient ? avgMarginSum / projectsCount : 0
      };
    },
    {
      projectsCount: 0,
      invoicesToSendCount: 0,
      avgMarginSum: 0,
      avgMargin: 0
    }
  );

  return {
    clients: clientsWithProjectMargins,
    metadata
  };
}

function shouldIncludeInvoiceStatus(
  project_invoices: FinanceQuery["clients"][number]["projects"][number]["project_invoices"],
  status
) {
  if (status === "all") {
    return true;
  } else if (status === "sent") {
    return project_invoices.length > 0 && project_invoices.every(pi => pi.invoiced);
  } else if (status === "not_invoiced") {
    return project_invoices.length > 0 && project_invoices.some(pi => !pi.invoiced);
  }
  return true;
}

function shouldIncludeArchived(
  archived: boolean,
  showArchived: boolean,
  {
    project_invoices = []
  }: {
    project_invoices?: FinanceQuery["clients"][number]["projects"][number]["project_invoices"];
  }
) {
  return project_invoices.some(pi => !pi.invoiced) || showArchived ? true : archived === false;
}
