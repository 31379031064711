import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { NavigationButton } from "components/ui/NavigationButton";
import { Title } from "lib/styled";
import { Gender, type MetricPeriod } from "src/typings/hypeauditor";

import { SectionTable } from "../common/SectionTable";
import type { HypeAuditorTikTokReportQuery } from "./generated";
import {
  getAgeRows,
  getEngagementRows,
  getGenderRows,
  getLocationByCountriesRows,
  getReachRows
} from "./utils/rows";

export const TiktokReport: FC<{
  report: HypeAuditorTikTokReportQuery["hypeauditor"][number];
}> = ({ report }) => {
  const {
    basic = {},
    features: {
      demography_by_age,
      audience_geo: { data: audience_geo }
    },
    metrics: {
      er,
      subscribers_count,
      views_avg,
      comments_likes_ratio,
      comments_avg,
      alikes_avg,
      reactions_avg,
      subscribers_quality
    }
  } = report.data;

  const { title, username } = basic;

  const { state: locationState } = useLocation() as { state: { from: string } };
  const [metricsPeriod, setMetricsPeriod] = useState<MetricPeriod>("30d");

  const handleMetricsPeriodChange = e => setMetricsPeriod(e.target.value);

  return (
    <Root>
      {["/hypeauditor/tiktok/aggregated"].includes(locationState?.from ?? "") && (
        <NavigationButton direction="back" className="nav-button" />
      )}

      <Title>
        Account analysis for{" "}
        <Link
          to={`https://app.hypeauditor.com/tiktok/${username}/`}
          rel="noreferrer"
          target="_blank"
        >
          @{title}
        </Link>
      </Title>

      <RadioGroup
        row
        aria-label="metrics-period"
        name="metrics-period"
        value={metricsPeriod}
        onChange={handleMetricsPeriodChange}
      >
        <FormControlLabel value="end" control={<Radio color="primary" value="all" />} label="All" />
        <FormControlLabel
          value="end"
          control={<Radio color="primary" value="30d" />}
          label="30 days"
        />
        <FormControlLabel
          value="end"
          control={<Radio color="primary" value="90d" />}
          label="90 days"
        />
        <FormControlLabel
          value="end"
          control={<Radio color="primary" value="180d" />}
          label="180 days"
        />
      </RadioGroup>

      <SectionTable
        sectionName="Reach"
        rows={getReachRows({ subscribers_count, views_avg, metricsPeriod, subscribers_quality })}
        showHeaders={false}
      />
      <SectionTable
        sectionName="Engagement & interactions"
        rows={getEngagementRows({
          er,
          comments_likes_ratio,
          comments_avg,
          alikes_avg,
          views_avg,
          reactions_avg,
          metricsPeriod
        })}
        showHeaders={false}
      />

      <Title>Audience demographics</Title>
      {(demography_by_age?.length ?? []) > 0 ? (
        <>
          <SectionTable
            sectionName="Gender"
            rows={getGenderRows({ demography_by_age, subscribers_count })}
          />
          <SectionTable
            sectionName="Age - all"
            rows={getAgeRows({ demography_by_age, subscribers_count, type: Gender.all })}
          />
          <SectionTable
            sectionName="Age - male"
            rows={getAgeRows({ demography_by_age, subscribers_count, type: Gender.male })}
            defaultOpen={false}
          />
          <SectionTable
            sectionName="Age - female"
            rows={getAgeRows({ demography_by_age, subscribers_count, type: Gender.female })}
            defaultOpen={false}
          />
        </>
      ) : null}

      {audience_geo && (
        <SectionTable
          sectionName="Location: by country"
          rows={getLocationByCountriesRows({
            audience_geo,
            subscribers_count
          })}
        />
      )}
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
  margin: auto;

  .nav-button {
    position: absolute;
    left: 0px;
    top: 23px;
  }
`;
