import { type FC, useEffect, useRef, useState } from "react";

import type { Platform_Enum } from "@relatable/gql/generated-base";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { useParams } from "react-router-dom";

import { Title } from "lib/styled";

import { Footer } from "../Footer";
import { MetaSection } from "../MetaSection";
import {
  ProjectDetailsDocument,
  useProjectDetailsQuery,
  useUpdateProjectMetaMutation,
  useUpdateProjectMutation
} from "../generated";
import { AppendixSection } from "./AppendixSection";
import { ArchiveSection } from "./ArchiveSection";
import { AttachmentSection } from "./AttachmentSection";
import { BudgetSection } from "./BudgetSection";
import { CampaignSection } from "./CampaignSection";
import { GoalsSection } from "./GoalsSection";
import { InvoicesSection } from "./InvoicesSection";
import { TeamSection } from "./TeamSection";
import type { Budget, SectionRef } from "./types";

export const ProjectUpdate: FC = () => {
  useDocumentTitle("Edit project");
  const { projectStub } = useParams<{ projectStub: string }>();

  const snackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const metaSectionRef = useRef<SectionRef>(null);
  const appendixSectionRef = useRef<SectionRef>(null);
  const invoiceSectionRef = useRef<SectionRef>(null);
  const goalSectionRef = useRef<SectionRef>(null);
  const budgetSectionRef = useRef<SectionRef>(null);
  const campaignSectionRef = useRef<SectionRef>(null);
  const attachmentSectionRef = useRef<SectionRef>(null);
  const teamSectionRef = useRef<SectionRef>(null);

  const refs = [
    metaSectionRef,
    invoiceSectionRef,
    goalSectionRef,
    budgetSectionRef,
    campaignSectionRef,
    appendixSectionRef,
    attachmentSectionRef,
    teamSectionRef
  ];

  const { data, loading } = useProjectDetailsQuery({
    variables: { stub: projectStub ?? "" }
  });
  const project = data?.projects[0];
  const [updateProject] = useUpdateProjectMutation({
    refetchQueries: [ProjectDetailsDocument],
    awaitRefetchQueries: true
  });

  const [budgets, setBudgets] = useState<Budget[]>([]);
  const [platforms, setPlatforms] = useState<Platform_Enum[]>([]);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState<number | null>(null);
  const [targetParticipantsMin, setTargetParticipantsMin] = useState<number | null>(null);
  const [targetParticipantsMax, setTargetParticipantsMax] = useState<number | null>(null);

  useEffect(() => {
    if (!project) return;
    setBudgets(
      project.project_budgets.map(b => ({
        revenue_allocation: b.revenue_allocation,
        target_margin: b.target_margin,
        type: b.type,
        id: b.id
      }))
    );
    setPlatforms(
      (typeof project.platforms === "string"
        ? [project.platforms]
        : project.platforms || []) as Platform_Enum[]
    );
    setTotalInvoiceAmount(project.project_invoicing_detail?.total_amount || null);
    setTargetParticipantsMin(project.target_participants_min || null);
    setTargetParticipantsMax(project.target_participants_max || null);
  }, [project]);

  const handleSubmit = async () => {
    if (isLoading || !project?.id) return;
    const errors = refs.flatMap(ref => ref.current?.validate() || []);
    if (errors.length) {
      snackbar.error(`Found ${errors.length === 1 ? "1 error" : `${errors.length} errors`}`);
      return;
    }

    try {
      setIsLoading(true);
      await Promise.all(refs.map(ref => ref.current?.submit()));
      await updateProject({
        variables: { projectId: project?.id, set: { updated_at: new Date().toISOString() } }
      });
      snackbar.success("Project successfully saved");
    } catch (e) {
      snackbar.error(e.message);
    }
    setIsLoading(false);
  };

  const [updateProjectMeta, updateProjectMetaOptions] = useUpdateProjectMetaMutation();

  return (
    <div>
      <Title>Edit project</Title>

      {project?.campaigns.length === 0 && <ArchiveSection project={project} />}

      <MetaSection
        variant="update"
        ref={metaSectionRef}
        project={project}
        submitError={updateProjectMetaOptions.error}
        isSubmitting={updateProjectMetaOptions.loading || isLoading}
        onSubmit={async set => {
          await updateProjectMeta({
            variables: {
              projectStub: projectStub ?? "",
              set
            }
          });
        }}
      />

      <AppendixSection isSubmitting={isLoading} project={project} ref={appendixSectionRef} />

      <InvoicesSection
        onTotalInvoiceAmountChange={setTotalInvoiceAmount}
        totalInvoiceAmount={totalInvoiceAmount}
        isSubmitting={isLoading}
        ref={invoiceSectionRef}
        project={project}
      />

      <GoalsSection
        platforms={platforms}
        onPlatformsChange={setPlatforms}
        isSubmitting={isLoading}
        ref={goalSectionRef}
        project={project}
        onTargetParticipantsMaxChange={setTargetParticipantsMax}
        onTargetParticipantsMinChange={setTargetParticipantsMin}
        targetParticipantsMax={targetParticipantsMax}
        targetParticipantsMin={targetParticipantsMin}
      />

      <BudgetSection
        totalInvoiceAmount={totalInvoiceAmount}
        platforms={platforms}
        budgets={budgets}
        onBudgetsChange={setBudgets}
        isSubmitting={isLoading}
        ref={budgetSectionRef}
        project={project}
      />

      <CampaignSection
        targetParticipantsMin={targetParticipantsMin}
        targetParticipantsMax={targetParticipantsMax}
        budgets={budgets}
        isSubmitting={isLoading}
        ref={campaignSectionRef}
        project={project}
      />

      <AttachmentSection isSubmitting={isLoading} ref={attachmentSectionRef} project={project} />

      <TeamSection
        isSubmitting={isLoading}
        ref={teamSectionRef}
        project={project}
        accounts={data?.accounts}
        slack={data?.slack}
      />

      <div style={{ width: "100%", height: 100 }} />
      <Footer
        isArchived={Boolean(project?.archived)}
        lastUpdatedAt={project?.updated_at}
        backHref="/overview"
        handleSubmit={handleSubmit}
        isLoading={isLoading || loading}
      />
    </div>
  );
};
