import type { Platform_Enum } from "@relatable/gql/generated-base";
import { type CONTENT_APPROVAL_STATE, CONTENT_TYPES } from "@relatable/helpers/constants";

export const getTypeLabel = ({
  type,
  platform
}: {
  type: string;
  platform: Platform_Enum | null | undefined;
}): string => {
  if (platform === "instagram") {
    const labelObj = Object.values(CONTENT_TYPES).find(t => t.value === type);
    if (!labelObj) throw new Error("Cannot find label");
    return labelObj.label;
  } else if (platform === "youtube") {
    return "Video";
  } else if (platform === "tiktok") {
    return "TikTok";
  } else if (platform === "snapchat") {
    return "Snapchat";
  } else {
    throw new Error(`Invalid platform ${platform}`);
  }
};

export const CONTENT_APPROVAL_STATE_MANAGER_LABELS: Record<CONTENT_APPROVAL_STATE, string> = {
  CREATOR_INITIAL_UPLOAD: "Creator creating content",
  MANAGER_REVIEWING: "Needs internal review",
  MANAGER_REJECTED: "Creator to revise",
  CLIENT_REVIEWING: "In client review",
  CLIENT_REJECTED: "Client requests updates",
  CLIENT_APPROVED: "Approved by client",
  READY_FOR_PUBLISHING: "Ready for publishing"
};
