import type { FC, PropsWithChildren } from "react";

import styled from "@emotion/styled";
import { Button, type DialogProps } from "@mui/material";
import { palette } from "@relatable/ui/Palette";

import { Popup } from "./Popup";

const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  > .middle {
    display: flex;
    align-items: center;

    > .dots {
      display: flex;

      > .dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: ${palette.gray[60]};
        margin-right: 10px;
        font-size: 0;
        border: 0;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
        }

        &.active {
          background-color: ${palette.primary.red};
          width: 32px;
        }
      }
    }
  }
`;

export const StepperPopup: FC<
  PropsWithChildren<{
    title: string | JSX.Element;
    onClose(): void;
    onSetStep(i: number): void;
    steps: number;
    currentStep: number;
    disabledSteps?: number[];
    maxWidth?: DialogProps["maxWidth"];
    allowOverflow?: boolean;
    hideButtons?: boolean;
    padding?: number | string;
  }>
> = ({
  children,
  onClose,
  title,
  onSetStep,
  steps,
  currentStep,
  maxWidth,
  disabledSteps = [],
  allowOverflow,
  padding,
  hideButtons
}) => {
  return (
    <Popup
      title={title}
      onClose={onClose}
      maxWidth={maxWidth}
      allowOverflow={allowOverflow}
      padding={padding}
    >
      {children}
      <Footer>
        <div className="left">
          {!hideButtons && (
            <Button
              disabled={currentStep < 1 || disabledSteps.includes(currentStep - 1)}
              style={{ minHeight: 35, marginTop: "auto" }}
              onClick={() => onSetStep(currentStep - 1)}
              variant="contained"
              color="secondary"
            >
              Previous
            </Button>
          )}
        </div>
        <div className="middle">
          {steps > 1 && (
            <div className="dots">
              {new Array(steps).fill(0).map((_, i) => (
                <button
                  type="button"
                  key={i}
                  className={`dot ${i === currentStep ? "active" : ""} ${
                    disabledSteps.includes(i) ? "disabled" : ""
                  }`}
                  onClick={() => !disabledSteps.includes(i) && onSetStep(i)}
                >
                  {i}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="right">
          {!hideButtons && (
            <Button
              disabled={currentStep + 1 > steps || disabledSteps.includes(currentStep + 1)}
              style={{ minHeight: 35, marginTop: "auto" }}
              onClick={() => (currentStep + 1 === steps ? onClose() : onSetStep(currentStep + 1))}
              variant="contained"
              color="primary"
            >
              {currentStep + 1 === steps ? "Done" : "Next"}
            </Button>
          )}
        </div>
      </Footer>
    </Popup>
  );
};
