import { useState } from "react";

import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";

import { Delete } from "@mui/icons-material";
import { Popup } from "components/ui/Popup";

const Root = styled.div`
  width: 40px;
  margin-right: 20px;

  .btn-primary {
    color: ${palette.gray.white};
    background: ${palette.gray[60]};
    height: 40px;
    min-width: 40px;
    border-radius: 20px;
  }
`;

export const DeleteMedia: React.FC<{
  onDelete?: () => void;
}> = ({ onDelete }) => {
  const [isDeletePopup, setIsDeletePopup] = useState(false);

  if (!onDelete) return null;

  return (
    <Root>
      {isDeletePopup && (
        <Popup
          title="Deleting this clip will remove it from the Influencer Upload and Client Workspace. Are you sure?"
          actionLabel="Yes, archive it!"
          onClose={() => setIsDeletePopup(false)}
          onAction={() => onDelete()}
        />
      )}
      <Button
        className="btn-primary"
        onClick={() => setIsDeletePopup(true)}
        tooltipText="Delete clip"
        variant="text"
        style={{ height: "100%" }}
      >
        <Delete />
      </Button>
    </Root>
  );
};
