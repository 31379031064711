import type { FC } from "react";

import { CircularProgress } from "@mui/material";
import { getUserLabel } from "@relatable/helpers/user";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { useParams } from "react-router-dom";

import { Center, ContentContainer } from "lib/styled";

import { UserCampaigns } from "./UserCampaigns";
import { UserInfo } from "./UserInfo";
import { useUserCampaignsQuery, useUserQuery } from "./generated";

export const User: FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const {
    data: { users_by_pk: user } = {},
    loading
  } = useUserQuery({
    variables: { id: Number(userId) }
  });

  const {
    data: { campaign_user: userCampaigns = [] } = {},
    error
  } = useUserCampaignsQuery({
    variables: { where: { user_id: { _eq: Number(userId) }, state: { _is_null: false } } }
  });

  useDocumentTitle(`User: ${getUserLabel(user) || ""}`);

  if (loading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  if (!user || error) {
    return (
      <>
        <span>Can not find that user... 😱</span>
      </>
    );
  }

  return (
    <ContentContainer>
      <UserInfo user={user} />
      <UserCampaigns user={user} userCampaigns={userCampaigns} />
    </ContentContainer>
  );
};
