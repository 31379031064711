import styled from "@emotion/styled";

import type { InfluencerMarketingBudget } from "src/typings/global";

import { MarginsContainer } from "./MarginsContainer";
import { PieChartMetric } from "./PieChartMetric";

interface MetricsContainerProps {
  budgetUsed: number;
  liveContentCount: number;
  max_budget?: number;
  target_participants: number;
  number_of_posts: number;
  participantsCount: number;
  participantsReach: number;
  target_reach: number;
  influencerMarketingBudget: InfluencerMarketingBudget;
}

export const MetricsContainer: React.FC<MetricsContainerProps> = ({
  budgetUsed = 0,
  liveContentCount = 0,
  influencerMarketingBudget,
  max_budget = 0,
  target_participants = 0,
  number_of_posts = 0,
  participantsCount = 0,
  participantsReach = 0,
  target_reach = 0
}) => {
  const currentMargin =
    1 - budgetUsed / (max_budget / (1 - influencerMarketingBudget.target_margin));

  return (
    <Root>
      <PieChartContainer>
        <PieChartMetric current={budgetUsed} total={max_budget} title="Budget" />
        <PieChartMetric current={participantsReach} total={target_reach} title="Reach" />
        <PieChartMetric
          current={participantsCount}
          total={target_participants}
          title="Participants"
        />
        <PieChartMetric
          current={liveContentCount}
          total={number_of_posts * target_participants}
          title="Content live"
        />
      </PieChartContainer>
      <MarginsContainer
        current={currentMargin}
        estimated={influencerMarketingBudget.target_margin}
      />
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  margin: 36px 0px;
`;

const PieChartContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 12px));
  grid-gap: 24px;
`;
