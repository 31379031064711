import type { FC } from "react";

import { Box } from "@mui/material";
import { unique } from "@relatable/helpers/index";
import { getUserLabel } from "@relatable/helpers/user";
import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { Select } from "@relatable/ui/Select";

import {
  CAMPAIGN_USER_STATE_DISPLAY_NAMES,
  CAMPAIGN_USER_STATE_TYPES,
  SORTED_CAMPAIGN_USER_STATES
} from "lib/constants";

import { HowToReg, PersonOff } from "@mui/icons-material";
import type { CampaignParticipantsQuery } from "../generated";

const getStateLabel = (state: string | null | undefined) =>
  CAMPAIGN_USER_STATE_DISPLAY_NAMES[state ?? ""] || "Not set";

export const SendEmailUsers: FC<{
  users: CampaignParticipantsQuery["users_in_lists"];
  selectedUserIds: number[];
  onChange(selectedUserIds: number[]): void;
}> = ({ users, selectedUserIds, onChange }) => {
  const sortedUsersByState = [...users].sort((v1, v2) => {
    const v1Order = SORTED_CAMPAIGN_USER_STATES.findIndex(
      v => v === getStateLabel(v1.campaign_user?.state)
    );
    const v2Order = SORTED_CAMPAIGN_USER_STATES.findIndex(
      v => v === getStateLabel(v2.campaign_user?.state)
    );
    return v1Order - v2Order;
  });

  const handleAddByState = (state: string) => {
    onChange(
      unique([
        ...selectedUserIds,
        ...users.filter(user => user.campaign_user?.state === state).map(user => user.user_id ?? 0)
      ])
    );
  };

  return (
    <div>
      <div style={{ marginBottom: 15 }}>
        <Button
          icon={selectedUserIds.length === 0 ? <HowToReg /> : <PersonOff />}
          onClick={() => {
            onChange(selectedUserIds.length > 0 ? [] : users.map(user => user.user_id ?? 0));
          }}
        >
          Select {selectedUserIds.length > 0 ? "none" : "all"}
        </Button>

        <Select
          label="Add by state"
          options={CAMPAIGN_USER_STATE_TYPES.map(state => ({
            value: state,
            label: CAMPAIGN_USER_STATE_DISPLAY_NAMES[state]
          }))}
          value={null}
          style={{ width: 150, margin: "0 30px" }}
          size="small"
          onChange={handleAddByState}
        />
      </div>

      <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
        {sortedUsersByState.map(user => (
          <Checkbox
            key={user.user_id}
            label={`${getUserLabel(user)} (${getStateLabel(user.campaign_user?.state)})`}
            checked={selectedUserIds.includes(user.user_id ?? 0)}
            onChange={() => {
              if (selectedUserIds.includes(user.user_id ?? 0)) {
                onChange(selectedUserIds.filter(id => id !== user.user_id));
              } else {
                onChange([...selectedUserIds, user.user_id ?? 0]);
              }
            }}
          />
        ))}
      </Box>
    </div>
  );
};
