import { type FC, useLayoutEffect, useRef } from "react";

import styled from "@emotion/styled";

import { Controls } from "./Controls";
import { Messages } from "./Messages";
import type { AiConversationWithMessagesQuery } from "./generated";

export const ChatView: FC<{
  messages: NonNullable<AiConversationWithMessagesQuery["ai_conversations_by_pk"]>["ai_messages"];
  onAbort: () => void;
  onSubmit: ({ text, temperature }: { text: string; temperature?: number }) => void;
  loading: boolean;
  generating: boolean;
}> = ({ messages, onAbort, onSubmit, loading, generating }) => {
  const scrollEnd = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    if (!scrollEnd.current) return;
    scrollEnd.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <Root>
      <div className="top">
        <Messages messages={messages} loading={generating} />
        <span ref={scrollEnd} />
      </div>
      <div className="bottom">
        <Controls loading={loading} generating={generating} onAbort={onAbort} onSubmit={onSubmit} />
      </div>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);

  > .top {
    background: white;
    flex: 1 1 auto;
    overflow: auto;
    margin-bottom: 20px;
  }

  > .bottom {
    flex: 0 0 auto;
  }
`;
