import { type FC, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { TextInput } from "@relatable/ui/TextInput";

export const AttachmentLink: FC<{
  link: string;
  onSetLink: (link: string) => void;
  loading: boolean;
}> = props => {
  const { link, onSetLink, loading } = props;
  const [localLink, setLocalLink] = useState(link ?? "");

  useEffect(() => {
    setLocalLink(link ?? "");
  }, [link]);

  return (
    <Root>
      <div className="left">
        <span className="title">Add an attachment link</span>
        <span className="text">If you want to add an extra file, please add it here.</span>
      </div>
      <div className="right">
        <TextInput placeholder="Paste url here" value={localLink} onChange={setLocalLink} />
        <Button
          disabled={loading || localLink === (link ?? "")}
          className="btn"
          onClick={() => onSetLink(localLink)}
        >
          Save
        </Button>
      </div>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 20px 25px;
  border: 1px solid ${palette.gray[10]};

  > .left {
    display: flex;
    flex-direction: column;

    > .title {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  > .right {
    width: 50%;
    display: flex;

    .MuiInputBase-root {
      background: ${palette.gray[10]};
    }

    .btn {
      width: 30%;
      margin-left: 10px;
    }
  }
`;
