import type { FC } from "react";

import styled from "@emotion/styled";
import { managerApproved, normalizeState } from "@relatable/helpers/approvalLogs";
import { palette } from "@relatable/ui/Palette";

import type { ContentOverviewProps } from "../ContentOverview";
import type { ContentOverviewQuery } from "../generated";
import { CaptionEditor } from "./CaptionEditor";
import { ContentPieceState } from "./ContentPieceState";

export const Caption: FC<{
  caption?: NonNullable<ContentOverviewQuery["activeContents"][number]>["new_caption"];
  legacyCaption?: string;
  filter: "all" | "relatable" | "client";
  updateCaptionLoading?: boolean;
  onWriteMessage?: (target: "creator" | "client", text: string) => void;
  onUpdateCaption?: (caption: string) => void;
  onUpdateState?: ContentOverviewProps["onUpdateState"];
}> = ({
  caption,
  legacyCaption,
  filter,
  updateCaptionLoading,
  onWriteMessage,
  onUpdateCaption,
  onUpdateState
}) => {
  // it shouldn't show the content item if the client filtering is active and client state is "N/A"
  if (filter === "client" && caption) {
    if (!managerApproved(caption?.content_preapproval_logs[0]?.state)) {
      return null;
    }
  }

  const numComments = caption?.logs.filter(l => l.text).length;

  return (
    <Root>
      <div className="left">
        <div className="caption">
          {legacyCaption || (
            <CaptionEditor
              text={legacyCaption ?? (caption?.text || "")}
              numComments={numComments ?? 0}
              updateCaptionLoading={updateCaptionLoading ?? false}
              onUpdateCaption={onUpdateCaption}
            />
          )}
        </div>
      </div>
      <div className="right">
        {caption ? (
          <ContentPieceState
            isCaption
            captionText={caption.text || ""}
            state={normalizeState(caption.content_preapproval_logs[0]?.state)}
            logs={caption.logs}
            filter={filter}
            onWriteMessage={onWriteMessage}
            onUpdateState={(author, newState) => {
              if (!onUpdateState) return;
              onUpdateState({
                author,
                type: "caption",
                id: caption.id,
                newState
              });
            }}
          />
        ) : null}
      </div>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  margin-bottom: 25px;

  > .left {
    width: 50%;
    background: ${palette.gray[10]};
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    position: relative;

    > .caption {
      width: 90%;
      max-width: 460px;
      margin: 0;
      display: flex;
      justify-content: center;
    }
  }

  > .right {
    width: 50%;
    padding: 20px 25px;
    border: 1px solid ${palette.gray[10]};
    border-left: 0;
  }
`;
