import { palette } from "@relatable/ui/Palette";
import type { FC, ReactElement } from "react";

import type { SvgIconComponent } from "@mui/icons-material";
import { Section, SectionSubtitle, SectionValue } from "../user.styled";

export const MiniStatBox: FC<{
  value: string | number | undefined | null;
  title: string;
  Icon: SvgIconComponent | string;
  rightAppend?: ReactElement;
}> = ({ value, title, Icon, rightAppend }) => (
  <Section
    style={{
      padding: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }}
  >
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SectionSubtitle>{title}</SectionSubtitle>
      <SectionValue>{value || "-"}</SectionValue>
    </div>
    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
      {typeof Icon === "string" ? (
        <span style={{ fontSize: 30 }}>{Icon}</span>
      ) : (
        <Icon fontSize="large" style={{ color: palette.gray[50] }} />
      )}
      {rightAppend}
    </div>
  </Section>
);
