import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchBoxProjectsQueryVariables = Types.Exact<{
  projectWhere?: Types.InputMaybe<Types.Project_Bool_Exp>;
}>;


export type SearchBoxProjectsQuery = { __typename?: 'Query', project: Array<{ __typename?: 'project', id: number, stub: string, name: string }> };

export type SearchBoxCampaignsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchBoxCampaignsQuery = { __typename?: 'Query', campaign: Array<{ __typename?: 'campaign', id: number, stub: string, title: string }> };

export type SearchBoxListsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchBoxListsQuery = { __typename?: 'Query', lists: Array<{ __typename?: 'lists', id: number, name?: string | null }> };

export type SearchBoxUserQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchBoxUserQuery = { __typename?: 'Query', users: Array<{ __typename?: 'users', id: number, email?: string | null, user_instagram?: { __typename?: 'users_instagram', id: number } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number } | null, user_youtube?: { __typename?: 'users_youtube', id: number } | null }> };

export type SearchBoxUsersIgQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchBoxUsersIgQuery = { __typename?: 'Query', users_instagram: Array<{ __typename?: 'users_instagram', id: number, username: string, user?: { __typename?: 'users', id: number } | null }> };

export type SearchBoxUsersTtQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchBoxUsersTtQuery = { __typename?: 'Query', user_tiktok: Array<{ __typename?: 'user_tiktok', id: number, username?: string | null, user?: { __typename?: 'users', id: number } | null }> };

export type SearchBoxUsersScQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchBoxUsersScQuery = { __typename?: 'Query', users_snapchat: Array<{ __typename?: 'users_snapchat', id: number, username: string, user?: { __typename?: 'users', id: number } | null }> };

export type SearchBoxUsersYtQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchBoxUsersYtQuery = { __typename?: 'Query', users_youtube: Array<{ __typename?: 'users_youtube', id: number, username?: string | null, channel_title?: string | null, user?: { __typename?: 'users', id: number } | null }> };


export const SearchBoxProjectsDocument = gql`
    query searchBoxProjects($projectWhere: project_bool_exp) {
  project(where: $projectWhere, limit: 10, order_by: {id: desc}) {
    id
    stub
    name
  }
}
    `;

/**
 * __useSearchBoxProjectsQuery__
 *
 * To run a query within a React component, call `useSearchBoxProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBoxProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBoxProjectsQuery({
 *   variables: {
 *      projectWhere: // value for 'projectWhere'
 *   },
 * });
 */
export function useSearchBoxProjectsQuery(baseOptions?: Apollo.QueryHookOptions<SearchBoxProjectsQuery, SearchBoxProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBoxProjectsQuery, SearchBoxProjectsQueryVariables>(SearchBoxProjectsDocument, options);
      }
export function useSearchBoxProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBoxProjectsQuery, SearchBoxProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBoxProjectsQuery, SearchBoxProjectsQueryVariables>(SearchBoxProjectsDocument, options);
        }
export function useSearchBoxProjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchBoxProjectsQuery, SearchBoxProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchBoxProjectsQuery, SearchBoxProjectsQueryVariables>(SearchBoxProjectsDocument, options);
        }
export type SearchBoxProjectsQueryHookResult = ReturnType<typeof useSearchBoxProjectsQuery>;
export type SearchBoxProjectsLazyQueryHookResult = ReturnType<typeof useSearchBoxProjectsLazyQuery>;
export type SearchBoxProjectsSuspenseQueryHookResult = ReturnType<typeof useSearchBoxProjectsSuspenseQuery>;
export type SearchBoxProjectsQueryResult = Apollo.QueryResult<SearchBoxProjectsQuery, SearchBoxProjectsQueryVariables>;
export const SearchBoxCampaignsDocument = gql`
    query searchBoxCampaigns($search: String) {
  campaign(
    where: {_or: [{title: {_ilike: $search}}, {stub: {_ilike: $search}}]}
    limit: 10
    order_by: {id: desc}
  ) {
    id
    stub
    title
  }
}
    `;

/**
 * __useSearchBoxCampaignsQuery__
 *
 * To run a query within a React component, call `useSearchBoxCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBoxCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBoxCampaignsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchBoxCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<SearchBoxCampaignsQuery, SearchBoxCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBoxCampaignsQuery, SearchBoxCampaignsQueryVariables>(SearchBoxCampaignsDocument, options);
      }
export function useSearchBoxCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBoxCampaignsQuery, SearchBoxCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBoxCampaignsQuery, SearchBoxCampaignsQueryVariables>(SearchBoxCampaignsDocument, options);
        }
export function useSearchBoxCampaignsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchBoxCampaignsQuery, SearchBoxCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchBoxCampaignsQuery, SearchBoxCampaignsQueryVariables>(SearchBoxCampaignsDocument, options);
        }
export type SearchBoxCampaignsQueryHookResult = ReturnType<typeof useSearchBoxCampaignsQuery>;
export type SearchBoxCampaignsLazyQueryHookResult = ReturnType<typeof useSearchBoxCampaignsLazyQuery>;
export type SearchBoxCampaignsSuspenseQueryHookResult = ReturnType<typeof useSearchBoxCampaignsSuspenseQuery>;
export type SearchBoxCampaignsQueryResult = Apollo.QueryResult<SearchBoxCampaignsQuery, SearchBoxCampaignsQueryVariables>;
export const SearchBoxListsDocument = gql`
    query searchBoxLists($search: String) {
  lists(
    where: {_or: [{name: {_ilike: $search}}]}
    limit: 10
    order_by: {id: desc}
  ) {
    id
    name
  }
}
    `;

/**
 * __useSearchBoxListsQuery__
 *
 * To run a query within a React component, call `useSearchBoxListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBoxListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBoxListsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchBoxListsQuery(baseOptions?: Apollo.QueryHookOptions<SearchBoxListsQuery, SearchBoxListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBoxListsQuery, SearchBoxListsQueryVariables>(SearchBoxListsDocument, options);
      }
export function useSearchBoxListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBoxListsQuery, SearchBoxListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBoxListsQuery, SearchBoxListsQueryVariables>(SearchBoxListsDocument, options);
        }
export function useSearchBoxListsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchBoxListsQuery, SearchBoxListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchBoxListsQuery, SearchBoxListsQueryVariables>(SearchBoxListsDocument, options);
        }
export type SearchBoxListsQueryHookResult = ReturnType<typeof useSearchBoxListsQuery>;
export type SearchBoxListsLazyQueryHookResult = ReturnType<typeof useSearchBoxListsLazyQuery>;
export type SearchBoxListsSuspenseQueryHookResult = ReturnType<typeof useSearchBoxListsSuspenseQuery>;
export type SearchBoxListsQueryResult = Apollo.QueryResult<SearchBoxListsQuery, SearchBoxListsQueryVariables>;
export const SearchBoxUserDocument = gql`
    query searchBoxUser($search: String) {
  users(where: {email: {_ilike: $search}}, limit: 10) {
    id
    email
    user_instagram {
      id
    }
    user_tiktok {
      id
    }
    user_snapchat {
      id
    }
    user_youtube {
      id
    }
  }
}
    `;

/**
 * __useSearchBoxUserQuery__
 *
 * To run a query within a React component, call `useSearchBoxUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBoxUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBoxUserQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchBoxUserQuery(baseOptions?: Apollo.QueryHookOptions<SearchBoxUserQuery, SearchBoxUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBoxUserQuery, SearchBoxUserQueryVariables>(SearchBoxUserDocument, options);
      }
export function useSearchBoxUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBoxUserQuery, SearchBoxUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBoxUserQuery, SearchBoxUserQueryVariables>(SearchBoxUserDocument, options);
        }
export function useSearchBoxUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchBoxUserQuery, SearchBoxUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchBoxUserQuery, SearchBoxUserQueryVariables>(SearchBoxUserDocument, options);
        }
export type SearchBoxUserQueryHookResult = ReturnType<typeof useSearchBoxUserQuery>;
export type SearchBoxUserLazyQueryHookResult = ReturnType<typeof useSearchBoxUserLazyQuery>;
export type SearchBoxUserSuspenseQueryHookResult = ReturnType<typeof useSearchBoxUserSuspenseQuery>;
export type SearchBoxUserQueryResult = Apollo.QueryResult<SearchBoxUserQuery, SearchBoxUserQueryVariables>;
export const SearchBoxUsersIgDocument = gql`
    query searchBoxUsersIG($search: String) {
  users_instagram(where: {username: {_ilike: $search}}, limit: 10) {
    id
    username
    user {
      id
    }
  }
}
    `;

/**
 * __useSearchBoxUsersIgQuery__
 *
 * To run a query within a React component, call `useSearchBoxUsersIgQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBoxUsersIgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBoxUsersIgQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchBoxUsersIgQuery(baseOptions?: Apollo.QueryHookOptions<SearchBoxUsersIgQuery, SearchBoxUsersIgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBoxUsersIgQuery, SearchBoxUsersIgQueryVariables>(SearchBoxUsersIgDocument, options);
      }
export function useSearchBoxUsersIgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBoxUsersIgQuery, SearchBoxUsersIgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBoxUsersIgQuery, SearchBoxUsersIgQueryVariables>(SearchBoxUsersIgDocument, options);
        }
export function useSearchBoxUsersIgSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchBoxUsersIgQuery, SearchBoxUsersIgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchBoxUsersIgQuery, SearchBoxUsersIgQueryVariables>(SearchBoxUsersIgDocument, options);
        }
export type SearchBoxUsersIgQueryHookResult = ReturnType<typeof useSearchBoxUsersIgQuery>;
export type SearchBoxUsersIgLazyQueryHookResult = ReturnType<typeof useSearchBoxUsersIgLazyQuery>;
export type SearchBoxUsersIgSuspenseQueryHookResult = ReturnType<typeof useSearchBoxUsersIgSuspenseQuery>;
export type SearchBoxUsersIgQueryResult = Apollo.QueryResult<SearchBoxUsersIgQuery, SearchBoxUsersIgQueryVariables>;
export const SearchBoxUsersTtDocument = gql`
    query searchBoxUsersTT($search: String) {
  user_tiktok(where: {username: {_ilike: $search}}, limit: 10) {
    id
    username
    user {
      id
    }
  }
}
    `;

/**
 * __useSearchBoxUsersTtQuery__
 *
 * To run a query within a React component, call `useSearchBoxUsersTtQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBoxUsersTtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBoxUsersTtQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchBoxUsersTtQuery(baseOptions?: Apollo.QueryHookOptions<SearchBoxUsersTtQuery, SearchBoxUsersTtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBoxUsersTtQuery, SearchBoxUsersTtQueryVariables>(SearchBoxUsersTtDocument, options);
      }
export function useSearchBoxUsersTtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBoxUsersTtQuery, SearchBoxUsersTtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBoxUsersTtQuery, SearchBoxUsersTtQueryVariables>(SearchBoxUsersTtDocument, options);
        }
export function useSearchBoxUsersTtSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchBoxUsersTtQuery, SearchBoxUsersTtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchBoxUsersTtQuery, SearchBoxUsersTtQueryVariables>(SearchBoxUsersTtDocument, options);
        }
export type SearchBoxUsersTtQueryHookResult = ReturnType<typeof useSearchBoxUsersTtQuery>;
export type SearchBoxUsersTtLazyQueryHookResult = ReturnType<typeof useSearchBoxUsersTtLazyQuery>;
export type SearchBoxUsersTtSuspenseQueryHookResult = ReturnType<typeof useSearchBoxUsersTtSuspenseQuery>;
export type SearchBoxUsersTtQueryResult = Apollo.QueryResult<SearchBoxUsersTtQuery, SearchBoxUsersTtQueryVariables>;
export const SearchBoxUsersScDocument = gql`
    query searchBoxUsersSC($search: String) {
  users_snapchat(where: {username: {_ilike: $search}}, limit: 10) {
    id
    username
    user {
      id
    }
  }
}
    `;

/**
 * __useSearchBoxUsersScQuery__
 *
 * To run a query within a React component, call `useSearchBoxUsersScQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBoxUsersScQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBoxUsersScQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchBoxUsersScQuery(baseOptions?: Apollo.QueryHookOptions<SearchBoxUsersScQuery, SearchBoxUsersScQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBoxUsersScQuery, SearchBoxUsersScQueryVariables>(SearchBoxUsersScDocument, options);
      }
export function useSearchBoxUsersScLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBoxUsersScQuery, SearchBoxUsersScQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBoxUsersScQuery, SearchBoxUsersScQueryVariables>(SearchBoxUsersScDocument, options);
        }
export function useSearchBoxUsersScSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchBoxUsersScQuery, SearchBoxUsersScQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchBoxUsersScQuery, SearchBoxUsersScQueryVariables>(SearchBoxUsersScDocument, options);
        }
export type SearchBoxUsersScQueryHookResult = ReturnType<typeof useSearchBoxUsersScQuery>;
export type SearchBoxUsersScLazyQueryHookResult = ReturnType<typeof useSearchBoxUsersScLazyQuery>;
export type SearchBoxUsersScSuspenseQueryHookResult = ReturnType<typeof useSearchBoxUsersScSuspenseQuery>;
export type SearchBoxUsersScQueryResult = Apollo.QueryResult<SearchBoxUsersScQuery, SearchBoxUsersScQueryVariables>;
export const SearchBoxUsersYtDocument = gql`
    query searchBoxUsersYT($search: String) {
  users_youtube(where: {channel_title: {_ilike: $search}}, limit: 10) {
    id
    username
    channel_title
    user {
      id
    }
  }
}
    `;

/**
 * __useSearchBoxUsersYtQuery__
 *
 * To run a query within a React component, call `useSearchBoxUsersYtQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBoxUsersYtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBoxUsersYtQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchBoxUsersYtQuery(baseOptions?: Apollo.QueryHookOptions<SearchBoxUsersYtQuery, SearchBoxUsersYtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBoxUsersYtQuery, SearchBoxUsersYtQueryVariables>(SearchBoxUsersYtDocument, options);
      }
export function useSearchBoxUsersYtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBoxUsersYtQuery, SearchBoxUsersYtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBoxUsersYtQuery, SearchBoxUsersYtQueryVariables>(SearchBoxUsersYtDocument, options);
        }
export function useSearchBoxUsersYtSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchBoxUsersYtQuery, SearchBoxUsersYtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchBoxUsersYtQuery, SearchBoxUsersYtQueryVariables>(SearchBoxUsersYtDocument, options);
        }
export type SearchBoxUsersYtQueryHookResult = ReturnType<typeof useSearchBoxUsersYtQuery>;
export type SearchBoxUsersYtLazyQueryHookResult = ReturnType<typeof useSearchBoxUsersYtLazyQuery>;
export type SearchBoxUsersYtSuspenseQueryHookResult = ReturnType<typeof useSearchBoxUsersYtSuspenseQuery>;
export type SearchBoxUsersYtQueryResult = Apollo.QueryResult<SearchBoxUsersYtQuery, SearchBoxUsersYtQueryVariables>;