import { type FC, useMemo, useState } from "react";

import styled from "@emotion/styled";
import { prettifyNumber } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";

import { Loader } from "@relatable/ui/Loader";
import { PeriodSelector } from "components/PeriodSelector";
import { useMeQuery } from "hooks/generated";
import { ContentContainer } from "lib/styled";

import { Download } from "@mui/icons-material";
import { ClientCard } from "./ClientCard";
import { useFinanceQuery } from "./generated";
import { reshapeClients } from "./reshapeClients";

const CLIENTS_PER_LOAD = 15;

export const Finance: FC = () => {
  useDocumentTitle("Finance");

  const { data: me } = useMeQuery();
  const isAdmin = (me?.me?.admin?.roles ?? []).includes("admin");

  const [showArchived, setShowArchived] = useState(false);
  const [clientsToRender, setClientsToRender] = useState(CLIENTS_PER_LOAD);
  const [status, setStatus] = useState<"all" | "not_invoiced" | "sent">("not_invoiced");

  const [selectedExportPeriod, setSelectedExportPeriod] = useState<null | {
    start: string;
    end: string;
  }>(null);

  const { data, loading } = useFinanceQuery({
    variables: { showArchived }
  });

  const {
    clients: reshapedClients,
    metadata: { avgMargin, invoicesToSendCount, projectsCount }
  } = useMemo(
    () =>
      reshapeClients({
        clients: data?.clients || [],
        filterValues: { status },
        showArchived
      }),
    [data, status, showArchived]
  );

  return (
    <>
      <SFilterRow>
        <Header>
          <Checkbox
            label="Show archived projects"
            onChange={setShowArchived}
            checked={showArchived}
          />

          <Select
            style={{ margin: "auto", marginTop: 5, width: 200 }}
            hideNone
            label="Status"
            value={status}
            onChange={setStatus}
            options={[
              { value: "all", label: "All statuses" },
              { value: "not_invoiced", label: "Not invoiced" },
              { value: "sent", label: "Sent" }
            ]}
          />

          <InfoContainer>
            <InfoColumn>
              <InfoLabel>Number of projects</InfoLabel>
              <InfoValue>{projectsCount}</InfoValue>
            </InfoColumn>

            <InfoColumn>
              <InfoLabel>Invoices to send</InfoLabel>
              <InfoValue>{invoicesToSendCount}</InfoValue>
            </InfoColumn>
          </InfoContainer>

          <InfoContainer>
            <InfoColumn>
              <InfoLabel>Average margin</InfoLabel>
              <InfoValue>{prettifyNumber(avgMargin, { percentages: true })}</InfoValue>
            </InfoColumn>
          </InfoContainer>
        </Header>
      </SFilterRow>

      {isAdmin && (
        <SFilterRow>
          <Header style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <Button
                icon={<Download />}
                style={{ marginLeft: 10 }}
                onClick={() => {
                  window.location.href = `${process.env.GRAPH_URL}/export/projects`;
                }}
              >
                Export all projects to CSV
              </Button>
            </div>
            <div style={{ display: "flex" }}>
              <PeriodSelector startDate={2017} onSelect={setSelectedExportPeriod} />

              <Button
                icon={<Download />}
                style={{ marginLeft: 10 }}
                onClick={() => {
                  if (!selectedExportPeriod) throw Error("Missing start and end date");
                  window.location.href = `${process.env.GRAPH_URL}/export/clients?start_date=${selectedExportPeriod.start}&end_date=${selectedExportPeriod.end}`;
                }}
              >
                Export to CSV
              </Button>
            </div>
          </Header>
        </SFilterRow>
      )}

      <ContentContainer>
        {loading ? (
          <Loader style={{ top: 0 }} rootClassName="loader" />
        ) : reshapedClients.length > 0 ? (
          <>
            {reshapedClients
              .slice(0, clientsToRender)
              .map(({ hubspot_company_id, name, projects }) => (
                <ClientCard key={hubspot_company_id} name={name || ""} projects={projects || []} />
              ))}
            {reshapedClients.length > CLIENTS_PER_LOAD && (
              <Button onClick={() => setClientsToRender(clientsToRender + CLIENTS_PER_LOAD)}>
                Load more
              </Button>
            )}
          </>
        ) : (
          <span>No projects match the filters</span>
        )}
      </ContentContainer>
    </>
  );
};

const Header = styled(ContentContainer)`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 20px;
  padding: 0;
`;

const SFilterRow = styled.div`
  background-color: ${palette.gray.white};
  border-bottom: 1px solid ${palette.gray.whiteOff};
  display: flex;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 18px;
  margin: 0px 8px;

  &:first-of-type {
    margin-left: 0px;
  }
`;

const InfoLabel = styled.span`
  font-size: 12px;
`;

const InfoValue = styled.span`
  font-weight: 700;
`;
