import type { FC } from "react";

import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import { LinearProgress } from "components/ui/LinearProgress";

import { useCampaignVideoStatsQuery } from "./generated";

const sum = (arr: number[]): number => arr.reduce((prev, curr) => prev + curr, 0);

export const StatsHead: FC = () => {
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  const { data } = useCampaignVideoStatsQuery({ variables: { stubs: campaignStubs } });
  const campaigns = data?.campaigns || [];

  const totalTargetReach = sum(campaigns.map(c => c.target_reach ?? 0));

  const totalContentSettings = sum(
    campaigns.map(c => c.campaign_content_settings_aggregate.aggregate?.sum?.videosCount ?? 0)
  );

  const totalParticipants = sum(campaigns.map(c => c.target_participants ?? 0));

  const allVideos = campaigns.flatMap(c => c.campaign_users.flatMap(cu => cu.campaign_videos));

  const totalViews = sum(allVideos.map(v => v.campaign_video_stat?.view_count ?? 0));

  return (
    <StatsContainer>
      <LinearProgress label="Views" value={totalViews} max={totalTargetReach} />

      <LinearProgress
        label="Videos"
        value={allVideos.length}
        max={totalContentSettings * totalParticipants}
      />
    </StatsContainer>
  );
};

const StatsContainer = styled.div`
  display: grid;
  gap: 2%;
  grid-template-columns: 49% 49%;
`;
