import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";

import type { CreatorsInListQuery } from "../generated";

const Root = styled.div`
  color: ${palette.gray[60]};
  font-size: 12px;
  border-top: 1px solid ${palette.gray[20]};
  border-bottom: 1px solid ${palette.gray[20]};
  padding: 12px 0;
  margin: 15px 0;
  display: flex;
  width: 100%;
  gap: 6px;
  > .list {
    > .link {
      font-weight: bold;
      color: ${palette.primary.blue};
    }
  }
`;

export const CampaignList: React.FC<{ data: CreatorsInListQuery }> = ({ data }) => {
  if (!data?.list?.campaigns.length) return null;

  return (
    <Root>
      <div>Attached to</div>
      <div className="list">
        {data?.list?.campaigns?.map(campaign => (
          <Link key={campaign.id} className="link" to={`/campaign/${campaign.stub}`}>
            {campaign.title}
          </Link>
        ))}
      </div>
    </Root>
  );
};
