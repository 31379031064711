import { useState } from "react";

import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";

import { ReportNotFound } from "../common/ReportNotFound";
import { useRequestHypeAuditorReportMutation } from "../generated";
import { TiktokReport } from "./TiktokReport";
import { HypeAuditorTikTokReportDocument, useHypeAuditorTikTokReportQuery } from "./generated";

export const TiktokReportContainer = (): JSX.Element => {
  const { userId } = useParams<{ userId: string }>();
  const {
    data: {
      hypeauditor: [report] = []
    } = {},
    loading: getHypeauditorReportLoading
  } = useHypeAuditorTikTokReportQuery({
    pollInterval: 30 * 1000,
    variables: { userId: Number(userId) }
  });

  const [error, setError] = useState<string | null>();
  const [requestHypeauditorReport, { loading: requestHypeauditorReportLoading }] =
    useRequestHypeAuditorReportMutation({
      onError: err => setError(err.message),
      refetchQueries: [
        { query: HypeAuditorTikTokReportDocument, variables: { users_id: Number(userId) } }
      ],
      awaitRefetchQueries: true
    });

  const requestReport = () => {
    setError(null);
    if (!report?.user?.id) throw new Error("missing user_id");
    requestHypeauditorReport({
      variables: {
        objects: [
          {
            users_id: report?.user?.id,
            updated_at: new Date().toISOString(),
            state: "pending_rel"
          }
        ]
      }
    });
  };

  if (requestHypeauditorReportLoading || getHypeauditorReportLoading) {
    return <Loader />;
  }

  if (
    !report?.data ||
    report?.not_available ||
    error ||
    report?.state === "error" ||
    (report?.state?.indexOf("pending") || 0) > -1
  ) {
    return (
      <ReportNotFound
        requestReport={requestReport}
        username={report?.user?.user_tiktok?.username}
        report={report}
        error={error || report?.data?.message}
      />
    );
  }

  return <TiktokReport report={report} />;
};
