import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

const stateStore: Record<string, unknown> = {};

/** Keeps the state even after the component is unmounted */
export const useSessionState = <T>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    if (key in stateStore) {
      return stateStore[key];
    }
    return typeof defaultValue === "function" ? defaultValue() : defaultValue;
  });

  useEffect(() => {
    stateStore[key] = state;
  }, [key, state]);

  return [state, setState];
};
