import type { Project_Budget, Project_Budget_Insert_Input } from "@relatable/gql/generated-base";
import { BUDGET_CATEGORIES } from "@relatable/helpers/constants";

export const getProjectPayoutBudget = (projectTargetMargin = 0, imBudget = 0): number =>
  (1 - projectTargetMargin) * imBudget;

export const getProjectMargin = (usedBudget = 0, totalBudget = 0): number | undefined =>
  usedBudget > 0 && totalBudget !== 0 ? 1 - usedBudget / totalBudget : 0;

// only for project_budget.type === "influencer_marketing"
export const getProjectPayoutBudgetUsedInSEK = (globalPayList: number[]) =>
  globalPayList.reduce((acc, globalPayListItem) => acc + globalPayListItem, 0);

// total budget for a campaign
export const getProjectBudgetUsedInSEK = (
  project_budgets: Project_Budget_Insert_Input[] | undefined = [],
  { payoutBudgetUsedInSEK }: { payoutBudgetUsedInSEK: number } = { payoutBudgetUsedInSEK: 0 }
): number =>
  project_budgets
    // if no payout budget is used yet, take the target margin for im
    .filter(({ type }) =>
      payoutBudgetUsedInSEK > 0
        ? !(
            [
              BUDGET_CATEGORIES.INFLUENCER_MARKETING_IG.value,
              BUDGET_CATEGORIES.INFLUENCER_MARKETING_TT.value,
              BUDGET_CATEGORIES.INFLUENCER_MARKETING_YT.value
            ] as string[]
          ).includes(type || "")
        : true
    )
    .reduce(
      (acc, { revenue_allocation, target_margin }) =>
        acc + (revenue_allocation || 0) * (1 - (target_margin || 0)),
      0
    ) + payoutBudgetUsedInSEK;

export const getEstimatedProjectBudgetInSEK = (
  project_budgets: Project_Budget_Insert_Input[] = []
): number =>
  project_budgets.reduce(
    (acc, pb) => acc + (pb.revenue_allocation || 0) * (pb.target_margin || 0),
    0
  );

// get the sum/avg values for type ilike influencer_marketing budgets (IG + YT)
export const getInfluencerMarketingBudget = (
  project_budgets: Project_Budget[] = []
): { revenue_allocation: number; target_margin: number; payout_budget: number } =>
  project_budgets
    .filter(pb =>
      (
        [
          BUDGET_CATEGORIES.INFLUENCER_MARKETING_IG.value,
          BUDGET_CATEGORIES.INFLUENCER_MARKETING_YT.value,
          BUDGET_CATEGORIES.INFLUENCER_MARKETING_TT.value,
          BUDGET_CATEGORIES.LIVE_SHOPPING.value
        ] as string[]
      ).includes(pb.type)
    )
    .reduce(
      ({ revenue_allocation, payout_budget }, pb, index, srcArray) => {
        const targetMarginCasted = Number(pb.target_margin);
        const budgetRevenueAllocationCasted = Number(pb.revenue_allocation);

        const newPayoutBudget =
          payout_budget +
          (budgetRevenueAllocationCasted || budgetRevenueAllocationCasted === 0
            ? Math.round(budgetRevenueAllocationCasted * (1 - targetMarginCasted))
            : 0);
        const newRevenueAllocation = revenue_allocation + budgetRevenueAllocationCasted;

        return {
          revenue_allocation: newRevenueAllocation,
          payout_budget: newPayoutBudget,
          target_margin:
            index === srcArray.length - 1 ? 1 - newPayoutBudget / newRevenueAllocation : 0
        };
      },
      { revenue_allocation: 0, target_margin: 0, payout_budget: 0 }
    );
