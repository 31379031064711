import { useEffect, useRef, useState } from "react";

export const IframeTester: React.FC<{ setResult: (result: boolean) => void }> = ({ setResult }) => {
  const [finished, setFinished] = useState(false);
  const resultTimer = useRef(0);

  const onLoadIframe = () => {
    resultTimer.current = setTimeout(() => {
      setFinished(true);
      setResult(false);
    }, 1000) as unknown as number;
  };

  useEffect(() => {
    if (finished) return () => undefined;

    const onMessage = e => {
      if (e.data === "bamp-iframe-ok") {
        clearTimeout(resultTimer.current);
        setResult(true);
        setFinished(true);
      }
    };

    window.addEventListener("message", onMessage);
    return () => window.removeEventListener("message", onMessage);
  }, [finished, setResult]);

  if (finished) return null;

  return (
    <iframe
      src={`${process.env.GRAPH_URL}/iframe/test`}
      title="Iframe test"
      onLoad={onLoadIframe}
      style={{ border: 0, opacity: 0.001 }}
    />
  );
};
