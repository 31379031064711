import { useState } from "react";

import styled from "@emotion/styled";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { NavigationButton } from "components/ui/NavigationButton";
import { Title } from "lib/styled";

import { SectionTable } from "../common/SectionTable";
import * as constants from "../common/constants";
import {
  getAgeRows,
  getEngagementRows,
  getGenderRows,
  getLocationByCountriesRows,
  getReachRows
} from "./utils/rows";

const { AGE_SECTION_TYPES } = constants;

export const YoutubeReport = ({ data }) => {
  const {
    basic: { id: channelId, title },
    features: {
      demography_by_age,
      audience_geo: { data: audience_geo }
    },
    metrics: {
      subscribers_count,
      video_views_avg,
      videos_per_week,
      ltd_rate,
      comments_avg,
      alikes_avg,
      er
    }
  } = data;

  const { state: locationState } = useLocation() as { state: { from: string } };
  const [metricsPeriod, setMetricsPeriod] = useState("all");

  const handleMetricsPeriodChange = e => setMetricsPeriod(e.target.value);

  return (
    <Root>
      {["/hypeauditor/youtube/aggregated"].includes(locationState?.from) && (
        <NavigationButton direction="back" className="nav-button" />
      )}

      <Title>
        Account analysis for{" "}
        <Link
          to={`https://app.hypeauditor.com/youtube/${channelId}/`}
          rel="noreferrer"
          target="_blank"
        >
          @{title}
        </Link>
      </Title>

      <RadioGroup
        row
        aria-label="metrics-period"
        name="metrics-period"
        value={metricsPeriod}
        onChange={handleMetricsPeriodChange}
      >
        <FormControlLabel value="end" control={<Radio color="primary" value="all" />} label="All" />
        <FormControlLabel
          value="end"
          control={<Radio color="primary" value="30d" />}
          label="30 days"
        />
        <FormControlLabel
          value="end"
          control={<Radio color="primary" value="90d" />}
          label="90 days"
        />
      </RadioGroup>

      <SectionTable
        sectionName="Reach"
        rows={getReachRows({ subscribers_count, video_views_avg, videos_per_week, metricsPeriod })}
        showHeaders={false}
      />
      <SectionTable
        sectionName="Engagement & interactions"
        rows={getEngagementRows({
          ltd_rate,
          comments_avg,
          alikes_avg,
          er,
          metricsPeriod
        })}
        showHeaders={false}
      />

      <Title>Audience demographics</Title>
      <SectionTable
        sectionName="Gender"
        rows={getGenderRows({ demography_by_age, subscribers_count })}
      />
      <SectionTable
        sectionName="Age - all"
        rows={getAgeRows({ demography_by_age, subscribers_count, type: AGE_SECTION_TYPES.ALL })}
      />
      <SectionTable
        sectionName="Age - male"
        rows={getAgeRows({ demography_by_age, subscribers_count, type: AGE_SECTION_TYPES.MALE })}
        defaultOpen={false}
      />
      <SectionTable
        sectionName="Age - female"
        rows={getAgeRows({ demography_by_age, subscribers_count, type: AGE_SECTION_TYPES.FEMALE })}
        defaultOpen={false}
      />

      {audience_geo && (
        <SectionTable
          sectionName="Location: by country"
          rows={getLocationByCountriesRows({
            countries: audience_geo,
            subscribers_count
          })}
        />
      )}
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
  margin: auto;

  .nav-button {
    position: absolute;
    left: 0px;
    top: 23px;
  }
`;
