import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnableClientReviewAndSendEmailsMutationVariables = Types.Exact<{
  projectStub: Types.Scalars['String']['input'];
}>;


export type EnableClientReviewAndSendEmailsMutation = { __typename?: 'Mutation', setProjectReadyForClientReview?: void | null };

export type CreateProjectBudgetMutationVariables = Types.Exact<{
  object: Types.Project_Budget_Insert_Input;
}>;


export type CreateProjectBudgetMutation = { __typename?: 'Mutation', insert_project_budget_one?: { __typename?: 'project_budget', id: number } | null };

export type UpdateProjectBudgetMutationVariables = Types.Exact<{
  projectBudgetId: Types.Scalars['Int']['input'];
  set: Types.Project_Budget_Set_Input;
}>;


export type UpdateProjectBudgetMutation = { __typename?: 'Mutation', update_project_budget_by_pk?: { __typename?: 'project_budget', id: number } | null };

export type DeleteProjectBudgetMutationVariables = Types.Exact<{
  projectBudgetId: Types.Scalars['Int']['input'];
}>;


export type DeleteProjectBudgetMutation = { __typename?: 'Mutation', delete_project_budget_by_pk?: { __typename?: 'project_budget', id: number } | null };


export const EnableClientReviewAndSendEmailsDocument = gql`
    mutation enableClientReviewAndSendEmails($projectStub: String!) {
  setProjectReadyForClientReview(projectStub: $projectStub)
}
    `;
export type EnableClientReviewAndSendEmailsMutationFn = Apollo.MutationFunction<EnableClientReviewAndSendEmailsMutation, EnableClientReviewAndSendEmailsMutationVariables>;

/**
 * __useEnableClientReviewAndSendEmailsMutation__
 *
 * To run a mutation, you first call `useEnableClientReviewAndSendEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableClientReviewAndSendEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableClientReviewAndSendEmailsMutation, { data, loading, error }] = useEnableClientReviewAndSendEmailsMutation({
 *   variables: {
 *      projectStub: // value for 'projectStub'
 *   },
 * });
 */
export function useEnableClientReviewAndSendEmailsMutation(baseOptions?: Apollo.MutationHookOptions<EnableClientReviewAndSendEmailsMutation, EnableClientReviewAndSendEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableClientReviewAndSendEmailsMutation, EnableClientReviewAndSendEmailsMutationVariables>(EnableClientReviewAndSendEmailsDocument, options);
      }
export type EnableClientReviewAndSendEmailsMutationHookResult = ReturnType<typeof useEnableClientReviewAndSendEmailsMutation>;
export type EnableClientReviewAndSendEmailsMutationResult = Apollo.MutationResult<EnableClientReviewAndSendEmailsMutation>;
export type EnableClientReviewAndSendEmailsMutationOptions = Apollo.BaseMutationOptions<EnableClientReviewAndSendEmailsMutation, EnableClientReviewAndSendEmailsMutationVariables>;
export const CreateProjectBudgetDocument = gql`
    mutation createProjectBudget($object: project_budget_insert_input!) {
  insert_project_budget_one(object: $object) {
    id
  }
}
    `;
export type CreateProjectBudgetMutationFn = Apollo.MutationFunction<CreateProjectBudgetMutation, CreateProjectBudgetMutationVariables>;

/**
 * __useCreateProjectBudgetMutation__
 *
 * To run a mutation, you first call `useCreateProjectBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectBudgetMutation, { data, loading, error }] = useCreateProjectBudgetMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateProjectBudgetMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectBudgetMutation, CreateProjectBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectBudgetMutation, CreateProjectBudgetMutationVariables>(CreateProjectBudgetDocument, options);
      }
export type CreateProjectBudgetMutationHookResult = ReturnType<typeof useCreateProjectBudgetMutation>;
export type CreateProjectBudgetMutationResult = Apollo.MutationResult<CreateProjectBudgetMutation>;
export type CreateProjectBudgetMutationOptions = Apollo.BaseMutationOptions<CreateProjectBudgetMutation, CreateProjectBudgetMutationVariables>;
export const UpdateProjectBudgetDocument = gql`
    mutation updateProjectBudget($projectBudgetId: Int!, $set: project_budget_set_input!) {
  update_project_budget_by_pk(pk_columns: {id: $projectBudgetId}, _set: $set) {
    id
  }
}
    `;
export type UpdateProjectBudgetMutationFn = Apollo.MutationFunction<UpdateProjectBudgetMutation, UpdateProjectBudgetMutationVariables>;

/**
 * __useUpdateProjectBudgetMutation__
 *
 * To run a mutation, you first call `useUpdateProjectBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectBudgetMutation, { data, loading, error }] = useUpdateProjectBudgetMutation({
 *   variables: {
 *      projectBudgetId: // value for 'projectBudgetId'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateProjectBudgetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectBudgetMutation, UpdateProjectBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectBudgetMutation, UpdateProjectBudgetMutationVariables>(UpdateProjectBudgetDocument, options);
      }
export type UpdateProjectBudgetMutationHookResult = ReturnType<typeof useUpdateProjectBudgetMutation>;
export type UpdateProjectBudgetMutationResult = Apollo.MutationResult<UpdateProjectBudgetMutation>;
export type UpdateProjectBudgetMutationOptions = Apollo.BaseMutationOptions<UpdateProjectBudgetMutation, UpdateProjectBudgetMutationVariables>;
export const DeleteProjectBudgetDocument = gql`
    mutation deleteProjectBudget($projectBudgetId: Int!) {
  delete_project_budget_by_pk(id: $projectBudgetId) {
    id
  }
}
    `;
export type DeleteProjectBudgetMutationFn = Apollo.MutationFunction<DeleteProjectBudgetMutation, DeleteProjectBudgetMutationVariables>;

/**
 * __useDeleteProjectBudgetMutation__
 *
 * To run a mutation, you first call `useDeleteProjectBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectBudgetMutation, { data, loading, error }] = useDeleteProjectBudgetMutation({
 *   variables: {
 *      projectBudgetId: // value for 'projectBudgetId'
 *   },
 * });
 */
export function useDeleteProjectBudgetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectBudgetMutation, DeleteProjectBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectBudgetMutation, DeleteProjectBudgetMutationVariables>(DeleteProjectBudgetDocument, options);
      }
export type DeleteProjectBudgetMutationHookResult = ReturnType<typeof useDeleteProjectBudgetMutation>;
export type DeleteProjectBudgetMutationResult = Apollo.MutationResult<DeleteProjectBudgetMutation>;
export type DeleteProjectBudgetMutationOptions = Apollo.BaseMutationOptions<DeleteProjectBudgetMutation, DeleteProjectBudgetMutationVariables>;