import styled from "@emotion/styled";
import { prettifyNumber } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";

import { PlatformIcon } from "@relatable/ui/icons/PlatformIcon";
import type { CreatorsInListQuery } from "../generated";

const Root = styled.ul`
  margin-top: 12px;
  list-style: none;
  display: flex;
  width: 100%;
  padding: 0;
  gap: 14px;

  > li {
    flex-grow: 1;
    border: 1px solid ${palette.gray[20]};
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    padding: 5px 12px;

    > .title {
      font-weight: normal;
      display: block;
      font-size: 12px;
      color: ${palette.gray[60]};
    }
  }
`;

// Jun/22 format
const shortDate = (n: string | undefined | null) => {
  if (!n) return "-";
  const date = new Date(n);
  if (Number.isNaN(date.getTime())) return "";

  const dateFormat = new Intl.DateTimeFormat("en-US", {
    month: "short",
    year: "2-digit"
  });

  return dateFormat.format(date).replace(" ", "/");
};

export const ListCards: React.FC<{ data: CreatorsInListQuery }> = ({ data }) => {
  if (!data?.list) return null;

  const lastEdited = (data.users_in_lists ?? [])
    .map(u => u.campaign_user?.created_at)
    .filter(u => Boolean(u))
    .sort((a, b) => new Date(b as string).valueOf() - new Date(a as string).valueOf())[0];

  return (
    <Root>
      <li>
        <span className="title">Creators</span>
        {prettifyNumber(data.users_in_lists.length)}
      </li>
      <li>
        <span className="title">Created on</span>
        {shortDate(data.list.created_at)}
      </li>
      <li>
        <span className="title">Last edited</span>
        {shortDate(lastEdited)}
      </li>
      <li>
        <span className="title">Platform</span>
        {data.list.platform ? <PlatformIcon platform={data.list.platform} /> : "-"}
      </li>
    </Root>
  );
};
