import type { FC } from "react";

import styled from "@emotion/styled";
import { normalizeState, prettifyDate } from "@relatable/helpers";
import { getUserLabel, getUserProfilePicture } from "@relatable/helpers/user";
import { Avatar } from "@relatable/ui/Avatar";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { useMeQuery } from "hooks/generated";
import { CONTENT_APPROVAL_STATE_MANAGER_LABELS } from "modules/campaign/ContentApproval/common/utils";

import { Delete } from "@mui/icons-material";
import { type ContentApprovalLogWithUserFragment, ContentMediaDocument } from "../generated";
import { useDeleteApprovalCommentMutation } from "./generated";

export const ContentLogs: FC<{
  captionText: string;
  legacyInfluencerComment?: string | null;
  logs: ContentApprovalLogWithUserFragment[];
}> = ({ captionText, legacyInfluencerComment, logs }) => {
  const { data } = useMeQuery();
  const snackbar = useSnackbar();

  const [deleteComment, { loading: isDeleting }] = useDeleteApprovalCommentMutation({
    refetchQueries: [ContentMediaDocument],
    awaitRefetchQueries: true,
    onCompleted() {
      snackbar.success("Comment deleted successfully");
    }
  });

  const getAuthor = (log: ContentApprovalLogWithUserFragment) => {
    if (log.campaign_user) {
      return {
        name: log.campaign_user.user?.first_name || getUserLabel(log.campaign_user.user),
        picture: getUserProfilePicture(log.campaign_user.user),
        accountId: null
      };
    }
    if (log.account) {
      return {
        name: log.account.full_name || "",
        picture: log.account.profile_picture,
        accountId: log.account.id
      };
    }
    if (log.client_contact) {
      return {
        name: log.client_contact.first_name || "",
        picture: log.client_contact.picture,
        accountId: null
      };
    }
    return { name: "Somebody", picture: null };
  };

  // max 2 characters
  const getMonogram = (log: ContentApprovalLogWithUserFragment) => {
    const { name } = getAuthor(log);
    if (!name) return "";
    const parts = name.split(" ") || [];
    if (parts.length === 1) {
      return parts[0][0].toUpperCase();
    }
    return [parts[0][0].toUpperCase(), parts[parts.length - 1][0]?.toUpperCase()].join("");
  };

  const getAction = (log: ContentApprovalLogWithUserFragment) => {
    if (log.prev_caption) {
      return "changed the caption to";
    }
    if (log.text) {
      const target = `${log?.target?.slice(0, 1)?.toUpperCase()}${log?.target?.slice(1)}`;
      return `replied to ${target}.`;
    }
    if (log.state !== null) {
      return (
        <>
          changed status to&nbsp;
          <strong>{CONTENT_APPROVAL_STATE_MANAGER_LABELS[normalizeState(log.state)]}</strong>
        </>
      );
    }
    return null; // unknown action
  };

  const getLogCaption = (log: ContentApprovalLogWithUserFragment) => {
    // we only store the previous caption text in the log
    // but we need to show the new one

    if (!log.prev_caption) return null;

    const captionLogs = logs.filter(l => Boolean(l.prev_caption));
    if (captionLogs.length === 0) return null;

    const logIndex = captionLogs.findIndex(l => l.id === log.id);
    if (logIndex === 0) {
      return captionText;
    }

    return captionLogs[logIndex - 1]?.prev_caption;
  };

  return (
    <Root>
      {logs.map(log => {
        const actionLog = getAction(log);
        if (!actionLog) return null;
        const contentText = log.text || getLogCaption(log);
        const author = getAuthor(log);
        const adminId = data?.me?.admin?.id;
        const isMe = adminId && data?.me?.admin?.id === author.accountId;

        return (
          <LogItem key={log.id}>
            <div className="action">
              <div className="left">
                {author.picture ? (
                  <Avatar
                    style={{ filter: "none", marginRight: 5 }}
                    size={34}
                    src={author.picture}
                  />
                ) : (
                  <div className="profile-pic placeholder">{getMonogram(log)}</div>
                )}
                <strong>{author.name ?? "Somebody"}</strong>&nbsp;
                {actionLog}
              </div>
              <span className="date">
                {prettifyDate(log.created_at)}{" "}
                {isMe && log.text && (
                  <Delete
                    onClick={() => {
                      if (isDeleting) return;
                      deleteComment({ variables: { id: log.id } });
                    }}
                    fontSize="small"
                    style={{
                      color: isDeleting ? palette.gray[40] : palette.primary.red,
                      cursor: "pointer"
                    }}
                  />
                )}
              </span>
            </div>
            {contentText && <div className="text">{contentText}</div>}
          </LogItem>
        );
      })}
      {legacyInfluencerComment ? (
        <LogItem>
          <div className="action">
            <div className="left">
              <div className="profile-pic placeholder">I</div>
              <strong>Influencer</strong>&nbsp;
            </div>
          </div>
          <div className="text">{legacyInfluencerComment}</div>
        </LogItem>
      ) : null}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  max-height: 300px;
  overflow: auto;
  margin-top: 15px;
  padding-right: 10px;
`;

const LogItem = styled.div`
  width: 100%;
  margin-bottom: 24px;

  > .action {
    display: flex;
    min-height: 32px;
    justify-content: space-between;
    align-items: center;

    > .left {
      display: flex;
      align-items: center;

      > .profile-pic {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        border-radius: 16px;
        &.placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          background: ${palette.secondary.green};
          color: ${palette.gray.white};
        }
      }
    }
  }

  > .text {
    margin-left: 40px;
    padding: 8px 12px;
    display: block;
    background: ${palette.gray.whiteOff};
    white-space: pre-wrap;
  }
`;
