import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";

import { fadeIn } from "lib/styled";

export const ScrollableSectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  padding-top: 10px;
  height: 150px;
  overflow: auto;
`;

export const FloatingContainer = styled.div`
  position: absolute;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  background: white;
  padding: 10px;
  border-radius: 15px;
  z-index: 10;
  right: 0;
  bottom: 10px;
  animation: ${fadeIn} 200ms ease;
`;

export const DeleteButton = styled.button`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${palette.secondary.red};
  position: absolute;
  top: -5px;
  right: -5px;
  border: 0;
  cursor: pointer;
  animation: ${fadeIn} 200ms ease;
`;
