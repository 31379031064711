import type { FC } from "react";

import styled from "@emotion/styled";
import { Route, Routes } from "react-router-dom";

import { AddExistingMessage } from "./AddExistingMessage";
import { Content } from "./Content";
import { CreateNewMessage } from "./CreateNewMessage";
import { MessageList } from "./MessageList";
import { MessageOverride } from "./MessageOverride";
import { MessageOverrideEditor } from "./MessageOverrideEditor";
import { MessageStats } from "./MessageStats";

const Root = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const Messages: FC = () => {
  return (
    <Root>
      <Routes>
        <Route path="" element={<MessageList />} />
        <Route path="new" element={<CreateNewMessage />} />
        <Route path="link" element={<AddExistingMessage />} />
        <Route path="stats/:contentName" element={<MessageStats />} />
        <Route path="edit/:contentName" element={<Content />} />
        <Route path="overrides" element={<MessageOverride />} />
        <Route path="overrides/:messageId/edit" element={<MessageOverrideEditor />} />
      </Routes>
    </Root>
  );
};
