import { type FC, useMemo } from "react";

import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { useNavigate } from "react-router-dom";

import { useMeQuery } from "hooks/generated";
import { Title } from "lib/styled";

import { MetaSection } from "./MetaSection";
import { useCreateProjectMutation } from "./generated";

export const ProjectCreate: FC = () => {
  useDocumentTitle("Create project");

  const navigate = useNavigate();

  const [createProject, { loading, error }] = useCreateProjectMutation({
    onCompleted: response => {
      navigate(`/project/${response.createProject.stub}/edit`);
    }
  });

  const meQuery = useMeQuery({
    notifyOnNetworkStatusChange: true
  });

  const project = useMemo(
    () => (meQuery.data ? { project_owner_id: meQuery.data.me?.admin?.id } : undefined),
    [meQuery.data]
  );

  return (
    <>
      <Title>Create project</Title>

      <MetaSection
        variant="create"
        project={project}
        submitError={error}
        isSubmitting={loading}
        onSubmit={async ({ exchange_rate, currency, ...rest }) => {
          await createProject({
            variables: {
              input: {
                ...rest,
                project_invoicing_detail: {
                  data: { exchange_rate, currency }
                }
              }
            }
          });
        }}
      />
    </>
  );
};
