import styled from "@emotion/styled";

export const NotFound = () => (
  <Root>
    <h1>😕</h1>
    <p>Looks like the page you requested does not exist...</p>
  </Root>
);

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
