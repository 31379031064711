import { TIMELINE_GROUP_WEIGHT } from "./constants";
import { isBefore } from "./date";

export const getCampaignProgress = (
  items: { group: number; end_date?: string | null | undefined }[]
) => {
  const groups = TIMELINE_GROUP_WEIGHT.map((_, index) =>
    items.filter(item => item.group === index)
  );

  if (groups.some(g => !g.length)) {
    // when there is no timeline for the campaign
    return null;
  }

  const now = new Date().toISOString();

  const progress = groups.reduce((acc, groupItems) => {
    const groupWeight = TIMELINE_GROUP_WEIGHT[groupItems[0].group];
    if (groupWeight === undefined) throw new Error("Invalid group");

    const itemPercentage = groupWeight / 100 / groupItems.length;
    const finishedItems = groupItems.filter(item => item.end_date && isBefore(item.end_date, now));

    return acc + finishedItems.length * itemPercentage;
  }, 0);

  return progress;
};
