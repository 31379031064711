import type { FC } from "react";

import styled from "@emotion/styled";
import { prettifyDate, truncateText } from "@relatable/helpers";
import { Table } from "@relatable/ui/Table";
import { Link } from "react-router-dom";

import { useMeQuery } from "hooks/generated";

import { useAiConversationsQuery } from "./generated";

export const Conversations: FC = () => {
  const {
    data: { me } = {}
  } = useMeQuery();
  const { data, loading } = useAiConversationsQuery({
    variables: { accountId: me?.admin?.id ?? 0 },
    skip: !me?.admin?.id,
    fetchPolicy: "no-cache"
  });

  const rows =
    data?.ai_conversations.map(conversation => ({
      key: conversation.id,
      question: conversation.ai_messages[0]?.content ?? "",
      createdAt: conversation.created_at
    })) ?? [];

  return (
    <Root>
      <Table
        tableId="chat-conversations"
        loading={loading}
        columns={[
          {
            field: "createdAt",
            headerName: "Date",
            renderCell: ({ value }) => (
              <span style={{ whiteSpace: "nowrap" }} title={prettifyDate(value, { full: true })}>
                {prettifyDate(value)}
              </span>
            )
          },
          {
            field: "question",
            headerName: "Question",
            minWidth: 500,
            renderCell: ({ row, value }) => (
              <Link to={`/chat/${row.key}`}>{truncateText(value || "-", 300)}</Link>
            )
          }
        ]}
        rows={rows}
      />
    </Root>
  );
};

const Root = styled.div``;
