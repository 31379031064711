import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignContentSettingsQueryVariables = Types.Exact<{
  stub: Types.Scalars['String']['input'];
}>;


export type CampaignContentSettingsQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, platform?: Types.Platform_Enum | null, campaign_content_settings: Array<{ __typename?: 'campaign_content_settings', id: number, type: Types.Content_Preapproval_Type_Enum, updated_at?: string | null, created_at: string, publish_date_max: string, publish_date_min: string, number_of_posts: number, name?: string | null }> }> };

export type DeleteCampaignContentSettingMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteCampaignContentSettingMutation = { __typename?: 'Mutation', delete_campaign_content_settings_by_pk?: { __typename?: 'campaign_content_settings', id: number } | null };

export type UpdateCampaignContentSettingMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  set: Types.Campaign_Content_Settings_Set_Input;
}>;


export type UpdateCampaignContentSettingMutation = { __typename?: 'Mutation', update_campaign_content_settings_by_pk?: { __typename?: 'campaign_content_settings', id: number } | null };

export type InsertCampaignContentSettingMutationVariables = Types.Exact<{
  object: Types.Campaign_Content_Settings_Insert_Input;
}>;


export type InsertCampaignContentSettingMutation = { __typename?: 'Mutation', insert_campaign_content_settings_one?: { __typename?: 'campaign_content_settings', id: number } | null };


export const CampaignContentSettingsDocument = gql`
    query campaignContentSettings($stub: String!) {
  campaigns: campaign(where: {stub: {_eq: $stub}}, limit: 1) {
    id
    platform
    campaign_content_settings(order_by: {created_at: asc, id: asc}) {
      id
      type
      updated_at
      created_at
      publish_date_max
      publish_date_min
      number_of_posts
      name
    }
  }
}
    `;

/**
 * __useCampaignContentSettingsQuery__
 *
 * To run a query within a React component, call `useCampaignContentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignContentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignContentSettingsQuery({
 *   variables: {
 *      stub: // value for 'stub'
 *   },
 * });
 */
export function useCampaignContentSettingsQuery(baseOptions: Apollo.QueryHookOptions<CampaignContentSettingsQuery, CampaignContentSettingsQueryVariables> & ({ variables: CampaignContentSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignContentSettingsQuery, CampaignContentSettingsQueryVariables>(CampaignContentSettingsDocument, options);
      }
export function useCampaignContentSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignContentSettingsQuery, CampaignContentSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignContentSettingsQuery, CampaignContentSettingsQueryVariables>(CampaignContentSettingsDocument, options);
        }
export function useCampaignContentSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignContentSettingsQuery, CampaignContentSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignContentSettingsQuery, CampaignContentSettingsQueryVariables>(CampaignContentSettingsDocument, options);
        }
export type CampaignContentSettingsQueryHookResult = ReturnType<typeof useCampaignContentSettingsQuery>;
export type CampaignContentSettingsLazyQueryHookResult = ReturnType<typeof useCampaignContentSettingsLazyQuery>;
export type CampaignContentSettingsSuspenseQueryHookResult = ReturnType<typeof useCampaignContentSettingsSuspenseQuery>;
export type CampaignContentSettingsQueryResult = Apollo.QueryResult<CampaignContentSettingsQuery, CampaignContentSettingsQueryVariables>;
export const DeleteCampaignContentSettingDocument = gql`
    mutation deleteCampaignContentSetting($id: Int!) {
  delete_campaign_content_settings_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteCampaignContentSettingMutationFn = Apollo.MutationFunction<DeleteCampaignContentSettingMutation, DeleteCampaignContentSettingMutationVariables>;

/**
 * __useDeleteCampaignContentSettingMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignContentSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignContentSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignContentSettingMutation, { data, loading, error }] = useDeleteCampaignContentSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCampaignContentSettingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignContentSettingMutation, DeleteCampaignContentSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignContentSettingMutation, DeleteCampaignContentSettingMutationVariables>(DeleteCampaignContentSettingDocument, options);
      }
export type DeleteCampaignContentSettingMutationHookResult = ReturnType<typeof useDeleteCampaignContentSettingMutation>;
export type DeleteCampaignContentSettingMutationResult = Apollo.MutationResult<DeleteCampaignContentSettingMutation>;
export type DeleteCampaignContentSettingMutationOptions = Apollo.BaseMutationOptions<DeleteCampaignContentSettingMutation, DeleteCampaignContentSettingMutationVariables>;
export const UpdateCampaignContentSettingDocument = gql`
    mutation updateCampaignContentSetting($id: Int!, $set: campaign_content_settings_set_input!) {
  update_campaign_content_settings_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;
export type UpdateCampaignContentSettingMutationFn = Apollo.MutationFunction<UpdateCampaignContentSettingMutation, UpdateCampaignContentSettingMutationVariables>;

/**
 * __useUpdateCampaignContentSettingMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignContentSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignContentSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignContentSettingMutation, { data, loading, error }] = useUpdateCampaignContentSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCampaignContentSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignContentSettingMutation, UpdateCampaignContentSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignContentSettingMutation, UpdateCampaignContentSettingMutationVariables>(UpdateCampaignContentSettingDocument, options);
      }
export type UpdateCampaignContentSettingMutationHookResult = ReturnType<typeof useUpdateCampaignContentSettingMutation>;
export type UpdateCampaignContentSettingMutationResult = Apollo.MutationResult<UpdateCampaignContentSettingMutation>;
export type UpdateCampaignContentSettingMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignContentSettingMutation, UpdateCampaignContentSettingMutationVariables>;
export const InsertCampaignContentSettingDocument = gql`
    mutation insertCampaignContentSetting($object: campaign_content_settings_insert_input!) {
  insert_campaign_content_settings_one(object: $object) {
    id
  }
}
    `;
export type InsertCampaignContentSettingMutationFn = Apollo.MutationFunction<InsertCampaignContentSettingMutation, InsertCampaignContentSettingMutationVariables>;

/**
 * __useInsertCampaignContentSettingMutation__
 *
 * To run a mutation, you first call `useInsertCampaignContentSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCampaignContentSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCampaignContentSettingMutation, { data, loading, error }] = useInsertCampaignContentSettingMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCampaignContentSettingMutation(baseOptions?: Apollo.MutationHookOptions<InsertCampaignContentSettingMutation, InsertCampaignContentSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCampaignContentSettingMutation, InsertCampaignContentSettingMutationVariables>(InsertCampaignContentSettingDocument, options);
      }
export type InsertCampaignContentSettingMutationHookResult = ReturnType<typeof useInsertCampaignContentSettingMutation>;
export type InsertCampaignContentSettingMutationResult = Apollo.MutationResult<InsertCampaignContentSettingMutation>;
export type InsertCampaignContentSettingMutationOptions = Apollo.BaseMutationOptions<InsertCampaignContentSettingMutation, InsertCampaignContentSettingMutationVariables>;