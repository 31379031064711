import { List, ListItem } from "@mui/material";
import { type FC, useState } from "react";

import { Loader } from "@relatable/ui/Loader";
import { Popup } from "components/ui/Popup";
import { Center } from "lib/styled";

import { Link } from "@mui/icons-material";
import { useStoryLinksQuery } from "./generated";

const PopupBody: FC<{ campaignStub: string; storyId: number }> = ({ campaignStub, storyId }) => {
  const { data } = useStoryLinksQuery({ variables: { stub: campaignStub, storyId } });

  if (!data) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  const [story] = data.campaigns[0].campaign_stories;
  return (
    <div>
      <h2>{story.user?.user_instagram?.username}</h2>
      <List>
        {story.campaign_story_clips.map(clip => (
          <ListItem key={clip.track_url}>{clip.track_url}</ListItem>
        ))}
      </List>
    </div>
  );
};

export const LinksCell: FC<{ campaignStub: string; storyId: number }> = ({
  campaignStub,
  storyId
}) => {
  const [isLinksPopup, setIsLinksPopup] = useState(false);
  return (
    <>
      <Link
        onClick={() => {
          if (!storyId) return;
          setIsLinksPopup(true);
        }}
        style={{ cursor: storyId ? "pointer" : "not-allowed", opacity: storyId ? 1 : 0.3 }}
        color="action"
      />
      {isLinksPopup && (
        <Popup onClose={() => setIsLinksPopup(false)}>
          <PopupBody campaignStub={campaignStub} storyId={storyId} />
        </Popup>
      )}
    </>
  );
};
