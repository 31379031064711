import { type FC, useMemo, useState } from "react";

import styled from "@emotion/styled";
import { debounce } from "@mui/material";
import type { Campaign_Snap_Set_Input } from "@relatable/gql/generated-base";
import { prettifyDate } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { useParams } from "react-router-dom";

import { NavigationButton } from "components/ui/NavigationButton";
import { useUpdateSnapMutation } from "modules/campaign/Snaps/generated";
import { ContentDisplay } from "modules/campaign/common/ContentDisplay";

import { CampaignSnapDocument, type CampaignSnapQuery } from "./generated";

export const SnapContent: FC<{ snap: CampaignSnapQuery }> = ({ snap }) => {
  const snackbar = useSnackbar();
  const [updateSnap] = useUpdateSnapMutation({
    onError(err) {
      snackbar.error(err.message);
    },
    refetchQueries: [CampaignSnapDocument],
    awaitRefetchQueries: true
  });
  const { snapId, campaignStub } = useParams<{ snapId: string; campaignStub: string }>();

  const [campaignSnap] = snap.campaignSnap;

  const [snapData, setSnapData] = useState({
    track_url: campaignSnap.track_url,
    views: campaignSnap.views,
    reach: campaignSnap.reach,
    screenshots: campaignSnap.screenshots,
    comments: campaignSnap.comments,
    clicks: campaignSnap.clicks
  });

  const isSnapApproved = Boolean(campaignSnap.approved);

  const handleApprove = async () => {
    await updateSnap({
      variables: {
        id: Number(snapId),
        set: {
          approved: true,
          approved_at: new Date().toISOString()
        }
      },
      refetchQueries: [CampaignSnapDocument],
      awaitRefetchQueries: true
    });

    snackbar.success("Snap approved");
  };

  const debouncedHandleSnapChange = useMemo(
    () =>
      debounce(async (set: Campaign_Snap_Set_Input) => {
        await updateSnap({
          variables: {
            id: Number(snapId),
            set
          }
        });

        snackbar.success("Snap updated");
      }, 1000),
    [snapId, updateSnap, snackbar]
  );

  const handleSnapChange = (set: Campaign_Snap_Set_Input) => {
    const user_snapchat_id = campaignSnap.campaign_user.user?.user_snapchat?.id;
    if (!user_snapchat_id) throw new Error("missing user_snapchat_id");

    setSnapData(p => ({ ...p, ...set }));
    debouncedHandleSnapChange({ ...snapData, ...set });
  };

  const userSnap = campaignSnap.campaign_user.user?.user_snapchat;
  const campaignUser = campaignSnap.campaign_user;
  const username = userSnap?.username;

  const shortlinks = Array.isArray(campaignUser?.tracking?.shortlinks)
    ? campaignUser?.tracking.shortlinks
    : [];

  return (
    <>
      <NavigationButton
        style={{ width: "fit-content" }}
        pathname={`/campaign/${campaignStub}/snaps`}
      />

      <Title>Snap video by @{username}</Title>
      <MediaContainer>
        <Title>Created at {prettifyDate(campaignSnap.created_at)}</Title>
        <Container>
          {campaignSnap.url && (
            <ContentDisplay
              url={campaignSnap.url}
              thumbnail={campaignSnap.url}
              status={isSnapApproved ? "approved" : ""}
              isVideo
            />
          )}
          <StoryStateContainer>
            {isSnapApproved ? (
              <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", gap: 20 }}>
                <TextInput
                  type="number"
                  label="Views"
                  value={String(snapData.views || 0)}
                  onChange={v => handleSnapChange({ views: Math.round(Number(v)) })}
                />

                <TextInput
                  type="number"
                  label="Reach"
                  value={String(snapData.reach || 0)}
                  onChange={v => handleSnapChange({ reach: Math.round(Number(v)) })}
                />

                <TextInput
                  type="number"
                  label="Screenshots"
                  value={String(snapData.screenshots || 0)}
                  onChange={v => handleSnapChange({ screenshots: Math.round(Number(v)) })}
                />

                <TextInput
                  type="number"
                  label="Comments"
                  value={String(snapData.comments || 0)}
                  onChange={v => handleSnapChange({ comments: Math.round(Number(v)) })}
                />

                <TextInput
                  type="number"
                  label="Clicks"
                  value={String(snapData.clicks || 0)}
                  onChange={v => handleSnapChange({ clicks: Math.round(Number(v)) })}
                />

                <Select
                  label="Tracking url"
                  onChange={track_url => handleSnapChange({ track_url })}
                  value={snapData.track_url || ""}
                  options={shortlinks.map(url => ({
                    label: url,
                    value: url
                  }))}
                />
              </div>
            ) : null}
          </StoryStateContainer>
        </Container>
      </MediaContainer>

      {isSnapApproved && (
        <span style={{ marginLeft: "auto" }}>
          This <strong>Snap</strong> was approved on <i>{prettifyDate(campaignSnap.approved_at)}</i>
        </span>
      )}

      <Button
        style={{ marginTop: 10, minWidth: 200, marginLeft: "auto" }}
        disabled={isSnapApproved}
        onClick={handleApprove}
      >
        {isSnapApproved ? "Approved" : "Approve"}
      </Button>
    </>
  );
};

const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
`;

const Container = styled.div`
  display: flex;
  margin: 20px 0px;
  padding-right: 50px;
`;

const Title = styled.h5`
  font-size: 1.34rem;
  line-height: 110%;
  margin: 0.82rem 0 0.656rem 0;
  font-weight: 400;
  margin-bottom: 10px;
`;

const StoryStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
