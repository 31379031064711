import { type FC, useEffect, useState } from "react";

import { Button } from "@mui/material";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { useSearchParams } from "@relatable/ui/hooks/useSearchParams";
import { Popup } from "components/ui/Popup";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";

import { DataPicker } from "./DataPicker";
import { useAggregatedListPlatform } from "./useAggregatedListPlatform";
import { useDataPickerSections } from "./useDataPickerSections";

export const CSVBuilderModal: FC<{
  closeModal: () => void;
  filename: string;
  showConfirmed: boolean;
}> = ({ closeModal, showConfirmed, filename }) => {
  const platform = useAggregatedListPlatform();
  const snackbar = useSnackbar();
  const { campaignIds, listIds } = useSearchParams();
  const [exportURL, setExportURL] = useState("");
  const { copyToClipboard } = useCopyToClipboard(3000);
  const { checkedRows, sections, toggleSectionRow } = useDataPickerSections({ platform });

  useEffect(() => {
    const url = new URL(`${process.env.GRAPH_URL}/export/hypeauditor`);
    url.searchParams.set("platform", platform);
    url.searchParams.set("fields", checkedRows.map(cr => cr.key).join(","));
    if (listIds) url.searchParams.set("listIds", listIds);
    if (filename) url.searchParams.set("filename", filename);
    if (campaignIds) url.searchParams.set("campaignIds", campaignIds);
    if (showConfirmed) url.searchParams.set("onlyConfirmed", "true");
    setExportURL(String(url));
  }, [campaignIds, filename, listIds, platform, checkedRows, showConfirmed]);

  return (
    <Popup
      title="Build CSV to export"
      onClose={closeModal}
      onAction={() => {
        window.open(exportURL, "_blank")?.focus();
      }}
      actionLabel="Download CSV"
      maxWidth={false}
    >
      <DataPicker sections={sections} toggleSectionRow={toggleSectionRow} />

      <Button
        variant="contained"
        onClick={() => {
          copyToClipboard(`=QUERY(IMPORTDATA("${exportURL}"),"select * order by Col1",-1)`);
          snackbar.success("URL copied to the clipboard successfully");
          closeModal();
        }}
      >
        Copy Google sheet query
      </Button>
    </Popup>
  );
};
