import { Box } from "@mui/material";
import type {
  Project_Invoice,
  Project_Invoice_Set_Input,
  Project_Invoicing_Detail
} from "@relatable/gql/generated-base";
import { YYYY_MM_DD, calculatePercentages } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { DateInput } from "@relatable/ui/DateInput";
import { Note } from "@relatable/ui/Note";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { TextInput } from "@relatable/ui/TextInput";
import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { LinearProgress } from "components/ui/LinearProgress";

import { Add, Delete } from "@mui/icons-material";
import { Section, SectionTitle, SubSection } from "../Section";
import {
  type ProjectDetailsQuery,
  useCreateProjectInvoiceMutation,
  useDeleteProjectInvoiceMutation,
  useInsertProjectInvoiceDetailsMutation,
  useUpdateProjectInvoiceDetailsMutation,
  useUpdateProjectInvoicesMutation
} from "../generated";
import { getChangedFields } from "../helpers";
import type { SectionRef } from "./types";

type ProjectInvoiceFields = Pick<
  Project_Invoice,
  "amount" | "po_number" | "invoice_upon" | "invoice_by_date" | "invoice_by_condition"
> & { id?: number };

type Fields = Pick<
  Project_Invoicing_Detail,
  | "company_name"
  | "address"
  | "client_reference"
  | "client_email"
  | "invoice_email"
  | "organization_number"
  | "vat_number"
  | "payment_days"
  | "additional_info"
>;

const INVOICE_UPON_TYPES = ["other", "date"] as const;

export const InvoicesSection = forwardRef<
  SectionRef,
  {
    project: ProjectDetailsQuery["projects"][number] | undefined;
    isSubmitting: boolean;
    onTotalInvoiceAmountChange(v: number): void;
    totalInvoiceAmount: number | null;
  }
>(({ project, isSubmitting, onTotalInvoiceAmountChange, totalInvoiceAmount }, ref) => {
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const [fields, setFields] = useState<Partial<Fields>>({});
  const [projectInvoiceFields, setProjectInvoiceFields] = useState<ProjectInvoiceFields[]>([]);

  useEffect(() => {
    if (!project) return;
    setProjectInvoiceFields(
      project.project_invoices.map(i => ({
        invoice_upon: i.invoice_upon,
        amount: i.amount,
        id: i.id,
        invoice_by_condition: i.invoice_by_condition,
        invoice_by_date: i.invoice_by_date,
        po_number: i.po_number
      }))
    );

    const d = project.project_invoicing_detail;
    if (!d) return;
    setFields({
      additional_info: d.additional_info,
      address: d.address,
      client_email: d.client_email,
      client_reference: d.client_reference,
      company_name: d.company_name,
      invoice_email: d.invoice_email,
      organization_number: d.organization_number,
      payment_days: d.payment_days,
      vat_number: d.vat_number
    });
  }, [project]);

  const [updateProjectInvoiceDetails, updateProjectInvoiceDetailsConfig] =
    useUpdateProjectInvoiceDetailsMutation();
  const [insertProjectInvoiceDetails, insertProjectInvoiceDetailsConfig] =
    useInsertProjectInvoiceDetailsMutation();
  const [updateProjectInvoices, updateProjectInvoicesConfig] = useUpdateProjectInvoicesMutation();
  const [createProjectInvoice, createProjectInvoiceConfig] = useCreateProjectInvoiceMutation();
  const [deleteProjectInvoice, deleteProjectInvoiceConfig] = useDeleteProjectInvoiceMutation();

  const changedFields = project?.project_invoicing_detail
    ? getChangedFields({
        data: { ...fields, total_amount: totalInvoiceAmount },
        initialData: project?.project_invoicing_detail
      })
    : fields;

  const handleUpdateDetails = async () => {
    if (!project) throw new Error("Project is missing");

    const invoicingDetailId = project?.project_invoicing_detail?.id;
    if (!invoicingDetailId) {
      await insertProjectInvoiceDetails({
        variables: {
          object: {
            project_id: project.id,
            ...changedFields
          }
        }
      });
      return;
    }

    if (!Object.values(changedFields).length) return;

    await updateProjectInvoiceDetails({
      variables: {
        projectId: project.id,
        set: {
          id: invoicingDetailId,
          updated_at: new Date().toISOString(),
          ...changedFields
        }
      }
    });
  };

  const validate = () => {
    const errors: string[] = [];

    if (!totalInvoiceAmount) errors.push("Provide total invoice amount!");
    if ((fields.payment_days || 0) < 1) {
      errors.push("Provide at least 1 day as Payment day");
    }

    projectInvoiceFields.forEach((invoice, index) => {
      const order = index + 1;
      if (!invoice.amount) errors.push(`Provide amount for the ${order} invoice`);
      if (!invoice.po_number) errors.push(`Provide PO number for the ${order} invoice`);
      if (invoice.invoice_upon === "date") {
        if (!invoice.invoice_by_date) errors.push(`Provide date for the ${order} invoice`);
      } else if (!invoice.invoice_by_condition) {
        errors.push(`Provide "Invoice by" for the ${order} invoice`);
      }
    });

    setFieldErrors(errors);
    return errors;
  };

  const getChangedInvoices = () => {
    const invoiceIdsToDelete: number[] = [];
    const invoicesToUpdate: { invoiceId: number; set: Project_Invoice_Set_Input }[] = [];

    if (!project?.id) return { invoiceIdsToDelete, invoicesToUpdate, invoicesToCreate: [] };

    project.project_invoices.forEach(async existing => {
      const invoice = projectInvoiceFields.find(i => i.id === existing.id);
      if (!invoice?.id) {
        invoiceIdsToDelete.push(existing.id);
        return;
      }

      const fieldsData = { ...invoice };
      if (fieldsData.invoice_upon === "date") {
        fieldsData.invoice_by_condition = null;
      }
      if (fieldsData.invoice_upon === "other") {
        fieldsData.invoice_by_date = null;
      }
      const set = getChangedFields({
        data: fieldsData,
        initialData: existing
      });

      if (!Object.values(set).length) return;

      invoicesToUpdate.push({ invoiceId: invoice.id, set });
    });

    return {
      invoiceIdsToDelete,
      invoicesToUpdate,
      invoicesToCreate: projectInvoiceFields.filter(invoice => !invoice.id)
    };
  };

  const { invoicesToCreate, invoiceIdsToDelete, invoicesToUpdate } = getChangedInvoices();

  const handleUpdate = async () => {
    if (!project?.id) fieldErrors.push("Project is missing id");

    if (validate().length) return;
    if (!project?.id) return;

    await Promise.all([
      ...invoicesToCreate.map(async invoice => {
        if (invoice.id) throw new Error("not existing Invoice has id");

        const object = { ...invoice };
        if (object.invoice_upon === "date") {
          object.invoice_by_condition = null;
        }
        if (object.invoice_upon === "other") {
          object.invoice_by_date = null;
        }
        await createProjectInvoice({
          variables: {
            object: { ...object, project_id: project.id }
          }
        });
      }),
      ...invoicesToUpdate.map(({ invoiceId, set }) =>
        updateProjectInvoices({
          variables: {
            invoiceId,
            set
          }
        })
      ),
      ...invoiceIdsToDelete.map(invoiceId => deleteProjectInvoice({ variables: { invoiceId } })),
      handleUpdateDetails()
    ]);
  };

  const invoicesAmountSum = projectInvoiceFields.reduce((acc, v) => acc + (v.amount || 0), 0);
  const invoiceAmountDiff = (totalInvoiceAmount || 0) - invoicesAmountSum;
  const isLoading =
    !project ||
    isSubmitting ||
    updateProjectInvoicesConfig.loading ||
    updateProjectInvoiceDetailsConfig.loading ||
    createProjectInvoiceConfig.loading ||
    insertProjectInvoiceDetailsConfig.loading ||
    deleteProjectInvoiceConfig.loading;

  const handleFieldChange = (partialData: Partial<Fields>) => {
    setFields(prev => ({ ...prev, ...partialData }));
  };

  const isChanged = Boolean(
    Object.values(changedFields).length ||
      invoicesToCreate.length ||
      invoiceIdsToDelete.length ||
      invoicesToUpdate.length
  );

  useImperativeHandle(ref, () => ({
    submit: handleUpdate,
    validate,
    isChanged,
    isLoading
  }));

  const latestUpdatedAt = project
    ? Math.max(
        ...(project?.project_invoices.map(i => new Date(i.updated_at || i.created_at).getTime()) ||
          [])
      )
    : null;
  return (
    <Section
      isChanged={isChanged}
      title="Invoice details"
      fieldErrors={fieldErrors}
      submitError={
        updateProjectInvoicesConfig.error ||
        updateProjectInvoiceDetailsConfig.error ||
        createProjectInvoiceConfig.error ||
        deleteProjectInvoiceConfig.error ||
        insertProjectInvoiceDetailsConfig.error
      }
      updated_at={latestUpdatedAt ? new Date(latestUpdatedAt).toLocaleString() : null}
      sidebar={
        <Note
          variant={
            invoiceAmountDiff === 0 ? "success" : invoiceAmountDiff > 0 ? "warning" : "error"
          }
          label="Invoice budget available"
        >
          {invoiceAmountDiff === 0
            ? "You spent all the invoice budget."
            : invoiceAmountDiff > 0
              ? `You still have ${invoiceAmountDiff} of the budget`
              : `You exceeded the invoice budget by ${Math.abs(invoiceAmountDiff)}`}
        </Note>
      }
    >
      <TextInput
        type="number"
        label="Total invoice amount"
        disabled={isLoading}
        value={String(totalInvoiceAmount)}
        onChange={v => onTotalInvoiceAmountChange(Number(v))}
      />
      <TextInput
        label="Company name"
        value={fields.company_name || ""}
        disabled={isLoading}
        onChange={v => handleFieldChange({ company_name: v })}
      />

      <TextInput
        label="Invoicing address"
        disabled={isLoading}
        value={fields.address || ""}
        onChange={v => handleFieldChange({ address: v })}
      />
      <TextInput
        label="Client reference"
        disabled={isLoading}
        value={fields.client_reference || ""}
        onChange={v => handleFieldChange({ client_reference: v })}
      />
      <TextInput
        label="Client e-mail"
        disabled={isLoading}
        value={fields.client_email || ""}
        onChange={v => handleFieldChange({ client_email: v })}
      />
      <TextInput
        label="Send invoice to (e-mail)"
        disabled={isLoading}
        value={fields.invoice_email || ""}
        onChange={v => handleFieldChange({ invoice_email: v })}
      />
      <TextInput
        label="Organization number"
        disabled={isLoading}
        value={fields.organization_number || ""}
        onChange={v => handleFieldChange({ organization_number: v })}
      />
      <TextInput
        label="VAT"
        disabled={isLoading}
        value={fields.vat_number || ""}
        onChange={v => handleFieldChange({ vat_number: v })}
      />
      <TextInput
        type="number"
        label="Payment days"
        disabled={isLoading}
        value={String(fields.payment_days)}
        onChange={v => handleFieldChange({ payment_days: Number(v) })}
      />
      <TextInput
        multiline
        label="Additional info"
        disabled={isLoading}
        value={fields.additional_info || ""}
        onChange={v => handleFieldChange({ additional_info: v })}
      />

      <SectionTitle>Invoices: {projectInvoiceFields.length}</SectionTitle>
      <div />

      {projectInvoiceFields.map((invoice, index) => {
        const handleChange = (partialData: Partial<ProjectInvoiceFields>) => {
          const newProjectInvoices = projectInvoiceFields.map((i, _index) =>
            _index === index ? { ...i, ...partialData } : i
          );
          setProjectInvoiceFields(newProjectInvoices);
        };

        return (
          <Fragment key={invoice.id}>
            <h4>Invoice nr {index + 1}</h4>
            <div />

            <SubSection>
              <TextInput
                required
                type="number"
                disabled={isLoading}
                label={`Invoice amount ${project?.project_invoicing_detail?.currency}`}
                value={String(invoice.amount)}
                onChange={v => handleChange({ amount: Number(v) })}
                style={{ flexGrow: 1 }}
              />
              <TextInput
                disabled
                label="% of total amount"
                value={String(
                  calculatePercentages({ total: totalInvoiceAmount, of: invoice.amount })
                )}
                onChange={() => null}
              />
            </SubSection>
            <Box display="flex" alignItems="center" gap={0.5}>
              <TextInput
                required
                disabled={isLoading}
                label="PO number"
                value={invoice.po_number || ""}
                onChange={v => handleChange({ po_number: v })}
              />

              <Select
                required
                hideNone
                disabled={isLoading}
                label="Invoice upon"
                value={invoice.invoice_upon}
                onChange={v => v && handleChange({ invoice_upon: v })}
                options={INVOICE_UPON_TYPES.map(type => ({ label: type, value: type }))}
              />
              {invoice.invoice_upon === "date" ? (
                <DateInput
                  label="Invoice by*"
                  style={{ flexGrow: 1 }}
                  onChange={v => handleChange({ invoice_by_date: v })}
                  value={invoice.invoice_by_date || ""}
                />
              ) : (
                <TextInput
                  required
                  disabled={isLoading}
                  label="Invoice by"
                  style={{ flexGrow: 1 }}
                  value={invoice.invoice_by_condition || ""}
                  onChange={v => handleChange({ invoice_by_condition: v })}
                />
              )}

              <Delete
                style={{ cursor: "pointer", color: palette.primary.red }}
                onClick={() =>
                  setProjectInvoiceFields(
                    projectInvoiceFields.filter((_, _index) => _index !== index)
                  )
                }
              />
            </Box>
          </Fragment>
        );
      })}

      <LinearProgress
        showLeft
        max={totalInvoiceAmount}
        value={invoicesAmountSum}
        label="Total allocated"
      />

      <Button
        disabled={isLoading}
        icon={<Add />}
        onClick={() =>
          setProjectInvoiceFields([
            ...projectInvoiceFields,
            {
              amount: 0,
              invoice_upon: "date",
              invoice_by_date: YYYY_MM_DD(new Date()),
              po_number: "",
              invoice_by_condition: null
            }
          ])
        }
        size="medium"
        style={{ margin: "auto" }}
      >
        Add a new invoice
      </Button>
    </Section>
  );
});
