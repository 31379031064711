import { type FC, memo } from "react";

import styled from "@emotion/styled";
import { Checkbox, FormControlLabel } from "@mui/material";
import { palette } from "@relatable/ui/Palette";

import type { HandleCheckboxClick } from "./DataPicker";
import type { Section } from "./useDataPickerSections";

export const DataPickerSection: FC<{
  handleCheckboxClick: HandleCheckboxClick;
  sectionKey: string;
  sections: Section[];
  title: string;
}> = memo(({ title, sections, sectionKey, handleCheckboxClick }) => {
  return (
    <>
      <SubTitle>{title}</SubTitle>
      <SectionContainer>
        {sections.map(({ key: columnKey, label, rows }) => (
          <SSection key={columnKey}>
            <h4>{label}</h4>
            {rows.map(({ key: rowKey, ...r }) => (
              <StyledFormControlLabel
                key={rowKey}
                control={
                  <StyledCheckbox
                    color="default"
                    checked={r.checked}
                    onChange={handleCheckboxClick({
                      sectionKey,
                      columnKey,
                      rowKey
                    })}
                  />
                }
                label={r.uiLabel}
              />
            ))}
          </SSection>
        ))}
      </SectionContainer>
    </>
  );
});

const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 120px;
`;

const SSection = styled.div`
  margin-bottom: 20px;

  h4 {
    padding-left: 21px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    display: flex;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 1px;
    padding-right: 9px;
  }
`;

const SubTitle = styled.h4`
  font-size: 1.4rem;
  line-height: 110%;
  padding: 15px 0px;
  font-weight: 500;
  color: ${palette.gray[80]};
`;
