import type { FC } from "react";

import styled from "@emotion/styled";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { Route, Routes } from "react-router-dom";

import { AccountEditor } from "./AccountEditor";
import { AccountList } from "./AccountList";

const Root = styled.div`
  width: 100%;
`;

export const Accounts: FC = () => {
  useDocumentTitle("Accounts");

  return (
    <Root>
      <Routes>
        <Route path="/edit/:accountId" element={<AccountEditor />} />
        <Route path="/new" element={<AccountEditor />} />
        <Route path="/" element={<AccountList />} />
      </Routes>
    </Root>
  );
};
