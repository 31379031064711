import type { FC } from "react";

import styled from "@emotion/styled";
import { Route, Routes } from "react-router-dom";

import { ExpenseEditor } from "./ExpenseEditor";
import { ExpenseList } from "./ExpenseList";

const Root = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const Expenses: FC = () => {
  return (
    <Root>
      <Routes>
        <Route path="edit/:expenseId" element={<ExpenseEditor />} />
        <Route path="new" element={<ExpenseEditor />} />
        <Route path="" element={<ExpenseList />} />
      </Routes>
    </Root>
  );
};
