import { useRef, useState } from "react";

import styled from "@emotion/styled";
import { Autocomplete, Card as MaterialCard, Popover, TextField } from "@mui/material";
import { Button } from "@relatable/ui/Button";
import { useSnackbar } from "@relatable/ui/Snackbar";

import {
  CreatorsInListDocument,
  SearchListsDocument,
  useHypeauditorListsQuery,
  useImportHypeauditorListMutation
} from "../generated";

const Root = styled.div`
  display: inline-block;
  margin-left: 12px;
`;

const Card = styled(MaterialCard)`
  && {
    padding: 15px;
    display: flex;
    flex-direction: column;
    overflow: visible;
    min-width: 300px;
  }
`;

export const ImportHypeAuditorList: React.FC<{ internalListId: number }> = ({ internalListId }) => {
  const snackbar = useSnackbar();
  const [haListId, setHaListId] = useState<number | null>(null);
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const { data, loading: loadingQuery } = useHypeauditorListsQuery({ skip: !open });

  const [importList, { loading: loadingImport }] = useImportHypeauditorListMutation({
    onCompleted: () => snackbar.success("List was imported"),
    onError: error => snackbar.error(error.message),
    refetchQueries: [CreatorsInListDocument, SearchListsDocument]
  });

  const onImport = async () => {
    if (!haListId) return;
    await importList({
      variables: { hypeauditor_list_id: haListId, internal_list_id: internalListId }
    });
    setOpen(false);
    setHaListId(null);
  };

  if (!internalListId) return null;

  return (
    <Root ref={rootRef}>
      <Button
        icon={<img src="/images/hypeauditor.svg" alt="HypeAuditor" />}
        onClick={() => setOpen(true)}
      >
        HypeAuditor
      </Button>
      <Popover
        open={open}
        anchorEl={rootRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        PaperProps={{ style: { overflow: "visible" } }}
      >
        <Card>
          <Autocomplete
            disablePortal
            disabled={loadingQuery}
            loading={loadingQuery}
            size="small"
            options={
              data?.hypeauditorLists.map(list => ({
                id: list?.id,
                label: `${list?.name} (${list?.count})`
              })) ?? []
            }
            sx={{ width: "100%" }}
            style={{ marginBottom: 15 }}
            onChange={(e, value) => {
              setHaListId(value?.id as number | null);
            }}
            renderInput={params => <TextField {...params} label="Select HypeAuditor list" />}
          />
          <Button
            icon={<img src="/images/hypeauditor.svg" alt="HypeAuditor" />}
            disabled={!haListId || loadingQuery}
            isLoading={loadingImport}
            onClick={onImport}
          >
            Import HypeAuditor list
          </Button>
        </Card>
      </Popover>
    </Root>
  );
};
