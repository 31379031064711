import { useLocation } from "react-router-dom";

export const useSearchParams = <
  T = {
    [key: string]: string;
  }
>(): T => {
  const { search = "" } = useLocation();
  const searchParams = new URLSearchParams(search);
  let toReturn = {};

  searchParams.forEach((value, key) => {
    toReturn = { ...toReturn, [key]: decodeURIComponent(value) };
  });

  return toReturn as T;
};
