import type { Project_Attachments_Set_Input } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { Note } from "@relatable/ui/Note";
import { palette } from "@relatable/ui/Palette";
import { TextInput } from "@relatable/ui/TextInput";
import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { Add, Delete } from "@mui/icons-material";
import { Section, SubSection } from "../Section";
import {
  type ProjectDetailsQuery,
  useCreateProjectAttachmentMutation,
  useDeleteProjectAttachmentsMutation,
  useUpdateProjectAttachmentMutation
} from "../generated";
import { getChangedFields } from "../helpers";
import type { SectionRef } from "./types";

export const AttachmentSection = forwardRef<
  SectionRef,
  {
    project: ProjectDetailsQuery["projects"][number] | undefined;
    isSubmitting: boolean;
  }
>(({ project, isSubmitting }, ref) => {
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<
    Partial<ProjectDetailsQuery["projects"][number]["project_attachments"][number]>[]
  >([]);

  useEffect(() => {
    if (!project) return;
    setAttachments(project.project_attachments);
  }, [project]);

  const [createProjectAttachment, createProjectAttachmentParams] =
    useCreateProjectAttachmentMutation();
  const [deleteProjectAttachments, deleteProjectAttachmentsParams] =
    useDeleteProjectAttachmentsMutation();
  const [updateProjectAttachment, updateProjectAttachmentParams] =
    useUpdateProjectAttachmentMutation();

  const validate = () => {
    const errors: string[] = [];
    attachments.forEach((attachment, index) => {
      if (!attachment.name) errors.push(`Attachment no.${index} is missing name!`);
      if (!attachment.link || !attachment.link.startsWith("https://")) {
        errors.push(`Attachment no.${index} has invalid link URL!`);
      }
    });

    setFieldErrors(errors);
    return errors;
  };

  const getChangedAttachments = () => {
    const attachmentIdsToDelete: number[] = [];
    const attachmentsToUpdate: {
      set: Project_Attachments_Set_Input;
      attachmentId: number;
    }[] = [];
    if (!project || !project.id) {
      return { attachmentIdsToDelete, attachmentsToUpdate, attachmentsToCreate: [] };
    }

    project.project_attachments.forEach(async existing => {
      const attachment = attachments.find(i => i.id === existing.id);
      if (!attachment?.id) {
        attachmentIdsToDelete.push(existing.id);
        return;
      }
      const changedFields = getChangedFields({ data: attachment, initialData: existing });
      if (!Object.values(changedFields).length) return;
      attachmentsToUpdate.push({ attachmentId: attachment.id, set: changedFields });
    });

    return {
      attachmentIdsToDelete,
      attachmentsToUpdate,
      attachmentsToCreate: attachments.filter(a => !a.id)
    };
  };

  const { attachmentIdsToDelete, attachmentsToCreate, attachmentsToUpdate } =
    getChangedAttachments();

  const handleUpdate = async () => {
    if (validate().length) return;

    if (!project?.id) throw new Error("missing project id");

    await Promise.all([
      attachmentIdsToDelete.length &&
        deleteProjectAttachments({ variables: { ids: attachmentIdsToDelete } }),
      ...attachmentsToUpdate.map(({ attachmentId, set }) =>
        updateProjectAttachment({
          variables: {
            attachmentId,
            set
          }
        })
      ),
      attachmentsToCreate.length &&
        createProjectAttachment({
          variables: {
            objects: attachmentsToCreate.map(c => ({
              project_id: project.id,
              name: c.name,
              link: c.link
            }))
          }
        })
    ]);
  };

  const isLoading =
    !project ||
    isSubmitting ||
    createProjectAttachmentParams.loading ||
    deleteProjectAttachmentsParams.loading ||
    updateProjectAttachmentParams.loading;

  const isChanged = Boolean(
    project
      ? attachmentIdsToDelete.length || attachmentsToCreate.length || attachmentsToUpdate.length
      : false
  );

  useImperativeHandle(ref, () => ({
    submit: handleUpdate,
    validate
  }));

  return (
    <Section
      title="Project links"
      isChanged={isChanged}
      fieldErrors={fieldErrors}
      sidebar={
        <Note variant="info" label="Project links">
          Note: Links are visible at the cog dropdown at the top right of the campaign pages.
        </Note>
      }
      updatedAtNotImplemented
      updated_at={undefined}
      submitError={
        createProjectAttachmentParams.error ||
        deleteProjectAttachmentsParams.error ||
        updateProjectAttachmentParams.error
      }
    >
      {attachments.map((attachment, index) => {
        const handleChange = (partialData: Partial<(typeof attachments)[number]>) => {
          setAttachments(prev =>
            prev.map((i, _index) => (_index === index ? { ...i, ...partialData } : i))
          );
        };

        return (
          <Fragment key={attachment.id}>
            <SubSection style={{ gridColumn: "1/3" }}>
              <TextInput
                required
                label="Name"
                disabled={isLoading}
                value={attachment.name || ""}
                onChange={name => handleChange({ name })}
                style={{ flexGrow: 1 }}
              />

              <TextInput
                required
                label="Link URL"
                disabled={isLoading}
                value={attachment.link || ""}
                onChange={link => handleChange({ link: link.trim() })}
                style={{ flexGrow: 1 }}
              />

              <Delete
                style={{ color: palette.primary.red, cursor: "pointer" }}
                onClick={() => setAttachments(prev => prev.filter((_, _index) => index !== _index))}
              />
            </SubSection>
          </Fragment>
        );
      })}

      <Button
        icon={<Add />}
        disabled={isLoading}
        onClick={() => setAttachments(prev => [...prev, { name: "", link: "" }])}
        size="medium"
        style={{ margin: "auto" }}
      >
        Add a link
      </Button>
    </Section>
  );
});
