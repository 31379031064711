import type { FC } from "react";

import { Switch as MUISwitch, type SwitchProps } from "@mui/material";

import { TooltipWrapper } from "./TooltipWrapper";

export const Switch: FC<{
  value: boolean;
  onChange(v: boolean): void;
  tooltip?: string;
  color?: SwitchProps["color"];
}> = ({ onChange, value, tooltip, color = "success" }) => (
  <TooltipWrapper tooltipText={tooltip || ""}>
    <MUISwitch color={color} checked={value} onChange={() => onChange(!value)} />
  </TooltipWrapper>
);
