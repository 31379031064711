import type { FC } from "react";

import styled from "@emotion/styled";

import { useMeQuery } from "hooks/generated";

import { Message } from "./Message";

export const Messages: FC<{
  messages: { id: number; role: string; content: string }[];
  loading: boolean;
}> = ({ messages, loading }) => {
  const {
    data: { me } = {}
  } = useMeQuery();

  return (
    <Root>
      {messages.length ? (
        messages.map((message, i) => (
          <div className="item" key={i}>
            <Message
              username={me?.admin?.full_name ?? "Anonymous"}
              userRole={message.role}
              content={message.content}
              loading={messages.length - 1 === i && loading && message.role !== "user"}
            />
          </div>
        ))
      ) : (
        <div className="item">
          <Message
            username="Relatable AI"
            userRole="assistant"
            content="Type your question below."
          />
        </div>
      )}
    </Root>
  );
};

const Root = styled.div`
  padding: 10px 20px;
  background: white;

  > .item {
    background: white;

    &:nth-of-type(even) {
      background: #f5f5f5;
    }
  }
`;
