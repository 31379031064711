import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { type FC, useState } from "react";

import { AddCircle } from "@mui/icons-material";
import { AccountsTable } from "./AccountsTable";
import { useAccountsQuery } from "./generated";

export const AccountList: FC = () => {
  const [includeInactive, setIncludeInactive] = useState(false);
  const { data, loading } = useAccountsQuery({
    variables: { where: !includeInactive ? { is_active: { _eq: !includeInactive } } : {} }
  });

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <Checkbox label="Show inactive" checked={includeInactive} onChange={setIncludeInactive} />
        <Button href="/time-reporting/accounts/new">
          <AddCircle style={{ marginRight: 10 }} />
          Add new
        </Button>
      </div>

      <AccountsTable loading={loading} accounts={data?.accounts} />
    </>
  );
};
