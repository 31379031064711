import type { FC } from "react";

import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { getPlatformLabel } from "@relatable/gql";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";

import { Center } from "lib/styled";

import { PlatformIcon } from "@relatable/ui/icons/PlatformIcon";
import {
  type UserCampaignImagesQuery,
  type UserCampaignsQuery,
  type UserQuery,
  useUserCampaignImagesQuery
} from "../generated";

const Root = styled.div`
  padding: 10px;
  border: 1px solid ${palette.gray[10]};
  border-radius: 10px;

  > .image-list {
    overflow-x: scroll;
    overflow-y: hidden;

    > .wrapper {
      white-space: nowrap;

      > a {
        display: inline-block;
        overflow: hidden;
        border-radius: 5px;
        margin-right: 6px;
        height: 50px;

        > img,
        > video {
          height: 50px;
          width: 50px;
          object-fit: cover;
        }
      }
    }
  }

  > .text {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: ${palette.gray[60]};

    > .right {
      > a {
        color: ${palette.gray[60]};
        text-decoration: underline;
      }
    }
  }
`;

const mergeCampaignImages = (
  user: NonNullable<UserQuery["users_by_pk"]>,
  query: UserCampaignImagesQuery | undefined
) => {
  const images = [] as { link: string; imgSrc?: string; videoSrc?: string }[];
  if (!query) return images;

  query.ig_posts.forEach(post => {
    images.push({
      link: `https://www.instagram.com/p/${post.shortcode}`,
      ...(post.isVideo
        ? { videoSrc: post.internal_url || "" }
        : { imgSrc: post.internal_url || "" })
    });
  });

  query.ig_story_clips.forEach(post => {
    images.push({
      link: `https://www.instagram.com/${user.user_instagram?.username}`,
      ...(post.isVideo ? { videoSrc: post.url || "" } : { imgSrc: post.url || "" })
    });
  });

  query.yt_videos.forEach(post => {
    images.push({
      link: `https://www.youtube.com/watch?v=${post.video_id}`,
      imgSrc: post.thumbnail || ""
    });
  });

  query.tt_videos.forEach(post => {
    images.push({
      link: `https://www.tiktok.com/@${user.user_tiktok?.username}`,
      videoSrc: post.url || ""
    });
  });

  return images.filter(img => img.imgSrc || img.videoSrc);
};

export const CampaignImages: FC<{
  user: NonNullable<UserQuery["users_by_pk"]>;
  campaignUser: NonNullable<UserCampaignsQuery["campaign_user"][number]>;
}> = ({ user, campaignUser }) => {
  const { campaign } = campaignUser;

  const { data, loading } = useUserCampaignImagesQuery({
    variables: { userId: user.id, campaignId: campaignUser.campaign.id }
  });

  const campaignImages = mergeCampaignImages(user, data);

  if (loading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  if (campaignImages.length === 0) {
    return null;
  }

  return (
    <Root>
      <div className="image-list">
        <div className="wrapper">
          {campaignImages.map(image => (
            <Link key={image.imgSrc} to={image.link} target="_blank" rel="noreferrer">
              {image.imgSrc ? (
                <img src={image.imgSrc} loading="lazy" alt="Creator content" />
              ) : (
                <video src={image.videoSrc} preload="metadata" />
              )}
            </Link>
          ))}
        </div>
      </div>
      <div className="text">
        <span className="left">
          <PlatformIcon platform={campaign.platform} style={{ marginRight: 5 }} />
          Platform: <strong>{getPlatformLabel(campaign.platform)}</strong>
        </span>
        <span className="right">
          <Link to={`/campaign/${campaign.stub}`} target="_blank" rel="noreferrer">
            More details
          </Link>
        </span>
      </div>
    </Root>
  );
};
