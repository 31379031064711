import type { FC } from "react";

import { prettifyDate } from "@relatable/helpers";
import { getUserLabel } from "@relatable/helpers/user";
import { Table } from "@relatable/ui/Table";
import { Link, useParams } from "react-router-dom";

import { useEmailStatisticsQuery } from "./generated";

export const MessageStats: FC = () => {
  const { campaignStub, contentName } = useParams<{ campaignStub: string; contentName: string }>();

  const { data, loading } = useEmailStatisticsQuery({
    variables: { campaignStub: campaignStub ?? "", contentName: contentName ?? "" },
    skip: !campaignStub || !contentName
  });

  return (
    <div>
      <h2>Message stats</h2>
      <br />
      <Table
        tableId="campaign-message-stats"
        loading={loading}
        columns={[
          {
            field: "userLabel",
            headerName: "Creator",
            renderCell: ({ row, value }) => <Link to={`/user/${row.userId}`}>{value}</Link>
          },
          {
            field: "createdAt",
            headerName: "Email sent",
            renderCell: ({ value }) => prettifyDate(value, { full: true })
          },
          {
            field: "lastUpdated",
            headerName: "Stats last updated",
            renderCell: ({ value }) => prettifyDate(value, { full: true })
          },
          {
            field: "message",
            headerName: "Message"
          },
          {
            field: "status",
            headerName: "Status",
            renderCell: ({ value, row }) => {
              if (value === "sent") return "sent";
              return (
                <span style={{ color: "red" }}>
                  {value} - {row.rejectReason}
                </span>
              );
            }
          },
          {
            field: "opens",
            headerName: "Opens"
          },
          {
            field: "clicks",
            headerName: "Clicks"
          }
        ]}
        rows={
          data?.campaign_content[0].mandrills.map(m => ({
            key: m.id,
            userId: m.user?.id,
            userLabel: getUserLabel(m.user),
            createdAt: m?.created_at,
            lastUpdated: m?.last_check_ts,
            message: data.campaign_content[0].content?.name,
            status: m?.status,
            rejectReason: m?.reject_reason,
            opens: m?.opens ?? 0,
            clicks: m?.clicks ?? 0
          })) ?? []
        }
      />
    </div>
  );
};
