import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";

export const Section = styled.div`
  background: white;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  width: 100%;
`;

export const SectionSubtitle = styled.span`
  font-size: 12px;
  color: ${palette.gray[50]};
`;

export const SectionValue = styled.span`
  font-size: 20px;
  font-weight: bold;
`;
