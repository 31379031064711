import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HypeAuditorSnapchatReportQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
}>;


export type HypeAuditorSnapchatReportQuery = { __typename?: 'Query', hypeauditor: Array<{ __typename?: 'hypeauditor', id: number, data?: any | null, state?: Types.Hypeauditor_Report_State_Enum | null, not_available?: boolean | null, user: { __typename?: 'users', id: number, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string } | null } }> };


export const HypeAuditorSnapchatReportDocument = gql`
    query hypeAuditorSnapchatReport($userId: Int!) {
  hypeauditor(where: {users_id: {_eq: $userId}}) {
    id
    data
    state
    not_available
    user {
      id
      user_snapchat {
        id
        username
      }
    }
  }
}
    `;

/**
 * __useHypeAuditorSnapchatReportQuery__
 *
 * To run a query within a React component, call `useHypeAuditorSnapchatReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useHypeAuditorSnapchatReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHypeAuditorSnapchatReportQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useHypeAuditorSnapchatReportQuery(baseOptions: Apollo.QueryHookOptions<HypeAuditorSnapchatReportQuery, HypeAuditorSnapchatReportQueryVariables> & ({ variables: HypeAuditorSnapchatReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HypeAuditorSnapchatReportQuery, HypeAuditorSnapchatReportQueryVariables>(HypeAuditorSnapchatReportDocument, options);
      }
export function useHypeAuditorSnapchatReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HypeAuditorSnapchatReportQuery, HypeAuditorSnapchatReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HypeAuditorSnapchatReportQuery, HypeAuditorSnapchatReportQueryVariables>(HypeAuditorSnapchatReportDocument, options);
        }
export function useHypeAuditorSnapchatReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HypeAuditorSnapchatReportQuery, HypeAuditorSnapchatReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HypeAuditorSnapchatReportQuery, HypeAuditorSnapchatReportQueryVariables>(HypeAuditorSnapchatReportDocument, options);
        }
export type HypeAuditorSnapchatReportQueryHookResult = ReturnType<typeof useHypeAuditorSnapchatReportQuery>;
export type HypeAuditorSnapchatReportLazyQueryHookResult = ReturnType<typeof useHypeAuditorSnapchatReportLazyQuery>;
export type HypeAuditorSnapchatReportSuspenseQueryHookResult = ReturnType<typeof useHypeAuditorSnapchatReportSuspenseQuery>;
export type HypeAuditorSnapchatReportQueryResult = Apollo.QueryResult<HypeAuditorSnapchatReportQuery, HypeAuditorSnapchatReportQueryVariables>;