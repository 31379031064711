import { prettifyDate, prettifyNumber, prettifyPercentages } from "@relatable/helpers/format";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { Table } from "@relatable/ui/Table";
import { type FC, useState } from "react";
import { Link } from "react-router-dom";

import { UpdateStats } from "components/UpdateStats";
import { Popup } from "components/ui/Popup";
import { useLinksByKeyQuery } from "modules/links/generated";
import { extractRltToKey } from "modules/links/util";

import { Check, Close, Delete } from "@mui/icons-material";
import { PlatformIcon } from "@relatable/ui/icons/PlatformIcon";
import { NoteCell } from "./NoteCell";
import {
  CampaignReelsTableDocument,
  useCampaignReelsTableQuery,
  useDeleteCampaignReelMutation,
  useUpdateCampaignReelMutation,
  useUpdateCampaignReelStatsMutation
} from "./generated";

export const ReelsTable: FC<{ campaignStubs: string[] }> = ({ campaignStubs }) => {
  const snackbar = useSnackbar();
  const { loading, data } = useCampaignReelsTableQuery({ variables: { stubs: campaignStubs } });
  const [updateReel] = useUpdateCampaignReelMutation({
    awaitRefetchQueries: true,
    refetchQueries: [CampaignReelsTableDocument]
  });
  const [updateStats] = useUpdateCampaignReelStatsMutation({
    awaitRefetchQueries: true,
    refetchQueries: [CampaignReelsTableDocument],
    onError(error) {
      snackbar.error("Sth went wrong when updating a reel");
      snackbar.error(error.message);
      console.error(error);
    },
    onCompleted() {
      snackbar.success("Reel successfully updated");
    }
  });

  const rltToKeys = (data?.campaign_reels ?? [])
    .map(p => extractRltToKey(p.track_url))
    .filter(Boolean) as string[];

  const { data: linkData, loading: linksLoading } = useLinksByKeyQuery({
    variables: { keys: rltToKeys },
    skip: !rltToKeys.length
  });

  return (
    <Table
      tableId="campaign-reels"
      sortable
      canSelectColumns
      canExportCSV
      stickyColumn
      noDataText="No Reels detected"
      loading={loading || linksLoading}
      rows={
        data?.campaign_reels.map(reel => {
          const followers = reel.user?.user_instagram?.followed_by;
          const totalInteractions =
            reel.total_interactions || (reel.comments || 0) + (reel.likes || 0) + (reel.saved || 0);

          const postRltLink = linkData?.link_clicks.find(
            lc => lc.key === extractRltToKey(reel.track_url)
          );
          const clicks = (reel.bitlyClicks || 0) + (postRltLink?.clicks || 0);
          return {
            key: reel.id,
            id: reel.id,
            approved: reel.approved,
            username: reel.user?.user_instagram?.username,
            campaignTitle: reel.campaign?.title,
            campaignStub: reel.campaign?.stub,
            instagramLink: `https://www.instagram.com/p/${reel.shortcode}`,
            isBusinessTokenValid: Boolean(
              reel.user?.user_instagram?.access_token_facebook?.token_is_valid
            ),
            notes: reel.notes,
            lock_statistics_at: reel.lock_statistics_at,
            followers,
            lockedStatsAt: prettifyDate(reel.lock_statistics_at) || "-",
            actualReach: reel.actual_reach,
            impressions: reel.impressions_legacy,
            likes: reel.likes,
            comments: reel.comments,
            shares: reel.shares,
            saved: reel.saved,
            clicks,
            shortcode: reel.shortcode,
            clips_replays_count: reel.clips_replays_count,
            plays: reel.plays,
            all_plays_count: reel.all_plays_count,
            avg_watch_time: reel.avg_watch_time,
            video_view_total_time: reel.video_view_total_time,
            total_interactions: reel.total_interactions,
            frequency: (reel.impressions_legacy || 0) / (reel.actual_reach || 0),
            audienceReach: prettifyPercentages({
              of: reel.actual_reach,
              total: followers
            }),
            reachEngagement: prettifyPercentages({ total: followers, of: totalInteractions }),
            actualReachEngagement: prettifyPercentages({
              total: reel.actual_reach,
              of: totalInteractions
            }),
            avgEngagement: prettifyPercentages({
              of: reel.user?.hypeauditor?.avg_engagement,
              total: 1
            })
          };
        }) || []
      }
      columns={[
        {
          field: "username",
          headerName: "User",
          renderCell: ({ value, row }) => (
            <Link
              target="_blank"
              rel="noreferrer"
              to={`https://www.instagram.com/${value}`}
              style={{ whiteSpace: "nowrap" }}
            >
              {row.approved ? (
                <Check style={{ marginRight: 10, color: palette.secondary.green }} />
              ) : (
                <Close style={{ marginRight: 10, color: palette.secondary.red }} />
              )}
              {(data?.campaigns.length || 0) > 1 ? `${row.campaignTitle} - ` : ""}
              {value}
            </Link>
          )
        },
        {
          field: "instagramLink",
          headerName: "Reel",
          minWidth: 150,
          renderCell: ({ value, row }) => (
            <>
              <Link
                style={{ display: "inline-block", verticalAlign: "middle", marginRight: 5 }}
                target="_blank"
                rel="noreferrer"
                to={value}
              >
                <PlatformIcon platform={"instagram"} color={palette.social.instagram} />
              </Link>
              <Link to={`/campaign/${row.campaignStub}/content/reel/${row.key}`}>
                {value.split("https://www.instagram.com/p/")[1]}
              </Link>
            </>
          )
        },
        {
          headerName: "Notes",
          field: "notes",
          renderCell: ({ value, row }) => <NoteCell note={value || ""} reelId={row.key} />
        },
        {
          field: "lock_statistics_at",
          headerName: "Statistics",
          minWidth: 75,
          renderCell: ({ value, row }) => (
            <UpdateStats
              lockStats={{
                lockedAt: value,
                isTokenValid: row.isBusinessTokenValid,
                onToggle: lock_statistics =>
                  updateReel({
                    variables: {
                      reelId: row.id,
                      set: { lock_statistics_at: lock_statistics ? new Date().toISOString() : null }
                    }
                  })
              }}
              onUpdate={() => updateStats({ variables: { reelId: row.id } })}
            />
          )
        },
        {
          field: "lockedStatsAt",
          headerName: "Locked",
          minWidth: 130
        },
        {
          field: "followers",
          headerName: "Followers",
          renderCell: ({ value }) => prettifyNumber(value)
        },
        {
          field: "actualReach",
          headerName: "Actual reach",
          renderCell: ({ value }) => prettifyNumber(value)
        },
        {
          field: "all_plays_count",
          headerName: "All views",
          renderCell: ({ value }) => prettifyNumber(value)
        },
        {
          field: "audienceReach",
          headerName: "Audience reach"
        },
        {
          field: "avg_watch_time",
          headerName: "Avg. watch time",
          renderCell: ({ value }) => prettifyNumber(value)
        },
        {
          field: "video_view_total_time",
          headerName: "Total time (minutes)",
          renderCell: ({ value }) => prettifyNumber(value ? value / 60 : value)
        },
        { field: "likes", headerName: "Likes", renderCell: ({ value }) => prettifyNumber(value) },
        {
          field: "comments",
          headerName: "Comments",
          renderCell: ({ value }) => prettifyNumber(value)
        },
        { field: "saved", headerName: "Saves", renderCell: ({ value }) => prettifyNumber(value) },
        {
          field: "clicks",
          headerName: "Clicks",
          renderCell: ({ value }) => prettifyNumber(value)
        },
        {
          field: "total_interactions",
          headerName: "Total interactions",
          renderCell: ({ value }) => prettifyNumber(value)
        },
        { field: "reachEngagement", headerName: "Follower eng." },
        { field: "actualReachEngagement", headerName: "Actual reach eng." },
        { field: "avgEngagement", headerName: "Organic eng." },
        {
          field: "impressions",
          headerName: "Impressions [legacy]",
          renderCell: ({ value }) => prettifyNumber(value)
        },
        {
          field: "frequency",
          headerName: "Frequency [legacy]",
          renderCell: ({ value }) => prettifyNumber(value)
        },
        {
          field: "key",
          headerName: "Delete",
          renderCell: ({ value, row }) => <DeleteReelButton title={row.shortcode} reelId={value} />
        }
      ]}
    />
  );
};

const DeleteReelButton: FC<{ reelId: number; title: string }> = ({ reelId, title }) => {
  const [isConfirmPopup, setIsConfirmPopup] = useState(false);
  const [deletePost] = useDeleteCampaignReelMutation({
    variables: { reelId },
    onCompleted: () => setIsConfirmPopup(false),
    refetchQueries: [CampaignReelsTableDocument],
    awaitRefetchQueries: true
  });
  return (
    <>
      <Button
        variant="text"
        onClick={() => setIsConfirmPopup(true)}
        icon={<Delete style={{ color: palette.primary.red }} />}
      >
        Delete
      </Button>
      {isConfirmPopup && (
        <Popup
          actionLabel="Delete"
          onAction={() => deletePost()}
          title={`Delete ${title} post`}
          onClose={() => setIsConfirmPopup(false)}
        />
      )}
    </>
  );
};
