import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApproveStoryMutationVariables = Types.Exact<{
  input: Types.Campaign_Story_Insert_Input;
}>;


export type ApproveStoryMutation = { __typename?: 'Mutation', approveStory?: void | null };

export type UpdateStoryClipMutationVariables = Types.Exact<{
  _set?: Types.InputMaybe<Types.Campaign_Story_Clip_Set_Input>;
  clipId: Types.Scalars['Int']['input'];
}>;


export type UpdateStoryClipMutation = { __typename?: 'Mutation', update_campaign_story_clip?: { __typename?: 'campaign_story_clip_mutation_response', returning: Array<{ __typename?: 'campaign_story_clip', id: number, actual_reach?: number | null, impressions?: number | null, track_url?: string | null, content_setting_id?: number | null }> } | null };

export type StoryQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int']['input'];
  contentSettingId: Types.Scalars['Int']['input'];
  campaignStub: Types.Scalars['String']['input'];
}>;


export type StoryQuery = { __typename?: 'Query', users: Array<{ __typename?: 'users', id: number, campaignUser: Array<{ __typename?: 'campaign_user', id: number, tracking?: any | null }>, instagram?: { __typename?: 'users_instagram', id: number, username: string } | null }>, campaignStories: Array<{ __typename?: 'campaign_story', id: number, approved?: boolean | null, approved_at?: string | null, user_id?: number | null, content_setting_id?: number | null }>, contentSettings: Array<{ __typename?: 'campaign_content_settings', id: number, publish_date_max: string, publish_date_min: string, number_of_posts: number }>, campaigns: Array<{ __typename?: 'campaign', id: number }>, clips: Array<{ __typename?: 'campaign_story_clip', id: number, actual_reach?: number | null, campaign_story_id?: number | null, content_setting_id?: number | null, created_at?: string | null, filename?: string | null, impressions?: number | null, isVideo?: boolean | null, thumbnail?: string | null, track_url?: string | null, url?: string | null }> };


export const ApproveStoryDocument = gql`
    mutation approveStory($input: campaign_story_insert_input!) {
  approveStory(input: $input)
}
    `;
export type ApproveStoryMutationFn = Apollo.MutationFunction<ApproveStoryMutation, ApproveStoryMutationVariables>;

/**
 * __useApproveStoryMutation__
 *
 * To run a mutation, you first call `useApproveStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveStoryMutation, { data, loading, error }] = useApproveStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveStoryMutation(baseOptions?: Apollo.MutationHookOptions<ApproveStoryMutation, ApproveStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveStoryMutation, ApproveStoryMutationVariables>(ApproveStoryDocument, options);
      }
export type ApproveStoryMutationHookResult = ReturnType<typeof useApproveStoryMutation>;
export type ApproveStoryMutationResult = Apollo.MutationResult<ApproveStoryMutation>;
export type ApproveStoryMutationOptions = Apollo.BaseMutationOptions<ApproveStoryMutation, ApproveStoryMutationVariables>;
export const UpdateStoryClipDocument = gql`
    mutation updateStoryClip($_set: campaign_story_clip_set_input, $clipId: Int!) {
  update_campaign_story_clip(_set: $_set, where: {id: {_eq: $clipId}}) {
    returning {
      id
      actual_reach
      impressions
      track_url
      content_setting_id
    }
  }
}
    `;
export type UpdateStoryClipMutationFn = Apollo.MutationFunction<UpdateStoryClipMutation, UpdateStoryClipMutationVariables>;

/**
 * __useUpdateStoryClipMutation__
 *
 * To run a mutation, you first call `useUpdateStoryClipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryClipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryClipMutation, { data, loading, error }] = useUpdateStoryClipMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      clipId: // value for 'clipId'
 *   },
 * });
 */
export function useUpdateStoryClipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryClipMutation, UpdateStoryClipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoryClipMutation, UpdateStoryClipMutationVariables>(UpdateStoryClipDocument, options);
      }
export type UpdateStoryClipMutationHookResult = ReturnType<typeof useUpdateStoryClipMutation>;
export type UpdateStoryClipMutationResult = Apollo.MutationResult<UpdateStoryClipMutation>;
export type UpdateStoryClipMutationOptions = Apollo.BaseMutationOptions<UpdateStoryClipMutation, UpdateStoryClipMutationVariables>;
export const StoryDocument = gql`
    query story($userId: Int!, $contentSettingId: Int!, $campaignStub: String!) {
  users(where: {id: {_eq: $userId}}) {
    id
    campaignUser: campaign_users(where: {campaign: {stub: {_eq: $campaignStub}}}) {
      id
      tracking
    }
    instagram: user_instagram {
      id
      username
    }
  }
  campaignStories: campaign_story(
    where: {user_id: {_eq: $userId}, content_setting_id: {_eq: $contentSettingId}}
    limit: 1
  ) {
    id
    approved
    approved_at
    user_id
    content_setting_id
  }
  contentSettings: campaign_content_settings(
    where: {id: {_eq: $contentSettingId}}
    limit: 1
  ) {
    id
    publish_date_max
    publish_date_min
    number_of_posts
  }
  campaigns: campaign(where: {stub: {_eq: $campaignStub}}, limit: 1) {
    id
  }
  clips: campaign_story_clip(
    where: {_and: [{user_id: {_eq: $userId}}, {_or: [{content_setting_id: {_eq: $contentSettingId}}, {content_setting_id: {_is_null: true}}]}, {should_delete: {_eq: false}}]}
    order_by: {content_setting_id: desc_nulls_last, created_at: desc, id: desc}
  ) {
    id
    actual_reach
    campaign_story_id
    content_setting_id
    created_at
    filename
    impressions
    isVideo
    thumbnail
    track_url
    url
  }
}
    `;

/**
 * __useStoryQuery__
 *
 * To run a query within a React component, call `useStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      contentSettingId: // value for 'contentSettingId'
 *      campaignStub: // value for 'campaignStub'
 *   },
 * });
 */
export function useStoryQuery(baseOptions: Apollo.QueryHookOptions<StoryQuery, StoryQueryVariables> & ({ variables: StoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryQuery, StoryQueryVariables>(StoryDocument, options);
      }
export function useStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryQuery, StoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryQuery, StoryQueryVariables>(StoryDocument, options);
        }
export function useStorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StoryQuery, StoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StoryQuery, StoryQueryVariables>(StoryDocument, options);
        }
export type StoryQueryHookResult = ReturnType<typeof useStoryQuery>;
export type StoryLazyQueryHookResult = ReturnType<typeof useStoryLazyQuery>;
export type StorySuspenseQueryHookResult = ReturnType<typeof useStorySuspenseQuery>;
export type StoryQueryResult = Apollo.QueryResult<StoryQuery, StoryQueryVariables>;