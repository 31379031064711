import type { Platform_Enum } from "@relatable/gql/generated-base";
import { useLocation } from "react-router-dom";

import { PLATFORMS } from "lib/constants";

export const useAggregatedListPlatform = (): Platform_Enum => {
  const { pathname } = useLocation();
  const platform = PLATFORMS.find(p => pathname.includes(`/${p}/`));
  if (!platform) throw new Error("Cannot determine platform");
  return platform;
};
