import type { CSSProperties, FC } from "react";

import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useNavigate } from "react-router-dom";

export const NavigationButton: FC<{
  className?: string;
  direction?: "back" | "forward";
  nextStateIsNotReact?: boolean;
  pathname?: string;
  style?: CSSProperties;
}> = ({ className, direction = "back", nextStateIsNotReact = false, pathname, style }) => {
  const navigate = useNavigate();

  let action: () => void;

  const isForwardDirection = direction === "forward";

  if (pathname) {
    action = nextStateIsNotReact
      ? // biome-ignore lint/suspicious/noAssignInExpressions:
        () => (window.location.pathname = pathname)
      : () => navigate(pathname);
  } else {
    action = isForwardDirection ? () => navigate(1) : () => navigate(-1);
  }

  return (
    <Button
      style={{ marginBottom: 10, ...style }}
      onClick={action}
      className={className}
      variant="text"
    >
      {isForwardDirection ? (
        <>
          <NavigateNext style={{ marginLeft: 10, color: palette.gray.black }} />
          Forward
        </>
      ) : (
        <>
          <NavigateBefore style={{ marginRight: 10, color: palette.gray.black }} />
          Back
        </>
      )}
    </Button>
  );
};
