import styled from "@emotion/styled";
import { isInternalLink } from "@relatable/helpers";
import { Link } from "react-router-dom";

interface CustomLinkProps {
  className?: string;
  url?: string;
  show?: boolean;
  children: React.ReactNode;
}

export const CustomLink: React.FC<CustomLinkProps> = ({
  className = "",
  url,
  show = true,
  children
}) => {
  const customLinkProps = { $show: show, className };

  const stopPropagation = event => event.stopPropagation();

  if (!url) {
    return children as React.ReactElement;
  }

  return isInternalLink(url) ? (
    <StyledLink to={url} {...customLinkProps}>
      {children}
    </StyledLink>
  ) : (
    <StyledAnchorTag to={url} target="_blank" {...customLinkProps} onClick={stopPropagation}>
      {children}
    </StyledAnchorTag>
  );
};

const StyledAnchorTag = styled(Link)<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? "block" : "none")};
  position: relative;
  color: inherit;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }
`;

const StyledLink = styled(Link)<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? "block" : "none")};
  height: 100%;
`;
