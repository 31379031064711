import type { CSSProperties, FC, ReactNode } from "react";

import { Alert, type AlertColor, AlertTitle } from "@mui/material";

export const Note: FC<{
  label?: string;
  children: ReactNode;
  variant?: AlertColor;
  style?: CSSProperties;
}> = ({ label = "", children, variant = "warning", style }) => (
  <Alert style={{ width: "100%", borderRadius: 10, ...style }} severity={variant}>
    <AlertTitle>{label}</AlertTitle>
    {children}
  </Alert>
);
