import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useParams } from "react-router-dom";

import { Upload } from "@mui/icons-material";
import { UploadTikTokPopup } from "./UploadTikTokPopup";

export const ActionButtons: FC = () => {
  const [isUploadPopupOpen, setUploadPopupOpen] = useState(false);

  const snackbar = useSnackbar();

  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  if (campaignStubs.length !== 1) return null;

  return (
    <SActionButtons>
      <Button
        icon={<Upload />}
        onClick={() => setUploadPopupOpen(true)}
        style={{ marginBottom: 16 }}
      >
        Upload TikToks
      </Button>

      {isUploadPopupOpen && (
        <UploadTikTokPopup
          onUpload={() => snackbar.success("Clips have been successfully uploaded")}
          onClose={() => setUploadPopupOpen(false)}
        />
      )}
    </SActionButtons>
  );
};

const SActionButtons = styled.div`
  display: flex;
  gap: 16px;
`;
