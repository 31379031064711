import { type AddFields, Table } from "@relatable/ui/Table";
import type { FC } from "react";
import { Link } from "react-router-dom";

import { Check, Close, Edit } from "@mui/icons-material";
import type { AccountsQuery } from "./generated";

export const AccountsTable: FC<{
  loading: boolean;
  accounts?: AccountsQuery["accounts"];
}> = ({ loading, accounts }) => {
  return (
    <Table
      tableId="timereporting-accounts"
      sortable
      canSelectColumns
      loading={loading}
      rows={
        accounts?.map(e => {
          const r = {
            key: e.id,
            email: e.email,
            intercomEmail: e.intercom_email,
            firstName: e.first_name,
            lastName: e.last_name,
            isActive: e.is_active
          };
          return r as AddFields<typeof r, "actions">;
        }) ?? []
      }
      columns={[
        {
          field: "isActive",
          headerName: "Active",
          renderCell: ({ value }) => <span>{value ? <Check /> : <Close />}</span>
        },
        {
          field: "firstName",
          headerName: "First name"
        },
        {
          field: "lastName",
          headerName: "Last name"
        },
        {
          field: "email",
          headerName: "Email"
        },
        {
          field: "intercomEmail",
          headerName: "Intercom email"
        },
        {
          field: "actions",
          headerName: "",
          renderCell: ({ row }) => (
            <span>
              <Link to={`/time-reporting/accounts/edit/${row.key}`}>
                <Edit />
              </Link>
            </span>
          )
        }
      ]}
    />
  );
};
