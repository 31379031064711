import { useRef, useState } from "react";

import styled from "@emotion/styled";
import { Card as MaterialCard, Popover, TextField } from "@mui/material";
import type { AddUserToListInput, Platform_Enum } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { AddCircle } from "@mui/icons-material";
import {
  CreatorsInListDocument,
  SearchListsDocument,
  useAddUserToListMutation
} from "../generated";

const Root = styled.div`
  display: inline-block;

  > button {
    border: 0;
    background: none;
    color: ${palette.gray[60]};
    cursor: pointer;
  }
`;

const Card = styled(MaterialCard)`
  padding: 15px;
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

export const ManualAdd: React.FC<{ listId: number; platform: Platform_Enum }> = ({
  listId,
  platform
}) => {
  const snackbar = useSnackbar();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState<string>("");

  const [addUserToList, { loading: loadingAdd }] = useAddUserToListMutation({
    onCompleted: () => snackbar.success("User was added to the list"),
    onError: error => snackbar.error(error.message),
    refetchQueries: [CreatorsInListDocument, SearchListsDocument]
  });

  const onAdd = async () => {
    const trimmedUsername = username.trim();
    if (!trimmedUsername) return;
    if (trimmedUsername.startsWith("@")) {
      snackbar.error("@ character shouldn't be included here");
      return;
    }

    const selector: Partial<AddUserToListInput> = (() => {
      if (platform === "instagram") {
        return { ig_username: trimmedUsername };
      }
      if (platform === "tiktok") {
        return { tt_username: trimmedUsername };
      }
      if (platform === "snapchat") {
        return { sc_username: trimmedUsername };
      }
      if (platform === "youtube") {
        if (trimmedUsername.startsWith("UC")) {
          return { yt_channel_id: trimmedUsername };
        }
        return { yt_username: trimmedUsername };
      }
      throw new Error("Unsupported platform");
    })();

    await addUserToList({
      variables: {
        list_id: listId,
        ...selector
      }
    });
    setOpen(false);
    setUsername("");
  };

  return (
    <Root ref={rootRef}>
      <button
        style={{ display: "flex", alignItems: "center", gap: 5 }}
        type="button"
        onClick={() => setOpen(true)}
      >
        <AddCircle /> Add manually
      </button>

      <Popover
        open={open && Boolean(listId)}
        anchorEl={rootRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Card>
          <TextField
            size="small"
            label={
              platform === "youtube" ? "Profile's Channel ID or @ handle" : "Profile's username"
            }
            placeholder={platform === "youtube" ? "Channel ID or @ handle" : "username"}
            value={username}
            onChange={e => setUsername(e.target.value)}
            inputProps={{ style: { height: 40 } }}
          />
          <Button
            icon={<AddCircle />}
            isLoading={loadingAdd}
            onClick={onAdd}
            style={{ marginTop: 15 }}
          >
            Add
          </Button>
        </Card>
      </Popover>
    </Root>
  );
};
