import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";

export const AQS = ({ score, name, description }) => {
  const descriptionSplitted = description.split("; ");

  return (
    <Root>
      <Summary>
        <Score score={score}>
          <h1>{score}</h1>
          <p>of 100</p>
        </Score>
        <Description>{name}</Description>
      </Summary>
      <AudienceQuality>
        {descriptionSplitted.map((d, index) => (
          <li key={index}>{d}</li>
        ))}
      </AudienceQuality>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  margin-bottom: 40px;
  width: 100%;
`;

const Summary = styled.div`
  width: 140px;
  height: 140px;
  box-shadow: 0px 5px 25px ${palette.gray[10]};
  border-radius: 6px;
  margin-right: 50px;
`;

const Score = styled.div<{ score: number }>`
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ score }) => {
    if (score <= 25) {
      return palette.primary.red;
    }
    if (score <= 40) {
      return palette.primary.gold;
    }
    if (score <= 60) {
      return palette.secondary.gold;
    }
    if (score <= 80) {
      return palette.secondary.green;
    }
    return palette.primary.green;
  }};
  color: ${palette.gray.white};

  > * {
    margin: 0px;
  }
`;

const Description = styled.span`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
`;

const AudienceQuality = styled.ul`
  margin-right: auto;

  padding: 0px;

  li {
    margin: 15px;
  }
`;
