import type { FC } from "react";

import styled from "@emotion/styled";
import { useParams } from "react-router-dom";

import { LinearProgress } from "components/ui/LinearProgress";

import { useCampaignTiktokStatsQuery } from "./generated";

export const StatsHead: FC = () => {
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  const { data } = useCampaignTiktokStatsQuery({ variables: { stubs: campaignStubs } });
  const campaigns = data?.campaigns || [];

  const approvedTiktoksCount = campaigns.reduce(
    (prev, curr) => prev + (curr?.campaign_tiktoks.filter(t => t.approved).length || 0),
    0
  );
  const totalTikToksCount = campaigns.reduce(
    (prev, curr) => prev + (curr?.campaign_tiktoks.length || 0),
    0
  );

  const targetReach = campaigns.reduce((prev, curr) => prev + (curr?.target_reach || 0), 0);
  const audienceReach = campaigns
    .map(
      campaign =>
        campaign?.campaign_tiktoks.reduce((acc, i) => acc + (i.user_tiktok?.followed_by || 0), 0) ||
        0
    )
    .reduce((prev, curr) => prev + curr, 0);

  return (
    <StatsContainer>
      <LinearProgress
        label="TikTok reach - Audience size"
        value={audienceReach}
        max={targetReach}
      />

      <LinearProgress
        label="Approved TikToks"
        value={approvedTiktoksCount}
        max={totalTikToksCount}
      />
    </StatsContainer>
  );
};

const StatsContainer = styled.div`
  display: grid;
  gap: 2%;
  grid-template-columns: 49% 49%;
`;
