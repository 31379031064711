import type { Project_Client_Contact_Set_Input } from "@relatable/gql/generated-base";
import { CLIENT_REVIEW_FIELDS } from "@relatable/helpers/constants";
import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { Note } from "@relatable/ui/Note";
import { palette } from "@relatable/ui/Palette";
import { MultipleSelect } from "@relatable/ui/Select";
import { TextInput } from "@relatable/ui/TextInput";
import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { Autocomplete } from "components/ui/Autocomplete";
import { useEnableClientReviewAndSendEmailsMutation } from "modules/project/ProjectUpdate/generated";

import { Add, Delete, Search } from "@mui/icons-material";
import { Section, SectionTitle, SubSection } from "../Section";
import {
  ProjectDetailsDocument,
  type ProjectDetailsQuery,
  useCreateProjectClientContactMutation,
  useCreateProjectTeamMemberMutation,
  useDeleteProjectClientContactsMutation,
  useDeleteProjectTeamMembersMutation,
  useUpdateProjectChannelIdMutation,
  useUpdateProjectClientContactMutation
} from "../generated";
import { getChangedFields } from "../helpers";
import type { SectionRef } from "./types";

export const TeamSection = forwardRef<
  SectionRef,
  {
    project: ProjectDetailsQuery["projects"][number] | undefined;
    accounts: ProjectDetailsQuery["accounts"] | undefined;
    slack: ProjectDetailsQuery["slack"] | undefined;
    isSubmitting: boolean;
  }
>(({ project, accounts, slack, isSubmitting }, ref) => {
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const [teamMembers, setTeamMembers] = useState<{ fullName: string; accountId: number }[]>([]);
  const [slackChannelId, setSlackChannelId] = useState("");
  const [clients, setClients] = useState<
    Partial<ProjectDetailsQuery["projects"][number]["project_client_contacts"][number]>[]
  >([]);

  const getInitialTeamMembers = (
    team_members: ProjectDetailsQuery["projects"][number]["project_team_members"]
  ) =>
    team_members.map(member => ({
      fullName: member.account.full_name || "",
      accountId: member.account.id
    }));

  useEffect(() => {
    if (!project) return;
    setSlackChannelId(project.slack_channel_id || "");
    setTeamMembers(getInitialTeamMembers(project.project_team_members));
    setClients(project.project_client_contacts);
  }, [project]);

  const [updateProjectChannelId, updateProjectChannelIdConfig] =
    useUpdateProjectChannelIdMutation();
  const [createProjectTeamMember, createProjectTeamMemberConfig] =
    useCreateProjectTeamMemberMutation();
  const [deleteProjectTeamMembers, deleteProjectTeamMembersConfig] =
    useDeleteProjectTeamMembersMutation();
  const [createProjectClientContact, createProjectClientContactConfig] =
    useCreateProjectClientContactMutation();
  const [deleteProjectClientContacts, deleteProjectClientContactsConfig] =
    useDeleteProjectClientContactsMutation();
  const [updateProjectClientContact, updateProjectClientContactConfig] =
    useUpdateProjectClientContactMutation();
  const [enableClientReviewAndSendEmails, enableClientReviewAndSendEmailsOptions] =
    useEnableClientReviewAndSendEmailsMutation({
      awaitRefetchQueries: true,
      refetchQueries: [ProjectDetailsDocument]
    });

  const validate = () => {
    const errors: string[] = [];
    clients.forEach((client, index) => {
      if (!client.email) errors.push(`Client contact no.${index} is missing email!`);
      if (!client.first_name) errors.push(`Client contact no.${index} is missing first name!`);
      if (!client.last_name) errors.push(`Client contact no.${index} is missing last name!`);
    });

    setFieldErrors(errors);
    return errors;
  };

  const existingMemberIds = project?.project_team_members.map(c => c.account.id) || [];
  const newMemberIds = teamMembers.map(c => c.accountId);
  const memberIdsToDelete = existingMemberIds.filter(id => !newMemberIds.includes(id));
  const membersToCreate = teamMembers.filter(m => !existingMemberIds.includes(m.accountId));

  const getChangedClients = () => {
    const clientIdsToDelete: number[] = [];
    const clientsToUpdate: { set: Project_Client_Contact_Set_Input; clientContactId: number }[] =
      [];
    if (!project || !project.id) return { clientIdsToDelete, clientsToUpdate, clientsToCreate: [] };

    project.project_client_contacts.forEach(async existing => {
      const client = clients.find(i => i.id === existing.id);
      if (!client?.id) {
        clientIdsToDelete.push(existing.id);
        return;
      }
      const changedFields = getChangedFields({ data: client, initialData: existing });
      if (!Object.values(changedFields).length) return;
      clientsToUpdate.push({ clientContactId: client.id, set: changedFields });
    });

    return { clientIdsToDelete, clientsToUpdate, clientsToCreate: clients.filter(c => !c.id) };
  };

  const { clientIdsToDelete, clientsToCreate, clientsToUpdate } = getChangedClients();

  const handleUpdate = async () => {
    if (validate().length) return;

    if (!project?.id) throw new Error("missing project id");

    await Promise.all([
      slackChannelId !== project.slack_channel_id &&
        updateProjectChannelId({ variables: { projectId: project.id, slackChannelId } }),
      ...membersToCreate.map(m =>
        createProjectTeamMember({
          variables: { object: { project_id: project.id, accounts_id: m.accountId } }
        })
      ),
      memberIdsToDelete.length &&
        deleteProjectTeamMembers({
          variables: { accountIds: memberIdsToDelete, projectId: project.id }
        }),
      clientIdsToDelete.length &&
        deleteProjectClientContacts({ variables: { ids: clientIdsToDelete } }),
      ...clientsToUpdate.map(({ clientContactId, set }) =>
        updateProjectClientContact({
          variables: {
            clientContactId,
            set
          }
        })
      ),
      clientsToCreate.length &&
        createProjectClientContact({
          variables: {
            objects: clientsToCreate.map(c => ({
              project_id: project.id,
              clients_hubspot_company_id: project.clients_hubspot_company_id,
              ...c
            }))
          }
        })
    ]);
  };

  const isLoading =
    !project ||
    isSubmitting ||
    updateProjectChannelIdConfig.loading ||
    createProjectTeamMemberConfig.loading ||
    deleteProjectTeamMembersConfig.loading ||
    createProjectClientContactConfig.loading ||
    deleteProjectClientContactsConfig.loading ||
    updateProjectClientContactConfig.loading;

  const isChanged = Boolean(
    project
      ? clientIdsToDelete.length ||
          clientsToCreate.length ||
          clientsToUpdate.length ||
          memberIdsToDelete.length ||
          membersToCreate.length ||
          slackChannelId !== (project.slack_channel_id || "")
      : false
  );

  useImperativeHandle(ref, () => ({
    submit: handleUpdate,
    validate
  }));

  return (
    <Section
      title="Team"
      isChanged={isChanged}
      fieldErrors={fieldErrors}
      sidebar={
        <Note variant="info" label="Contacts">
          Note: Adding a contact will automatically send an invitation for them to join Workspace.
          Only add contacts when you are ready for them to review the Creator list.
        </Note>
      }
      updatedAtNotImplemented
      updated_at={undefined}
      submitError={
        updateProjectChannelIdConfig.error ||
        createProjectTeamMemberConfig.error ||
        deleteProjectTeamMembersConfig.error ||
        createProjectClientContactConfig.error ||
        deleteProjectClientContactsConfig.error ||
        updateProjectClientContactConfig.error
      }
    >
      <MultipleSelect
        label="Relatable team members"
        style={{ flexGrow: 1 }}
        disabled={isLoading}
        value={teamMembers.map(member => member.accountId)}
        onChange={accountIds => {
          setTeamMembers(
            accountIds?.map(accountId => ({
              accountId,
              fullName: accounts?.find(account => account.id === accountId)?.full_name || ""
            })) || []
          );
        }}
        options={
          accounts
            ?.filter(i => i.is_active)
            ?.map(account => ({
              label: account.full_name || "",
              value: account.id
            }))
            .sort((a, b) => a.label.localeCompare(b.label)) || []
        }
      />
      <Autocomplete
        label="Slack channel for updates"
        isDisabled={isLoading}
        onChange={id => setSlackChannelId(id || "")}
        value={slackChannelId}
        options={
          slack?.channels
            .map(channel => ({ label: channel.name, id: channel.id }))
            .sort((a, b) => a.label.localeCompare(b.label)) || []
        }
      />

      <SectionTitle>Client contacts:</SectionTitle>
      <div />
      {clients.map((client, index) => {
        const handleChange = (partialData: Partial<(typeof clients)[number]>) => {
          setClients(prev =>
            prev.map((i, _index) => (_index === index ? { ...i, ...partialData } : i))
          );
        };

        return (
          <Fragment key={client.id}>
            <SubSection>
              <TextInput
                required
                label="First name"
                disabled={isLoading}
                value={client.first_name || ""}
                onChange={first_name => handleChange({ first_name })}
                style={{ flexGrow: 1 }}
              />
              <TextInput
                required
                label="Last name"
                disabled={isLoading}
                value={client.last_name || ""}
                onChange={last_name => handleChange({ last_name })}
                style={{ flexGrow: 1 }}
              />
            </SubSection>

            <SubSection>
              <Checkbox
                label="Notifications"
                disabled={isLoading}
                checked={Boolean(client.should_get_notifications)}
                onChange={v => handleChange({ should_get_notifications: Boolean(v) })}
              />
              <TextInput
                required
                disabled={Boolean(client.id) || isLoading}
                label="Email"
                value={client.email}
                onChange={v => handleChange({ email: v.toLowerCase() })}
                style={{ flexGrow: 1 }}
              />

              <Delete
                style={{ cursor: "pointer", color: palette.primary.red }}
                onClick={() => setClients(prev => prev.filter((_, _index) => index !== _index))}
              />
            </SubSection>
          </Fragment>
        );
      })}
      <div />
      <Button
        icon={<Add />}
        disabled={isLoading}
        onClick={() => setClients(prev => [...prev, { should_get_notifications: true }])}
        size="medium"
        style={{ margin: "auto" }}
      >
        Add a Client contact
      </Button>

      <SectionTitle>Client&apos;s review:</SectionTitle>
      <div />
      {project?.project_client_review?.is_submitted ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {CLIENT_REVIEW_FIELDS.map(({ answers, id, title, textInput }) => (
            <div key={id} style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontWeight: "bolder", whiteSpace: "nowrap", paddingRight: 20 }}>
                {title}:
              </span>

              <span>
                {project.project_client_review?.[id]} {!textInput && <>/ {answers.length}</>}
              </span>
            </div>
          ))}
        </div>
      ) : (
        <Button
          size="medium"
          style={{ margin: "auto" }}
          onAsyncClick={() => {
            if (!project) throw Error("Missing project");
            return enableClientReviewAndSendEmails({ variables: { projectStub: project.stub } });
          }}
          icon={<Search />}
          disabled={
            project?.archived ||
            project?.is_ready_for_client_review ||
            enableClientReviewAndSendEmailsOptions.loading
          }
        >
          Send emails
        </Button>
      )}
      <SubSection style={{ flexDirection: "column", gap: 15 }}>
        <Note variant="info" label="How does it work?">
          We send an email automatically when the project gets archived (when you archive the last
          campaign). If you want to send the emails before you archive the project, press the button
          on the left. Be careful! Sent emails cannot be unsent.
        </Note>
        {project?.project_client_review?.is_submitted && (
          <Note variant="success" label="Review submitted">
            Client has submitted the review. See the results on the left.
          </Note>
        )}
      </SubSection>
    </Section>
  );
});
