import type { FC } from "react";

import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { useParams } from "react-router-dom";

import { StatisticsTable } from "./ParticipantsTable/StatisticsTable";
import { useCampaignParticipantsOtherStatisticsQuery } from "./generated";

export const ContentStatistics: FC = () => {
  useDocumentTitle("Content Statistics");

  const params = useParams<{ campaignStub: string }>();
  const campaignStubs = (params.campaignStub ?? "").split(",");

  const { data } = useCampaignParticipantsOtherStatisticsQuery({
    variables: { campaignStubs }
  });

  return <StatisticsTable data={data} />;
};
