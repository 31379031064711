import type { FC } from "react";

import styled from "@emotion/styled";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { Route, Routes, useMatch, useNavigate } from "react-router-dom";

import { ContentContainer } from "lib/styled";

import { ChatLogic } from "./ChatLogic";
import { Conversations } from "./Conversations";
import { Documentation } from "./Documentation";

export const Chat: FC = () => {
  useDocumentTitle("Relatable AI chat");
  const navigate = useNavigate();
  const match = useMatch("/chat/:path");
  const path = match?.params?.path ?? "";

  return (
    <ContentContainer>
      <Root>
        <ToggleButtonGroup
          color="primary"
          value={path ?? ""}
          exclusive
          onChange={(event, value) => navigate(`/chat/${value ?? ""}`)}
          style={{ marginBottom: 15 }}
        >
          <ToggleButton value="" selected={!["list", "documentation"].includes(path)}>
            Chat
          </ToggleButton>
          <ToggleButton value="list">My conversations</ToggleButton>
          <ToggleButton value="documentation">Documentation</ToggleButton>
        </ToggleButtonGroup>

        <Routes>
          <Route path="/list" element={<Conversations />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/:conversationId?" element={<ChatLogic />} />
        </Routes>
      </Root>
    </ContentContainer>
  );
};

const Root = styled.div`
  margin-top: 20px;
  width: 100%;
`;
