import { type FC, useState } from "react";

import { TextInput } from "@relatable/ui/TextInput";

import { Popup } from "components/ui/Popup";
import { useUpdateUserMutation } from "modules/generated";

export const UpdateEmailPopup: FC<{ id: number; onClose(): void }> = ({ id, onClose }) => {
  const [updateUser] = useUpdateUserMutation({ onCompleted: onClose });
  const [email, setEmail] = useState("");

  return (
    <Popup
      onAction={() => updateUser({ variables: { id, set: { email } } })}
      onClose={onClose}
      title="Update email"
      actionLabel="Update"
    >
      <TextInput label="email" value={email} onChange={setEmail} />
    </Popup>
  );
};
