import type { Content_Preapproval_Log_Insert_Input } from "@relatable/gql/generated-base";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useNavigate, useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { useSendEmailToCampaignUserMutation } from "modules/generated";

import type { UserContentStatus } from "../Overview/common/reshape_data";
import { ContentOverview, type ContentOverviewProps } from "./ContentOverview";
import {
  ContentMediaDocument,
  ContentOverviewDocument,
  useContentMediaQuery,
  useContentOverviewQuery,
  useContentOverviewSettingsQuery,
  useDeleteContentApprovalMediaMutation,
  useInsertContentApprovalLogMutation,
  useMoveMediaUpMutation,
  useReplyToMutation,
  useSetLinkMutation,
  useUpdateCaptionMutation,
  useUpdateStateMutation
} from "./generated";

interface ContentOverviewContainerProps {
  filteredUsersContentStatus: UserContentStatus[];
}

export const ContentOverviewContainer: React.FC<ContentOverviewContainerProps> = ({
  filteredUsersContentStatus
}) => {
  const navigate = useNavigate();
  const params = useParams<{
    campaignStub: string;
    contentSettingId: string;
    campaignUserId: string;
  }>();
  const snackbar = useSnackbar();

  const {
    data: { activeContents } = {}
  } = useContentOverviewQuery({
    variables: {
      campaignUserId: Number(params.campaignUserId),
      contentSettingId: Number(params.contentSettingId)
    },
    skip: !params.contentSettingId || !params.campaignUserId
  });

  const activeContent = activeContents?.[0];

  const {
    data: { medias: activeContentMedias } = {}
  } = useContentMediaQuery({
    variables: {
      campaignUserId: Number(params.campaignUserId),
      contentSettingId: Number(params.contentSettingId)
    },
    skip: !params.contentSettingId || !params.campaignUserId
  });

  const { data: contentOverviewSetting = {} } = useContentOverviewSettingsQuery({
    variables: {
      contentSettingId: Number(params.contentSettingId),
      campaignUserId: Number(params.campaignUserId)
    }
  });

  const { me } = contentOverviewSetting;
  const accountId = me?.admin?.id ?? 0;

  const mutationProps = {
    refetchQueries: [ContentOverviewDocument, ContentMediaDocument],
    awaitRefetchQueries: true
  };

  const [replyTo] = useReplyToMutation(mutationProps);
  const [updateState] = useUpdateStateMutation(mutationProps);
  const [setLink, { loading: setLinkLoading }] = useSetLinkMutation(mutationProps);
  const [sendCreatorEmail] = useSendEmailToCampaignUserMutation(mutationProps);
  const [insertApprovalLogs] = useInsertContentApprovalLogMutation(mutationProps);
  const [moveMediaUp, { loading: moveMediaUpLoading }] = useMoveMediaUpMutation(mutationProps);
  const [updateCaption, { loading: updateCaptionLoading }] =
    useUpdateCaptionMutation(mutationProps);
  const [deleteMedia, { loading: deleteMediaLoading }] =
    useDeleteContentApprovalMediaMutation(mutationProps);

  const currentContentStatusItemIndex = filteredUsersContentStatus?.findIndex(
    item =>
      item.user_id === Number(params.campaignUserId) &&
      item.content_setting_id === Number(params.contentSettingId)
  );

  const prev = filteredUsersContentStatus
    ?.slice(0, currentContentStatusItemIndex)
    .reverse()
    .find(status => status.user_id && status.content_setting_id);

  const handleGoToPrevious = () => {
    if (!prev) return;
    const url = `/campaign/${params.campaignStub}/approval/${prev.user_id}/${prev.content_setting_id}`;

    navigate(url);
  };

  const next = filteredUsersContentStatus
    ?.slice(currentContentStatusItemIndex + 1)
    .find(status => status.user_id && status.content_setting_id);

  const handleGoToNext = () => {
    if (!next) return;
    const url = `/campaign/${params.campaignStub}/approval/${next.user_id}/${next.content_setting_id}`;

    navigate(url);
  };

  const handleAcceptClientReview = async () => {
    if (!activeContent?.id) return;
    await insertApprovalLogs({
      variables: {
        objects: [
          ...activeContent.campaign_preapproval_content_medias.map(m => ({
            campaign_preapproval_content_media_id: m.id
          })),

          ...(activeContent.new_caption
            ? [{ content_preapproval_caption_id: activeContent.new_caption.id }]
            : [])
        ].map(
          o =>
            ({
              ...o,
              action_by_account_id: accountId,
              state: CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING,
              target: "relatable"
            }) as Content_Preapproval_Log_Insert_Input
        )
      }
    });
    snackbar.success("Content ready for publishing!");
  };

  const handleNotifyCreator = async () => {
    if (!activeContent?.id) return;
    await sendCreatorEmail({
      variables: {
        campaignUserId: Number(params.campaignUserId),
        template: "preapproval-revisions-requested"
      }
    });
    snackbar.success("Creator was notified about the revisions request via email");
  };

  type HandleUpdateStateParams = Parameters<ContentOverviewProps["onUpdateState"]>[0];
  const handleUpdateState = ({ author, type, id, newState }: HandleUpdateStateParams) => {
    return updateState({
      variables: {
        ...(type === "media" ? { mediaId: id } : { captionId: id }),
        accountId,
        state: newState,
        ...(author === "creator"
          ? {
              target: "creator"
            }
          : {
              target: "client"
            })
      }
    });
  };

  const handleWriteMessage = (
    id: number,
    contentType: "media" | "caption",
    target: "creator" | "client",
    text: string
  ) => {
    return replyTo({
      variables: {
        text,
        accountId,
        target,
        ...(contentType === "media" ? { mediaId: id } : { captionId: id })
      }
    });
  };

  const handleUpdateCaption = (id: number, oldCaption: string, newCaption: string) => {
    return updateCaption({
      variables: {
        id,
        accountId,
        text: newCaption,
        prevCaption: oldCaption
      }
    });
  };

  const handleSetAttachmentLink = (link: string) => {
    if (!activeContent) return Promise.resolve();

    const prefixedLink =
      link.startsWith("http://") || link.startsWith("https://") ? link : `http://${link}`;

    return setLink({ variables: { id: Number(activeContent.id), link: prefixedLink } });
  };

  if (
    !accountId ||
    !activeContents ||
    !contentOverviewSetting?.setting?.id ||
    !Array.isArray(activeContentMedias) ||
    deleteMediaLoading
  ) {
    return <Loader />;
  }

  return (
    <ContentOverview
      contentOverviewSetting={contentOverviewSetting}
      activeContent={activeContent ?? null}
      medias={activeContentMedias || []}
      onNotifyCreator={handleNotifyCreator}
      onAcceptClientReview={handleAcceptClientReview}
      moveMediaUp={mediaId => moveMediaUp({ variables: { mediaId } })}
      onGoToPrevious={handleGoToPrevious}
      onGoToNext={handleGoToNext}
      onUpdateState={handleUpdateState}
      onUpdateCaption={handleUpdateCaption}
      onWriteMessage={handleWriteMessage}
      onSetAttachmentLink={handleSetAttachmentLink}
      moveMediaLoading={moveMediaUpLoading}
      setLinkLoading={setLinkLoading}
      updateCaptionLoading={updateCaptionLoading}
      onDelete={(mediaId: number) => deleteMedia({ variables: { mediaId } })}
    />
  );
};
