import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";
import { useOnOutsideClick } from "@relatable/ui/hooks/useOnOutsideClick";

import { AddCircle, Check, LocalOffer, ModeEdit } from "@mui/icons-material";
import { fadeIn } from "lib/styled";
import { useTagsQuery } from "modules/generated";

const TransparentSection = styled.div`
  background: white;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  width: 100%;
  background: transparent;
  box-shadow: none;
`;

const FloatingContainer = styled.div`
  position: absolute;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  background: white;
  padding: 10px;
  border-radius: 15px;
  z-index: 10;
  right: 0;
  bottom: 10px;
  animation: ${fadeIn} 200ms ease;
`;

const ScrollableSectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  padding-top: 10px;
  height: 150px;
  overflow: auto;
`;

const Tag = styled.div`
  background: white;
  padding: 5px 10px;
  font-weight: 600;
  color: ${palette.gray[50]};
  border-radius: 10px;
  font-size: 12px;
  text-transform: capitalize;
  animation: ${fadeIn} 200ms ease;
`;

const DeleteButton = styled.button`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${palette.secondary.red};
  position: absolute;
  top: -5px;
  right: -5px;
  border: 0;
  cursor: pointer;
  animation: ${fadeIn} 200ms ease;
`;

export const TagEditor: FC<{
  tagIds: number[];
  onAdd: (tagIidd: number) => void;
  onDelete: (id: number) => void;
}> = ({ tagIds, onAdd, onDelete }) => {
  const { data } = useTagsQuery();

  const [isAddPopup, setIsAddPopup] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const sectionRef = useOnOutsideClick(() => {
    setIsAddPopup(false);
    setIsEditing(false);
  });

  return (
    <TransparentSection ref={sectionRef}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <LocalOffer style={{ color: palette.gray[50] }} />
        <span style={{ marginLeft: 10, fontSize: 12, color: palette.gray[50] }}>Tags</span>
        <div style={{ marginLeft: "auto", position: "relative" }}>
          <AddCircle
            style={{ color: palette.gray[50], cursor: "pointer", marginRight: 5 }}
            onClick={() => setIsAddPopup(p => !p)}
          />
          {isEditing ? (
            <Check
              style={{ color: palette.primary.green, cursor: "pointer", width: 14 }}
              onClick={() => setIsEditing(p => !p)}
            />
          ) : (
            <ModeEdit
              style={{ color: palette.gray[50], cursor: "pointer", width: 14 }}
              onClick={() => setIsEditing(p => !p)}
            />
          )}

          {isAddPopup && (
            <FloatingContainer>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontSize: 12, color: palette.gray[50] }}>Add tags</span>
              </div>
              <div
                style={{
                  borderColor: palette.gray[50],
                  color: palette.gray[50],
                  borderRadius: 15,
                  height: 200,
                  width: 300,
                  marginTop: 10,
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "start",
                  gap: 10,
                  overflow: "auto"
                }}
              >
                {data?.user_tag_type.map(tag => {
                  const isActive = tagIds.includes(tag.id);

                  return (
                    <button
                      type="button"
                      onClick={() => {
                        if (isActive) {
                          onDelete(tag.id);
                          return;
                        }
                        onAdd(tag.id);
                      }}
                      style={{
                        border: 0,
                        fontWeight: 600,
                        background: isActive ? palette.primary.green : palette.gray[20],
                        color: isActive ? "white" : palette.gray[70],
                        padding: "5px 10px",
                        transition: "background 150ms ease",
                        borderRadius: 15,
                        height: 30,
                        cursor: "pointer",
                        textTransform: "capitalize"
                      }}
                      key={tag.id}
                    >
                      {tag.name}
                    </button>
                  );
                })}
              </div>
            </FloatingContainer>
          )}
        </div>
      </div>

      <ScrollableSectionContent>
        {!tagIds.length && (
          <p style={{ fontSize: 12, color: palette.gray[50] }}>No tags assigned to this user.</p>
        )}
        {tagIds.map(id => (
          <div key={id} style={{ position: "relative" }}>
            {isEditing && (
              <DeleteButton type="button" onClick={() => onDelete(id)}>
                <div
                  style={{
                    width: 10,
                    height: 2,
                    background: "white",
                    position: "relative",
                    left: -3
                  }}
                />
              </DeleteButton>
            )}
            <Tag key={id}>{data?.user_tag_type.find(tt => tt.id === id)?.name}</Tag>
          </div>
        ))}
      </ScrollableSectionContent>
    </TransparentSection>
  );
};
