import { useApolloClient } from "@apollo/client";

import { UserDocument, type UserQuery, type UserQueryVariables } from "../generated";

export const useOptimisticUserUpdate = (userId: number) => {
  const apolloClient = useApolloClient();

  return (
    callback: (
      prev: NonNullable<UserQuery["users_by_pk"]>
    ) => Partial<NonNullable<UserQuery["users_by_pk"]>>
  ) => {
    const data = apolloClient.readQuery<UserQuery, UserQueryVariables>({
      query: UserDocument,
      variables: { id: userId }
    });

    if (!data?.users_by_pk) {
      apolloClient.refetchQueries({ include: [UserDocument] });
      return;
    }
    apolloClient.writeQuery<UserQuery, UserQueryVariables>({
      query: UserDocument,
      variables: { id: userId },
      data: {
        ...data,
        users_by_pk: {
          ...data.users_by_pk,
          ...callback(data.users_by_pk)
        }
      }
    });
  };
};
