import styled from "@emotion/styled";
import { FormControl } from "@mui/material";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  & .MuiInputLabel-formControl {
    top: -7px;
    left: 13px;
  }
  & .MuiFormControl-root {
    margin-bottom: 30px;
  }
`;

export const FormContainer = styled(FormControl)`
  & label + .MuiInput-formControl {
    height: 30px;
  }
`;
