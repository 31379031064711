/* eslint-disable newline-per-chained-call */
import { type FC, useCallback } from "react";

import styled from "@emotion/styled";
import { MenuItem, Select } from "@mui/material";
import { useSnackbar } from "@relatable/ui/Snackbar";
import type { Level } from "@tiptap/extension-heading";
import { useCurrentEditor } from "@tiptap/react";

import {
  Code,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatUnderlined,
  Link,
  Redo,
  Undo
} from "@mui/icons-material";
import { LinkToolbar } from "./LinkToolbar";
import { ToolbarButton } from "./ToolbarButton";

const BLOCK_TYPES = {
  p: "Paragraph",
  h1: "Heading 1",
  h2: "Heading 2",
  h3: "Heading 3",
  h4: "Heading 4"
} as const;

export const Toolbar: FC<{ onShowHTML: () => void }> = ({ onShowHTML }) => {
  const { editor } = useCurrentEditor();
  const snackbar = useSnackbar();

  const handleBlockChange = useCallback(
    e => {
      if (!editor) return;
      const { value } = e.target;
      const cmd = editor.chain().focus();
      if (value === "p") {
        cmd.setParagraph().run();
      } else if (value.startsWith("h")) {
        const headingIndex = Number(value.slice(1)) as Level;
        cmd.toggleHeading({ level: headingIndex }).run();
      }
    },
    [editor]
  );

  const handleAddLink = useCallback(() => {
    // eslint-disable-next-line no-alert
    const url = window.prompt("Link URL");
    if (!editor || !url) return;
    if (!(url.startsWith("http") || url.startsWith("*|"))) {
      snackbar.error(
        "Invalid URL. It needs to be a tag like *|CREATIVE_BRIEF_URL|* or it should start with https://"
      );
      return;
    }
    editor.chain().focus().extendMarkRange("link").setLink({ href: url, target: "_blank" }).run();
  }, [snackbar, editor]);

  if (!editor) {
    return null;
  }

  const activeBlock = (() => {
    if (editor.isActive("paragraph")) return "p";
    for (let i = 1; i <= 4; i++) {
      if (editor.isActive("heading", { level: i })) return `h${i}`;
    }
    console.error("Cannot find block type");
    return "p"; // fallback
  })();

  return (
    <StyledToolbar>
      <LinkToolbar />
      <ToolbarButton
        icon={<FormatBold />}
        title="Bold"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        active={editor.isActive("bold")}
      />

      <ToolbarButton
        icon={<FormatItalic />}
        title="Italic"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        active={editor.isActive("italic")}
      />

      <ToolbarButton
        icon={<FormatUnderlined />}
        title="Underline"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        active={editor.isActive("underline")}
      />

      <ToolbarButton
        icon={<FormatListBulleted />}
        title="Bullet list"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        disabled={!editor.can().chain().focus().toggleBulletList().run()}
        active={editor.isActive("listItem")}
      />

      <ToolbarButton
        icon={<Undo />}
        title="Undo"
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
      />

      <ToolbarButton
        icon={<Redo />}
        title="Redo"
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
      />

      <ToolbarButton
        icon={<Link />}
        title="Add link"
        onClick={handleAddLink}
        disabled={editor.state.selection.empty}
      />

      <Select
        value={activeBlock}
        onChange={handleBlockChange}
        size="small"
        style={{ marginRight: 10 }}
      >
        {Object.entries(BLOCK_TYPES).map(([k, v]) => (
          <MenuItem key={k} value={k}>
            {v}
          </MenuItem>
        ))}
      </Select>

      <ToolbarButton icon={<Code />} title="Show HTML editor" onClick={onShowHTML} />
    </StyledToolbar>
  );
};

const StyledToolbar = styled.div`
  position: relative;
  padding-bottom: 10px;
  border-bottom: 2px solid #eee;
`;
