import type { FC } from "react";

import styled from "@emotion/styled";
import { prettifyPercentages } from "@relatable/helpers";

import { BarChart, Groups, SignalCellularAlt, StarPurple500 } from "@mui/icons-material";
import { Section } from "../user.styled";
import { StatsSectionItem } from "./StatsSectionItem";

const StyledSection = styled(Section)`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 15px;
`;

export const StatsSection: FC<{
  collaborationScore: number | null;
  contentQualityScore: number | null;
  engagementRate: number | null;
  vertical: string | null;
}> = ({ collaborationScore, contentQualityScore, engagementRate, vertical }) => (
  <StyledSection>
    {[
      {
        title: "Collaboration Score",
        value: collaborationScore ? collaborationScore.toFixed(2) : "-",
        Icon: BarChart
      },
      {
        title: "Content Quality Score",
        value: contentQualityScore ? contentQualityScore.toFixed(2) : "-",
        Icon: SignalCellularAlt
      },
      {
        title: "Engagement rate (HA)",
        value: engagementRate ? prettifyPercentages({ total: 1, of: engagementRate }) : "",
        Icon: Groups
      },
      {
        title: "Vertical",
        value: vertical || "-",
        Icon: StarPurple500
      }
    ].map(stat => (
      <StatsSectionItem key={stat.title} title={stat.title} value={stat.value} Icon={stat.Icon} />
    ))}
  </StyledSection>
);
