import type {
  Campaign_Insert_Input,
  Campaign_Set_Input,
  Platform_Enum
} from "@relatable/gql/generated-base";
import { calculatePercentages, prettifyNumber, sum } from "@relatable/helpers";
import { BUDGET_CATEGORIES } from "@relatable/helpers/constants";
import { Accordion } from "@relatable/ui/Accordion";
import { Button } from "@relatable/ui/Button";
import { Note } from "@relatable/ui/Note";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { TextInput } from "@relatable/ui/TextInput";
import {
  type FC,
  type SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from "react";

import { LinearProgress } from "components/ui/LinearProgress";
import { platformOptions } from "lib/campaigns";
import { CURRENCIES } from "lib/constants";

import { Add, AssistantDirection, Delete } from "@mui/icons-material";
import { ExchangeRate } from "../ExchangeRate";
import { Section, SubSection } from "../Section";
import {
  type ProjectDetailsQuery,
  useInsertCampaignMutation,
  useUpdateProjectCampaignMutation
} from "../generated";
import { getChangedFields } from "../helpers";
import type { Budget, SectionRef } from "./types";

type Campaign = Omit<ProjectDetailsQuery["projects"][number]["campaigns"][number], "id"> & {
  id?: number | undefined;
};
export const CampaignSection = forwardRef<
  SectionRef,
  {
    project: ProjectDetailsQuery["projects"][number] | undefined;
    isSubmitting: boolean;
    budgets: Budget[];
    targetParticipantsMin: number | null;
    targetParticipantsMax: number | null;
  }
>(({ project, isSubmitting, budgets, targetParticipantsMin, targetParticipantsMax }, ref) => {
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const [activeCampaigns, setActiveCampaigns] = useState<Campaign[]>([]);
  const [archivedCampaigns, setArchivedCampaigns] = useState<Campaign[]>([]);
  const campaigns = [...activeCampaigns, ...archivedCampaigns];

  const [updateProjectCampaign, updateProjectCampaignConfig] = useUpdateProjectCampaignMutation();
  const [insertCampaign, insertCampaignConfig] = useInsertCampaignMutation();

  useEffect(() => {
    if (!project) return;
    setArchivedCampaigns(project.campaigns.filter(c => c.archived));
    setActiveCampaigns(project.campaigns.filter(c => !c.archived));
  }, [project]);

  const validate = () => {
    const errors: string[] = [];

    campaigns.forEach((campaign, index) => {
      const title = campaign.title || `campaign no. ${index + 1}`;
      if (!campaign.title) errors.push(`${title} is missing Title`);
      if (!campaign.platform) errors.push(`${title} is missing Platform`);
      if (typeof campaign.max_budget !== "number") errors.push(`${title} is missing Payout Budget`);
      if (!campaign.target_reach) errors.push(`${title} is missing Reach`);
      if (!campaign.default_payout_currency) errors.push(`${title} is missing currency`);
      if (!campaign.exchange_rate) errors.push(`${title} is missing exchange_rate`);
      if (typeof campaign.max_participants !== "number") {
        errors.push(`${title} is missing Max Participants`);
      }
      if (typeof campaign.target_participants !== "number") {
        errors.push(`${title} is missing Min participants`);
      }
    });

    setFieldErrors(errors);
    return errors;
  };

  const getChangedCampaigns = () => {
    const campaignsToCreate: Campaign_Insert_Input[] = [];
    const campaignsToUpdate: { campaignId: number; set: Campaign_Set_Input }[] = [];

    if (!project || !project.id) return { campaignsToCreate, campaignsToUpdate };

    campaigns.forEach(async campaign => {
      if (!campaign.platform) {
        return;
      }

      const platformBudgets: Record<Platform_Enum, number> = {
        instagram:
          budgets.find(pb => pb.type === BUDGET_CATEGORIES.INFLUENCER_MARKETING_IG.value)
            ?.target_margin || 0,
        tiktok:
          budgets.find(pb => pb.type === BUDGET_CATEGORIES.INFLUENCER_MARKETING_TT.value)
            ?.target_margin || 0,
        youtube:
          budgets.find(pb => pb.type === BUDGET_CATEGORIES.INFLUENCER_MARKETING_YT.value)
            ?.target_margin || 0,
        snapchat:
          budgets.find(pb => pb.type === BUDGET_CATEGORIES.INFLUENCER_MARKETING_SC.value)
            ?.target_margin || 0
      };

      if (campaign.id) {
        const existing = project.campaigns.find(c => c.id === campaign.id);
        const changedFields = getChangedFields({
          data: {
            ...campaign,
            client_invoice_amount: Math.ceil(
              (campaign.max_budget || 0) / (1 - platformBudgets[campaign.platform])
            )
          },
          initialData: existing
        });
        if (!Object.values(changedFields).length) return;
        campaignsToUpdate.push({
          campaignId: campaign.id,
          set: {
            project_id: project.id,
            ...changedFields
          }
        });
        return;
      }

      campaignsToCreate.push({
        ...campaign,
        project_id: project.id,
        client_invoice_amount: Math.ceil(
          (campaign.max_budget || 0) / (1 - platformBudgets[campaign.platform])
        )
      });
    });

    return { campaignsToCreate, campaignsToUpdate };
  };

  const { campaignsToCreate, campaignsToUpdate } = getChangedCampaigns();

  const handleUpdate = async () => {
    if (validate().length) return;

    if (!project || !project.id) throw new Error("missing project");

    await Promise.all([
      ...campaignsToCreate.map(input =>
        insertCampaign({
          variables: {
            input
          }
        })
      ),
      ...campaignsToUpdate.map(({ campaignId, set }) =>
        updateProjectCampaign({
          variables: {
            campaignId,
            set
          }
        })
      )
    ]);
  };

  const projectBudgetSum = sum(
    budgets.map(budget =>
      (
        [
          BUDGET_CATEGORIES.INFLUENCER_MARKETING_IG.value,
          BUDGET_CATEGORIES.INFLUENCER_MARKETING_TT.value,
          BUDGET_CATEGORIES.INFLUENCER_MARKETING_YT.value,
          BUDGET_CATEGORIES.INFLUENCER_MARKETING_SC.value
        ] as string[]
      ).includes(budget.type)
        ? Math.round(
            (budget.revenue_allocation || 0) * ((100 - (budget.target_margin * 100 || 0)) / 100)
          )
        : 0
    ) || []
  );
  const minParticipantsLeft =
    (targetParticipantsMin || 0) - sum(campaigns.map(c => c.target_participants || 0) || []);
  const maxParticipantsLeft =
    (targetParticipantsMax || 0) - sum(campaigns.map(c => c.max_participants || 0) || []);
  const projectBudgetUsed = sum(campaigns.map(campaign => campaign.max_budget || 0));
  const availableBudget = projectBudgetSum - projectBudgetUsed;

  const isChanged = project ? Boolean(campaignsToCreate.length || campaignsToUpdate.length) : false;

  useImperativeHandle(ref, () => ({
    submit: handleUpdate,
    validate
  }));

  const isLoading =
    !project || isSubmitting || updateProjectCampaignConfig.loading || insertCampaignConfig.loading;
  return (
    <Section
      isChanged={isChanged}
      title="Campaigns"
      fieldErrors={fieldErrors}
      updatedAtNotImplemented
      updated_at={undefined}
      submitError={updateProjectCampaignConfig.error || insertCampaignConfig.error}
      sidebar={
        <>
          {campaigns.length ? (
            <>
              <Note
                variant={minParticipantsLeft === 0 ? "success" : "warning"}
                label="Min participants:"
              >
                {minParticipantsLeft === 0
                  ? "You used all available participants from the project."
                  : minParticipantsLeft > 0
                    ? `${minParticipantsLeft} left in the project`
                    : `${Math.abs(
                        minParticipantsLeft
                      )} is more than it is available in the project`}
              </Note>
              <Note
                variant={maxParticipantsLeft === 0 ? "success" : "warning"}
                label="Max participants:"
              >
                {maxParticipantsLeft === 0
                  ? "You used all available participants from the project."
                  : maxParticipantsLeft > 0
                    ? `${maxParticipantsLeft} left in the project`
                    : `${Math.abs(
                        maxParticipantsLeft
                      )} is more than it is available in the project`}
              </Note>
              <Note
                variant={
                  availableBudget < 0 ? "error" : availableBudget === 0 ? "success" : "warning"
                }
                label="Available budget:"
              >
                {availableBudget < 0
                  ? `You exceeded the budget by ${prettifyNumber(Math.abs(availableBudget))}`
                  : availableBudget === 0
                    ? "You used all the budget"
                    : `You may still use ${prettifyNumber(availableBudget)} of the budget`}
              </Note>
            </>
          ) : (
            <Note variant="info" label="No campaigns yet…">
              Add your first campaign!
            </Note>
          )}

          <Note variant="warning" label="REMEMBER!">
            The currency can be set only when creating a campaign and cannot be updated afterwards!
          </Note>
        </>
      }
    >
      {activeCampaigns
        .filter(c => !c.archived)
        .map((campaign, index) => (
          <CampaignItem
            isLoading={isLoading}
            projectBudgetSum={projectBudgetSum}
            campaign={campaign}
            index={index}
            key={index}
            setCampaigns={setActiveCampaigns}
          />
        ))}
      {Boolean(archivedCampaigns.length) && (
        <Accordion
          style={{ gridColumn: "span 2" }}
          contentContainerStyles={{
            gridTemplateColumns: "50% auto",
            display: "grid",
            gap: 20,
            alignItems: "center",
            alignContent: "center"
          }}
          options={archivedCampaigns.map((c, index) => ({
            title: `Archived - ${c.title}`,
            content: (
              <CampaignItem
                isLoading={isLoading}
                projectBudgetSum={projectBudgetSum}
                campaign={c}
                index={index}
                key={c.id}
                setCampaigns={setArchivedCampaigns}
              />
            )
          }))}
        />
      )}
      <LinearProgress max={projectBudgetSum} value={projectBudgetUsed} />
      <Button
        disabled={isLoading}
        icon={<Add />}
        onClick={() => {
          setActiveCampaigns(prev => [...prev, { stub: "", title: "" }]);
        }}
        size="medium"
        style={{ margin: "auto" }}
      >
        Add a campaign
      </Button>
    </Section>
  );
});

const CampaignItem: FC<{
  campaign: Campaign;
  setCampaigns: (v: SetStateAction<Campaign[]>) => void;
  index: number;
  isLoading: boolean;
  projectBudgetSum: number;
}> = ({ campaign, setCampaigns, projectBudgetSum, index, isLoading }) => {
  const handleChange = useCallback(
    (partialData: Partial<Campaign>) => {
      setCampaigns(prev =>
        prev.map((i, _index) => (index === _index ? { ...i, ...partialData } : i))
      );
    },
    [setCampaigns, index]
  );

  const handleOnRateChange = useCallback(
    v => {
      if (campaign.exchange_rate !== v) handleChange({ exchange_rate: v });
    },
    [handleChange, campaign.exchange_rate]
  );

  return (
    <>
      <TextInput
        required
        label="Name"
        disabled={isLoading}
        value={campaign.title || ""}
        onChange={title => handleChange({ title })}
        style={{ width: "100%" }}
      />
      <Select
        required
        hideNone
        disabled={isLoading}
        label="Platform"
        value={campaign.platform}
        style={{ flexGrow: 1, minWidth: 100 }}
        onChange={platform => handleChange({ platform })}
        options={platformOptions}
      />

      <SubSection>
        <TextInput
          required
          disabled={isLoading}
          type="number"
          label="Payout budget SEK"
          value={String(campaign.max_budget || 0)}
          onChange={v => {
            handleChange({
              max_budget: Number(v),
              cpm_payout:
                v &&
                Number.isFinite(Number(v)) &&
                campaign.target_reach &&
                Number.isFinite(campaign.target_reach)
                  ? Math.round((Number(v) / (campaign.target_reach || 0)) * 1000)
                  : campaign.cpm_payout
            });
          }}
        />
        <TextInput
          disabled
          label="%"
          type="number"
          value={String(calculatePercentages({ total: projectBudgetSum, of: campaign.max_budget }))}
          onChange={() => null}
        />
        <TextInput
          label="CPM"
          type="number"
          disabled={isLoading}
          value={String(campaign.cpm_payout || 0)}
          onChange={v =>
            handleChange({
              cpm_payout: Number(v),
              max_budget: campaign.target_reach
                ? Math.round(Number(v) * (campaign.target_reach / 1000))
                : campaign.max_budget,
              target_reach:
                campaign.target_reach ||
                (campaign.max_budget
                  ? Math.round((campaign.max_budget / Number(v)) * 1000)
                  : campaign.target_reach)
            })
          }
        />
      </SubSection>

      <SubSection>
        <TextInput
          required
          disabled={isLoading}
          type="number"
          label="Reach"
          value={String(campaign.target_reach || 0)}
          onChange={v => {
            handleChange({
              target_reach: Number(v),
              max_budget:
                campaign.cpm_payout && Number.isFinite(campaign.cpm_payout)
                  ? Math.round(campaign.cpm_payout * (Number(v) / 1000))
                  : campaign.max_budget,
              cpm_payout:
                campaign.max_budget && Number.isFinite(campaign.max_budget) && !campaign.cpm_payout
                  ? Math.round(campaign.max_budget / (Number(v) * 1000))
                  : campaign.cpm_payout
            });
          }}
        />
        <TextInput
          required
          disabled={isLoading}
          type="number"
          label="Participants min"
          value={String(campaign.target_participants || 0)}
          onChange={v => handleChange({ target_participants: Number(v) })}
        />
        <TextInput
          required
          disabled={isLoading}
          type="number"
          label="Participants max"
          value={String(campaign.max_participants || 0)}
          onChange={v => handleChange({ max_participants: Number(v) })}
        />

        {campaign.id ? (
          <AssistantDirection
            style={{
              color: campaign.id ? palette.secondary.blue : palette.gray[50],
              cursor: "pointer"
            }}
            onClick={() => campaign.id && window.open(`/campaign/${campaign.stub}/edit`, "_blank")}
          />
        ) : (
          <Delete
            style={{
              cursor: "pointer",
              color: campaign.id ? palette.gray[50] : palette.primary.red
            }}
            onClick={() => {
              if (campaign.id) return;
              setCampaigns(prev => prev.filter((_, _index) => index !== _index));
            }}
          />
        )}
      </SubSection>

      <SubSection style={{ marginBottom: 20 }}>
        <Select
          hideNone
          required
          style={{ width: "100%" }}
          disabled={Boolean(campaign.id) || isLoading}
          label="Payout currency"
          value={campaign.default_payout_currency}
          options={Object.values(CURRENCIES).map(c => ({ value: c, label: c }))}
          onChange={v => {
            if (!v) return;
            handleChange({ default_payout_currency: v });
          }}
        />
        {campaign.id ? (
          <div>
            {campaign.exchange_rate !== 1 && (
              <TextInput
                disabled
                onChange={() => null}
                label="Exchange rate SEK"
                type="number"
                value={String(campaign.exchange_rate)}
              />
            )}
          </div>
        ) : (
          <ExchangeRate currency={campaign.default_payout_currency} onChange={handleOnRateChange} />
        )}
      </SubSection>
      <SubSection style={{ marginBottom: 20 }} />
    </>
  );
};
