import type { FC } from "react";

import { Alert } from "@mui/material";
import { Button } from "@relatable/ui/Button";
import { Note } from "@relatable/ui/Note";

import { Section } from "../Section";
import {
  ProjectDetailsDocument,
  type ProjectDetailsQuery,
  useUpdateProjectMutation
} from "../generated";

export const ArchiveSection: FC<{
  project: ProjectDetailsQuery["projects"][number] | undefined;
}> = ({ project }) => {
  const [updateProject, { loading: updateProjectLoading }] = useUpdateProjectMutation({
    awaitRefetchQueries: true,
    refetchQueries: [ProjectDetailsDocument]
  });

  return (
    <Section
      title="Archive"
      fieldErrors={[]}
      isChanged={false}
      sidebar={<Note>This action does not require saving</Note>}
      updatedAtNotImplemented
      updated_at={undefined}
    >
      {project?.archived && (
        <Alert variant="filled" severity="success">
          The campaign has been successfully archived.
        </Alert>
      )}

      <Button
        size="medium"
        color="secondary"
        onClick={() => {
          if (project?.id) {
            updateProject({ variables: { projectId: project.id, set: { archived: true } } });
          }
        }}
        disabled={Boolean(project?.archived)}
        isLoading={updateProjectLoading}
      >
        Archive project
      </Button>
    </Section>
  );
};
