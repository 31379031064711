import type { FC } from "react";

import { Route, Routes } from "react-router-dom";

import { Post } from "./Post";
import { Reel } from "./Reel";
import { Snap } from "./Snap";
import { Story } from "./Story";
import { TikTok } from "./TikTok";

export const Content: FC = () => {
  return (
    <Routes>
      <Route path="story/:userId/:contentSettingId" element={<Story />} />
      <Route path="reel/:reelId" element={<Reel />} />
      <Route path="post/:postId" element={<Post />} />
      <Route path="snap/:snapId" element={<Snap />} />
      <Route path="tiktok/:postId" element={<TikTok />} />
    </Routes>
  );
};
