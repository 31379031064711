import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";
import { useParams } from "react-router-dom";

import { Add } from "@mui/icons-material";
import { AddVideoPopup } from "./AddVideoPopup";

export const ActionButtons: FC = () => {
  const [showAddVideoPopup, setShowAddVideoPopup] = useState(false);

  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  if (campaignStubs.length !== 1) return null;
  return (
    <SActionButtons>
      <Button icon={<Add />} onClick={() => setShowAddVideoPopup(true)}>
        Add video
      </Button>

      {showAddVideoPopup && <AddVideoPopup onClose={() => setShowAddVideoPopup(false)} />}
    </SActionButtons>
  );
};

const SActionButtons = styled.div`
  display: flex;
  gap: 16px;
`;
