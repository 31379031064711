import { type FC, useEffect, useMemo, useState } from "react";

import { YYYY_MM_DD } from "@relatable/helpers/date";
import { Select } from "@relatable/ui/Select";

const REPORTING_PERIODS = {
  "Y1 (whole year)": (year: number) => [`${year}-01-01`, `${year}-12-31`],
  H1: (year: number) => [`${year}-01-01`, `${year}-06-30`],
  H2: (year: number) => [`${year}-07-01`, `${year}-12-31`],
  Q1: (year: number) => [`${year}-01-01`, `${year}-03-31`],
  Q2: (year: number) => [`${year}-04-01`, `${year}-06-30`],
  Q3: (year: number) => [`${year}-07-01`, `${year}-09-30`],
  Q4: (year: number) => [`${year}-10-01`, `${year}-12-31`]
} as const;

const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
] as const;

const getWeeksInMonth = (year: number, month: number) => {
  const weeks: { label: string; start: Date; end: Date }[] = [];

  const mondays: Date[] = [];

  for (let i = 1; i <= 31; i++) {
    const date = new Date(year, month - 1, i);
    if (date.getDate() !== i) {
      break;
    }
    if (date.getDay() === 1) mondays.push(date);
  }

  // add preceding Monday
  if (mondays[0].getDate() !== 1) {
    let day = mondays[0].getDate() - 1;
    while (new Date(year, month - 1, day).getDay() !== 1) {
      day--;
    }
    mondays.unshift(new Date(year, month - 1, day));
  }

  // add Monday from next month
  if (mondays[mondays.length - 1].getDay() !== 0) {
    let day = mondays[mondays.length - 1].getDate() + 1;
    while (new Date(year, month - 1, day).getDay() !== 1) {
      day++;
    }
    mondays.push(new Date(year, month - 1, day));
  }

  for (let i = 0; i < mondays.length - 1; i++) {
    const start = mondays[i];
    const end = new Date(
      mondays[i + 1].getFullYear(),
      mondays[i + 1].getMonth(),
      mondays[i + 1].getDate() - 1
    );
    const startMonth = MONTH_NAMES[start.getMonth()];
    const endMonth = start.getMonth() === end.getMonth() ? " " : `${MONTH_NAMES[end.getMonth()]} `;

    weeks.push({
      label: `${startMonth} ${start.getDate()} - ${endMonth}${end.getDate()}`,
      start,
      end
    });
  }

  return weeks;
};

const getLastDayOfMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate();
};

export const PeriodSelector: FC<{
  startDate: number;
  onSelect: ({ start, end }: { start: string; end: string }) => void;
  showWeeks?: boolean;
}> = ({ startDate, onSelect, showWeeks }) => {
  const [selectedYearFilter, setSelectedYearFilter] = useState(new Date().getFullYear().toString());
  const [selectedMonthFilter, setSelectedMonthFilter] = useState(
    (new Date().getMonth() + 1).toString()
  );
  const [selectedWeekFilter, setSelectedWeekFilter] = useState<number | null>(null);

  const weeksInMonth = useMemo(
    () =>
      !REPORTING_PERIODS[selectedMonthFilter]
        ? getWeeksInMonth(Number(selectedYearFilter), Number(selectedMonthFilter))
        : [],
    [selectedYearFilter, selectedMonthFilter]
  );

  useEffect(() => {
    if (selectedWeekFilter !== null) {
      const { start, end } = weeksInMonth[selectedWeekFilter];
      onSelect({
        start: YYYY_MM_DD(start),
        end: YYYY_MM_DD(end)
      });
    } else if (REPORTING_PERIODS[selectedMonthFilter]) {
      onSelect({
        start: REPORTING_PERIODS[selectedMonthFilter]?.(selectedYearFilter)[0],
        end: REPORTING_PERIODS[selectedMonthFilter]?.(selectedYearFilter)[1]
      });
    } else {
      onSelect({
        start: `${selectedYearFilter}-${selectedMonthFilter.padStart(2, "0")}-01`,
        end: `${selectedYearFilter}-${selectedMonthFilter.padStart(2, "0")}-${getLastDayOfMonth(Number(selectedYearFilter), Number(selectedMonthFilter))}`
      });
    }
  }, [onSelect, weeksInMonth, selectedYearFilter, selectedMonthFilter, selectedWeekFilter]);

  const yearFilterOptions: { value: string; label: string }[] = [
    ...Array(new Date().getFullYear() - startDate + 1)
      .fill(0)
      .map((_, i, arr) => `${startDate - i + arr.length - 1}`)
      .map(value => ({ value, label: value }))
  ];

  const monthFilterOptions: { value: string; label: string }[] = [
    ...MONTH_NAMES.map((label, index) => ({
      value: String(index + 1),
      label
    })),
    ...Object.keys(REPORTING_PERIODS).map(period => ({
      value: period,
      label: period
    }))
  ];

  const dayFilterOptions: { value: number; label: string }[] = REPORTING_PERIODS[
    selectedMonthFilter
  ]
    ? []
    : weeksInMonth.map((week, index) => ({
        label: week.label,
        value: index
      }));

  return (
    <>
      <Select
        required
        hideNone
        label="Year"
        value={selectedYearFilter}
        onChange={v => {
          setSelectedWeekFilter(null);
          setSelectedYearFilter(v ?? new Date().getFullYear().toString());
        }}
        options={yearFilterOptions}
        style={{ width: 150, marginRight: 16 }}
      />

      <Select
        required
        hideNone
        label="Month(s)"
        value={selectedMonthFilter}
        onChange={v => {
          setSelectedWeekFilter(null);
          setSelectedMonthFilter(v ?? "1");
        }}
        options={monthFilterOptions}
        style={{ width: 150, marginRight: showWeeks ? 16 : 0 }}
      />

      {showWeeks && (
        <Select
          label="Week"
          value={selectedWeekFilter}
          onChange={v => setSelectedWeekFilter(v)}
          options={dayFilterOptions}
          style={{ width: 200 }}
        />
      )}
    </>
  );
};
