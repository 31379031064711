import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import { UserInfoFragmentDoc, UserProfilePictureFragmentDoc, MediaStateFragmentDoc, CaptionStateFragmentDoc, IntercomFragmentDoc } from '../../../../../../packages/gql/generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageListQueryVariables = Types.Exact<{
  campaignStub: Types.Scalars['String']['input'];
}>;


export type MessageListQuery = { __typename?: 'Query', campaign_contents: Array<{ __typename?: 'campaign_content', id: number, content_type?: Types.Content_Type_Enum | null, content?: { __typename?: 'email_contents', id: number, name?: string | null } | null, mandrills: Array<{ __typename?: 'mandrill', id: number, status?: string | null, opens?: number | null, clicks?: number | null }> }> };

export type MessageOverridesQueryVariables = Types.Exact<{
  campaignStub: Types.Scalars['String']['input'];
}>;


export type MessageOverridesQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, message_override?: any | null }> };

export type SearchContentQueryVariables = Types.Exact<{
  where: Types.Email_Contents_Bool_Exp;
}>;


export type SearchContentQuery = { __typename?: 'Query', contents: Array<{ __typename?: 'email_contents', id: number, name?: string | null }> };

export type ContentListQueryVariables = Types.Exact<{
  campaignStub: Types.Scalars['String']['input'];
  campaignStubLike: Types.Scalars['String']['input'];
}>;


export type ContentListQuery = { __typename?: 'Query', email_contents: Array<{ __typename?: 'email_contents', id: number, name?: string | null, is_base_template: boolean }>, campaigns: Array<{ __typename?: 'campaign', id: number, message_override?: any | null }> };

export type EmailStatisticsQueryVariables = Types.Exact<{
  campaignStub: Types.Scalars['String']['input'];
  contentName: Types.Scalars['String']['input'];
}>;


export type EmailStatisticsQuery = { __typename?: 'Query', campaign_content: Array<{ __typename?: 'campaign_content', id: number, content?: { __typename?: 'email_contents', id: number, name?: string | null } | null, campaign?: { __typename?: 'campaign', id: number, platform?: Types.Platform_Enum | null } | null, mandrills: Array<{ __typename?: 'mandrill', id: number, created_at?: string | null, last_check_ts?: string | null, status?: string | null, reject_reason?: string | null, opens?: number | null, clicks?: number | null, user?: { __typename?: 'users', id: number, user_youtube?: { __typename?: 'users_youtube', id: number, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null } | null, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null } | null }> }> };

export type MandrillTemplatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MandrillTemplatesQuery = { __typename?: 'Query', mandrillTemplates: Array<{ __typename?: 'MandrillTemplateResponseItem', slug: string, name: string, subject?: string | null, labels: Array<string> }> };

export type MandrillPreviewQueryVariables = Types.Exact<{
  query: Types.MandrillPreviewRequest;
}>;


export type MandrillPreviewQuery = { __typename?: 'Query', mandrillPreview: { __typename?: 'MandrillPreviewResponse', html: string } };

export type MandrillVariablesQueryVariables = Types.Exact<{
  query: Types.MandrillVariablesRequest;
}>;


export type MandrillVariablesQuery = { __typename?: 'Query', mandrillVariables: Array<{ __typename?: 'MandrillVariablesResponseItem', key?: string | null, value?: string | null }> };

export type MessageContentQueryVariables = Types.Exact<{
  campaignStub: Types.Scalars['String']['input'];
  contentName: Types.Scalars['String']['input'];
}>;


export type MessageContentQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'campaign', id: number, title: string, stub: string, campaign_users: Array<{ __typename?: 'campaign_user', id: number, state?: Types.Campaign_User_State_Enum | null, user?: { __typename?: 'users', id: number, user_youtube?: { __typename?: 'users_youtube', id: number, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null } | null, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null } | null }> }>, contents: Array<{ __typename?: 'email_contents', id: number, name?: string | null, template?: string | null, is_base_template: boolean, enabled?: boolean | null, data?: any | null, subject?: string | null }> };

export type UpdateContentMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  set: Types.Email_Contents_Set_Input;
}>;


export type UpdateContentMutation = { __typename?: 'Mutation', update_email_contents_by_pk?: { __typename?: 'email_contents', id: number } | null };

export type InsertCampaignContentMutationVariables = Types.Exact<{
  object: Types.Campaign_Content_Insert_Input;
}>;


export type InsertCampaignContentMutation = { __typename?: 'Mutation', insert_campaign_content_one?: { __typename?: 'campaign_content', id: number } | null };

export type SendMessagePreviewMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  query: Types.MandrillPreviewRequest;
}>;


export type SendMessagePreviewMutation = { __typename?: 'Mutation', sendMandrillPreview: { __typename?: 'MandrillPreviewReturn', error?: string | null } };


export const MessageListDocument = gql`
    query messageList($campaignStub: String!) {
  campaign_contents: campaign_content(
    where: {campaign: {stub: {_eq: $campaignStub}}}
    order_by: {content: {name: asc}}
  ) {
    id
    content_type
    content {
      id
      name
    }
    mandrills {
      id
      status
      opens
      clicks
    }
  }
}
    `;

/**
 * __useMessageListQuery__
 *
 * To run a query within a React component, call `useMessageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageListQuery({
 *   variables: {
 *      campaignStub: // value for 'campaignStub'
 *   },
 * });
 */
export function useMessageListQuery(baseOptions: Apollo.QueryHookOptions<MessageListQuery, MessageListQueryVariables> & ({ variables: MessageListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageListQuery, MessageListQueryVariables>(MessageListDocument, options);
      }
export function useMessageListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageListQuery, MessageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageListQuery, MessageListQueryVariables>(MessageListDocument, options);
        }
export function useMessageListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MessageListQuery, MessageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessageListQuery, MessageListQueryVariables>(MessageListDocument, options);
        }
export type MessageListQueryHookResult = ReturnType<typeof useMessageListQuery>;
export type MessageListLazyQueryHookResult = ReturnType<typeof useMessageListLazyQuery>;
export type MessageListSuspenseQueryHookResult = ReturnType<typeof useMessageListSuspenseQuery>;
export type MessageListQueryResult = Apollo.QueryResult<MessageListQuery, MessageListQueryVariables>;
export const MessageOverridesDocument = gql`
    query messageOverrides($campaignStub: String!) {
  campaigns: campaign(where: {stub: {_eq: $campaignStub}}, limit: 1) {
    id
    message_override
  }
}
    `;

/**
 * __useMessageOverridesQuery__
 *
 * To run a query within a React component, call `useMessageOverridesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageOverridesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageOverridesQuery({
 *   variables: {
 *      campaignStub: // value for 'campaignStub'
 *   },
 * });
 */
export function useMessageOverridesQuery(baseOptions: Apollo.QueryHookOptions<MessageOverridesQuery, MessageOverridesQueryVariables> & ({ variables: MessageOverridesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageOverridesQuery, MessageOverridesQueryVariables>(MessageOverridesDocument, options);
      }
export function useMessageOverridesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageOverridesQuery, MessageOverridesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageOverridesQuery, MessageOverridesQueryVariables>(MessageOverridesDocument, options);
        }
export function useMessageOverridesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MessageOverridesQuery, MessageOverridesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessageOverridesQuery, MessageOverridesQueryVariables>(MessageOverridesDocument, options);
        }
export type MessageOverridesQueryHookResult = ReturnType<typeof useMessageOverridesQuery>;
export type MessageOverridesLazyQueryHookResult = ReturnType<typeof useMessageOverridesLazyQuery>;
export type MessageOverridesSuspenseQueryHookResult = ReturnType<typeof useMessageOverridesSuspenseQuery>;
export type MessageOverridesQueryResult = Apollo.QueryResult<MessageOverridesQuery, MessageOverridesQueryVariables>;
export const SearchContentDocument = gql`
    query searchContent($where: email_contents_bool_exp!) {
  contents: email_contents(where: $where, order_by: {id: desc}, limit: 30) {
    id
    name
  }
}
    `;

/**
 * __useSearchContentQuery__
 *
 * To run a query within a React component, call `useSearchContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSearchContentQuery(baseOptions: Apollo.QueryHookOptions<SearchContentQuery, SearchContentQueryVariables> & ({ variables: SearchContentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchContentQuery, SearchContentQueryVariables>(SearchContentDocument, options);
      }
export function useSearchContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchContentQuery, SearchContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchContentQuery, SearchContentQueryVariables>(SearchContentDocument, options);
        }
export function useSearchContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchContentQuery, SearchContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchContentQuery, SearchContentQueryVariables>(SearchContentDocument, options);
        }
export type SearchContentQueryHookResult = ReturnType<typeof useSearchContentQuery>;
export type SearchContentLazyQueryHookResult = ReturnType<typeof useSearchContentLazyQuery>;
export type SearchContentSuspenseQueryHookResult = ReturnType<typeof useSearchContentSuspenseQuery>;
export type SearchContentQueryResult = Apollo.QueryResult<SearchContentQuery, SearchContentQueryVariables>;
export const ContentListDocument = gql`
    query contentList($campaignStub: String!, $campaignStubLike: String!) {
  email_contents(
    where: {_or: [{is_base_template: {_eq: true}}, {name: {_like: $campaignStubLike}}]}
    order_by: {name: asc}
  ) {
    id
    name
    is_base_template
  }
  campaigns: campaign(where: {stub: {_eq: $campaignStub}}, limit: 1) {
    id
    message_override
  }
}
    `;

/**
 * __useContentListQuery__
 *
 * To run a query within a React component, call `useContentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentListQuery({
 *   variables: {
 *      campaignStub: // value for 'campaignStub'
 *      campaignStubLike: // value for 'campaignStubLike'
 *   },
 * });
 */
export function useContentListQuery(baseOptions: Apollo.QueryHookOptions<ContentListQuery, ContentListQueryVariables> & ({ variables: ContentListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentListQuery, ContentListQueryVariables>(ContentListDocument, options);
      }
export function useContentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentListQuery, ContentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentListQuery, ContentListQueryVariables>(ContentListDocument, options);
        }
export function useContentListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ContentListQuery, ContentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContentListQuery, ContentListQueryVariables>(ContentListDocument, options);
        }
export type ContentListQueryHookResult = ReturnType<typeof useContentListQuery>;
export type ContentListLazyQueryHookResult = ReturnType<typeof useContentListLazyQuery>;
export type ContentListSuspenseQueryHookResult = ReturnType<typeof useContentListSuspenseQuery>;
export type ContentListQueryResult = Apollo.QueryResult<ContentListQuery, ContentListQueryVariables>;
export const EmailStatisticsDocument = gql`
    query emailStatistics($campaignStub: String!, $contentName: String!) {
  campaign_content(
    where: {content: {name: {_eq: $contentName}}, campaign: {stub: {_eq: $campaignStub}}}
  ) {
    id
    content {
      id
      name
    }
    campaign {
      id
      platform
    }
    mandrills(order_by: {last_check_ts: desc}) {
      id
      created_at
      last_check_ts
      status
      reject_reason
      opens
      clicks
      user {
        id
        ...UserInfo
      }
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useEmailStatisticsQuery__
 *
 * To run a query within a React component, call `useEmailStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailStatisticsQuery({
 *   variables: {
 *      campaignStub: // value for 'campaignStub'
 *      contentName: // value for 'contentName'
 *   },
 * });
 */
export function useEmailStatisticsQuery(baseOptions: Apollo.QueryHookOptions<EmailStatisticsQuery, EmailStatisticsQueryVariables> & ({ variables: EmailStatisticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailStatisticsQuery, EmailStatisticsQueryVariables>(EmailStatisticsDocument, options);
      }
export function useEmailStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailStatisticsQuery, EmailStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailStatisticsQuery, EmailStatisticsQueryVariables>(EmailStatisticsDocument, options);
        }
export function useEmailStatisticsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EmailStatisticsQuery, EmailStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmailStatisticsQuery, EmailStatisticsQueryVariables>(EmailStatisticsDocument, options);
        }
export type EmailStatisticsQueryHookResult = ReturnType<typeof useEmailStatisticsQuery>;
export type EmailStatisticsLazyQueryHookResult = ReturnType<typeof useEmailStatisticsLazyQuery>;
export type EmailStatisticsSuspenseQueryHookResult = ReturnType<typeof useEmailStatisticsSuspenseQuery>;
export type EmailStatisticsQueryResult = Apollo.QueryResult<EmailStatisticsQuery, EmailStatisticsQueryVariables>;
export const MandrillTemplatesDocument = gql`
    query mandrillTemplates {
  mandrillTemplates {
    slug
    name
    subject
    labels
  }
}
    `;

/**
 * __useMandrillTemplatesQuery__
 *
 * To run a query within a React component, call `useMandrillTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandrillTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandrillTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMandrillTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<MandrillTemplatesQuery, MandrillTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MandrillTemplatesQuery, MandrillTemplatesQueryVariables>(MandrillTemplatesDocument, options);
      }
export function useMandrillTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MandrillTemplatesQuery, MandrillTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MandrillTemplatesQuery, MandrillTemplatesQueryVariables>(MandrillTemplatesDocument, options);
        }
export function useMandrillTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MandrillTemplatesQuery, MandrillTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MandrillTemplatesQuery, MandrillTemplatesQueryVariables>(MandrillTemplatesDocument, options);
        }
export type MandrillTemplatesQueryHookResult = ReturnType<typeof useMandrillTemplatesQuery>;
export type MandrillTemplatesLazyQueryHookResult = ReturnType<typeof useMandrillTemplatesLazyQuery>;
export type MandrillTemplatesSuspenseQueryHookResult = ReturnType<typeof useMandrillTemplatesSuspenseQuery>;
export type MandrillTemplatesQueryResult = Apollo.QueryResult<MandrillTemplatesQuery, MandrillTemplatesQueryVariables>;
export const MandrillPreviewDocument = gql`
    query mandrillPreview($query: MandrillPreviewRequest!) {
  mandrillPreview(query: $query) {
    html
  }
}
    `;

/**
 * __useMandrillPreviewQuery__
 *
 * To run a query within a React component, call `useMandrillPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandrillPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandrillPreviewQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useMandrillPreviewQuery(baseOptions: Apollo.QueryHookOptions<MandrillPreviewQuery, MandrillPreviewQueryVariables> & ({ variables: MandrillPreviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MandrillPreviewQuery, MandrillPreviewQueryVariables>(MandrillPreviewDocument, options);
      }
export function useMandrillPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MandrillPreviewQuery, MandrillPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MandrillPreviewQuery, MandrillPreviewQueryVariables>(MandrillPreviewDocument, options);
        }
export function useMandrillPreviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MandrillPreviewQuery, MandrillPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MandrillPreviewQuery, MandrillPreviewQueryVariables>(MandrillPreviewDocument, options);
        }
export type MandrillPreviewQueryHookResult = ReturnType<typeof useMandrillPreviewQuery>;
export type MandrillPreviewLazyQueryHookResult = ReturnType<typeof useMandrillPreviewLazyQuery>;
export type MandrillPreviewSuspenseQueryHookResult = ReturnType<typeof useMandrillPreviewSuspenseQuery>;
export type MandrillPreviewQueryResult = Apollo.QueryResult<MandrillPreviewQuery, MandrillPreviewQueryVariables>;
export const MandrillVariablesDocument = gql`
    query mandrillVariables($query: MandrillVariablesRequest!) {
  mandrillVariables(query: $query) {
    key
    value
  }
}
    `;

/**
 * __useMandrillVariablesQuery__
 *
 * To run a query within a React component, call `useMandrillVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandrillVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandrillVariablesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useMandrillVariablesQuery(baseOptions: Apollo.QueryHookOptions<MandrillVariablesQuery, MandrillVariablesQueryVariables> & ({ variables: MandrillVariablesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MandrillVariablesQuery, MandrillVariablesQueryVariables>(MandrillVariablesDocument, options);
      }
export function useMandrillVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MandrillVariablesQuery, MandrillVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MandrillVariablesQuery, MandrillVariablesQueryVariables>(MandrillVariablesDocument, options);
        }
export function useMandrillVariablesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MandrillVariablesQuery, MandrillVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MandrillVariablesQuery, MandrillVariablesQueryVariables>(MandrillVariablesDocument, options);
        }
export type MandrillVariablesQueryHookResult = ReturnType<typeof useMandrillVariablesQuery>;
export type MandrillVariablesLazyQueryHookResult = ReturnType<typeof useMandrillVariablesLazyQuery>;
export type MandrillVariablesSuspenseQueryHookResult = ReturnType<typeof useMandrillVariablesSuspenseQuery>;
export type MandrillVariablesQueryResult = Apollo.QueryResult<MandrillVariablesQuery, MandrillVariablesQueryVariables>;
export const MessageContentDocument = gql`
    query messageContent($campaignStub: String!, $contentName: String!) {
  campaigns: campaign(where: {stub: {_eq: $campaignStub}}, limit: 1) {
    id
    title
    stub
    campaign_users {
      id
      state
      user {
        ...UserInfo
      }
    }
  }
  contents: email_contents(where: {name: {_eq: $contentName}}, limit: 1) {
    id
    name
    template
    is_base_template
    enabled
    data
    subject
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useMessageContentQuery__
 *
 * To run a query within a React component, call `useMessageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageContentQuery({
 *   variables: {
 *      campaignStub: // value for 'campaignStub'
 *      contentName: // value for 'contentName'
 *   },
 * });
 */
export function useMessageContentQuery(baseOptions: Apollo.QueryHookOptions<MessageContentQuery, MessageContentQueryVariables> & ({ variables: MessageContentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageContentQuery, MessageContentQueryVariables>(MessageContentDocument, options);
      }
export function useMessageContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageContentQuery, MessageContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageContentQuery, MessageContentQueryVariables>(MessageContentDocument, options);
        }
export function useMessageContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MessageContentQuery, MessageContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessageContentQuery, MessageContentQueryVariables>(MessageContentDocument, options);
        }
export type MessageContentQueryHookResult = ReturnType<typeof useMessageContentQuery>;
export type MessageContentLazyQueryHookResult = ReturnType<typeof useMessageContentLazyQuery>;
export type MessageContentSuspenseQueryHookResult = ReturnType<typeof useMessageContentSuspenseQuery>;
export type MessageContentQueryResult = Apollo.QueryResult<MessageContentQuery, MessageContentQueryVariables>;
export const UpdateContentDocument = gql`
    mutation updateContent($id: Int!, $set: email_contents_set_input!) {
  update_email_contents_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;
export type UpdateContentMutationFn = Apollo.MutationFunction<UpdateContentMutation, UpdateContentMutationVariables>;

/**
 * __useUpdateContentMutation__
 *
 * To run a mutation, you first call `useUpdateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentMutation, { data, loading, error }] = useUpdateContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContentMutation, UpdateContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContentMutation, UpdateContentMutationVariables>(UpdateContentDocument, options);
      }
export type UpdateContentMutationHookResult = ReturnType<typeof useUpdateContentMutation>;
export type UpdateContentMutationResult = Apollo.MutationResult<UpdateContentMutation>;
export type UpdateContentMutationOptions = Apollo.BaseMutationOptions<UpdateContentMutation, UpdateContentMutationVariables>;
export const InsertCampaignContentDocument = gql`
    mutation insertCampaignContent($object: campaign_content_insert_input!) {
  insert_campaign_content_one(object: $object) {
    id
  }
}
    `;
export type InsertCampaignContentMutationFn = Apollo.MutationFunction<InsertCampaignContentMutation, InsertCampaignContentMutationVariables>;

/**
 * __useInsertCampaignContentMutation__
 *
 * To run a mutation, you first call `useInsertCampaignContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCampaignContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCampaignContentMutation, { data, loading, error }] = useInsertCampaignContentMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertCampaignContentMutation(baseOptions?: Apollo.MutationHookOptions<InsertCampaignContentMutation, InsertCampaignContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCampaignContentMutation, InsertCampaignContentMutationVariables>(InsertCampaignContentDocument, options);
      }
export type InsertCampaignContentMutationHookResult = ReturnType<typeof useInsertCampaignContentMutation>;
export type InsertCampaignContentMutationResult = Apollo.MutationResult<InsertCampaignContentMutation>;
export type InsertCampaignContentMutationOptions = Apollo.BaseMutationOptions<InsertCampaignContentMutation, InsertCampaignContentMutationVariables>;
export const SendMessagePreviewDocument = gql`
    mutation sendMessagePreview($email: String!, $query: MandrillPreviewRequest!) {
  sendMandrillPreview(email: $email, query: $query) {
    error
  }
}
    `;
export type SendMessagePreviewMutationFn = Apollo.MutationFunction<SendMessagePreviewMutation, SendMessagePreviewMutationVariables>;

/**
 * __useSendMessagePreviewMutation__
 *
 * To run a mutation, you first call `useSendMessagePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessagePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessagePreviewMutation, { data, loading, error }] = useSendMessagePreviewMutation({
 *   variables: {
 *      email: // value for 'email'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSendMessagePreviewMutation(baseOptions?: Apollo.MutationHookOptions<SendMessagePreviewMutation, SendMessagePreviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessagePreviewMutation, SendMessagePreviewMutationVariables>(SendMessagePreviewDocument, options);
      }
export type SendMessagePreviewMutationHookResult = ReturnType<typeof useSendMessagePreviewMutation>;
export type SendMessagePreviewMutationResult = Apollo.MutationResult<SendMessagePreviewMutation>;
export type SendMessagePreviewMutationOptions = Apollo.BaseMutationOptions<SendMessagePreviewMutation, SendMessagePreviewMutationVariables>;