import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AiConversationsQueryVariables = Types.Exact<{
  accountId: Types.Scalars['Int']['input'];
}>;


export type AiConversationsQuery = { __typename?: 'Query', ai_conversations: Array<{ __typename?: 'ai_conversations', id: number, created_at: string, ai_messages: Array<{ __typename?: 'ai_messages', id: number, content: string }> }> };

export type AiConversationWithMessagesQueryVariables = Types.Exact<{
  conversationId: Types.Scalars['Int']['input'];
}>;


export type AiConversationWithMessagesQuery = { __typename?: 'Query', ai_conversations_by_pk?: { __typename?: 'ai_conversations', id: number, generating: boolean, ai_messages: Array<{ __typename?: 'ai_messages', id: number, role: string, content: string }> } | null };

export type CreateNewAiConversationMutationVariables = Types.Exact<{
  accountId: Types.Scalars['Int']['input'];
  userQuestion: Types.Scalars['String']['input'];
}>;


export type CreateNewAiConversationMutation = { __typename?: 'Mutation', insert_ai_conversations_one?: { __typename?: 'ai_conversations', id: number } | null };

export type InsertAiMessageMutationVariables = Types.Exact<{
  conversationId: Types.Scalars['Int']['input'];
  content: Types.Scalars['String']['input'];
}>;


export type InsertAiMessageMutation = { __typename?: 'Mutation', insert_ai_messages_one?: { __typename?: 'ai_messages', id: number } | null };

export type StartAiMutationVariables = Types.Exact<{
  conversationId: Types.Scalars['Int']['input'];
  temperature?: Types.InputMaybe<Types.Scalars['Float']['input']>;
}>;


export type StartAiMutation = { __typename?: 'Mutation', startAI?: void | null };

export type AbortAiMutationVariables = Types.Exact<{
  conversationId: Types.Scalars['Int']['input'];
}>;


export type AbortAiMutation = { __typename?: 'Mutation', abortAI?: void | null };


export const AiConversationsDocument = gql`
    query aiConversations($accountId: Int!) {
  ai_conversations(
    where: {account_id: {_eq: $accountId}}
    order_by: {created_at: desc}
    limit: 100
  ) {
    id
    created_at
    ai_messages(order_by: {created_at: asc}, limit: 1) {
      id
      content
    }
  }
}
    `;

/**
 * __useAiConversationsQuery__
 *
 * To run a query within a React component, call `useAiConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiConversationsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAiConversationsQuery(baseOptions: Apollo.QueryHookOptions<AiConversationsQuery, AiConversationsQueryVariables> & ({ variables: AiConversationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiConversationsQuery, AiConversationsQueryVariables>(AiConversationsDocument, options);
      }
export function useAiConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiConversationsQuery, AiConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiConversationsQuery, AiConversationsQueryVariables>(AiConversationsDocument, options);
        }
export function useAiConversationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AiConversationsQuery, AiConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AiConversationsQuery, AiConversationsQueryVariables>(AiConversationsDocument, options);
        }
export type AiConversationsQueryHookResult = ReturnType<typeof useAiConversationsQuery>;
export type AiConversationsLazyQueryHookResult = ReturnType<typeof useAiConversationsLazyQuery>;
export type AiConversationsSuspenseQueryHookResult = ReturnType<typeof useAiConversationsSuspenseQuery>;
export type AiConversationsQueryResult = Apollo.QueryResult<AiConversationsQuery, AiConversationsQueryVariables>;
export const AiConversationWithMessagesDocument = gql`
    query aiConversationWithMessages($conversationId: Int!) {
  ai_conversations_by_pk(id: $conversationId) {
    id
    generating
    ai_messages(order_by: {created_at: asc}) {
      id
      role
      content
    }
  }
}
    `;

/**
 * __useAiConversationWithMessagesQuery__
 *
 * To run a query within a React component, call `useAiConversationWithMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiConversationWithMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiConversationWithMessagesQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useAiConversationWithMessagesQuery(baseOptions: Apollo.QueryHookOptions<AiConversationWithMessagesQuery, AiConversationWithMessagesQueryVariables> & ({ variables: AiConversationWithMessagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiConversationWithMessagesQuery, AiConversationWithMessagesQueryVariables>(AiConversationWithMessagesDocument, options);
      }
export function useAiConversationWithMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiConversationWithMessagesQuery, AiConversationWithMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiConversationWithMessagesQuery, AiConversationWithMessagesQueryVariables>(AiConversationWithMessagesDocument, options);
        }
export function useAiConversationWithMessagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AiConversationWithMessagesQuery, AiConversationWithMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AiConversationWithMessagesQuery, AiConversationWithMessagesQueryVariables>(AiConversationWithMessagesDocument, options);
        }
export type AiConversationWithMessagesQueryHookResult = ReturnType<typeof useAiConversationWithMessagesQuery>;
export type AiConversationWithMessagesLazyQueryHookResult = ReturnType<typeof useAiConversationWithMessagesLazyQuery>;
export type AiConversationWithMessagesSuspenseQueryHookResult = ReturnType<typeof useAiConversationWithMessagesSuspenseQuery>;
export type AiConversationWithMessagesQueryResult = Apollo.QueryResult<AiConversationWithMessagesQuery, AiConversationWithMessagesQueryVariables>;
export const CreateNewAiConversationDocument = gql`
    mutation createNewAiConversation($accountId: Int!, $userQuestion: String!) {
  insert_ai_conversations_one(
    object: {account_id: $accountId, ai_messages: {data: {role: "user", content: $userQuestion}}}
  ) {
    id
  }
}
    `;
export type CreateNewAiConversationMutationFn = Apollo.MutationFunction<CreateNewAiConversationMutation, CreateNewAiConversationMutationVariables>;

/**
 * __useCreateNewAiConversationMutation__
 *
 * To run a mutation, you first call `useCreateNewAiConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewAiConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewAiConversationMutation, { data, loading, error }] = useCreateNewAiConversationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      userQuestion: // value for 'userQuestion'
 *   },
 * });
 */
export function useCreateNewAiConversationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewAiConversationMutation, CreateNewAiConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewAiConversationMutation, CreateNewAiConversationMutationVariables>(CreateNewAiConversationDocument, options);
      }
export type CreateNewAiConversationMutationHookResult = ReturnType<typeof useCreateNewAiConversationMutation>;
export type CreateNewAiConversationMutationResult = Apollo.MutationResult<CreateNewAiConversationMutation>;
export type CreateNewAiConversationMutationOptions = Apollo.BaseMutationOptions<CreateNewAiConversationMutation, CreateNewAiConversationMutationVariables>;
export const InsertAiMessageDocument = gql`
    mutation insertAiMessage($conversationId: Int!, $content: String!) {
  insert_ai_messages_one(
    object: {conversation_id: $conversationId, role: "user", content: $content}
  ) {
    id
  }
}
    `;
export type InsertAiMessageMutationFn = Apollo.MutationFunction<InsertAiMessageMutation, InsertAiMessageMutationVariables>;

/**
 * __useInsertAiMessageMutation__
 *
 * To run a mutation, you first call `useInsertAiMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAiMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAiMessageMutation, { data, loading, error }] = useInsertAiMessageMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useInsertAiMessageMutation(baseOptions?: Apollo.MutationHookOptions<InsertAiMessageMutation, InsertAiMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertAiMessageMutation, InsertAiMessageMutationVariables>(InsertAiMessageDocument, options);
      }
export type InsertAiMessageMutationHookResult = ReturnType<typeof useInsertAiMessageMutation>;
export type InsertAiMessageMutationResult = Apollo.MutationResult<InsertAiMessageMutation>;
export type InsertAiMessageMutationOptions = Apollo.BaseMutationOptions<InsertAiMessageMutation, InsertAiMessageMutationVariables>;
export const StartAiDocument = gql`
    mutation startAi($conversationId: Int!, $temperature: Float) {
  startAI(conversationId: $conversationId, temperature: $temperature)
}
    `;
export type StartAiMutationFn = Apollo.MutationFunction<StartAiMutation, StartAiMutationVariables>;

/**
 * __useStartAiMutation__
 *
 * To run a mutation, you first call `useStartAiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartAiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startAiMutation, { data, loading, error }] = useStartAiMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      temperature: // value for 'temperature'
 *   },
 * });
 */
export function useStartAiMutation(baseOptions?: Apollo.MutationHookOptions<StartAiMutation, StartAiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartAiMutation, StartAiMutationVariables>(StartAiDocument, options);
      }
export type StartAiMutationHookResult = ReturnType<typeof useStartAiMutation>;
export type StartAiMutationResult = Apollo.MutationResult<StartAiMutation>;
export type StartAiMutationOptions = Apollo.BaseMutationOptions<StartAiMutation, StartAiMutationVariables>;
export const AbortAiDocument = gql`
    mutation abortAi($conversationId: Int!) {
  abortAI(conversationId: $conversationId)
}
    `;
export type AbortAiMutationFn = Apollo.MutationFunction<AbortAiMutation, AbortAiMutationVariables>;

/**
 * __useAbortAiMutation__
 *
 * To run a mutation, you first call `useAbortAiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAbortAiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [abortAiMutation, { data, loading, error }] = useAbortAiMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useAbortAiMutation(baseOptions?: Apollo.MutationHookOptions<AbortAiMutation, AbortAiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AbortAiMutation, AbortAiMutationVariables>(AbortAiDocument, options);
      }
export type AbortAiMutationHookResult = ReturnType<typeof useAbortAiMutation>;
export type AbortAiMutationResult = Apollo.MutationResult<AbortAiMutation>;
export type AbortAiMutationOptions = Apollo.BaseMutationOptions<AbortAiMutation, AbortAiMutationVariables>;