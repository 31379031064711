import { type FC, useCallback, useState } from "react";

import styled from "@emotion/styled";
import type { Editor } from "@tiptap/core";
import { EditorProvider } from "@tiptap/react";

import { TextFieldEditor } from "./TextFieldEditor";
import { Toolbar } from "./Toolbar";
import { extensions } from "./extensions";
import { normalizeHTML } from "./normalizeHTML";

export const RichTextEditor: FC<{ initialValue: string; onChange: (html: string) => void }> = ({
  initialValue,
  onChange
}) => {
  const [showHTMLEditor, setShowHTMLEditor] = useState(false);
  const [value, setValue] = useState(() => normalizeHTML(initialValue));

  const handleHTMLUpdate = useCallback(
    (html: string) => {
      setValue(html);
      onChange(html);
    },
    [onChange]
  );

  const handleRichTextUpdate = useCallback(
    ({ editor }: { editor: Editor }) => {
      const html = editor.getHTML();
      setValue(html);
      onChange(html);
    },
    [onChange]
  );

  return (
    <Root>
      {showHTMLEditor ? (
        <TextFieldEditor
          value={value}
          onChange={handleHTMLUpdate}
          onRichText={() => setShowHTMLEditor(false)}
        />
      ) : (
        <EditorProvider
          extensions={extensions}
          content={value}
          slotBefore={<Toolbar onShowHTML={() => setShowHTMLEditor(true)} />}
          onUpdate={handleRichTextUpdate}
        >
          {/* having at least one child component is mandatory */}{" "}
        </EditorProvider>
      )}
    </Root>
  );
};

const Root = styled.div`
  border: 1px solid #aaa;
  padding: 10px 20px;
  margin: 10px 0;

  .ProseMirror {
    outline: none;
  }
`;
