import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinanceQueryVariables = Types.Exact<{
  showArchived: Types.Scalars['Boolean']['input'];
}>;


export type FinanceQuery = { __typename?: 'Query', clients: Array<{ __typename?: 'clients', name?: string | null, hubspot_company_id: string, username?: string | null, projects: Array<{ __typename?: 'project', id: number, name: string, stub: string, archived?: boolean | null, project_owner_id?: number | null, account?: { __typename?: 'accounts', id: number, full_name?: string | null } | null, campaigns: Array<{ __typename?: 'campaign', id: number, exchange_rate?: number | null, campaign_participants_aggregate: { __typename?: 'campaign_user_aggregate', aggregate?: { __typename?: 'campaign_user_aggregate_fields', sum?: { __typename?: 'campaign_user_sum_fields', local_invoice_pay?: number | null } | null } | null } }>, project_invoicing_detail?: { __typename?: 'project_invoicing_detail', id: number, total_amount?: number | null, exchange_rate: number, currency: string, additional_info?: string | null } | null, project_budgets: Array<{ __typename?: 'project_budget', id: number, revenue_allocation: number, target_margin: number, type: string }>, project_invoices: Array<{ __typename?: 'project_invoice', id: number, name?: string | null, amount?: number | null, accounted_at?: string | null, invoiced?: boolean | null, invoice_by_date?: string | null, invoice_by_condition?: string | null, invoice_upon: string }> }> }> };


export const FinanceDocument = gql`
    query finance($showArchived: Boolean!) {
  clients(
    where: {projects: {id: {_is_null: false}, _or: [{archived: {_in: [false, $showArchived]}}, {project_invoices: {invoiced: {_eq: false}}}]}}
    order_by: {name: asc}
  ) {
    name
    hubspot_company_id
    username
    projects {
      id
      name
      stub
      archived
      project_owner_id
      account {
        id
        full_name
      }
      campaigns {
        id
        exchange_rate
        campaign_participants_aggregate: campaign_users_aggregate(
          where: {state: {_in: ["approved", "confirmed"]}}
        ) {
          aggregate {
            sum {
              local_invoice_pay
            }
          }
        }
      }
      project_invoicing_detail {
        id
        total_amount
        exchange_rate
        currency
        additional_info
      }
      project_budgets {
        id
        revenue_allocation
        target_margin
        type
      }
      project_invoices(order_by: {invoice_by_date: asc}) {
        id
        name
        amount
        accounted_at
        invoiced
        invoice_by_date
        invoice_by_condition
        invoice_upon
      }
    }
  }
}
    `;

/**
 * __useFinanceQuery__
 *
 * To run a query within a React component, call `useFinanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceQuery({
 *   variables: {
 *      showArchived: // value for 'showArchived'
 *   },
 * });
 */
export function useFinanceQuery(baseOptions: Apollo.QueryHookOptions<FinanceQuery, FinanceQueryVariables> & ({ variables: FinanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinanceQuery, FinanceQueryVariables>(FinanceDocument, options);
      }
export function useFinanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinanceQuery, FinanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinanceQuery, FinanceQueryVariables>(FinanceDocument, options);
        }
export function useFinanceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FinanceQuery, FinanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FinanceQuery, FinanceQueryVariables>(FinanceDocument, options);
        }
export type FinanceQueryHookResult = ReturnType<typeof useFinanceQuery>;
export type FinanceLazyQueryHookResult = ReturnType<typeof useFinanceLazyQuery>;
export type FinanceSuspenseQueryHookResult = ReturnType<typeof useFinanceSuspenseQuery>;
export type FinanceQueryResult = Apollo.QueryResult<FinanceQuery, FinanceQueryVariables>;