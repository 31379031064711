import styled from "@emotion/styled";

import { LinearProgress } from "components/ui/LinearProgress";

interface ContentApprovalProgress {
  totalContentCount: number;
  participantsCount: number;
  approvedParticipantsCount: number;
  submittedContentCount: number;
  approvedContentCount: number;
}

export const ContentApprovalProgress: React.FC<ContentApprovalProgress> = ({
  approvedContentCount,
  submittedContentCount,
  approvedParticipantsCount,
  participantsCount,
  totalContentCount
}) => {
  return (
    <StatsContainer>
      <LinearProgress
        label="Participants approved"
        value={approvedParticipantsCount}
        max={participantsCount}
      />
      <LinearProgress
        label="Content submitted"
        value={submittedContentCount}
        max={totalContentCount}
      />
      <LinearProgress
        label="Content approved"
        value={approvedContentCount}
        max={totalContentCount}
      />
    </StatsContainer>
  );
};

const StatsContainer = styled.div`
  display: grid;
  gap: 2%;
  grid-template-columns: 32% 32% 32%;
`;
