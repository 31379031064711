// wraps text lines with <p> to enable ProseMirror formatting with headings and paragraphs
export const normalizeHTML = (str: string) => {
  const separator = "---NEW-PARAGRAPH---";
  const parts = str
    .replace(/\r\n/g, "\n")
    .replace(/(\s*\n\s*){2,}/g, separator) // multiple empty lines means new paragraph
    .replace(/\n/g, "<br />")
    .split(separator);

  const newParts: string[] = [];
  for (const part of parts) {
    const s = part.trim();

    if (s.startsWith("<p") || s.startsWith("<h")) {
      // these elements don't need wrapping
      newParts.push(s);
    } else if (s.length !== 0) {
      newParts.push(`<p>${s}</p>`);
    }
  }
  const newHTML = newParts.join("");
  return newHTML;
};
