import type { FC } from "react";

import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";

import { ContentEditor } from "./ContentEditor";
import { useMandrillTemplatesQuery, useMessageContentQuery } from "./generated";

export const Content: FC = () => {
  const { campaignStub, contentName } = useParams<{ campaignStub: string; contentName: string }>();

  const { data, loading: loadingContent } = useMessageContentQuery({
    variables: { campaignStub: campaignStub ?? "", contentName: contentName ?? "" },
    skip: !campaignStub || !contentName
  });

  // slow query, it's better to keep it separated
  const {
    data: { mandrillTemplates } = {},
    loading: loadingTemplates
  } = useMandrillTemplatesQuery();

  if (loadingContent || loadingTemplates) return <Loader />;

  return (
    <div>
      <h2>Message settings</h2>
      <br />
      {!data?.contents.length && <p>Cannot find content</p>}
      {mandrillTemplates && data?.contents.length ? (
        <ContentEditor
          templates={mandrillTemplates ?? []}
          campaign={data.campaigns?.[0]}
          content={data.contents?.[0]}
        />
      ) : null}
    </div>
  );
};
