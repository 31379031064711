import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReelQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  campaignStub: Types.Scalars['String']['input'];
}>;


export type ReelQuery = { __typename?: 'Query', campaign_reels_by_pk?: { __typename?: 'campaign_reels', id: number, clips_replays_count?: number | null, comments?: number | null, all_plays_count?: number | null, avg_watch_time?: number | null, video_view_total_time?: number | null, likes?: number | null, plays?: number | null, actual_reach?: number | null, saved?: number | null, shares?: number | null, total_interactions?: number | null, impressions_legacy?: number | null, image_url?: string | null, approved_at?: string | null, approved?: boolean | null, internal_url?: string | null, track_url?: string | null, link_clicks?: number | null, user?: { __typename?: 'users', id: number, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, campaign_users: Array<{ __typename?: 'campaign_user', id: number, tracking?: any | null }> } | null } | null };

export type CampaignPostQueryVariables = Types.Exact<{
  postId: Types.Scalars['Int']['input'];
  campaignStub: Types.Scalars['String']['input'];
}>;


export type CampaignPostQuery = { __typename?: 'Query', campaign_posts_by_pk?: { __typename?: 'campaign_posts', id: number, approved?: boolean | null, approved_at?: string | null, shortcode: string, internal_url?: string | null, image_url?: string | null, media_type?: Types.Media_Type_Enum | null, assets?: any | null, track_url?: string | null, campaign_id?: number | null, actual_reach?: number | null, impressions?: number | null, saved?: number | null, video_views?: number | null, user?: { __typename?: 'users', id: number, campaign_users: Array<{ __typename?: 'campaign_user', id: number, tracking?: any | null }>, user_instagram?: { __typename?: 'users_instagram', id: number, username: string } | null } | null } | null };

export type UpdateCampaignPostMutationVariables = Types.Exact<{
  _set?: Types.InputMaybe<Types.Campaign_Posts_Set_Input>;
  postId: Types.Scalars['Int']['input'];
}>;


export type UpdateCampaignPostMutation = { __typename?: 'Mutation', update_campaign_posts_by_pk?: { __typename?: 'campaign_posts', id: number, track_url?: string | null } | null };


export const ReelDocument = gql`
    query reel($id: Int!, $campaignStub: String!) {
  campaign_reels_by_pk(id: $id) {
    id
    clips_replays_count
    comments
    all_plays_count
    avg_watch_time
    video_view_total_time
    likes
    plays
    actual_reach
    saved
    shares
    total_interactions
    impressions_legacy
    image_url
    approved_at
    approved
    internal_url
    track_url
    link_clicks
    user {
      id
      user_instagram {
        id
        username
        followed_by
      }
      campaign_users(where: {campaign: {stub: {_eq: $campaignStub}}}) {
        id
        tracking
      }
    }
  }
}
    `;

/**
 * __useReelQuery__
 *
 * To run a query within a React component, call `useReelQuery` and pass it any options that fit your needs.
 * When your component renders, `useReelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReelQuery({
 *   variables: {
 *      id: // value for 'id'
 *      campaignStub: // value for 'campaignStub'
 *   },
 * });
 */
export function useReelQuery(baseOptions: Apollo.QueryHookOptions<ReelQuery, ReelQueryVariables> & ({ variables: ReelQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReelQuery, ReelQueryVariables>(ReelDocument, options);
      }
export function useReelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReelQuery, ReelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReelQuery, ReelQueryVariables>(ReelDocument, options);
        }
export function useReelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReelQuery, ReelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReelQuery, ReelQueryVariables>(ReelDocument, options);
        }
export type ReelQueryHookResult = ReturnType<typeof useReelQuery>;
export type ReelLazyQueryHookResult = ReturnType<typeof useReelLazyQuery>;
export type ReelSuspenseQueryHookResult = ReturnType<typeof useReelSuspenseQuery>;
export type ReelQueryResult = Apollo.QueryResult<ReelQuery, ReelQueryVariables>;
export const CampaignPostDocument = gql`
    query campaignPost($postId: Int!, $campaignStub: String!) {
  campaign_posts_by_pk(id: $postId) {
    id
    approved
    approved_at
    shortcode
    internal_url
    image_url
    media_type
    assets
    internal_url
    track_url
    campaign_id
    actual_reach
    impressions
    actual_reach
    saved
    video_views
    user {
      id
      campaign_users(where: {campaign: {stub: {_eq: $campaignStub}}}) {
        id
        tracking
      }
      user_instagram {
        id
        username
      }
    }
  }
}
    `;

/**
 * __useCampaignPostQuery__
 *
 * To run a query within a React component, call `useCampaignPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignPostQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *      campaignStub: // value for 'campaignStub'
 *   },
 * });
 */
export function useCampaignPostQuery(baseOptions: Apollo.QueryHookOptions<CampaignPostQuery, CampaignPostQueryVariables> & ({ variables: CampaignPostQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignPostQuery, CampaignPostQueryVariables>(CampaignPostDocument, options);
      }
export function useCampaignPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignPostQuery, CampaignPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignPostQuery, CampaignPostQueryVariables>(CampaignPostDocument, options);
        }
export function useCampaignPostSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CampaignPostQuery, CampaignPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CampaignPostQuery, CampaignPostQueryVariables>(CampaignPostDocument, options);
        }
export type CampaignPostQueryHookResult = ReturnType<typeof useCampaignPostQuery>;
export type CampaignPostLazyQueryHookResult = ReturnType<typeof useCampaignPostLazyQuery>;
export type CampaignPostSuspenseQueryHookResult = ReturnType<typeof useCampaignPostSuspenseQuery>;
export type CampaignPostQueryResult = Apollo.QueryResult<CampaignPostQuery, CampaignPostQueryVariables>;
export const UpdateCampaignPostDocument = gql`
    mutation updateCampaignPost($_set: campaign_posts_set_input, $postId: Int!) {
  update_campaign_posts_by_pk(_set: $_set, pk_columns: {id: $postId}) {
    id
    track_url
  }
}
    `;
export type UpdateCampaignPostMutationFn = Apollo.MutationFunction<UpdateCampaignPostMutation, UpdateCampaignPostMutationVariables>;

/**
 * __useUpdateCampaignPostMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignPostMutation, { data, loading, error }] = useUpdateCampaignPostMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useUpdateCampaignPostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignPostMutation, UpdateCampaignPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignPostMutation, UpdateCampaignPostMutationVariables>(UpdateCampaignPostDocument, options);
      }
export type UpdateCampaignPostMutationHookResult = ReturnType<typeof useUpdateCampaignPostMutation>;
export type UpdateCampaignPostMutationResult = Apollo.MutationResult<UpdateCampaignPostMutation>;
export type UpdateCampaignPostMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignPostMutation, UpdateCampaignPostMutationVariables>;