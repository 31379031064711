import { useSnackbar } from "@relatable/ui/Snackbar";

import { useSendSlackNotificationMutation } from "./generated";

export const useSlack = () => {
  const snackbar = useSnackbar();

  const [sendSlackNotification, { loading }] = useSendSlackNotificationMutation({
    onCompleted(data) {
      if (data.sendSlackNotification?.error) {
        snackbar.error(
          `We didn't send slack notification, reason: ${data.sendSlackNotification?.error}`
        );
      }
    },
    onError: error => {
      snackbar.error(`We didn't send slack notification, reason: ${error.message}`);
    }
  });

  return { sendSlackNotification, loading };
};
