import { type FC, useEffect, useState } from "react";

import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { useDebounce } from "@relatable/ui/hooks/useDebounce";

import { useUpdateCampaignUserMutation } from "../generated";

export const CommentsCell: FC<{ value: string; campaignUserId: number }> = ({
  campaignUserId,
  value
}) => {
  const snackbar = useSnackbar();
  const [inputValue, setInputValue] = useState(value);
  const [updateCampaignUser] = useUpdateCampaignUserMutation({
    onCompleted: () => snackbar.success("User updated")
  });

  const debouncedValue = useDebounce(inputValue);

  useEffect(() => {
    if (value !== debouncedValue) {
      updateCampaignUser({ variables: { campaignUserId, _set: { notes: debouncedValue } } });
    }
  }, [debouncedValue, updateCampaignUser, campaignUserId, value]);

  return (
    <TextInput
      style={{ width: "100%" }}
      placeholder="Enter comment"
      onChange={setInputValue}
      value={inputValue}
    />
  );
};
