import { useState } from "react";

import { TextInput } from "@relatable/ui/TextInput";

import { Popup } from "components/ui/Popup";
import { useUpdateCampaignUserMutation } from "modules/campaign/generated";

import { PaymentNotesDocument, usePaymentNotesQuery } from "./generated";

export const EditNotesPopup: React.FC<{
  campaignUserId: number;
  onClose(): void;
}> = ({ campaignUserId, onClose }) => {
  const [notes, setNotes] = useState("");

  const { loading } = usePaymentNotesQuery({
    variables: { campaignUserId },
    onCompleted(data) {
      setNotes(data.campaign_user_by_pk?.notes ?? "");
    }
  });

  const [updateCampaignUser, { loading: updatingUser }] = useUpdateCampaignUserMutation({
    refetchQueries: [PaymentNotesDocument]
  });

  const handleSubmit = async () => {
    await updateCampaignUser({
      variables: {
        campaignUserId,
        _set: {
          notes
        }
      }
    });
    onClose();
  };

  return (
    <Popup
      title="Edit payment notes"
      onClose={() => onClose()}
      maxWidth="lg"
      allowOverflow={false}
      onAction={handleSubmit}
      disabledAction={loading || updatingUser}
      actionLabel="Save"
    >
      <TextInput label="Notes" value={notes} onChange={v => setNotes(v)} />
    </Popup>
  );
};
