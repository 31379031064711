import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectTimeRecordsQueryVariables = Types.Exact<{
  dateMin: Types.Scalars['date']['input'];
  dateMax: Types.Scalars['date']['input'];
}>;


export type ProjectTimeRecordsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'project', id: number, name: string, stub: string, time_tracking: Array<{ __typename?: 'time_tracking', id: number, hours: number, project_id: number, account_id: number }> }>, accounts: Array<{ __typename?: 'accounts', id: number, first_name?: string | null, last_name?: string | null }> };

export type AccountHoursLoggedQueryVariables = Types.Exact<{
  dateMin: Types.Scalars['date']['input'];
  dateMax: Types.Scalars['date']['input'];
}>;


export type AccountHoursLoggedQuery = { __typename?: 'Query', accounts: Array<{ __typename?: 'accounts', id: number, first_name?: string | null, last_name?: string | null, time_tracking: Array<{ __typename?: 'time_tracking', id: number, hours: number, project_id: number }> }>, projects: Array<{ __typename?: 'project', id: number, name: string, stub: string }> };


export const ProjectTimeRecordsDocument = gql`
    query projectTimeRecords($dateMin: date!, $dateMax: date!) {
  projects: project(
    where: {time_tracking: {date: {_gte: $dateMin, _lte: $dateMax}, hours: {_gt: 0}}}
  ) {
    id
    name
    stub
    time_tracking(where: {date: {_gte: $dateMin, _lte: $dateMax}, hours: {_gt: 0}}) {
      id
      hours
      project_id
      account_id
    }
  }
  accounts(
    where: {time_tracking: {date: {_gte: $dateMin, _lte: $dateMax}, hours: {_gt: 0}}}
  ) {
    id
    first_name
    last_name
  }
}
    `;

/**
 * __useProjectTimeRecordsQuery__
 *
 * To run a query within a React component, call `useProjectTimeRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimeRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimeRecordsQuery({
 *   variables: {
 *      dateMin: // value for 'dateMin'
 *      dateMax: // value for 'dateMax'
 *   },
 * });
 */
export function useProjectTimeRecordsQuery(baseOptions: Apollo.QueryHookOptions<ProjectTimeRecordsQuery, ProjectTimeRecordsQueryVariables> & ({ variables: ProjectTimeRecordsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectTimeRecordsQuery, ProjectTimeRecordsQueryVariables>(ProjectTimeRecordsDocument, options);
      }
export function useProjectTimeRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectTimeRecordsQuery, ProjectTimeRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectTimeRecordsQuery, ProjectTimeRecordsQueryVariables>(ProjectTimeRecordsDocument, options);
        }
export function useProjectTimeRecordsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectTimeRecordsQuery, ProjectTimeRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectTimeRecordsQuery, ProjectTimeRecordsQueryVariables>(ProjectTimeRecordsDocument, options);
        }
export type ProjectTimeRecordsQueryHookResult = ReturnType<typeof useProjectTimeRecordsQuery>;
export type ProjectTimeRecordsLazyQueryHookResult = ReturnType<typeof useProjectTimeRecordsLazyQuery>;
export type ProjectTimeRecordsSuspenseQueryHookResult = ReturnType<typeof useProjectTimeRecordsSuspenseQuery>;
export type ProjectTimeRecordsQueryResult = Apollo.QueryResult<ProjectTimeRecordsQuery, ProjectTimeRecordsQueryVariables>;
export const AccountHoursLoggedDocument = gql`
    query accountHoursLogged($dateMin: date!, $dateMax: date!) {
  accounts(
    where: {time_tracking: {date: {_gte: $dateMin, _lte: $dateMax}, hours: {_gt: 0}}}
  ) {
    id
    first_name
    last_name
    time_tracking(where: {date: {_gte: $dateMin, _lte: $dateMax}, hours: {_gt: 0}}) {
      id
      hours
      project_id
    }
  }
  projects: project(
    where: {time_tracking: {date: {_gte: $dateMin, _lte: $dateMax}, hours: {_gt: 0}}}
  ) {
    id
    name
    stub
  }
}
    `;

/**
 * __useAccountHoursLoggedQuery__
 *
 * To run a query within a React component, call `useAccountHoursLoggedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountHoursLoggedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountHoursLoggedQuery({
 *   variables: {
 *      dateMin: // value for 'dateMin'
 *      dateMax: // value for 'dateMax'
 *   },
 * });
 */
export function useAccountHoursLoggedQuery(baseOptions: Apollo.QueryHookOptions<AccountHoursLoggedQuery, AccountHoursLoggedQueryVariables> & ({ variables: AccountHoursLoggedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountHoursLoggedQuery, AccountHoursLoggedQueryVariables>(AccountHoursLoggedDocument, options);
      }
export function useAccountHoursLoggedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountHoursLoggedQuery, AccountHoursLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountHoursLoggedQuery, AccountHoursLoggedQueryVariables>(AccountHoursLoggedDocument, options);
        }
export function useAccountHoursLoggedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountHoursLoggedQuery, AccountHoursLoggedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountHoursLoggedQuery, AccountHoursLoggedQueryVariables>(AccountHoursLoggedDocument, options);
        }
export type AccountHoursLoggedQueryHookResult = ReturnType<typeof useAccountHoursLoggedQuery>;
export type AccountHoursLoggedLazyQueryHookResult = ReturnType<typeof useAccountHoursLoggedLazyQuery>;
export type AccountHoursLoggedSuspenseQueryHookResult = ReturnType<typeof useAccountHoursLoggedSuspenseQuery>;
export type AccountHoursLoggedQueryResult = Apollo.QueryResult<AccountHoursLoggedQuery, AccountHoursLoggedQueryVariables>;