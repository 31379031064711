import { useRef, useState } from "react";

import styled from "@emotion/styled";
import { Card as MaterialCard, Popover } from "@mui/material";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { ListSelector } from "components/ListSelector";

import {
  CreatorsInListDocument,
  SearchListsDocument,
  useImportInternalListMutation
} from "../generated";

const Root = styled.div`
  display: inline-block;
  margin-left: 12px;
`;

const Card = styled(MaterialCard)`
  && {
    padding: 15px;
    display: flex;
    flex-direction: column;
    overflow: visible;
    min-width: 300px;
  }
`;

export const ImportInternalList: React.FC<{ internalListId: number; platform: Platform_Enum }> = ({
  internalListId,
  platform
}) => {
  const snackbar = useSnackbar();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedListId, setSelectedListId] = useState<number | null>(null);

  const [importList, { loading: loadingImport }] = useImportInternalListMutation({
    onCompleted: () => snackbar.success("List was imported"),
    onError: error => snackbar.error(error.message),
    refetchQueries: [CreatorsInListDocument, SearchListsDocument]
  });

  const onImport = async () => {
    if (!selectedListId) return;
    await importList({ variables: { src_list_id: selectedListId, dest_list_id: internalListId } });
    setOpen(false);
    setSelectedListId(null);
  };

  return (
    <Root ref={rootRef}>
      <Button disabled={!internalListId} onClick={() => setOpen(true)}>
        <img
          style={{ width: 11, marginRight: 10 }}
          src="/images/relatable_white_r.svg"
          alt="Relatable logo"
        />
        Relatable
      </Button>
      <Popover
        open={open && Boolean(internalListId)}
        anchorEl={rootRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        PaperProps={{ style: { overflow: "visible" } }}
      >
        <Card>
          <ListSelector
            platform={platform}
            listId={selectedListId}
            onChange={listId => setSelectedListId(listId)}
          />
          <Button
            icon={
              <img
                style={{ width: 11, marginRight: 3 }}
                src="/images/relatable_white_r.svg"
                alt="Relatable logo"
              />
            }
            disabled={!selectedListId || loadingImport}
            isLoading={loadingImport}
            onClick={onImport}
            style={{ marginTop: 15 }}
          >
            Import internal list
          </Button>
        </Card>
      </Popover>
    </Root>
  );
};
