import { useState } from "react";

import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";

import { ReportNotFound } from "../common/ReportNotFound";
import { useRequestHypeAuditorReportMutation } from "../generated";
import { YoutubeReport } from "./YoutubeReport";
import { HypeAuditorYoutubeReportDocument, useHypeAuditorYoutubeReportQuery } from "./generated";

export const YoutubeReportContainer = () => {
  const { userId } = useParams<{ userId: string }>();
  const {
    data: {
      hypeauditor: [report] = []
    } = {},
    loading: getHypeauditorReportLoading
  } = useHypeAuditorYoutubeReportQuery({
    variables: { userId: Number(userId) },
    pollInterval: 30 * 1000
  });

  const [error, setError] = useState<string>();
  const [requestHypeauditorReport, { loading: requestHypeauditorReportLoading }] =
    useRequestHypeAuditorReportMutation({
      onError: err => setError(err.message),
      refetchQueries: [HypeAuditorYoutubeReportDocument],
      awaitRefetchQueries: true
    });

  const requestReport = () => {
    setError("");
    if (!report?.user?.id) throw new Error("missing user.id");
    requestHypeauditorReport({
      variables: {
        objects: [
          {
            users_id: report.user.id,
            updated_at: new Date().toISOString(),
            state: "pending_rel"
          }
        ]
      }
    });
  };

  if (requestHypeauditorReportLoading || getHypeauditorReportLoading) {
    return <Loader />;
  }

  if (!report?.data || report?.not_available || error || report?.state === "error") {
    return (
      <ReportNotFound
        requestReport={requestReport}
        username={report?.user?.user_youtube?.channel_title}
        report={report}
        error={error || report?.data?.message}
      />
    );
  }

  return <YoutubeReport data={report.data} />;
};
