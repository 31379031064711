import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContactFormQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
}>;


export type ContactFormQuery = { __typename?: 'Query', contact_form: Array<{ __typename?: 'contact_form', id: number, created_at: string, email: string, full_name?: string | null, ig_username?: string | null, tt_username?: string | null, yt_username?: string | null, sc_username?: string | null, markets: string | string[], verticals: string | number[] }>, verticals: Array<{ __typename?: 'vertical', id: number, name: string }> };


export const ContactFormDocument = gql`
    query contactForm($limit: Int!, $offset: Int!) {
  contact_form(order_by: {created_at: desc}, limit: $limit, offset: $offset) {
    id
    created_at
    email
    full_name
    ig_username
    tt_username
    yt_username
    sc_username
    markets
    verticals
  }
  verticals: vertical {
    id
    name
  }
}
    `;

/**
 * __useContactFormQuery__
 *
 * To run a query within a React component, call `useContactFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactFormQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useContactFormQuery(baseOptions: Apollo.QueryHookOptions<ContactFormQuery, ContactFormQueryVariables> & ({ variables: ContactFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactFormQuery, ContactFormQueryVariables>(ContactFormDocument, options);
      }
export function useContactFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactFormQuery, ContactFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactFormQuery, ContactFormQueryVariables>(ContactFormDocument, options);
        }
export function useContactFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ContactFormQuery, ContactFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContactFormQuery, ContactFormQueryVariables>(ContactFormDocument, options);
        }
export type ContactFormQueryHookResult = ReturnType<typeof useContactFormQuery>;
export type ContactFormLazyQueryHookResult = ReturnType<typeof useContactFormLazyQuery>;
export type ContactFormSuspenseQueryHookResult = ReturnType<typeof useContactFormSuspenseQuery>;
export type ContactFormQueryResult = Apollo.QueryResult<ContactFormQuery, ContactFormQueryVariables>;