import { useCallback, useEffect, useRef, useState } from "react";

import { useLatest } from "./useLatest";

type FnParam = (...args: any[]) => void | Promise<void>;

export function useDebounceFn<T extends FnParam>(fn: T, delay?: number): [T, { loading: boolean }] {
  const fnRef = useLatest(fn);
  const lastParams = useRef<Parameters<T> | null>(null);
  const [pendingCalls, setPendingCalls] = useState(0);

  useEffect(() => {
    if (pendingCalls < 1) return () => {};
    const timer = setTimeout(() => {
      if (!fnRef.current || lastParams.current === null) return;
      const res = fnRef.current(...lastParams.current);
      Promise.resolve(res).then(() => setPendingCalls(0));
      lastParams.current = null;
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [pendingCalls, fnRef, delay]);

  const handleCall = useCallback((...params: Parameters<T>): void => {
    setPendingCalls(prev => prev + 1);
    lastParams.current = params;
  }, []);

  return [handleCall as T, { loading: pendingCalls > 0 }];
}
