import { capitalizeFirstLetter, prettifyPercentages } from "@relatable/helpers";

import * as constants from "modules/hypeAuditor/common/constants";

const { AGE_SECTION_TYPES } = constants;

export const getReachRows = ({
  subscribers_count,
  videos_per_week,
  video_views_avg,
  metricsPeriod
}) => {
  return [
    { name: "Subscribers", size: subscribers_count.value },
    {
      name: "Average video views",
      size: video_views_avg.performance[metricsPeriod].value
    },
    {
      name: "Videos per week",
      size: videos_per_week.performance[metricsPeriod].value,
      value: videos_per_week.performance[metricsPeriod].mark
    }
  ];
};

export const getEngagementRows = ({ ltd_rate, comments_avg, alikes_avg, er, metricsPeriod }) => {
  return [
    {
      name: "Engagement (HA)",
      size: er?.performance?.[metricsPeriod]?.value,
      value: er?.performance?.[metricsPeriod]?.mark
    },
    {
      name: "Average likes on videos",
      size: alikes_avg.performance[metricsPeriod].value,
      value: alikes_avg.performance[metricsPeriod].mark
    },
    {
      name: "Average comments on videos",
      size: comments_avg.performance[metricsPeriod].value,
      value: comments_avg.performance[metricsPeriod].mark
    },
    {
      name: "Like to dislike ratio",
      size: ltd_rate?.performance[metricsPeriod].value,
      value: ltd_rate?.performance[metricsPeriod].mark
    }
  ];
};

export const getGenderRows = ({ demography_by_age, subscribers_count }) => {
  return demography_by_age
    .filter(({ gender }) => gender !== AGE_SECTION_TYPES.ALL)
    .map(({ gender, size }) => {
      return {
        name: capitalizeFirstLetter(gender),
        size,
        percentage: prettifyPercentages({ of: size, total: subscribers_count.value })
      };
    });
};

export const getAgeRows = ({
  demography_by_age,
  subscribers_count,
  type = AGE_SECTION_TYPES.ALL
}) => {
  return demography_by_age
    .find(({ gender }) => gender === type)
    .by_age_group.map(({ group, size }) => {
      const groupKeyFormatted = group.replace("age", "");
      return {
        name: groupKeyFormatted,
        size,
        percentage: prettifyPercentages({ of: size, total: subscribers_count.value })
      };
    });
};

export const getLocationByCountriesRows = ({ subscribers_count, countries }) => {
  return countries.map(({ name, size }) => ({
    name,
    size,
    percentage: prettifyPercentages({ of: size, total: subscribers_count.value })
  }));
};
