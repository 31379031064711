import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Button } from "@relatable/ui/Button";
import { Table } from "@relatable/ui/Table";
import { type FC, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { LocalOffer } from "@mui/icons-material";
import { useMandrillVariablesQuery } from "./generated";

export const MessageTags: FC<{
  campaignUserId: number | null;
}> = ({ campaignUserId }) => {
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const [open, setOpen] = useState(false);

  const { data, loading } = useMandrillVariablesQuery({
    variables: { query: { campaignStub, campaignUserId } },
    skip: !open || !campaignStub || !campaignUserId
  });

  return (
    <>
      <Button
        icon={<LocalOffer />}
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
      >
        View message tags
      </Button>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth="md">
        <DialogTitle>Message tags</DialogTitle>
        <DialogContent>
          <Table
            tableId="campaign-message-tags"
            sortable
            loading={loading}
            rows={
              campaignStub && !loading
                ? (data?.mandrillVariables.map(variable => ({
                    key: variable.key ?? "",
                    value: variable.value ?? ""
                  })) ?? [])
                : []
            }
            columns={[
              { headerName: "Tag", field: "key", renderCell: ({ value }) => `*|${value}|*` },
              {
                headerName: "Value",
                field: "value",
                renderCell({ value }) {
                  if (value.startsWith("http")) {
                    return (
                      <Link to={value} target="_blank" rel="noreferrer">
                        {value}
                      </Link>
                    );
                  }
                  return value;
                }
              }
            ]}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
