import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import type { HypeAuditorSCReport } from "@relatable/helpers/src/hypeauditor/snapchat";
import { Link, useLocation } from "react-router-dom";

import { NavigationButton } from "components/ui/NavigationButton";
import { Title } from "lib/styled";
import type { MetricPeriod } from "src/typings/hypeauditor";

import { SectionTable } from "../common/SectionTable";
import type { HypeAuditorSnapchatReportQuery } from "./generated";
import { getEngagementRows, getReachRows } from "./utils/rows";

export const SnapchatReport: FC<{
  report: HypeAuditorSnapchatReportQuery["hypeauditor"][number];
}> = ({ report }) => {
  const {
    basic: { title, username },
    metrics: { subscribers_count, views_avg, views_count, subscribers_growth_prc, media_per_week }
  } = report.data as HypeAuditorSCReport;

  const { state: locationState } = useLocation() as { state: { from: string } };
  const [metricsPeriod, setMetricsPeriod] = useState<MetricPeriod>("30d");

  const handleMetricsPeriodChange = e => setMetricsPeriod(e.target.value);

  return (
    <Root>
      {["/hypeauditor/snapchat/aggregated"].includes(locationState?.from ?? "") && (
        <NavigationButton direction="back" className="nav-button" />
      )}

      <Title>
        Account analysis for{" "}
        <Link
          to={`https://app.hypeauditor.com/snapchat/${username}/`}
          rel="noreferrer"
          target="_blank"
        >
          @{title}
        </Link>
      </Title>

      <RadioGroup
        row
        aria-label="metrics-period"
        name="metrics-period"
        value={metricsPeriod}
        onChange={handleMetricsPeriodChange}
      >
        <FormControlLabel value="end" control={<Radio color="primary" value="all" />} label="All" />
        <FormControlLabel
          value="end"
          control={<Radio color="primary" value="30d" />}
          label="30 days"
        />
        <FormControlLabel
          value="end"
          control={<Radio color="primary" value="90d" />}
          label="90 days"
        />
        <FormControlLabel
          value="end"
          control={<Radio color="primary" value="180d" />}
          label="180 days"
        />
      </RadioGroup>

      <SectionTable
        sectionName="Reach"
        rows={getReachRows({ subscribers_count, views_avg, metricsPeriod })}
        showHeaders={false}
      />
      <SectionTable
        sectionName="Engagement & interactions"
        rows={getEngagementRows({
          views_count,
          subscribers_growth_prc,
          media_per_week,
          views_avg,
          metricsPeriod
        })}
        showHeaders={false}
      />
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
  margin: auto;

  .nav-button {
    position: absolute;
    left: 0px;
    top: 23px;
  }
`;
