import { useMemo } from "react";

import type { Platform_Enum } from "@relatable/gql/generated-base";

import type { SearchCreatorQueryVariables } from "../generated";

export const useQueryParts = ({
  username,
  minFollowers,
  maxFollowers,
  minEngagement,
  collaborationScore,
  contentQualityScore,
  market,
  vertical,
  platforms,
  tagIds
}: {
  username: string;
  minFollowers: number | null;
  maxFollowers: number | null;
  minEngagement: number | null;
  collaborationScore: number | null;
  contentQualityScore: number | null;
  market: string | null;
  vertical: string | null;
  platforms: Platform_Enum[];
  tagIds: number[] | null;
}) => {
  const usernameQuery = useMemo<SearchCreatorQueryVariables["query"]>(
    () =>
      username
        ? {
            _or: [
              { user_instagram: { username: { _ilike: `%${username}%` } } },
              { user_tiktok: { username: { _ilike: `%${username}%` } } },
              { user_youtube: { channel_id: { _ilike: `%${username}%` } } },
              { user_snapchat: { username: { _ilike: `%${username}%` } } }
            ]
          }
        : {},
    [username]
  );

  const followersQuery = useMemo<SearchCreatorQueryVariables["query"]>(
    () =>
      minFollowers || maxFollowers
        ? {
            _or: [
              {
                user_instagram: {
                  followed_by: {
                    ...(minFollowers ? { _gte: minFollowers } : {}),
                    ...(maxFollowers ? { _lte: maxFollowers } : {}),
                    _is_null: false
                  }
                }
              },
              {
                user_tiktok: {
                  followed_by: {
                    ...(minFollowers ? { _gte: minFollowers } : {}),
                    ...(maxFollowers ? { _lte: maxFollowers } : {}),
                    _is_null: false
                  }
                }
              },
              {
                user_youtube: {
                  subscribers: {
                    ...(minFollowers ? { _gte: minFollowers.toString() } : {}),
                    ...(maxFollowers ? { _lte: maxFollowers.toString() } : {}),
                    _is_null: false
                  }
                }
              }
            ]
          }
        : {},
    [minFollowers, maxFollowers]
  );

  const engagementQuery = useMemo<SearchCreatorQueryVariables["query"]>(
    () =>
      minEngagement
        ? { hypeauditor: { avg_engagement: { _gte: minEngagement, _is_null: false } } }
        : {},
    [minEngagement]
  );

  const collaborationScoreQuery = useMemo<SearchCreatorQueryVariables["query"]>(
    () =>
      collaborationScore
        ? {
            campaign_users: {
              collaboration_score: { _gte: collaborationScore }
            }
          }
        : {},
    [collaborationScore]
  );

  const contentQualityScoreQuery = useMemo<SearchCreatorQueryVariables["query"]>(
    () =>
      contentQualityScore
        ? {
            campaign_users: {
              content_quality_score: { _gte: contentQualityScore }
            }
          }
        : {},
    [contentQualityScore]
  );

  const platformQuery = useMemo<SearchCreatorQueryVariables["query"]>(() => {
    if (!platforms.length) return {};
    const filters = [] as SearchCreatorQueryVariables["query"][];

    if (platforms.includes("instagram")) {
      filters.push({
        instagram_id: { _is_null: false }
      });
    }

    if (platforms.includes("tiktok")) {
      filters.push({
        tiktok_id: { _is_null: false }
      });
    }

    if (platforms.includes("youtube")) {
      filters.push({
        youtube_id: { _is_null: false }
      });
    }

    if (platforms.includes("snapchat")) {
      filters.push({
        snapchat_id: { _is_null: false }
      });
    }

    return {
      _or: filters
    };
  }, [platforms]);

  const marketQuery = useMemo<SearchCreatorQueryVariables["query"]>(() => {
    if (!market) return {};

    return {
      campaign_users: { user: { country: { _eq: market } } }
    };
  }, [market]);

  const verticalQuery = useMemo<SearchCreatorQueryVariables["query"]>(() => {
    if (!vertical) return {};

    return {
      campaign_users: { campaign: { vertical_ids_string: { _like: `%|${vertical}|%` } } }
    };
  }, [vertical]);

  const tagsQuery = useMemo<SearchCreatorQueryVariables["query"]>(() => {
    if (!tagIds) return {};
    return tagIds.map(id => ({
      _or: {
        tag_ids_string: { _like: `%|${id}|%` }
      }
    }));
  }, [tagIds]);

  return {
    usernameQuery,
    followersQuery,
    engagementQuery,
    collaborationScoreQuery,
    contentQualityScoreQuery,
    platformQuery,
    marketQuery,
    verticalQuery,
    tagsQuery
  };
};
