import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchProjectsQueryVariables = Types.Exact<{
  where: Types.Project_Bool_Exp;
}>;


export type SearchProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'project', id: number, name: string, stub: string }> };

export type AccountActiveProjectsQueryVariables = Types.Exact<{
  accountId: Types.Scalars['Int']['input'];
}>;


export type AccountActiveProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'project', id: number, stub: string, name: string }> };

export type AccountTimeRecordsQueryVariables = Types.Exact<{
  accountId: Types.Scalars['Int']['input'];
  date: Types.Scalars['date']['input'];
}>;


export type AccountTimeRecordsQuery = { __typename?: 'Query', time_tracking: Array<{ __typename?: 'time_tracking', id: number, hours: number, project: { __typename?: 'project', id: number, stub: string, name: string } }> };

export type InsertTimeTrackingMutationVariables = Types.Exact<{
  object: Types.Time_Tracking_Insert_Input;
}>;


export type InsertTimeTrackingMutation = { __typename?: 'Mutation', insert_time_tracking_one?: { __typename?: 'time_tracking', id: number } | null };

export type DeleteTimeTrackingMutationVariables = Types.Exact<{
  accountId: Types.Scalars['Int']['input'];
  projectId: Types.Scalars['Int']['input'];
  date: Types.Scalars['date']['input'];
}>;


export type DeleteTimeTrackingMutation = { __typename?: 'Mutation', delete_time_tracking?: { __typename?: 'time_tracking_mutation_response', affected_rows: number } | null };


export const SearchProjectsDocument = gql`
    query searchProjects($where: project_bool_exp!) {
  projects: project(
    where: $where
    limit: 30
    order_by: {created_at: desc_nulls_last}
  ) {
    id
    name
    stub
  }
}
    `;

/**
 * __useSearchProjectsQuery__
 *
 * To run a query within a React component, call `useSearchProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProjectsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSearchProjectsQuery(baseOptions: Apollo.QueryHookOptions<SearchProjectsQuery, SearchProjectsQueryVariables> & ({ variables: SearchProjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProjectsQuery, SearchProjectsQueryVariables>(SearchProjectsDocument, options);
      }
export function useSearchProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProjectsQuery, SearchProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProjectsQuery, SearchProjectsQueryVariables>(SearchProjectsDocument, options);
        }
export function useSearchProjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchProjectsQuery, SearchProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchProjectsQuery, SearchProjectsQueryVariables>(SearchProjectsDocument, options);
        }
export type SearchProjectsQueryHookResult = ReturnType<typeof useSearchProjectsQuery>;
export type SearchProjectsLazyQueryHookResult = ReturnType<typeof useSearchProjectsLazyQuery>;
export type SearchProjectsSuspenseQueryHookResult = ReturnType<typeof useSearchProjectsSuspenseQuery>;
export type SearchProjectsQueryResult = Apollo.QueryResult<SearchProjectsQuery, SearchProjectsQueryVariables>;
export const AccountActiveProjectsDocument = gql`
    query accountActiveProjects($accountId: Int!) {
  projects: project(
    where: {archived: {_eq: false}, project_team_members: {accounts_id: {_eq: $accountId}}}
    order_by: {name: asc}
  ) {
    id
    stub
    name
  }
}
    `;

/**
 * __useAccountActiveProjectsQuery__
 *
 * To run a query within a React component, call `useAccountActiveProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountActiveProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountActiveProjectsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountActiveProjectsQuery(baseOptions: Apollo.QueryHookOptions<AccountActiveProjectsQuery, AccountActiveProjectsQueryVariables> & ({ variables: AccountActiveProjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountActiveProjectsQuery, AccountActiveProjectsQueryVariables>(AccountActiveProjectsDocument, options);
      }
export function useAccountActiveProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountActiveProjectsQuery, AccountActiveProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountActiveProjectsQuery, AccountActiveProjectsQueryVariables>(AccountActiveProjectsDocument, options);
        }
export function useAccountActiveProjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountActiveProjectsQuery, AccountActiveProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountActiveProjectsQuery, AccountActiveProjectsQueryVariables>(AccountActiveProjectsDocument, options);
        }
export type AccountActiveProjectsQueryHookResult = ReturnType<typeof useAccountActiveProjectsQuery>;
export type AccountActiveProjectsLazyQueryHookResult = ReturnType<typeof useAccountActiveProjectsLazyQuery>;
export type AccountActiveProjectsSuspenseQueryHookResult = ReturnType<typeof useAccountActiveProjectsSuspenseQuery>;
export type AccountActiveProjectsQueryResult = Apollo.QueryResult<AccountActiveProjectsQuery, AccountActiveProjectsQueryVariables>;
export const AccountTimeRecordsDocument = gql`
    query accountTimeRecords($accountId: Int!, $date: date!) {
  time_tracking(where: {account_id: {_eq: $accountId}, date: {_eq: $date}}) {
    id
    hours
    project {
      id
      stub
      name
    }
  }
}
    `;

/**
 * __useAccountTimeRecordsQuery__
 *
 * To run a query within a React component, call `useAccountTimeRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTimeRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTimeRecordsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAccountTimeRecordsQuery(baseOptions: Apollo.QueryHookOptions<AccountTimeRecordsQuery, AccountTimeRecordsQueryVariables> & ({ variables: AccountTimeRecordsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountTimeRecordsQuery, AccountTimeRecordsQueryVariables>(AccountTimeRecordsDocument, options);
      }
export function useAccountTimeRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountTimeRecordsQuery, AccountTimeRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountTimeRecordsQuery, AccountTimeRecordsQueryVariables>(AccountTimeRecordsDocument, options);
        }
export function useAccountTimeRecordsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AccountTimeRecordsQuery, AccountTimeRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountTimeRecordsQuery, AccountTimeRecordsQueryVariables>(AccountTimeRecordsDocument, options);
        }
export type AccountTimeRecordsQueryHookResult = ReturnType<typeof useAccountTimeRecordsQuery>;
export type AccountTimeRecordsLazyQueryHookResult = ReturnType<typeof useAccountTimeRecordsLazyQuery>;
export type AccountTimeRecordsSuspenseQueryHookResult = ReturnType<typeof useAccountTimeRecordsSuspenseQuery>;
export type AccountTimeRecordsQueryResult = Apollo.QueryResult<AccountTimeRecordsQuery, AccountTimeRecordsQueryVariables>;
export const InsertTimeTrackingDocument = gql`
    mutation insertTimeTracking($object: time_tracking_insert_input!) {
  insert_time_tracking_one(
    object: $object
    on_conflict: {constraint: time_tracking_unique, update_columns: [hours]}
  ) {
    id
  }
}
    `;
export type InsertTimeTrackingMutationFn = Apollo.MutationFunction<InsertTimeTrackingMutation, InsertTimeTrackingMutationVariables>;

/**
 * __useInsertTimeTrackingMutation__
 *
 * To run a mutation, you first call `useInsertTimeTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTimeTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTimeTrackingMutation, { data, loading, error }] = useInsertTimeTrackingMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertTimeTrackingMutation(baseOptions?: Apollo.MutationHookOptions<InsertTimeTrackingMutation, InsertTimeTrackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertTimeTrackingMutation, InsertTimeTrackingMutationVariables>(InsertTimeTrackingDocument, options);
      }
export type InsertTimeTrackingMutationHookResult = ReturnType<typeof useInsertTimeTrackingMutation>;
export type InsertTimeTrackingMutationResult = Apollo.MutationResult<InsertTimeTrackingMutation>;
export type InsertTimeTrackingMutationOptions = Apollo.BaseMutationOptions<InsertTimeTrackingMutation, InsertTimeTrackingMutationVariables>;
export const DeleteTimeTrackingDocument = gql`
    mutation deleteTimeTracking($accountId: Int!, $projectId: Int!, $date: date!) {
  delete_time_tracking(
    where: {account_id: {_eq: $accountId}, project_id: {_eq: $projectId}, date: {_eq: $date}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteTimeTrackingMutationFn = Apollo.MutationFunction<DeleteTimeTrackingMutation, DeleteTimeTrackingMutationVariables>;

/**
 * __useDeleteTimeTrackingMutation__
 *
 * To run a mutation, you first call `useDeleteTimeTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeTrackingMutation, { data, loading, error }] = useDeleteTimeTrackingMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      projectId: // value for 'projectId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDeleteTimeTrackingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeTrackingMutation, DeleteTimeTrackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimeTrackingMutation, DeleteTimeTrackingMutationVariables>(DeleteTimeTrackingDocument, options);
      }
export type DeleteTimeTrackingMutationHookResult = ReturnType<typeof useDeleteTimeTrackingMutation>;
export type DeleteTimeTrackingMutationResult = Apollo.MutationResult<DeleteTimeTrackingMutation>;
export type DeleteTimeTrackingMutationOptions = Apollo.BaseMutationOptions<DeleteTimeTrackingMutation, DeleteTimeTrackingMutationVariables>;